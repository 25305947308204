import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
  animacao: {
    background: 'linear-gradient(45deg, #c7c7c7, #8a8a8a26, #cbcbcb24)',
    backgroundSize: '400% 400%',
    animation: '$gradient 3s ease infinite',
  },
  espaco: {
    marginTop: '25px',
  },
  box_grande: {
    height: '1000px',
    marginRight: '15px',
    marginTop: '7px',
    marginBottom: '5px',
    width: '100%',
    borderRadius: '5px',
  },
  texto: {
    height: '55px',
    marginRight: '15px',
    marginTop: '7px',
    marginBottom: '5px',
    width: '100%',
    borderRadius: '5px',
  },
  titulos: {
    height: '25px',
    marginRight: '7px',
    marginTop: '7px',
    marginBottom: '7px',
    borderRadius: '5px',
  },
})

export function VisualizarFichaPlaceholderComponent() {
  const classes = useStyles()
  return (
    <div className={`col-md-12 ${classes.espaco}`}>      
      <div className="col-md-12">
        <div className={`${classes.texto} ${classes.animacao}`} />
      </div>
      <div className="row">
        <div className="col-md-9">
          <div className={`${classes.box_grande} ${classes.animacao}`} />
        </div>
        <div className="col-md-3">
          <div className={`${classes.titulos} ${classes.animacao}`} />
          <div className={`${classes.texto} ${classes.animacao}`} />
          <div className={`${classes.texto} ${classes.animacao}`} />
        </div>
      </div>

    </div>
  )
}
