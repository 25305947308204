import { fatosApi } from '../../api/fatosApi'
import { processosApi } from '../../api/processosApi'
import { MontarQueryRecuperarFatosPayload } from '../../api/queries/MontarQueryRecuperarFatosPayload'
import { recuperarAliasStakeholderPorId } from '../stakeholder/recuperarAliasStakeholderPorId'
import { TipoFato } from '../../domain/TipoFato'
import { CountResponse, SearchResponse } from '../../infra/elastic/elasticTypes'

export async function recuperarContadoresDeTiposFatos(payload: MontarQueryRecuperarFatosPayload) {
  const stakeholdersComAlias = await recuperarAliasStakeholderPorId(payload.idsAtores, payload.idsEntidades)
  const [
    { body: respostaIndiceFatos },
    { body: respostaIndiceProcessos },
  ] = await Promise.all([
    fatosApi.buscarFatos(payload, stakeholdersComAlias.atores, stakeholdersComAlias.entidades),
    processosApi.buscarContadorProcessosPorFiltro(payload, stakeholdersComAlias.atores, stakeholdersComAlias.entidades),
  ])
  return montarViewModel(respostaIndiceFatos, respostaIndiceProcessos)
}

function montarViewModel(respostaIndiceFatos: SearchResponse, respostaIndiceProcessos: CountResponse) {
  const lista = respostaIndiceFatos.aggregations.fontes_fatos.buckets
  return {
    totalClipping: getTotalPorTipo(lista, 'NOTICIA_CLIPPING'),
    totalUniao: getTotalPorTipo(lista, 'NOTICIA_UNIAO'),
    totalEventos: getTotalPorTipo(lista, 'EVENTO_RELACIONA'),
    totalProposicoes: getTotalPorTipo(lista, 'PROPOSICAO_LEGISLATIVA'),
    totalProcessos: respostaIndiceProcessos.count,
  }
}

function getTotalPorTipo(lista: any[], tipo: TipoFato) {
  const obj = lista.find(item => item.key === tipo)
  if (!obj) {
    return 0
  }
  return obj.doc_count
}
