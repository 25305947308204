import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/styles'
import { HeaderComponent } from '../ui-components/comum/HeaderComponent'
import { AppTheme } from '../../style/theme'
import { MenuContainer } from '../containers/menu/MenuContainer'

const useStyles = makeStyles((theme: AppTheme) => ({
  layout: {
    minHeight: '100%',
    height: 'auto',
    width: '100%',
  },
  header: {
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
  },
  content: {
    backgroundColor: '#fff',
    '@media screen and (max-width:991px)': {
      paddingLeft: '35px',
      paddingRight: '35px',
    },
  },
  ajusteRow: {
    marginRight: '0px',
  },
}))

export interface MainLayoutProps {
  children: ReactNode
}

export function MenuHeaderLayout({ children }: MainLayoutProps) {
  const classes = useStyles()
  return (
    <div className={classes.layout}>
      <div className={classes.header}>
        <HeaderComponent />
      </div>
      <div className={`${classes.ajusteRow} row`}>
        <div className="col-lg-2 col-md-12 col-ls-12">
          <MenuContainer />
        </div>
        <div className={`${classes.content} col-lg-10 col-md-12 col-ls-12`}>
          {children}
        </div>
      </div>
    </div>
  )
}
