import React from 'react'
import { TipoTrechoFicha } from 'bff/src/main/domain/TipoTrechoFicha'
import { BotaoAdicionarTrechoNoDocumentoFichaComponent } from './BotaoAdicionarTrechoNoDocumentoFichaComponent'
import { TextfieldTrechoTextualFichaComponent } from './TextfieldTrechoTextualFichaComponent'
import { useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSpinningPromise } from '../../../infra/libs/react-swearing/SpinnerScreenProvider'
import { fichaThunkCreators } from '../../../store/actions/fichaActions'

interface BotaoAdicionarTrechoNoDocumentoFichaContainerProps {
  posicao: number
  fichaId: number
}

type TipoContainer = 'ACOES' | 'TEXTFIELD'

export function BotaoAdicionarTrechoNoDocumentoFichaContainer({ posicao, fichaId }: BotaoAdicionarTrechoNoDocumentoFichaContainerProps) {
  const dispatch = useTypedDispatch()
  const [tipoContainer, setTipoContainer] = React.useState<TipoContainer>('ACOES')
  const [tipoTrechoTextual, setTipoTrechoTextual] = React.useState<TipoTrechoFicha>()


  const { run: onClickCriarTrechoTextual } = useSpinningPromise((textoTrecho: string) => dispatch(fichaThunkCreators.adicionarTrechoTextualFicha(
    fichaId,
    // @ts-ignore
    { tipoTrechoFicha: tipoTrechoTextual, conteudo: textoTrecho, ordemTrecho: posicao },
  )), [tipoTrechoTextual])
  
  const onClickTipoTrecho = (tipoTrecho: TipoTrechoFicha) => {
    setTipoTrechoTextual(tipoTrecho)
    setTipoContainer('TEXTFIELD')
  }

  const onCancelar = () => {
    setTipoContainer('ACOES')
  }

  if (tipoContainer === 'ACOES' || !tipoTrechoTextual) {
    return <BotaoAdicionarTrechoNoDocumentoFichaComponent onClick={onClickTipoTrecho} />
  }

  return (
    <TextfieldTrechoTextualFichaComponent 
      tipoTrechoTextual={tipoTrechoTextual} 
      onConfirm={onClickCriarTrechoTextual} 
      onCancel={onCancelar}
      labelButtonCancel="Cancelar"
      labelButtonConfirm="Adicionar"
    />
  )
}
