import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { MapaColor } from '../../../style/theme'

const useStyles = makeStyles({
  box: {
    backgroundColor: MapaColor.neutralMediumLight,
    display: 'inline-block',
    marginRight: '8px',
    marginBottom: '8px',
    borderRadius: '3px',
    padding: '4px 10px',
  },
  label: {
    fontSize: '14px',
  },
  icon: {
    color: MapaColor.neutralDark,
    marginLeft: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

interface ClosabledLabelProps {
  label: string
  onClose: () => void
}

export function ClosableLabelComponent({ label, onClose }: ClosabledLabelProps) {
  const classes = useStyles()
  return (
    <div className={classes.box}>
      <span className={classes.label}>{label}</span>
      <i
        tabIndex={0}
        role="button"
        aria-label="Close"
        className={`${classes.icon} fas fa-times`}
        onClick={onClose}
        onKeyPress={onClose}
      />
    </div>
  )
}
