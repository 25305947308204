import client from 'cloud-config-client'
import { loadVaultConfig } from '../vault/vaultLoader'

const getCloudConfig = async (application: string, profiles: string|string[]) => client.load({
  endpoint: 'http://spring-cloud-config.prod.rancher.tcu.gov.br',
  application,
  profiles,
})

let CLOUD_CONFIG
let VAULT_CONFIG: any

export const loadVault = async (): Promise<void> => {
  console.info('Carregando vault config')
  try {
    VAULT_CONFIG = await loadVaultConfig()
    if (VAULT_CONFIG.status !== 200) console.log(`erro.${VAULT_CONFIG.data}`)
  } catch (err) {
    console.error('Erro ao carregar vault config:', err)
  }
  return VAULT_CONFIG
}

export const springCloudConfig = {

  load: async (applicationName, profile) => {
    console.info('carrega cloud')
    CLOUD_CONFIG = await getCloudConfig(applicationName, profile)
    console.info(CLOUD_CONFIG)
    console.info('carrega vault')
    VAULT_CONFIG = await loadVault()
    console.info(VAULT_CONFIG)
  },

  getProp: (propName) => {
    try {
      console.info('Vault status: ' + VAULT_CONFIG.status)
      if (VAULT_CONFIG.status === 200) {
        console.info('Entrou vault')
        console.info(VAULT_CONFIG.data.data.data)
        console.info('Nome attibuto ')
        console.info(propName)
        const value = VAULT_CONFIG.data.data.data[propName]
        if (value !== null && value !== undefined) {
          console.info('Valor vault:' + value)
          console.info(value)
          return value
        }
        console.info('Valor cloud 1:')
        console.info(CLOUD_CONFIG && CLOUD_CONFIG.get && CLOUD_CONFIG.get(propName))
        return CLOUD_CONFIG && CLOUD_CONFIG.get && CLOUD_CONFIG.get(propName)
      }
      console.info('Valor cloud 2:')
      console.info(CLOUD_CONFIG && CLOUD_CONFIG.get && CLOUD_CONFIG.get(propName))
      return CLOUD_CONFIG && CLOUD_CONFIG.get && CLOUD_CONFIG.get(propName)
    } catch (err) {
      console.info(err)
      console.info('Valor cloud 3:')
      console.info(CLOUD_CONFIG && CLOUD_CONFIG.get && CLOUD_CONFIG.get(propName))
      return CLOUD_CONFIG && CLOUD_CONFIG.get && CLOUD_CONFIG.get(propName)
    }
  },

}
