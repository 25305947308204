import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { MapaColor } from '../../../style/theme'

const useStyles = makeStyles({
  contador: {
    marginLeft: '10px',
    padding: '3px 8px',
    background: MapaColor.neutralMediumLight,
    color: MapaColor.neutralDark,
    borderRadius: '3px',
  },
})

export function CaixaComNumeroComponent({ numero } : { numero: number }) {
  const classes = useStyles()
  if (!numero) {
    return null
  }
  return <span className={classes.contador}>{numero}</span>
}
