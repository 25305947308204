import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import { IconFichaComponent } from '../icons/IconFichaComponent'
import { OpcoesFichaContainer } from '../../containers/ficha/OpcoesFichaContainer'


const useStyles = makeStyles({
  boxFichas: {
    width: '100%',
    height: '100%',
    borderRadius: '3px',
    border: '1px solid #c4c4c4',
    display: 'inline-block',
    padding: '16px',
    '&:hover': {
      cursor: 'pointer',
      '& $opcoes': {
        visibility: 'visible',
        marginLeft: '85%',
      },
    },
  },
  iconBox: {
    textAlign: 'center',
    padding: '16px 45px 40px 45px',
  },
  tituloFicha: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#434343',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  dataLabel: {
    fontSize: '14px',
    color: '#434343',
  },
  linkFicha: {
    display: 'inline-block',
  },
  opcoes: {
    visibility: 'hidden',
  },
})

export interface FichaComponentProps {
  fichaId: number
  tituloFicha: string
  dataLabelUltimoEvento: string
}

export function BoxFichaComponent({ fichaId, tituloFicha, dataLabelUltimoEvento }: FichaComponentProps) {
  const classes = useStyles()
  return (
    <div className={classes.boxFichas}>
      <OpcoesFichaContainer fichaId={fichaId} tituloFicha={tituloFicha} className={classes.opcoes} />
      <Link to={`/fichas/${fichaId}`}>
        <div className={classes.iconBox}>
          <IconFichaComponent fill="#2F5DC9" height="70" width="70" />
        </div>
        <div>
          <h4 className={classes.tituloFicha}>{tituloFicha}</h4>
          <p className={classes.dataLabel}>
            <span>{dataLabelUltimoEvento}</span>
          </p>
        </div>
      </Link>
    </div>
  )
}
