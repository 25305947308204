import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { InformacaoAdicionalPerfilHeaderComponent } from '../../comum/InformacaoAdicionalPerfilHeaderComponent'
import { AvatarStakeholderComponent } from '../AvatarStakeholderComponent'

const useStyles = makeStyles({
  alingAtor: {
    margin: 'auto 0',
    '@media screen and (max-width:567px)': {
      margin: '30px 0 0 0',
    },
  },
  descricaoAtor: {
    fontSize: '14px',
    marginTop: '20px',
    color: '#525252',
  },
  iconesAling: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    marginBottom: '10px',
    color: '#525252',
    '& i:nth-last-child(1)': {
      display: 'none',
    },
  },
  tituloInformacoes: {
    fontSize: '14px',
    color: '#525252',
  },
  spaceTop: {
    paddingTop: '36px',
  },
})

export interface PerfilAtorHeaderProps {
  nome: string
  urlImagem: string
  stakeholderInterno: boolean
  nomeCompleto: string
  cargo: string
  orgao: string
  descricao: string
  dataNascimento: string
  email: string
  telefone: string
  endereco: string
  cep: string
}

export function PerfilAtorHeaderComponent(props: PerfilAtorHeaderProps) {
  const classes = useStyles()
  return (
    <div className={`${classes.spaceTop} row`}>
      <AvatarStakeholderComponent
        nome={props.nome}
        urlImagem={props.urlImagem}
        stakeholderInterno={props.stakeholderInterno}
        nomeCompleto={props.nomeCompleto}
        cargo={props.cargo}
        orgao={props.orgao}
      />
      {renderDescricao(props, classes)}
      {renderInformacoesDeContato(props, classes)}
    </div>
  )
}

function renderInformacoesDeContato(props: PerfilAtorHeaderProps, classes) {
  return (
    props.telefone || props.email ? (
      <div className={`${classes.alingAtor} col-md-12 col-lg-12 col-xl-3`}>
        <h4 className={classes.tituloInformacoes}>Informações de Contato</h4>
        <div className={classes.iconesAling}>
          {
            Array.isArray(props.telefone)
              ? (
                <span>
                  <i className="fas fa-phone-alt" />
                  <InformacaoAdicionalPerfilHeaderComponent texto={props.telefone[0]} />
                </span>
              )
              : (
                <span>
                  <i className="fas fa-phone-alt" />
                  <InformacaoAdicionalPerfilHeaderComponent texto={props.telefone} />
                </span>
              )
          }
        </div>
        <div className={classes.iconesAling}>
          <i className="far fa-envelope" />
          <InformacaoAdicionalPerfilHeaderComponent texto={props.email} email />
        </div>
      </div>
    ) : null
  )
}

function renderDescricao(props: PerfilAtorHeaderProps, classes) {
  return (
    <div className={`${classes.alingAtor} col-md-12 col-lg-12 col-xl-3`}>
      {
        props.descricao ? (
          <p className={classes.descricaoAtor}>
            {props.descricao}
          </p>
        ) : null
      }
    </div>
  )
}
