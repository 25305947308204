import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  box: {
    backgroundColor: '#d9e3fa',
    display: 'inline-block',
    marginRight: '8px',
    marginTop: '8px',
    marginBottom: '8px',
    borderRadius: '3px',
    padding: '4px 10px',
  },
  label: {
    fontSize: '14px',
  },
})

export function TagFiltrosSelecionadosCenarioComponet({ label }: { label: string }) {
  const classes = useStyles()
  if (!label) {
    return null
  }
  return (
    <div className={classes.box}>
      <span className={classes.label}>{label}</span>
    </div>
  )
}
