import React from 'react'
import { History } from 'history'
import { useHistory } from 'react-router-dom'
import { TIPOS_FATO } from 'bff/src/main/domain/TipoFato'
import { updatePropertyInQueryParams } from 'bff/src/main/util/urlUtil'
import { CheckboxesOption } from '../../ui-components/comum/CheckboxesComponent'
import { useQueryParamAsArrayOfString } from '../../../infra/libs/react-location-hooks/routerHooks'
import { CheckboxesDefaultStyleComponent } from '../../ui-components/filtro/CheckboxesDefaultStyleComponent'
import {
  QUERY_PARAM_FILTRO_ESTADO_PROCESSO,
  QUERY_PARAM_FILTRO_RELATOR,
  QUERY_PARAM_FILTRO_TIPO_EVENTOS,
  QUERY_PARAM_FILTRO_TIPO_FATOS,
  QUERY_PARAM_FILTRO_TIPO_PROCESSOS,
} from '../../../util/constantesQueryParams'


interface CheckboxesFilterContainerProps {
  filterName: string
  filterLabel: string
  options: CheckboxesOption[]
  withOptionsFilter?: boolean
}

export function CheckboxesFilterContainer(
  {
    filterName, filterLabel, options, withOptionsFilter,
  }: CheckboxesFilterContainerProps,
) {
  const history = useHistory()
  const filterValue = useQueryParamAsArrayOfString(filterName)
  const filtroTiposFatos = useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_TIPO_FATOS)

  return (
    <CheckboxesDefaultStyleComponent
      label={filterLabel}
      value={filterValue}
      options={options}
      onChange={newValue => atualizarFiltroNoLocation({
        filterName, filterValue: newValue, history, filtroTiposFatos,
      })}
      withOptionsFilter={withOptionsFilter}
      withScrollArea
    />
  )
}

async function atualizarFiltroNoLocation(
  {
    filterName, filterValue, history, filtroTiposFatos,
  }: { filterName: string, filterValue: any[], history: History, filtroTiposFatos: string[] },
) {
  const { pathname, search } = history.location
  let stringfiedQueryParams = updatePropertyInQueryParams(filterName, filterValue, search)
  if (filterName === QUERY_PARAM_FILTRO_TIPO_FATOS) {
    stringfiedQueryParams = tratarSelecaoTipoFato(filterValue, stringfiedQueryParams)
  }
  if (filterName === QUERY_PARAM_FILTRO_TIPO_EVENTOS) {
    stringfiedQueryParams = tratarSelecaoTipoEvento(filtroTiposFatos, stringfiedQueryParams)
  }
  if (filterName === QUERY_PARAM_FILTRO_TIPO_PROCESSOS 
    || filterName === QUERY_PARAM_FILTRO_RELATOR 
    || filterName === QUERY_PARAM_FILTRO_ESTADO_PROCESSO) {
    stringfiedQueryParams = tratarSelecaoTipoProcessoEstadoProcessoRelatorProcesso(filtroTiposFatos, stringfiedQueryParams)
  }
  const url = `${pathname}?${stringfiedQueryParams}`
  history.push(url)
}

function tratarSelecaoTipoFato(filterValue: any[], stringfiedQueryParams: string) {
  let novoQueryParams = stringfiedQueryParams
  if (!filterValue.find(item => item === TIPOS_FATO.EVENTO_RELACIONA.id)) {
    novoQueryParams = updatePropertyInQueryParams(QUERY_PARAM_FILTRO_TIPO_EVENTOS, undefined, novoQueryParams)
  }
  if (!filterValue.find(item => item === TIPOS_FATO.PROCESSO.id)) {
    novoQueryParams = updatePropertyInQueryParams(QUERY_PARAM_FILTRO_TIPO_PROCESSOS, undefined, novoQueryParams)
  }
  return novoQueryParams
}

function tratarSelecaoTipoEvento(filtroTiposFatos: string[], stringfiedQueryParams: string) {
  let tiposFatos: string[]
  if (filtroTiposFatos.find(tipo => tipo === TIPOS_FATO.EVENTO_RELACIONA.id)) {
    tiposFatos = [...filtroTiposFatos]
  } else {
    tiposFatos = [...filtroTiposFatos, TIPOS_FATO.EVENTO_RELACIONA.id]
  }
  return updatePropertyInQueryParams(QUERY_PARAM_FILTRO_TIPO_FATOS, tiposFatos, stringfiedQueryParams)
}

function tratarSelecaoTipoProcessoEstadoProcessoRelatorProcesso(filtroTiposFatos: string[], stringfiedQueryParams: string) {
  let tiposFatos: string[]
  if (filtroTiposFatos.find(tipo => tipo === TIPOS_FATO.PROCESSO.id)) {
    tiposFatos = [...filtroTiposFatos]
  } else {
    tiposFatos = [...filtroTiposFatos, TIPOS_FATO.PROCESSO.id]
  }
  return updatePropertyInQueryParams(QUERY_PARAM_FILTRO_TIPO_FATOS, tiposFatos, stringfiedQueryParams)
}
