import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TitleComponent } from '../ui-components/head/TitleComponent'
import { ListaResultadoStakeholdersContainer } from '../containers/stakeholder/ListaResultadoStakeholdersContainer'

const useStyles = makeStyles({
  root: {
    marginTop: '18px',
  },
})

export function ResultadosStakeholdersView() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <TitleComponent title="Resultados" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-ls-12">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-ls-12">
                <ListaResultadoStakeholdersContainer />
              </div>
              <div className="col-lg-4 col-md-12 col-ls-12" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
