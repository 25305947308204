import {
  CriarFavoritoEndpoint,
  ExcluirFavoritoEndpoint,
  BuscarMeusFavoritosEndpoint,
  ContarMeusFavoritosEndpoint,
} from 'bff/src/main/controllers/favorito/favoritosEndpoints'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'
import { alertActionCreators } from './alertActions'

export const favoritoThunkCreators = {

  criarFavorito: (body: CriarFavoritoEndpoint.Body) => createBffRequestThunk({
    request: {
      endpoint: CriarFavoritoEndpoint.definition,
      body,
    },
    actions: {
      success: () => alertActionCreators.showSuccess('Item favoritado com sucesso!'),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar favoritar o item. Por favor tente novamente.'),
    },
  }),

  excluirFavorito: (idItemFavorito: string) => createBffRequestThunk({
    request: {
      endpoint: ExcluirFavoritoEndpoint.definition,
      params: { idItemFavorito },
    },
    actions: {
      success: () => alertActionCreators.showSuccess('Item desfavoritado com sucesso!'),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar desfavoritar o item. Por favor tente novamente.'),
    },
  }),

  recuperarMeusFavoritos: (tipoFavorito: string) => createBffRequestThunk({
    request: {
      endpoint: BuscarMeusFavoritosEndpoint.definition,
      params: { tipoFavorito },
    },
    actions: {
      error: () => alertActionCreators.showError('Ocorreu um erro ao consultar os favoritos.'),
    },
  }),

  contarMeusFavoritos: () => createBffRequestThunk({
    request: {
      endpoint: ContarMeusFavoritosEndpoint.definition,
    },
    actions: {
      error: () => alertActionCreators.showError('Ocorreu um erro ao contar os favoritos do usuário.'),
    },
  }),

}
