import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { CaixaComNumeroComponent } from './CaixaComNumeroComponent'
import { TituloComponent } from './TituloComponent'

const useStyles = makeStyles({
  texto: {
    fontSize: '16px',
  },
  textoListaWrapper: {
    '&:focus': {
      outline: 'none',
    },
  },
  titulo: {
    marginBottom: '24px',
  },
})

export interface TituloListaProps {
  titulo: string
  lista?: string[]
  exibirContador: boolean
  className?: string
}

export function TituloListaComponent({ titulo, lista, exibirContador = true, className }: TituloListaProps) {
  const history = useHistory()
  const classes = useStyles()
  if (!lista || lista.length < 1) {
    return null
  }
  
  function handleClick(e) {
    if (e.target.dataset.url) {
      history.push(e.target.dataset.url)
    }
  }
  
  return (
    <div className={className}>
      <TituloComponent
        className={classes.titulo}
        titulo={titulo}
        complemento={exibirContador ? <CaixaComNumeroComponent numero={lista.length} /> : null}
      />
      <ul className={classes.texto}>
        {lista?.map(item => (
          <li key={item}>
            <div
              className={classes.textoListaWrapper}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: item }}
              tabIndex={0}
              role="link"
              aria-label="Close"
              onClick={handleClick}
              onKeyPress={handleClick}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}
