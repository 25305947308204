import React from 'react'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSpinningPromise, useSpinningPromiseEffect } from '../../../infra/libs/react-swearing/SpinnerScreenProvider'
import { lgpdThunkCreators } from '../../../store/actions/lgpdActions'
import { LgpdComponent } from '../../ui-components/lgpd/LgpdComponent'


export interface LgpdGateProps {
  children: any
}

export function LgpdGate({ children }: LgpdGateProps) {

  const dispatch = useTypedDispatch()
  const {
    data: usuarioJaAceitouAntes,
    isResolved: isVerificacaoFinalizada,
  } = useSpinningPromiseEffect(verificarSeUsuarioLogadoAceitouLgpd, [dispatch])
  const { run: aceitar, isResolved: usuarioAceitouAgora } = useSpinningPromise(() => dispatch(lgpdThunkCreators.aceitarLgpd()))

  if (!isVerificacaoFinalizada) return null
  if (usuarioJaAceitouAntes || usuarioAceitouAgora) return children
  return <LgpdComponent onAceitarTermo={aceitar} />

}

async function verificarSeUsuarioLogadoAceitouLgpd(dispatch: TypedDispatch) {
  const { data: aceitou } = await dispatch(lgpdThunkCreators.verificarSeUsuarioLogadoAceitouLgpd())
  return aceitou
}
