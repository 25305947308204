import { fatosApi } from '../../api/fatosApi'
import { processosApi } from '../../api/processosApi'
import { MontarQueryRecuperarFatosPayload } from '../../api/queries/MontarQueryRecuperarFatosPayload'
import { TIPOS_FATO } from '../../domain/TipoFato'
import { recuperarAliasStakeholderPorId } from '../stakeholder/recuperarAliasStakeholderPorId'
import { montarResumoFato } from './montarResumoFato'
import { SearchResponse } from '../../infra/elastic/elasticTypes'
import { ResumoFato } from '../../domain/ResumoFato'

export async function recuperarFatosResumidos(payload: MontarQueryRecuperarFatosPayload) {
  const stakeholdersComAlias = await recuperarAliasStakeholderPorId(payload.idsAtores, payload.idsEntidades)
  const [
    { body: respostaIndiceFatos },
    { body: respostaIndiceProcessos },
  ] = await Promise.all([
    fatosApi.buscarFatos(payload, stakeholdersComAlias.atores, stakeholdersComAlias.entidades),
    processosApi.buscarProcessos(payload, stakeholdersComAlias.atores, stakeholdersComAlias.entidades),
  ])
  return montarViewModel(respostaIndiceFatos, respostaIndiceProcessos)
}

function montarViewModel(respostaIndiceFatos: SearchResponse, respostaIndiceProcessos: SearchResponse): ResumoFato[] {
  const fatos = respostaIndiceFatos.hits.hits
  const processos = respostaIndiceProcessos.hits.hits
  return [
    ...fatos.map(fato => montarResumoFato(fato, fato._source.tipo_fato)),
    ...processos.map(fato => montarResumoFato(fato, TIPOS_FATO.PROCESSO.id)),
  ]
}
