import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TrechoFato } from 'bff/src/main/domain/Ficha'
import { BotaoAcoesTrechoFichaContainer } from '../../containers/ficha/BotaoAcoesTrechoFichaContainer'
import { ButtonSpanComponent } from '../comum/ButtonSpanComponent'

const useStyles = makeStyles({
  paragrafo: {
    size: '16px',
    weight: 400,
    lineHeight: '24px',
    marginBottom: 0,
  },
  margin: {
    marginRight: '19px',
  },
  trechoFicha: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
  },
})

interface TrechoParagrafoComponentProps {
  trechoFato: TrechoFato
  onClickTexto: () => void
  fichaId: number
}

export function TrechoParagrafoComponent({ trechoFato, onClickTexto, fichaId }: TrechoParagrafoComponentProps) {
  const classes = useStyles()
  return (
    <div className={classes.trechoFicha}>
      <div className={classes.margin}>
        <BotaoAcoesTrechoFichaContainer
          fichaId={fichaId}
          trechoFichaId={trechoFato.id}
          tipoTrecho={trechoFato.tipo}
          fatoCitadoId={trechoFato.fatoCitado}
        />
      </div>
      <ButtonSpanComponent onClick={onClickTexto}>
        <p className={classes.paragrafo}>
          {trechoFato.conteudo}
        </p>
      </ButtonSpanComponent>
    </div>
  )
}
