import { EnumObjectWithId } from '../util/EnumObject'

export type EstadoProcesso =
  'ABERTO' | 'ENCERRADO'

export const ESTADOS_PROCESSO: EnumObjectWithId<EstadoProcesso, { key: string, label: string }> = {
  ABERTO: {
    id: 'ABERTO',
    key: 'ABERTO',
    label: 'Aberto',
  },
  ENCERRADO: {
    id: 'ENCERRADO',
    key: 'ENCERRADO',
    label: 'Encerrado',
  },
}
