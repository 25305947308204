import { dateUtil } from '../../../util/dateUtil'
import { frontendRoutesUtil } from '../../../util/frontendRoutesUtil'
import { TIPOS_FATO } from '../../../domain/TipoFato'
import { ResumoFato } from '../../../domain/ResumoFato'

export function montarResumoEvento(evento): ResumoFato {
  const tipoFato = TIPOS_FATO.EVENTO_RELACIONA.id
  return {
    id: evento._id,
    titulo: evento._source.titulo,
    tipoFato,
    resumo: evento._source.resumo ?? '',
    urlDestinoFato: frontendRoutesUtil.montarUrlDetalhamentoFato(evento._id, tipoFato),
    informacaoAdicionalMaisRelevante: evento._source.tipo_evento,
    informacaoAdicionalMenosRelevante: dateUtil.formatarDataHora(evento._source.data_publicacao) ?? '',
    isSigiloso: evento._source.sigiloso,
    data: evento._source.data_publicacao,
  }
}
