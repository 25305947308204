import { BuscarAtorEndpoint } from 'bff/src/main/controllers/stakeholder/atoresEndpoints'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'


export const atorActionCreators = {

  buscarAtorPorId: (id: string) => createBffRequestThunk({
    request: {
      endpoint: BuscarAtorEndpoint.definition,
      params: { id },
    },
  }),

}
