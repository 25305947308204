import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Ficha } from 'bff/src/main/domain/Ficha'
import { ModalComponent } from '../modal/ModalComponent'
import { HeaderCenariosComponent } from '../comum/HeaderCenariosComponent'
import { ButtonComponent } from '../comum/ButtonComponent'
import { ItemListaFichaComponent } from './ItemListaFichaComponent'
import { ButtonSpanComponent } from '../comum/ButtonSpanComponent'

const useStyles = makeStyles({
  box: {
    width: '583px',
  },
  margin: {
    marginBottom: '32px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButtom: {
    marginRight: '16px',
  },
})

interface AdicionarTrechoFatoFichaExistenteComponentProps {
  onConfirm: (fichaSelecionadaId?: number) => void
  onCancel: () => void
  fichas: Ficha[]
}

export function AdicionarTrechoFatoFichaExistenteComponent(propsModal: AdicionarTrechoFatoFichaExistenteComponentProps) {
  const classes = useStyles()

  const [fichaSelecionadaId, setFichaSelecionadaId] = useState<number | undefined>(undefined)
  
  return (
    <ModalComponent>
      <div className={classes.box}>
        <HeaderCenariosComponent
          titulo="Minhas fichas"
          descricao="Selecione a ficha na qual você deseja incluir o trecho selecionado"
        />
        <div className={classes.margin}>
          {
            propsModal.fichas.map(ficha => (
              <ButtonSpanComponent
                onClick={() => setFichaSelecionadaId(ficha.id)}
                key={ficha.id}
              >
                <ItemListaFichaComponent
                  tituloFicha={ficha.titulo}
                  descricaoFicha={ficha.descricao}
                  isActive={ficha.id === fichaSelecionadaId}
                />
              </ButtonSpanComponent>
            ))
          }
        </div>
        <div className={classes.buttons}>
          <ButtonComponent
            buttonText="Cancelar"
            buttonTypeColor="white"
            onClick={propsModal.onCancel}
            className={classes.cancelButtom}
          />
          <ButtonComponent
            disabled={!fichaSelecionadaId}
            buttonText="Adicionar trecho à ficha"
            buttonTypeColor="blue"
            onClick={() => propsModal.onConfirm(fichaSelecionadaId)}
          />
        </div>
      </div>
    </ModalComponent>
  )
}
