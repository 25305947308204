import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TipoStakeholder } from 'bff/src/main/domain/TipoStakeholder'
import greyAtorIcon from '../../../assets/atorIcon-grey.svg'
import greyEntidadeIcon from '../../../assets/entidadeIcon-grey.svg'

const useOptionsStyles = makeStyles({
  option: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '4px',
  },
  optionLabel: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  optionIcon: {
    marginRight: '15px',
    width: '30px',
    fontSize: '23px',
    color: '#777',
  },
  optionName: {
    color: '#777',
    marginRight: '10px',
  },
  optionRole: {
    color: '#777',
    marginLeft: '10px',
    textAlign: 'right',
  },
})

export interface BuscaAutocompleteInputOption {
  id?: any
  tipo: TipoStakeholder | 'TEMA' | 'KEYWORD'
  nome: string
  papel: string
  [extraProps: string]: any
}

interface BuscaAutocompleteOptionComponentProps {
  option: BuscaAutocompleteInputOption
  modoPesquisaLivre?: boolean
}

export function BuscaAutocompleteOptionComponent({ option, modoPesquisaLivre }: BuscaAutocompleteOptionComponentProps) {
  const classes = useOptionsStyles()
  const iconComponents = {
    ATOR: <img className={classes.optionIcon} src={greyAtorIcon} alt="Ator" />,
    ENTIDADE: <img className={classes.optionIcon} src={greyEntidadeIcon} alt="Entidade" />,
    TEMA: <i className={`fas fa-tags fa-fw ${classes.optionIcon}`} />,
    KEYWORD: <i className={`fas fa-search fa-fw ${classes.optionIcon}`} />,
  }
  return (
    <div className={classes.option}>
      {modoPesquisaLivre ? '' : iconComponents[option.tipo]}
      <div className={classes.optionLabel}>
        <div className={classes.optionName}>{option.nome}</div>
        {modoPesquisaLivre ? '' : <div className={classes.optionRole}>{option.papel}</div>}
      </div>
    </div>
  )
}
