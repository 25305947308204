import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { ImagemComBordaContainer } from '../../containers/comum/ImagemComBordaContainer'
import { ButtonIconFavoritarComponent } from '../comum/ButtonIconFavoritarComponent'
import { TipoFavorito } from '../favorito/TipoFavorito'

const useStyles = makeStyles({
  linkStakeholder: {
    width: '70px',
    position: 'relative',
    float: 'left',
    marginRight: '10px',
    zIndex: 100,
  },
  imagem: {
    width: '70px',
    height: '70px',
  },
  nomeStakeholder: {
    fontSize: '16px',
    color: '#434343',
    paddingTop: '25px',
    fontWeight: 'bold',
    margin: '10px',
    position: 'relative',
    display: 'block',
  },
  infoStakeholder: {
    color: '#9E9E9E',
    fontSize: '14px',
  },
  descricao: {
    marginTop: '10px',
    fontSize: '14px',
  },
  imagemENome: {
    display: 'block',
  },
  borderDiv: {
    borderBottom: '1px solid #ccc',
    paddingBottom: '20px',
  },
  icones: {
    diplay: 'inline',
    float: 'right',
  },
  favoritos: {
    float: 'left',
  },

})

interface ItemResultadoStakeholderComponentProps {
  id: string
  nome: string
  papel: string
  urlImagem: string
  urlDestino: string
  stakeholderInterno: boolean
  orgao: string
  descricao: string
  isFavorito: boolean
}

export function ItemResultadoStakeholderComponent({
  id, nome, papel, urlImagem, urlDestino, stakeholderInterno, orgao, descricao, isFavorito,
}: ItemResultadoStakeholderComponentProps) {
  const role = 'favoritos'
  const classes = useStyles()
  return (
    <div>
      <div className={classes.imagemENome}>
        <Link className={classes.linkStakeholder} to={urlDestino}>
          <div className={classes.imagem}>
            <ImagemComBordaContainer urlImagem={urlImagem} papel={papel} stakeholderInterno={stakeholderInterno} />
          </div>
        </Link>
        <div className={classes.icones}>
          <div role={role} className={classes.favoritos}>
            <ButtonIconFavoritarComponent idItemFavorito={id} isFavorito={isFavorito} tipoFavorito={TipoFavorito.STAKEHOLDER} />
          </div>
        </div>
        <div className={classes.nomeStakeholder}>{ nome }</div>
      </div>
      <br />
      <div>
        <span className={classes.infoStakeholder}>
          { `${papel} | ${orgao}` }
        </span>
      </div>
      <br />
      <div className={classes.descricao}>
        { descricao }
      </div>
      <hr />
    </div>
  )
}
