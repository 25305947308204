import { SnackbarNotification } from './SnackbarNotification'

export const enqueueSnackbar = (notification: SnackbarNotification) => {
  const key = notification.options && notification.options.key
  return {
    type: '@notistack/enqueueSnackbar',
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  }
}

export const closeSnackbar = key => ({
  type: '@notistack/closeSnackbar',
  dismissAll: !key, // dismiss all if no key has been defined
  key,
})

export const removeSnackbar = key => ({
  type: '@notistack/removeSnackbar',
  key,
})
