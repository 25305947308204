
export type ActionCreator = {
  (...args: any[]): {
    type: string
    [extraProps: string]: any
  }
  type: string
}

interface ActionCreatorsWithoutTypes {
  [type: string]: ActionCreatorWithoutType
}
type ActionCreatorWithoutType = (...args: any[]) => object
type ActionCreatorsWithTypes<CS extends ActionCreatorsWithoutTypes> = {
  [P in keyof CS]: ActionCreatorWithType<CS[P]>;
}
type ActionCreatorWithType<C extends ActionCreatorWithoutType> = WithType<FunctionReturnWithType<C>>

type FunctionReturnWithType<T extends (...args: any) => any> = (...args: Parameters<T>) => WithType<ReturnType<T>>
type WithType<T> = { type: string } & T


const actionCreatorsTypes = new Map<ActionCreatorWithoutType, string>()

/**
 * Cria uma coleção de action creators. O tipo da action que será gerada por cada action creator é uma string definida
 * como "<namespace>/<actionCreatorKey>".
 *
 * @param namespace Prefixo das action types geradas por cada action creator
 * @param actionCreators Objeto em que cada property define um pedaço de implementação de uma action creator. A key da
 * property indica o sufixo do action type. O value da property indica o pedaço de implementação da action creator.
 *
 * @example
 * // Implementando com defineActionCreators
 * const livroActions = defineActionCreators('livro', {
 *   adicionado: (nome, autor, edicao) => ({
 *     nome,
 *     autor,
 *     edicao,
 *   }),
 *   edicaoAtualizada: (id, edicao) => ({
 *     id,
 *     edicao,
 *   }),
 * };
 *
 * @example
 * // Implementando sem defineActionCreators
 * const livroActions = {
 *   adicionado: (nome, autor, edicao) => ({
 *     type: 'livro/adicionado',
 *     nome,
 *     autor,
 *     edicao,
 *   }),
 *   edicaoAtualizada: (id, edicao) => ({
 *     type: 'livro/edicaoAtualizada',
 *     id,
 *     edicao,
 *   }),
 * };
 *
 */
export function defineActionCreators<CS extends ActionCreatorsWithoutTypes>(namespace: string, actionCreators: CS) {
  const actionCreatorsKeys = Object.keys(actionCreators)
  const wrappedActionCreators = {}
  actionCreatorsKeys.forEach((acKey) => {
    wrappedActionCreators[acKey] = defineActionCreator(namespace, acKey, actionCreators)
  })
  return wrappedActionCreators as ActionCreatorsWithTypes<CS>
}

function defineActionCreator<CS>(namespace: string, acKey: string, actionCreators: CS) {
  const actionType = `${namespace}/${acKey}`
  const actionCreator = actionCreators[acKey]
  // TODO: checar se tem duplicado
  actionCreatorsTypes.set(actionCreator, actionType)
  return wrapActionCreator(actionCreator, actionType)
}

function wrapActionCreator<T extends any[], U>(actionCreator: ((...args: T) => U), actionType: string) {
  const wrapper = (...args: T): WithType<U> => ({
    ...(actionCreator(...args)),
    type: actionType,
  })
  wrapper.type = actionType
  return wrapper
}
