import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/styles'


const useStyles = makeStyles({
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
  },
})

interface ButtonSpanComponentProps {
  children: ReactNode
  onClick: (param?) => void
  className?: string
}

export function ButtonSpanComponent({ children, onClick, className }: ButtonSpanComponentProps) {
  const classes = useStyles()
  return (
    <span
      className={`${classes.textWrapper} ${className}`}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={onClick}
    >
      {children}
    </span>
  )
}
