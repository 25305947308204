import { frontendRoutesUtil } from '../../../util/frontendRoutesUtil'
import { retirarTagsHtml } from '../retirarTagsHtml'
import { TIPOS_FATO } from '../../../domain/TipoFato'
import { ResumoFato } from '../../../domain/ResumoFato'

export function montarResumoProposicaoLegislativa(proposicao): ResumoFato {
  const tipoFato = TIPOS_FATO.PROPOSICAO_LEGISLATIVA.id
  return {
    id: proposicao._id,
    titulo: proposicao._source.titulo.toUpperCase(),
    tipoFato,
    resumo: retirarTagsHtml(proposicao._source.texto),
    urlDestinoFato: frontendRoutesUtil.montarUrlDetalhamentoFato(proposicao._id, tipoFato),
    informacaoAdicionalMaisRelevante: verificarAutores(proposicao._source.autor),
    informacaoAdicionalMenosRelevante: proposicao._source.origem ?? '',
    data: proposicao._source.data_publicacao,
  }
}

function verificarAutores(autor: string) {
  if (autor && autor.includes(',')) {
    return 'Vários autores'
  }
  return autor
}
