import { DependencyList, useCallback } from 'react'
import {
  AsyncFulfilled,
  AsyncInitial,
  AsyncPending,
  AsyncRejected,
  useAsync,
} from 'react-async'


type Arr = readonly unknown[]

export type PromiseFunction<Args extends Arr, R> = (...promiseArgs: [...Args]) => Promise<R>

export type PromiseEffectState<Args extends Arr, R, S extends BaseState<Args, R> = BaseState<Args, R>>
  = AsyncInitial<R, S> | AsyncPending<R, S> | AsyncFulfilled<R, S> | AsyncRejected<R, S>

interface BaseState<Args extends Arr, R> {
  initialValue?: R | Error
  counter: number
  promise: Promise<R>
  run: (...args: [...Args]) => void
  reload: () => void
  cancel: () => void
  setData: (data: R, callback?: () => void) => R
  setError: (error: Error, callback?: () => void) => Error
}


// TODO: fazer documentação
export function usePromiseEffect<R>(
  promiseFunction: PromiseFunction<[], R>,
  promiseArgs?: [],
  extraDeps?: DependencyList,
): PromiseEffectState<[], R>
export function usePromiseEffect<Args extends Arr, R>(
  promiseFunction: PromiseFunction<Args, R>,
  promiseArgs: [...Args],
  extraDeps?: DependencyList,
): PromiseEffectState<Args, R>
export function usePromiseEffect<R>(
  promiseFunction: PromiseFunction<any[], R>,
  promiseArgs?: any[],
  extraDeps?: DependencyList,
): PromiseEffectState<any[], R> {
  const wrapperdPromiseFunction = () => promiseFunction(...(promiseArgs ?? []))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFunction = useCallback(wrapperdPromiseFunction, [...(promiseArgs ?? []), ...(extraDeps ?? [])])
  return useAsync(memoizedFunction, {})
}
