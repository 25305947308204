import React from 'react'
import { ESTADOS_PROCESSO } from 'bff/src/main/domain/EstadoProcesso'
import { CheckboxesOption } from '../../ui-components/comum/CheckboxesComponent'
import { CheckboxesFilterContainer } from './CheckboxesFilterContainer'
import { ordernarListaAlfabeticamentePorPropriedade } from '../../../util/sortUtil'
import { QUERY_PARAM_FILTRO_ESTADO_PROCESSO } from '../../../util/constantesQueryParams'

export function FiltroEstadosProcessoContainer() {
  return (
    <CheckboxesFilterContainer
      filterName={QUERY_PARAM_FILTRO_ESTADO_PROCESSO}
      filterLabel="Estado do processo"
      options={getOptions()}
    />
  )
}

function getOptions(): CheckboxesOption[] {
  const listaTiposProcesso = Object.values(ESTADOS_PROCESSO).map(
    tipoProcesso => ({ label: tipoProcesso.label, value: tipoProcesso.id }),
  )
  return ordernarListaAlfabeticamentePorPropriedade(listaTiposProcesso, 'label')
}
