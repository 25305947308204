/* eslint react/jsx-props-no-spreading: 0 */

import React from 'react'
import { makeStyles } from '@material-ui/styles' 
import { Ficha } from 'bff/src/main/domain/Ficha'
import {
  DragDropContext, Draggable, Droppable, DropResult, ResponderProvided, 
} from 'react-beautiful-dnd'
import background from '../../../assets/bg_inicial.jpg'
import logoMapa from '../../../assets/logo.png'
import logoTcu from '../../../assets/logotcu.svg'
import { VisualizacaoEdicaoTrechoFichaContainer } from '../../containers/ficha/VisualizacaoEdicaoTrechoFichaContainer'
import { BotaoAdicionarTrechoNoDocumentoFichaContainer } from './BotaoAdicionarTrechoNoDocumentoFichaContainer'
import { TextfieldTrechoTextualFichaComponent } from './TextfieldTrechoTextualFichaComponent'
import { ButtonSpanComponent } from '../comum/ButtonSpanComponent'

const useStyles = makeStyles({
  box: {
    backgroundColor: '#E5E5E5',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '40px',
    paddingBottom: '40px',
  },
  ficha: {
    backgroundColor: '#fff',
    width: '94%',
    padding: '0px 50px 0px 50px',
  },
  header: {
    backgroundImage: `url(${background})`,
    alignItems: 'center',
    display: 'flex',
    height: '150px',
    backgroundPosition: 'left 300%',
  },
  bgImage: {
    width: '100%',
    height: '100%',
  },
  logoMapa: {
    height: '64px',
    width: '145px',
    position: 'absolute',
    maxWidth: '100%',
  },
  tituloFicha: {
    fontSize: '32px',
    fontWeight: 500,
    display: 'inline-block',
    margin: '0 auto',
  },
  footer: {
    backgroundImage: `url(${background})`,
    alignItems: 'center',
    display: 'flex',
    height: '120px',
    backgroundPosition: 'left bottom',
  },
  logoTcu: {
    display: 'block',
    margin: '0 auto',
    marginBottom: '10px',
  },
  trechos: {
    minHeight: '742px',
  },
})

interface ConteudoFichaComponentProps {
  ficha: Ficha
  onDragAndDrop: OnDragEnd
  onEditarTitulo: (novoTitulo: string) => void
  onCancelarEdicaoTitulo: () => void
  onAbrirEdicaoTitulo: () => void
  acaoTitulo: 'EDITAR' | 'VISUALIZAR'
}

type OnDragEnd = (result: DropResult, provided: ResponderProvided) => void

export function ConteudoFichaComponent({
  ficha, onDragAndDrop, acaoTitulo, onEditarTitulo, onCancelarEdicaoTitulo, onAbrirEdicaoTitulo,
}: ConteudoFichaComponentProps) {
  const classes = useStyles()
  return (
    <div className={classes.box}>
      <div className={classes.ficha}>
        <div className={classes.header}>
          <img className={classes.logoMapa} src={logoMapa} alt="Logo MAPA" />
          {
            acaoTitulo === 'VISUALIZAR'
              ? (
                <ButtonSpanComponent onClick={onAbrirEdicaoTitulo} className={classes.tituloFicha}>
                  <h1 className={classes.tituloFicha}>{ficha.titulo}</h1>
                </ButtonSpanComponent>
              )
              : (
                <div className={classes.tituloFicha}>
                  <TextfieldTrechoTextualFichaComponent
                    labelButtonCancel="Cancelar"
                    labelButtonConfirm="Alterar"
                    onCancel={onCancelarEdicaoTitulo}
                    onConfirm={onEditarTitulo}
                    textoAnterior={ficha.titulo}
                  />
                </div>
              )
          }

        </div>
        {renderTrechos(classes, ficha, onDragAndDrop)}
        <div className={classes.footer}>
          <img className={classes.logoTcu} src={logoTcu} alt="Logo TCU" />
        </div>
      </div>
    </div>
  )
}

function renderTrechos(classes, ficha: Ficha, onDragAndDrop: OnDragEnd) {
  ficha.trechos.sort((a, b) => a.ordemTrecho - b.ordemTrecho)
  return (
    <div className={classes.trechos}>
      <DragDropContext onDragEnd={onDragAndDrop}>
        <Droppable droppableId="droppable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <BotaoAdicionarTrechoNoDocumentoFichaContainer fichaId={ficha.id} posicao={0} />
              {ficha.trechos.map((trecho, index) => (
                <Draggable
                  key={trecho.id}
                  draggableId={trecho.id.toString()}
                  index={index}
                >
                  {
                    providedDraggableProps => (
                      <div
                        ref={providedDraggableProps.innerRef}
                        {...providedDraggableProps.draggableProps}
                        {...providedDraggableProps.dragHandleProps}
                      >
                        <VisualizacaoEdicaoTrechoFichaContainer trecho={trecho} fichaId={ficha.id} />
                        <BotaoAdicionarTrechoNoDocumentoFichaContainer fichaId={ficha.id} posicao={trecho.ordemTrecho + 1} />
                      </div>
                    )
                  }
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
