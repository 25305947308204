import React, { useState } from 'react'
import { TrechoFato } from 'bff/src/main/domain/Ficha'
import { TIPOS_TRECHO_FICHA } from 'bff/src/main/domain/TipoTrechoFicha'
import { TextfieldTrechoTextualFichaComponent } from '../../ui-components/ficha/TextfieldTrechoTextualFichaComponent'
import { TrechoCitacaoFatoComponent } from '../../ui-components/ficha/TrechoCitacaoFatoComponent'
import { TrechoTituloSubtituloComponent } from '../../ui-components/ficha/TrechoTituloSubtituloComponent'
import { TrechoParagrafoComponent } from '../../ui-components/ficha/TrechoParagrafoComponent'
import { useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSpinningPromise } from '../../../infra/libs/react-swearing/SpinnerScreenProvider'
import { fichaThunkCreators } from '../../../store/actions/fichaActions'

const trechosMap = {
  [TIPOS_TRECHO_FICHA.CITACAO_FATO.id]: renderCitacao,
  [TIPOS_TRECHO_FICHA.TITULO.id]: renderTituloSubstitulo,
  [TIPOS_TRECHO_FICHA.SUBTITULO.id]: renderTituloSubstitulo,
  [TIPOS_TRECHO_FICHA.PARAGRAFO.id]: renderParagrafo,
}


interface VisualizacaoEdicaoTrechoFichaContainerProps {
  trecho: TrechoFato
  fichaId: number
}

type TipoContainer = 'VISUALIZAR' | 'EDITAR'

export function VisualizacaoEdicaoTrechoFichaContainer({ trecho, fichaId }: VisualizacaoEdicaoTrechoFichaContainerProps) {
  const dispatch = useTypedDispatch()
  const [tipoContainer, setTipoContainer] = useState<TipoContainer>('VISUALIZAR')

  const { run: onAtualizarTexto } = useSpinningPromise((texto: string) => (
    dispatch(fichaThunkCreators.editarConteudoTrechoTextual(fichaId, trecho.id, texto))))
  
  const onAbrirEdicao = () => setTipoContainer('EDITAR')
  
  const onCancelar = () => setTipoContainer('VISUALIZAR')
  
  if (tipoContainer === 'VISUALIZAR') {
    return trechosMap[trecho.tipo](fichaId, trecho, onAbrirEdicao)
  }
  
  return (
    <TextfieldTrechoTextualFichaComponent
      tipoTrechoTextual={trecho.tipo}
      onConfirm={onAtualizarTexto}
      onCancel={onCancelar}
      textoAnterior={trecho.conteudo}
      labelButtonCancel="Cancelar"
      labelButtonConfirm="Alterar"
    />
  )
}

function renderCitacao(fichaId: number, trechoFato: TrechoFato) {
  return <TrechoCitacaoFatoComponent trechoFato={trechoFato} fichaId={fichaId} />
}

function renderTituloSubstitulo(fichaId: number, trechoFato: TrechoFato, onClickTexto: () => void) {
  return <TrechoTituloSubtituloComponent trechoFato={trechoFato} onClickTexto={onClickTexto} fichaId={fichaId} />
}

function renderParagrafo(fichaId: number, trechoFato: TrechoFato, onClickTexto: () => void) {
  return <TrechoParagrafoComponent trechoFato={trechoFato} onClickTexto={onClickTexto} fichaId={fichaId} />
}
