import { ActionCreator } from './defineActionCreators'

type Handler<S, C extends ActionCreator, H extends ActionHandler<S, C>> = [C, H]
type ActionHandler<S, C extends ActionCreator> = (state: S, action: ReturnType<C>) => S

/**
 * - Cria uma função que cria um reducer do Redux com um `initialState`.
 *
 * - A função que cria o reducer recebe uma função `handlersBuilder` que deve retornar um map de {@link ActionCreator} e
 * {@link ActionHandler}.
 *
 * - Um {@link ActionCreator} é um action creator gerado por {@link defineActionCreators}.
 *
 * - Um {@link ActionHandler} é uma função que recebe um `previousState` e uma `action`, e retorna um `nextState`.
 *
 * - Para gerar cada par de {@link ActionCreator} e {@link ActionHandler}, a função recebida `handlersBuilder` tem
 * um parâmetro `handle` que é a função utilizada para gerar cada par.
 *
 * - Quando for disparado uma `action` via `dispatch`, o reducer criado irá ser processado recebendo o `previousState` e
 * a `action` disparada.
 *
 * - Se a `action` disparada tem tipo correspondente a um `actionCreator` mapeado anteriormente, o reducer irá processar
 * o `actionHandler` mapeado com o `actionCreator` para gerar o novo `state`.
 *
 * - Se a `action` disparada não tem tipo correspondente a um `actionCreator` mapeado anteriormente, o reducer irá
 * retornar o `previousState`.
 *
 * @param initialState
 */
export function createReducer<S>(initialState: S) {

  function createHandler<C extends ActionCreator>(actionCreator: C, actionHandler: ActionHandler<S, C>): [C, ActionHandler<S, C>] {
    return [actionCreator, actionHandler]
  }

  return (handlersBuilder: (handle: typeof createHandler) => Handler<S, any, any>[]) => {
    const handlers = handlersBuilder(createHandler)
    const typesHandlersMap = createTypesHandlersMap(handlers)
    return (previousState = initialState, action: ReturnType<ActionCreator>) => {
      const handler = typesHandlersMap[action.type]
      if (handler) {
        return handler(previousState, action)
      }
      return previousState
    }
  }
}

function createTypesHandlersMap <S, C extends ActionCreator, H extends ActionHandler<S, C>>(handlers: Handler<S, C, H>[]) {
  const mapTypesHandlers = {}
  handlers.forEach(([creator, handler]) => {
    const { type } = creator
    mapTypesHandlers[type] = handler
  })
  return mapTypesHandlers as { [type: string]: H }
}
