import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Cenario } from 'bff/src/main/domain/Cenario'
import { ItemMenuComponent } from './ItemMenuComponent'
import { OnBoardingTelaInicial } from '../../containers/onboarding/OnBoardingTelaInicial'
import { IconCenarioComponent } from '../icons/IconCenarioComponent'
import { IconMeusCenariosComponent } from '../icons/IconMeusCenariosComponent'
import { IconFichasComponent } from '../icons/IconFichasComponent'
import { IconListaLeituraComponent } from '../icons/IconListaLeituraComponent'


const useStyles = makeStyles({
  inicio: {
    color: '#434343',
    paddingTop: '16px',
    paggindLeft: '10px',
  },
  listaLeitura: {
    color: '#434343',
  },
  listaMenu: {
    paddingInlineStart: 0,
    listStyle: 'none',
  },
  tituloMenu: {
    fontSize: '16px',
    color: '#434343',
    fontWeight: 500,
    paddingLeft: '32px',
  },
  line: {
    marginLeft: '32px',
    marginRight: 0,
    marginTop: '10px',
    marginBottom: '25px',
    '@media screen and (max-width:567px)': {
      marginTop: '-5px',
    },
  },
})

interface MenuComponentProps {
  cenarios: Cenario[]
}

export function MenuComponent({ cenarios }: MenuComponentProps) {
  const classes = useStyles()
  return (
    <OnBoardingTelaInicial.Step1>
      <div>
        <ul className={classes.listaMenu}>
          <ItemMenuComponent
            className={classes.inicio}
            label="Início"
            url="/"
            icon={<i className="fas fa-home" />}
          />
          <ItemMenuComponent
            className={classes.listaLeitura}
            label="Minhas fichas"
            url="/fichas"
            icon={<IconFichasComponent />}
          />
          <ItemMenuComponent
            className={classes.listaLeitura}
            label="Lista de leitura"
            url="/lista-leitura"
            icon={<IconListaLeituraComponent />}
          />
          <hr className={classes.line} />
          {renderCenarios(classes, cenarios)}
          <hr className={classes.line} />
        </ul>
      </div>
    </OnBoardingTelaInicial.Step1>
  )
}

function renderCenarios(classes, cenarios: Cenario[]) {
  return (
    <div>
      <OnBoardingTelaInicial.Step2>
        <h4 className={classes.tituloMenu}>Cenários</h4>
        <ItemMenuComponent label="Meus cenários" url="/cenarios" icon={<IconMeusCenariosComponent />} />
        {
          cenarios.map(cenario => (
            <ItemMenuComponent
              key={cenario.id}
              label={cenario.nome}
              url={`/cenarios/${cenario.id}`}
              icon={<IconCenarioComponent />}
            />
          ))
        }
        <ItemMenuComponent color="blue" key="1" label="Criar novo cenário" url="/cenarios/novo" icon={<i className={`${classes.icon} fas fa-plus`} />} />
      </OnBoardingTelaInicial.Step2>
    </div>
  )
}
