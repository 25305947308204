import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Cenario } from 'bff/src/main/domain/Cenario'
import { SITUACAO_CENARIO } from 'bff/src/main/domain/SituacaoCenario'
import { LinhaCenarioContainer } from '../../containers/cenario/LinhaCenarioContainer'
import { MeusCenariosVazioComponent } from './MeusCenariosVazioComponent'
import { MeusCenariosHeaderComponent } from './MeusCenariosHeaderComponent'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHeader: {
    color: '#7e7e7e',
  },
  tituloAtivos: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#434343',
    marginTop: '48px',
    marginBottom: '36px',
  },
  root: {
    boxShadow: 'none',
  },
})


interface MeusCenariosComponentProps {
  cenarios: Cenario[]
  onClickNovoCenario: () => void
}

export function MeusCenariosComponent(props: MeusCenariosComponentProps) {
  const classes = useStyles()

  const cenariosAtivos = props.cenarios.filter(c => c.situacao === SITUACAO_CENARIO.ATIVO.id)
  const cenariosArquivados = props.cenarios.filter(c => c.situacao === SITUACAO_CENARIO.ARQUIVADO.id)

  if (props.cenarios.length < 1) {
    return (
      <div className="row">
        <MeusCenariosHeaderComponent onClickNovoCenario={props.onClickNovoCenario} mostrarBotaoNovoCenario={props.cenarios.length > 0} />
        <MeusCenariosVazioComponent />
      </div>
    )
  }
  
  return (
    <div className="row">
      <MeusCenariosHeaderComponent
        onClickNovoCenario={props.onClickNovoCenario}
        mostrarBotaoNovoCenario={props.cenarios.length > 0}
      />
      {renderCenarios(classes, 'Ativos', cenariosAtivos)}
      {renderCenarios(classes, 'Arquivados', cenariosArquivados)}
    </div>
  )
}

function renderCenarios(classes, titulo, cenarios: Cenario[]) {
  return (
    <>
      {
        cenarios.length < 1 ? null
          : (
            <div className="col-md-11">
              <h2 className={classes.tituloAtivos}>{titulo}</h2>
              <TableContainer 
                component={Paper} 
                classes={{
                  root: classes.root,
                }}
              >
                <Table className={classes.table} aria-label="simple table">
                  {renderTableHeader(classes)}
                  <TableBody>
                    {
                      cenarios.map(cenario => <LinhaCenarioContainer key={cenario.id} cenario={cenario} />)
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )
      }
    </>
  )
}

function renderTableHeader(classes) {
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableHeader}>Cenário</TableCell>
        <TableCell className={classes.tableHeader} align="left">Buscando Sobre</TableCell>
        <TableCell className={classes.tableHeader} align="left">Filtrado por</TableCell>
        <TableCell className={classes.tableHeader} align="left">Página Início</TableCell>
        <TableCell align="left">&nbsp;</TableCell>
      </TableRow>
    </TableHead>
  )
}
