import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { Ficha } from '../../domain/Ficha'
import { mapaApi } from '../../api/mapaApi'
import { TipoTrechoFicha } from '../../domain/TipoTrechoFicha'

export namespace RecuperarMinhasFichasEndpoint {
  export type Body = undefined
  export type Params = undefined
  export type Query = undefined
  export type ResponseBody = Ficha[]
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/fichas',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { data } = await mapaApi.recuperarMinhasFichas()
      response.json(data)
    },
  })
}

export namespace BuscarFichaPorIdEndpoint {
  export type Body = undefined
  export type Params = { id: number }
  export type Query = undefined
  export type ResponseBody = Ficha
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/fichas/:id',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { id } = request.params
      const { data: ficha } = await mapaApi.recuperarFichaPorId(id)
      response.json(ficha)
    },
  })
}

export namespace CriarFichaEndpoint {
  export type Body = {
    titulo: string
    descricao?: string
    trecho?: string
    fatoCitado?: string
    sigilosa?: boolean
  }
  export type Params = undefined
  export type Query = undefined
  export type ResponseBody = Ficha
  export const definition = defineEndpoint({
    httpMethod: 'POST',
    url: '/fichas',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { body } = request
      const { data } = await mapaApi.criarFicha(body)
      response.json(data)
    },
  })
}

export namespace EditarDescricaoFichaEndpoint {
  export type Body = {
    descricao: string
  }
  export type Params = { id: number }
  export type Query = undefined
  export type ResponseBody = Ficha
  export const definition = defineEndpoint({
    httpMethod: 'POST',
    url: '/editar-descricao-ficha/:id',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { body } = request
      const { id } = request.params
      const { data } = await mapaApi.editarDescricaoFicha(id, body.descricao)
      response.json(data)
    },
  })
}

export namespace EditarTituloFichaEndpoint {
  export type Body = {
    titulo: string
  }
  export type Params = { id: number }
  export type Query = undefined
  export type ResponseBody = Ficha
  export const definition = defineEndpoint({
    httpMethod: 'POST',
    url: '/editar-titulo-ficha/:id',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { body } = request
      const { id } = request.params
      const { data } = await mapaApi.editarTituloFicha(id, body.titulo)
      response.json(data)
    },
  })
}

export namespace ExcluirFichaEndpoint {
  export type Body = undefined
  export type Params = { id: number }
  export type Query = undefined
  export type ResponseBody = Ficha
  export const definition = defineEndpoint({
    httpMethod: 'DELETE',
    url: '/ficha/:id',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { id } = request.params
      const { data } = await mapaApi.deletarFicha(id)
      response.json(data)
    },
  })
}

export namespace AdicionarTrechoFatoFichaExistenteEndpoint {
  export type Body = {
    fatoCitado: string
    conteudo: string
  }
  export type Params = { id: string | number }
  export type Query = undefined
  export type ResponseBody = Ficha
  export const definition = defineEndpoint({
    httpMethod: 'POST',
    url: '/adicionar-trecho-fato-ficha-existente/:id',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { body } = request
      const { id } = request.params
      const { data } = await mapaApi.adicionarTrechoFatoFichaExistente(id, body)
      response.json(data)
    },
  })
}

export namespace AdicionarTrechoTextualFichaEndpoint {
  export type Body = {
    tipoTrechoFicha: TipoTrechoFicha
    conteudo: string
    ordemTrecho: number
  }
  export type Params = { id: string | number }
  export type Query = undefined
  export type ResponseBody = Ficha
  export const definition = defineEndpoint({
    httpMethod: 'POST',
    url: '/adicionar-trecho-textual-ficha/:id',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { body } = request
      const { id } = request.params
      const { data } = await mapaApi.adicionarTrechoTextualFicha(id, body)
      response.json(data)
    },
  })
}

export namespace RemoverTrechoFichaEndpoint {
  export type Body = {
    trechoId: number
  }
  export type Params = { fichaId: number }
  export type Query = undefined
  export type ResponseBody = undefined
  export const definition = defineEndpoint({
    httpMethod: 'DELETE',
    url: '/remover-trecho-ficha/:fichaId',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const fichaId = parseInt(request.params.fichaId)
      const { trechoId } = request.body
      await mapaApi.removerTrechoFicha(fichaId, trechoId)
      response.json()
    },
  })
}

export namespace AlterarTipoTrechoTextualEndpoint {
  export type Body = {
    trechoId: number
    tipoTrechoTextual: TipoTrechoFicha
  }
  export type Params = { fichaId: number }
  export type Query = undefined
  export type ResponseBody = Ficha
  export const definition = defineEndpoint({
    httpMethod: 'POST',
    url: '/alterar-tipo-trecho-textual/:fichaId',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const fichaId = parseInt(request.params.fichaId)
      const { body } = request
      const { data } = await mapaApi.alterarTipoTrechoFichaTextual(fichaId, body)
      response.json(data)
    },
  })
}

export namespace EditarConteudoTrechoTextualEndpoint {
  export type Body = {
    trechoId: number
    novoConteudo: string
  }
  export type Params = { fichaId: number }
  export type Query = undefined
  export type ResponseBody = Ficha
  export const definition = defineEndpoint({
    httpMethod: 'PUT',
    url: '/editar-conteudo-trecho-textual/:fichaId',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const fichaId = parseInt(request.params.fichaId)
      const { body } = request
      const { data } = await mapaApi.editarConteudoTrechoFichaTextual(fichaId, body)
      response.json(data)
    },
  })
}

export namespace AlterarOrdemTechoEndpoint {
  export type Body = {
    trechoId: number
    novaOrdemTrecho: number
  }
  export type Params = { fichaId: number }
  export type Query = undefined
  export type ResponseBody = Ficha
  export const definition = defineEndpoint({
    httpMethod: 'POST',
    url: '/alterar-ordem-trecho/:fichaId',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const fichaId = parseInt(request.params.fichaId)
      const { body } = request
      const { data } = await mapaApi.alterarOrdemTrechoFicha(fichaId, body)
      response.json(data)
    },
  })
}
