import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TipoConectorOu } from 'bff/src/main/domain/TipoConectorOu'
import { ConectorComponent } from './ConectorComponent'
import { montaNomeFuncaoConector } from '../../../util/conectoresUtil'

const useStyles = makeStyles({
  destaque: {
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
})

export interface CriteriosBusca {
  nome: string
  papel: string
}

export interface CriteriosBuscaPrincipalProps {
  criterios: CriteriosBusca[]
  conectoresOu: string[]
  tipoConector?: TipoConectorOu
  isConectorEntreCampos: boolean
}

export function CriteriosBuscaPrincipalComponent({ criterios, conectoresOu, tipoConector, isConectorEntreCampos } : CriteriosBuscaPrincipalProps) {
  const classes = useStyles()
  const separador = '"'
  return (
    <>
      {
        criterios.map((item, index) => (
          <span key={`${item.nome}${item.papel}`}>
            <div>
              <span key={`span${item.nome}${item.papel}`} className={classes.destaque}>
                {criterios.length === 1 ? `"${item.nome}"` : montaStringMuitosCriterios(index, separador, item, criterios[index + 1], tipoConector, conectoresOu)}
              </span>
            </div>
            {criterios[index + 1] === undefined && tipoConector !== undefined && isConectorEntreCampos && (
              <ConectorComponent
                isConectorOu={false}
                nomeFuncao={montaNomeFuncaoConector('conector-entre-campos-', tipoConector, 1)}
              />
            )}
          </span>
        ))
      }
    </>
  )
}

function montaStringMuitosCriterios(index, separador, item, proximoCriterio, tipoConector, conectoresOu) {
  return (
    <>
      {index === 0 ? separador + item.nome : montaStringCriterio(item.nome, separador, proximoCriterio)}
      {proximoCriterio !== undefined && (
        <ConectorComponent
          isConectorOu={tipoConector === undefined ? false : conectoresOu.includes(tipoConector)}
          nomeFuncao={montaNomeFuncaoConector('conector-interno-campo-', tipoConector, index + 1)}
        />
      )}
    </>
  )
}

function montaStringCriterio(nome, separador, proximoCriterio) {
  if (proximoCriterio === undefined) {
    return nome + separador
  } 
  return nome
}
