import React from 'react'
import { BuscarContadorFatosEndpoint } from 'bff/src/main/controllers/contador/contadoresEndpoints'
import { TipoFato } from 'bff/src/main/domain/TipoFato'
import { TipoEvento } from 'bff/src/main/domain/TipoEvento'
import { TipoProcesso } from 'bff/src/main/domain/TipoProcesso'
import { GrauSigilo } from 'bff/src/main/domain/GrauSigilo'
import { EstadoProcesso } from 'bff/src/main/domain/EstadoProcesso'
import { TipoConectorOu } from 'bff/src/main/domain/TipoConectorOu'
import {
  useQueryParamAsArrayOfString,
  useQueryParamAsString,
} from '../../../infra/libs/react-location-hooks/routerHooks'
import {
  QUERY_PARAM_ATORES,
  QUERY_PARAM_ENTIDADES,
  QUERY_PARAM_FILTRO_DATA_FIM,
  QUERY_PARAM_FILTRO_DATA_INICIO,
  QUERY_PARAM_FILTRO_GRAUS_SIGILO,
  QUERY_PARAM_FILTRO_TIPO_EVENTOS,
  QUERY_PARAM_FILTRO_TIPO_FATOS,
  QUERY_PARAM_FILTRO_TIPO_PROCESSOS,
  QUERY_PARAM_FILTRO_ESTADO_PROCESSO,
  QUERY_PARAM_KEYWORDS,
  QUERY_PARAM_TEMAS,
  QUERY_PARAM_FILTRO_RELATOR,
  QUERY_PARAM_TEXTO_LIVRE,
  QUERY_PARAM_CONECTOR_OU,
} from '../../../util/constantesQueryParams'
import { contadorThunkCreators } from '../../../store/actions/contadorActions'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { useMemoObject } from '../../../hooks/useMemoObject'

export function ContadorQuantidadeFatosFiltradosContainer({ className }:{ className?: string }) {

  const dispatch = useTypedDispatch()
  const payload = useMemoObject({
    idsAtores: useQueryParamAsArrayOfString(QUERY_PARAM_ATORES),
    idsEntidades: useQueryParamAsArrayOfString(QUERY_PARAM_ENTIDADES),
    keywords: useQueryParamAsArrayOfString(QUERY_PARAM_KEYWORDS),
    temas: useQueryParamAsArrayOfString(QUERY_PARAM_TEMAS),
    textoLivre: useQueryParamAsString(QUERY_PARAM_TEXTO_LIVRE),
    tiposFato: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_TIPO_FATOS) as TipoFato[],
    tiposEvento: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_TIPO_EVENTOS) as TipoEvento[],
    tiposProcesso: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_TIPO_PROCESSOS) as TipoProcesso[],
    estadosProcesso: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_ESTADO_PROCESSO) as EstadoProcesso[],
    relatores: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_RELATOR),
    grausSigilo: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_GRAUS_SIGILO) as GrauSigilo[],
    dtInicio: useQueryParamAsString(QUERY_PARAM_FILTRO_DATA_INICIO),
    dtFim: useQueryParamAsString(QUERY_PARAM_FILTRO_DATA_FIM),
    listaConectoresOu: useQueryParamAsArrayOfString(QUERY_PARAM_CONECTOR_OU) as TipoConectorOu[],
  })

  const { data: total } = usePromiseEffect(recuperarFatosPorTermos, [dispatch, payload])

  return (
    <h4 className={className}>
      { `${total ? total.toLocaleString('pt-BR') : 0} fatos` }
    </h4>
  )
}

async function recuperarFatosPorTermos(dispatch: TypedDispatch, payload: BuscarContadorFatosEndpoint.Query) {
  try {
    const { data: total } = await dispatch(contadorThunkCreators.buscarContadorFatos(payload))
    return total
  } catch (e) {
    console.warn(e)
  }
  return 0
}
