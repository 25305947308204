import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Processo } from 'bff/src/main/domain/Processo'
import { ListaStakeholdersContainer } from '../containers/stakeholder/ListaStakeholdersContainer'
import {
  useParamAsInt,
  useQueryParamAsArrayOfString,
  useQueryParamAsString,
} from '../../infra/libs/react-location-hooks/routerHooks'
import { FatoCompletoHeaderPlaceholderComponent } from '../ui-components/fato/FatoCompletoHeaderPlaceholderComponent'
import { FatoCompletoBodyPlaceholderComponent } from '../ui-components/fato/FatoCompletoBodyPlaceholderComponent'
import { ScrollToTopOnMount } from '../containers/util/ScrollToTopOnMount'
import { FatoCompletoHeaderComponent } from '../ui-components/fato/FatoCompletoHeaderComponent'
import { processoThunkCreators } from '../../store/actions/processoActions'
import { TituloListaComponent } from '../ui-components/comum/TituloListaComponent'
import { FatosRelacionadosComponent } from '../ui-components/fato/FatosRelacionadosComponent'
import { TitleComponent } from '../ui-components/head/TitleComponent'
import { InformacaoAdicionalFatoComponent } from '../ui-components/comum/InformacaoAdicionalFatoComponent'
import { RepresentantesComponent } from '../ui-components/comum/RepresentantesComponent'
import { PaginaDeErroHttpComponent } from '../ui-components/erro/PaginaDeErroHttpComponent'
import { useTypedDispatch, TypedDispatch } from '../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../infra/libs/react-swearing/usePromiseEffect'
import { QUERY_PARAM_KEYWORDS, QUERY_PARAM_TEMAS, QUERY_PARAM_TEXTO_LIVRE } from '../../util/constantesQueryParams'

const useStyles = makeStyles({
  root: {
    marginTop: '40px',
    paddingBottom: '100px',
  },
  header: {
    marginBottom: '48px',
  },
  descricao: {
    marginBottom: '40px',
  },
  teste: {
    color: 'red',   
  },
  situacao: {
    marginBottom: '56px',
  },
  unidadesJurisdicionadas: {
    marginBottom: '56px',
  },
  interessados: {
    marginBottom: '56px',
  },
  responsaveis: {
    marginBottom: '56px',
  },
})

export function ProcessoView() {
  const classes = useStyles()
  const dispatch = useTypedDispatch()
  const numeroProcesso = useParamAsInt('codigoProcesso')
  const keywords = useQueryParamAsArrayOfString(QUERY_PARAM_KEYWORDS)
  const temas = useQueryParamAsArrayOfString(QUERY_PARAM_TEMAS)
  const textoLivre = useQueryParamAsString(QUERY_PARAM_TEXTO_LIVRE)
  const { data: processo, isLoading } = usePromiseEffect(pegarProcesso, [dispatch, numeroProcesso])

  const termosParaDestacar = [...keywords, ...temas]
  if (textoLivre) {
    termosParaDestacar.push(...textoLivre.split(' '))
  }

  if (!isLoading && !processo) {
    return (
      <PaginaDeErroHttpComponent
        mensagemDeErro="Tivemos um problema para exibir o fato"
      />
    )
  }
  return render(classes, isLoading, termosParaDestacar, processo)
}

function render(classes, isLoading: boolean, termosParaDestacar: string[], processo?: Processo) {
  return (
    <div className={`container ${classes.root}`}>
      <TitleComponent title={processo?.titulo} />
      <ScrollToTopOnMount />
      <div className={classes.header}>
        {renderFatoHeader(isLoading, processo)}
      </div>
      <div className="row">
        <div className="col-md-6">
          {renderFatoContent(classes, isLoading, termosParaDestacar, processo)}
        </div>
        <div className="col-md-5 offset-md-1">
          {
            processo?.relatorId ? (
              <ListaStakeholdersContainer
                stakeholdersIds={[processo?.relatorId ?? ''] || []}
                tituloAutoridadesTcu="Relator do processo"
                exibirContadorAutoridades={false}
                tituloStakeholders="Stakeholders citados no fato"
                exibitContadorStakeholders={false}
              />
            ) : null  
          }
          <ListaStakeholdersContainer
            stakeholdersIds={processo?.stakeholdersIds || []}
            tituloAutoridadesTcu="Autoridades do TCU citadas no fato"
            tituloStakeholders="Stakeholders citados no fato"
          />
          <FatosRelacionadosComponent fatos={processo?.fatosRelacionados ?? []} />
        </div>
      </div>
    </div>
  )
}

async function pegarProcesso(dispatch: TypedDispatch, numeroProcesso: number) {
  const { data: processo } = await dispatch(processoThunkCreators.buscarProcessoPorCod(numeroProcesso))
  return processo
}

function renderFatoHeader(isLoading: boolean, processo?: Processo) {
  if (isLoading) {
    return <FatoCompletoHeaderPlaceholderComponent />
  }
  if (!processo) {
    return null
  }
  return (
    <FatoCompletoHeaderComponent
      tipoFato={processo.tipoFato}
      titulo={processo.titulo}
      urlFonte={processo.urlFonte}
      infoAdicional={processo.nomeUnidadeTecnica}
      dataHora={processo.dataAutuacao}
      isSigiloso={processo.isSigiloso}
    />
  )
}

function renderFatoContent(classes, isLoading: boolean, termosParaDestacar: string[], processo?: Processo) {
  if (isLoading) {
    return <FatoCompletoBodyPlaceholderComponent />
  }
  if (!processo) {
    return null
  }
  return (
    <div>
      <InformacaoAdicionalFatoComponent texto={processo?.assunto} className={classes.descricao} termosParaDestacar={termosParaDestacar} />
      <InformacaoAdicionalFatoComponent titulo="Situação" texto={processo.situacao} className={classes.situacao} termosParaDestacar={termosParaDestacar} />
      <TituloListaComponent titulo="Unidades Jurisdicionadas" lista={processo.unidadesJurisdicionadas} exibirContador={false} className={classes.unidadesJurisdicionadas} />
      <TituloListaComponent titulo="Interessados" lista={processo.interessados} exibirContador={false} className={classes.interessados} />
      <TituloListaComponent titulo="Responsáveis" lista={processo.responsaveis} exibirContador={false} className={classes.responsaveis} />
      <RepresentantesComponent lista={processo.representantesLegais} />
    </div>
  )
}
