import React from 'react'
import { TipoFato, TIPOS_FATO } from 'bff/src/main/domain/TipoFato'
import { BuscarContadoresTiposDeFatos } from 'bff/src/main/controllers/contador/contadoresEndpoints'
import { ContadoresTiposDeFatos } from 'bff/src/main/domain/ContadoresTiposDeFatos'
import { useParam } from '../../../infra/libs/react-location-hooks/routerHooks'
import { contadorThunkCreators } from '../../../store/actions/contadorActions'
import { ListaFatosPerfilAtorContainer } from '../fato/ListaFatosPerfilAtorContainer'
import { TabsComponent } from '../../ui-components/comum/TabsComponent'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'


export function PerfilAtorAbasContainer() {
  const dispatch = useTypedDispatch()
  const idAtor = useParam('id')
  const { data: contadores } = usePromiseEffect(recuperarContadoresTiposFato, [dispatch, idAtor])
  return <TabsComponent tabs={getAbas(contadores)} />
}

async function recuperarContadoresTiposFato(dispatch: TypedDispatch, idAtor: string) {
  const payload: BuscarContadoresTiposDeFatos.Query = { idsAtores: [idAtor] }
  const { data: contadores } = await dispatch(contadorThunkCreators.buscarContadoresTiposDeFato(payload))
  return contadores
}

function getAbas(contadores?: ContadoresTiposDeFatos) {
  return [
    {
      tabTitle: 'Últimas Notícias',
      tabBody: <ListaFatosPerfilAtorContainer key={0} tiposFato={[TIPOS_FATO.NOTICIA_CLIPPING.id, TIPOS_FATO.NOTICIA_UNIAO.id] as TipoFato[]} />,
      disabled: false,
    },
    {
      tabTitle: `Processos (${getNumeroFormatado(contadores?.totalProcessos)})`,
      tabBody: <ListaFatosPerfilAtorContainer key={1} tiposFato={[TIPOS_FATO.PROCESSO.id] as TipoFato[]} />,
      disabled: (contadores?.totalProcessos ?? 0) < 1,
    },
    {
      tabTitle: `Eventos (${getNumeroFormatado(contadores?.totalEventos)})`,
      tabBody: <ListaFatosPerfilAtorContainer key={2} tiposFato={[TIPOS_FATO.EVENTO_RELACIONA.id] as TipoFato[]} />,
      disabled: (contadores?.totalEventos ?? 0) < 1,
    },
    {
      tabTitle: `Proposições (${getNumeroFormatado(contadores?.totalProposicoes)})`,
      tabBody: <ListaFatosPerfilAtorContainer key={3} tiposFato={[TIPOS_FATO.PROPOSICAO_LEGISLATIVA.id] as TipoFato[]} />,
      disabled: (contadores?.totalProposicoes ?? 0) < 1,
    },
  ]
}

function getNumeroFormatado(quantidade?: number) {
  return quantidade ? quantidade.toLocaleString('pt-BR') : 0
}
