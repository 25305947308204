import { AxiosPromise } from 'axios'
import { createAxios } from '../axios/createAxios'
import { RecursoComputacional } from './RecursoComputacional'
import { springCloudConfig } from '../spring-cloud-config/springCloudConfig'
import { AuthParams } from './AuthParams'

const axiosInstance = createAxios({
  baseUrlCloudConfigProp: RecursoComputacional.AUTH_JWT_MS.baseUrlCloudConfigProp,
})

export const authJwtApi = {

  validarToken(authParams: AuthParams) {
    const url = '/api/v1/token-jwt/validar'
    const data = {
      tokenJwt: authParams.tokenJwt,
      userFingerPrint: authParams.userFingerPrint,
    }
    return axiosInstance.post(url, data)
  },

  gerarTokenDeSistema(authParams: AuthParams, codRecursoComputacional?: string): AxiosPromise<AuthParams> {
    const url = '/api/v1/token-jwt/por-login-sistema-com-usuario-na-origem'
    const listaRecursosComputacionais: string[] = []
    const data = {
      cifrar: false,
      listaRecursosComputacionais,
      seRolesNoToken: true,
      loginSistema: springCloudConfig.getProp('tcu.usuario-de-sistema'),
      senha: springCloudConfig.getProp('tcu.senha-usuario-de-sistema'),
      tokenJwtUsuarioOrigem: authParams.tokenJwt,
      userFingerPrintUsuarioOrigem: authParams.userFingerPrint,
    }
    if (codRecursoComputacional) {
      data.listaRecursosComputacionais = [codRecursoComputacional]
    }
    return axiosInstance.post(url, data)
  },

  gerarTokenDeUsuarioPeloTokenPortalTcu(tokenPortalTcu: string): AxiosPromise<AuthParams> {
    const url = '/api/v1/token-jwt/por-token-portal-tcu'
    const data = {
      cifrar: false,
      listaRecursosComputacionais: [RecursoComputacional.MAPA_BFF.codigoRecursoComputacional.toString()],
      seRolesNoToken: true,
      tokenPortalTcu,
    }
    return axiosInstance.post(url, data)
  },

  gerarTokenDeUsuarioDeTeste(): AxiosPromise<AuthParams> {
    const url = '/api/v1/token-jwt/por-login-senha-usuario-siga'
    const data = {
      cifrar: false,
      listaRecursosComputacionais: [RecursoComputacional.MAPA_BFF.codigoRecursoComputacional.toString()],
      seRolesNoToken: true,
      login: 'MAURICIOAW',
      senha: 'teste',
    }
    return axiosInstance.post(url, data)
  },

}
