import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSwan } from '../../../infra/libs/redux-swans/useSwan'
import { createReducer } from '../../../infra/libs/redux-hardtyped/createReducer'
import { cenarioActionCreators } from '../../../store/actions/cenarioActions'
import { defineActionCreators } from '../../../infra/libs/redux-hardtyped/defineActionCreators'
import { ModalWithActionsComponent } from '../../ui-components/modal/ModalWithActionsComponent'
import { IconCenarioComponent } from '../../ui-components/icons/IconCenarioComponent'


export const modalCenarioCriadoActions = defineActionCreators('modalCenarioCriado', {
  fecharModal: () => ({}),
})

export type ModalCenarioCriadoState = null | {
  idCenario: number
}

const initialState = null

const reducer = createReducer<ModalCenarioCriadoState>(initialState)(handle => ([
  handle(cenarioActionCreators.cenarioCriadoPelaTelaNovoCenario, (state, action) => {
    const { id } = action
    return { idCenario: id }
  }),
  handle(modalCenarioCriadoActions.fecharModal, () => null),
]))

export function ModalCenarioCriadoContainer() {
  const history = useHistory()
  const dispatch = useTypedDispatch()
  const state = useSwan(reducer)
  const modalEstaAberta = !!state?.idCenario
  const idCenario = state?.idCenario

  function onAction() {
    history.push(`/cenarios/${idCenario}`)
    dispatch(modalCenarioCriadoActions.fecharModal())
  }

  if (!modalEstaAberta) return null
  return (
    <ModalWithActionsComponent
      icon={<IconCenarioComponent fill="#2f5dc9" height="74" width="74" />}
      primaryColor="blue"
      title="Cenário criado"
      text="Você pode acompanhar as últimas atualizações e editar as configurações acessando a página do cenário."
      actionButtonText="Ir para a página do cenário"
      onAction={onAction}
    />
  )
}
