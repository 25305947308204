import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TitleComponent } from '../ui-components/head/TitleComponent'
import { MinhasFichasContainer } from '../containers/ficha/MinhasFichasContainer'

const useStyles = makeStyles({
  root: {
    marginTop: '32px',
    marginBottom: '80px',
    paddingLeft: '30px',
  },
})

export function MinhasFichasView() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <TitleComponent title="Minhas fichas" />
      <MinhasFichasContainer />
    </div>
  )
}
