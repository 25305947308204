import { dateUtil } from '../../../util/dateUtil'
import { frontendRoutesUtil } from '../../../util/frontendRoutesUtil'
import { retirarTagsHtml } from '../retirarTagsHtml'
import { TIPOS_FATO } from '../../../domain/TipoFato'
import { ResumoFato } from '../../../domain/ResumoFato'

export function montarResumoNoticiaClipping(noticia): ResumoFato {
  const tipoFato = TIPOS_FATO.NOTICIA_CLIPPING.id
  return {
    id: noticia._id,
    titulo: noticia._source.titulo,
    tipoFato,
    resumo: retirarTagsHtml(noticia._source.resumo),
    urlDestinoFato: frontendRoutesUtil.montarUrlDetalhamentoFato(noticia._id, tipoFato),
    informacaoAdicionalMaisRelevante: noticia._source.veiculo_nome,
    informacaoAdicionalMenosRelevante: dateUtil.formatarDataHora(noticia._source.data_publicacao) ?? '',
    data: noticia._source.data_publicacao,
  }
}
