import { BuscarProposicaoLegislativaEndpoint } from 'bff/src/main/controllers/fato/proposicoesLegislativasEndpoints'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'

export const proposicaoLegislativaThunkCreators = {

  buscarProposicaoLegislativaPorId: (id: string) => createBffRequestThunk({
    request: {
      endpoint: BuscarProposicaoLegislativaEndpoint.definition,
      params: { id },
    },
  }),

}
