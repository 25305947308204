import { AxiosPromise } from 'axios'
import { ambienteUtil } from '../../util/ambienteUtil'
import { authJwtApi } from './authJwtApi'
import { AuthParams } from './AuthParams'

export function obterTokenJwtDoUsuario(tokenPortalTcu?: string): AxiosPromise<AuthParams> {
  if (tokenPortalTcu) {
    return authJwtApi.gerarTokenDeUsuarioPeloTokenPortalTcu(tokenPortalTcu)
  }
  if (ambienteUtil.isNotAmbienteProducao()) {
    return authJwtApi.gerarTokenDeUsuarioDeTeste()
  }
  throw new Error('Token não informado.')
}
