/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Route, RouteProps, Switch } from 'react-router-dom'

export interface BasicSwitchRouterProps {
  routes: MapaRoute[]
}

export type MapaRoute = RouteProps

export function BasicSwitch({ routes }: BasicSwitchRouterProps) {
  return (
    <Switch>
      {routes.map((r, index) => (
        <Route
          key={index}
          location={r.location}
          component={r.component}
          render={r.render}
          path={r.path}
          exact
          sensitive={r.sensitive}
          strict={r.strict}
        >
          {r.children}
        </Route>
      ))}
    </Switch>
  )
}
