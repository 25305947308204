import { MapaRoute } from '../components/containers/infra/BasicSwitch'
import { ResultadosView } from '../components/views/ResultadosView'
import { PerfilAtorView } from '../components/views/PerfilAtorView'
import { NoticiaClippingView } from '../components/views/NoticiaClippingView'
import { EventoView } from '../components/views/EventoView'
import { NoticiaUniaoView } from '../components/views/NoticiaUniaoView'
import { ProcessoView } from '../components/views/ProcessoView'
import { ProposicaoLegislativaView } from '../components/views/ProposicaoLegislativaView'
import { PerfilEntidadeView } from '../components/views/PerfilEntidadeView'
import { VisualizarFichaView } from '../components/views/VisualizarFichaView'

export const headerLayoutRoutes: MapaRoute[] = [
  {
    path: '/resultados',
    component: ResultadosView,
  },
  {
    path: '/ator/:id',
    component: PerfilAtorView,
  },
  {
    path: '/entidade/:id',
    component: PerfilEntidadeView,
  },
  {
    path: '/noticia/:id',
    component: NoticiaClippingView,
  },
  {
    path: '/uniao/:id',
    component: NoticiaUniaoView,
  },
  {
    path: '/evento/:id',
    component: EventoView,
  },
  {
    path: '/processo/:codigoProcesso',
    component: ProcessoView,
  },
  {
    path: '/proposicao-legislativa/:id',
    component: ProposicaoLegislativaView,
  },
  {
    path: '/fichas/:id',
    component: VisualizarFichaView,
  },
]
