import React, { useEffect, useState } from 'react'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { ORDENACOES } from 'bff/src/main/domain/Ordenacao'
import { updatePropertyInQueryParams } from 'bff/src/main/util/urlUtil'
import { useQueryParamAsString } from '../../../infra/libs/react-location-hooks/routerHooks'
import { QUERY_PARAM_ORDENACAO } from '../../../util/constantesQueryParams'


const useStyles = makeStyles({
  titulo: {
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 400,
  },
  iconeOrdenacao: {
    marginRight: '8px',
  },
  iconeSeta: {
    marginLeft: '8px',
  },
})

export function OrdenacaoComponent() {

  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const [label, setLabel] = useState<string>()
  const ordenacao = useQueryParamAsString(QUERY_PARAM_ORDENACAO)

  useEffect(() => {
    const optionSelected = ORDENACOES[ordenacao ?? '']
    if (optionSelected) {
      setLabel(optionSelected.label)
    }
    setLabel(ORDENACOES.MAIS_RELEVANTE.label)
  }, [ordenacao])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    setAnchorEl(null)
    if (event.currentTarget.attributes.value) {
      const { pathname, search } = history.location
      const stringfiedQueryParams = updatePropertyInQueryParams(QUERY_PARAM_ORDENACAO, event.currentTarget.attributes.value.value, search)
      const url = `${pathname}?${stringfiedQueryParams}`
      history.push(url)
    }
  }

  return (
    <div>
      <Button className={classes.titulo} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <i className={`${classes.iconeOrdenacao} fas fa-sort-amount-down`} />
        {label}
        <i className={`${classes.iconeSeta} fa fa-caret-down`} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          Object.values(ORDENACOES).map(o => (
            <MenuItem
              key={o.id}
              onClick={handleClose}
              value={o.id}
            >
              {o.label}
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  )
}
