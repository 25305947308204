import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

const useStyles = makeStyles({
  boxStatus: {
    border: '1px solid #c4c4c4',
    borderRadius: '6px',
    padding: '16px 24px',
    marginTop: '40px',
  },
  tituloStatus: {
    fontSize: '18px',
    color: '#434343',
    fontWeight: 500,
    marginBottom: 0,
  },
  subTitulo: {
    fontSize: '14px',
    fontWeight: 400,
    marginBottom: '16px',
  },
  lista: {
      
  },
  listaTitulo: {
    fontWeight: 500,
    marginLeft: '-16px',
    fontSize: '14px',
    color: '#434343',
  },
  listaDado: {
    marginRight: '-16px',
    fontSize: '14px',
  },
  divider: {
    marginTop: '16px',
  },  
})

export interface StatusSistemaComponentProps {
  data?: string
  hora?: string
  atores: string
  entidades: string
  eventos: string
  NoticiasEuniao: string
  processos: string
  proposicoes: string
}

export function StatusSistemaComponent({
  data, hora, atores, entidades, eventos, NoticiasEuniao, processos, proposicoes, 
}: StatusSistemaComponentProps) {
  const classes = useStyles()
  return (
    <div className={classes.boxStatus}>
      <h4 className={classes.tituloStatus}>Status do Sistema</h4>
      {
        data && hora 
          ? (
            <span className={classes.subTitulo}>
              Atualizado em 
              <span>{data}</span>
              {' '}
              às
              <span>{hora}</span>
            </span>
          ) : null
      }
      <Divider className={classes.divider} />
      <div className={classes.lista}>
        <List dense>
          <ListItem>
            <ListItemText>
              <span className={classes.listaTitulo}>Atores</span>
            </ListItemText>
            <ListItemSecondaryAction>
              <span className={classes.listaDado}>{atores}</span>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>
              <span className={classes.listaTitulo}>Entidades</span>
            </ListItemText>
            <ListItemSecondaryAction>
              <span className={classes.listaDado}>{entidades}</span>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>
              <span className={classes.listaTitulo}>Eventos</span>
            </ListItemText>
            <ListItemSecondaryAction>
              <span className={classes.listaDado}>{eventos}</span>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>
              <span className={classes.listaTitulo}>Notícias/União</span>
            </ListItemText>
            <ListItemSecondaryAction>
              <span className={classes.listaDado}>{NoticiasEuniao}</span>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>
              <span className={classes.listaTitulo}>Processos</span>
            </ListItemText>
            <ListItemSecondaryAction>
              <span className={classes.listaDado}>{processos}</span>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText>
              <span className={classes.listaTitulo}>Proposições</span>
            </ListItemText>
            <ListItemSecondaryAction>
              <span className={classes.listaDado}>{proposicoes}</span>
            </ListItemSecondaryAction>
          </ListItem>  
        </List>
      </div>
    </div>
  )
}
