import {
  applyMiddleware,
  createStore,
  Reducer,
  Store,
  StoreEnhancer,
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { rootReducer } from './reducers/root/rootReducer'
import { middlewares } from './middlewares'
import { RootState } from './reducers/root/RootState'


const reduxStateLocalStorageItemKey = 'reduxState'

const persistableStateSliceKeys: (keyof RootState)[] = [
  'session',
]


export function setupStore() {
  const composedEnhancer = createComposedEnhancer()
  const newStore = generateStore(rootReducer, composedEnhancer)
  addStoreListenerToSaveState(newStore)
  return newStore
}

function generateStore(reducer: Reducer<RootState>, composedEnhancer: StoreEnhancer) {
  try {
    const persistedState = getPersistedState()
    return createStore(reducer, persistedState, composedEnhancer)
  } catch (e) {
    console.warn(
      'Não foi possível criar o store do redux usando o state persistido no LocalStorage. '
      + 'Alterando criação do store para não usar o state do LocalStorage...',
    )
    console.warn(e)
    return createStore(reducer, composedEnhancer)
  }
}

function getPersistedState() {
  const serializedState = localStorage.getItem(reduxStateLocalStorageItemKey)
  if (serializedState) {
    const persistedState = JSON.parse(serializedState)
    return persistedState ?? undefined
  }
  return undefined
}

function createComposedEnhancer() {
  const middlewaresEnhancer = applyMiddleware(...middlewares)
  return composeWithDevTools(middlewaresEnhancer)
}

function addStoreListenerToSaveState(thisStore: Store<RootState>) {
  thisStore.subscribe(() => saveState(thisStore.getState()))
}

function saveState(state: RootState) {
  const persitableStateSlice = extractPersitableStateSlice(state)
  const serializedStateSlice = JSON.stringify(persitableStateSlice)
  localStorage.setItem(reduxStateLocalStorageItemKey, serializedStateSlice)
}

function extractPersitableStateSlice(state: RootState) {
  const stateSlice: any = {}
  persistableStateSliceKeys.forEach((key) => {
    stateSlice[key] = state[key]
  })
  return stateSlice
}
