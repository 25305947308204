import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Representante } from 'bff/src/main/domain/Processo'
import { TituloComponent } from './TituloComponent'

const useStyles = makeStyles({
  nomeRepresentado: {
    '@media screen and (max-width:1280px)': {
      display: 'block',
      marginLeft: '0px',
    },
    fontSize: '14px',
    color: '#9e9e9e',
    marginLeft: '16px',
  },
  texto: {
    fontSize: '16px',
  },
  titulo: {
    marginBottom: '24px',
  },
})

interface RepresentantesListaComponentProps {
  lista?: Representante[]
  className?: string
}

export function RepresentantesComponent({ lista, className } : RepresentantesListaComponentProps) {
  const classes = useStyles()
  if (!lista || lista.length < 1) {
    return null
  }
  return (
    <div className={className}>
      <TituloComponent className={classes.titulo} titulo="Representantes" />
      <ul className={classes.texto}>
        {lista?.map(item => (
          <li key={`${item.nomeRepresentante}${item.nomeRepresentado}`}>
            <div>
              {item.nomeRepresentante}
              <span className={classes.nomeRepresentado}>{item.nomeRepresentado}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
