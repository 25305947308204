import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { NoticiaClipping } from 'bff/src/main/domain/NoticiaClipping'
import { ListaStakeholdersContainer } from '../containers/stakeholder/ListaStakeholdersContainer'
import {
  useParam,
  useQueryParamAsArrayOfString,
  useQueryParamAsString,
} from '../../infra/libs/react-location-hooks/routerHooks'
import { FatoCompletoHeaderPlaceholderComponent } from '../ui-components/fato/FatoCompletoHeaderPlaceholderComponent'
import { FatoCompletoBodyPlaceholderComponent } from '../ui-components/fato/FatoCompletoBodyPlaceholderComponent'
import { ScrollToTopOnMount } from '../containers/util/ScrollToTopOnMount'
import { FatoCompletoHeaderComponent } from '../ui-components/fato/FatoCompletoHeaderComponent'
import { noticiaClippingThunkCreators } from '../../store/actions/noticiaClippingActions'
import { FatosRelacionadosComponent } from '../ui-components/fato/FatosRelacionadosComponent'
import { TitleComponent } from '../ui-components/head/TitleComponent'
import { InformacaoAdicionalFatoComponent } from '../ui-components/comum/InformacaoAdicionalFatoComponent'
import { PaginaDeErroHttpComponent } from '../ui-components/erro/PaginaDeErroHttpComponent'
import { useTypedDispatch, TypedDispatch } from '../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../infra/libs/react-swearing/usePromiseEffect'
import {
  QUERY_PARAM_KEYWORDS,
  QUERY_PARAM_TEMAS,
  QUERY_PARAM_TEXTO_LIVRE,
} from '../../util/constantesQueryParams'


const useStyles = makeStyles({
  root: {
    marginTop: '40px',
    paddingBottom: '100px',
  },
  header: {
    marginBottom: '48px',
  },
  espacoMobile: {
    '@media screen and (max-width:767px)': {
      marginTop: '40px',
    },
  },
  autor: {
    marginTop: '40px',
  },
})

export function NoticiaClippingView() {
  const classes = useStyles()
  const dispatch = useTypedDispatch()
  const idFato = useParam('id')
  const keywords = useQueryParamAsArrayOfString(QUERY_PARAM_KEYWORDS)
  const temas = useQueryParamAsArrayOfString(QUERY_PARAM_TEMAS)
  const textoLivre = useQueryParamAsString(QUERY_PARAM_TEXTO_LIVRE)
  const { data: noticiaClipping, isLoading } = usePromiseEffect(pegarFato, [dispatch, idFato])

  const termosParaDestacar = [...keywords, ...temas]
  if (textoLivre) {
    termosParaDestacar.push(...textoLivre.split(' '))
  }
  
  if (!isLoading && !noticiaClipping) {
    return (
      <PaginaDeErroHttpComponent
        mensagemDeErro="Tivemos um problema para exibir o fato"
      />
    )
  }
  return render(classes, isLoading, termosParaDestacar, noticiaClipping)
}

function render(
  classes, isLoading: boolean, termosParaDestaque: string[],
  noticiaClipping?: NoticiaClipping, 
) {
  return (
    <div className={`container ${classes.root}`}>
      <TitleComponent title={noticiaClipping?.titulo} />
      <ScrollToTopOnMount />
      <div className={classes.header}>
        {renderFatoHeader(isLoading, noticiaClipping)}
      </div>
      <div className="row">
        <div className="col-md-5">
          {renderFatoBody(classes, isLoading, termosParaDestaque, noticiaClipping)}
        </div>
        <div className={`col-md-5 offset-md-2 ${classes.espacoMobile}`}>
          <ListaStakeholdersContainer
            stakeholdersIds={noticiaClipping?.stakeholdersIds || []}
            tituloAutoridadesTcu="Autoridades do TCU citadas no fato"
            tituloStakeholders="Stakeholders citados no fato"
          />
          <FatosRelacionadosComponent fatos={noticiaClipping?.fatosRelacionados ?? []} />
        </div>
      </div>
    </div>
  )
}

async function pegarFato(dispatch: TypedDispatch, idFato: string) {
  const { data: fato } = await dispatch(noticiaClippingThunkCreators.buscarNoticiaClippingPorId(idFato))
  return fato
}

function renderFatoHeader(isLoading: boolean, noticiaClipping?: NoticiaClipping) {
  if (isLoading) {
    return <FatoCompletoHeaderPlaceholderComponent />
  }
  if (!noticiaClipping) {
    return null
  }
  return (
    <FatoCompletoHeaderComponent
      tipoFato={noticiaClipping.tipoFato}
      titulo={noticiaClipping.titulo}
      urlFonte={noticiaClipping.urlFonte}
      infoAdicional={noticiaClipping.nomeEntidade}
      dataHora={noticiaClipping.dataHora}
    />
  )
}

function renderFatoBody(
  classes, isLoading: boolean, termosParaDestaque: string[],
  noticiaClipping?: NoticiaClipping,
) {
  if (isLoading) {
    return <FatoCompletoBodyPlaceholderComponent />
  }
  if (!noticiaClipping) {
    return null
  }
  return (
    <div>
      <InformacaoAdicionalFatoComponent texto={noticiaClipping.texto} termosParaDestacar={termosParaDestaque} />
      <InformacaoAdicionalFatoComponent titulo="Autor" texto={noticiaClipping.nomeAtor} className={classes.autor} termosParaDestacar={termosParaDestaque} />
    </div>
  )
}
