import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
  animacao: {
    background: 'linear-gradient(45deg, #c7c7c7, #8a8a8a26, #cbcbcb24)',
    backgroundSize: '400% 400%',
    animation: '$gradient 3s ease infinite',
  },
  root: {

  },
  paragrafo: {
    display: 'flex',
    marginTop: '35px',
  },
  linhas: {
    flex: '1 1 auto',
  },
  linha: {
    height: '25px',
    marginRight: '15px',
    marginBottom: '15px',
    width: '90%',
    borderRadius: '5px',
  },
  menor: {
    width: '50%',
  },
})

export function FatoCompletoBodyPlaceholderComponent() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {renderParagrafo(classes)}
      {renderParagrafo(classes)}
      {renderParagrafo(classes)}
      {renderParagrafo(classes)}
    </div>
  )
}

function renderParagrafo(classes) {
  return (
    <div className={classes.paragrafo}>
      <div className={classes.linhas}>
        <div className={`${classes.linha} ${classes.animacao}`} />
        <div className={`${classes.linha} ${classes.animacao}`} />
        <div className={`${classes.linha} ${classes.menor} ${classes.animacao}`} />
      </div>
    </div>
  )
}
