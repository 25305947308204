import moment from 'moment'


const MASCARA_DATA_HORA = 'DD/MM/YYYY [às] HH:mm'
const MASCARA_DATA = 'DD/MM/YYYY'

export const dateUtil = {

  formatarDataHora(data?: string) {
    if (data) {
      return moment(String(data)).format(MASCARA_DATA_HORA)
    }
    return undefined
  },

  formatarData(data?: string) {
    if (data) {
      return moment(String(data)).format(MASCARA_DATA)
    }
    return undefined
  },

}
