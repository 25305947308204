import { EnumObjectWithId } from '../util/EnumObject'

export type TipoTrechoFicha =
  'CITACAO_FATO' |
  'PARAGRAFO' |
  'TITULO' |
  'SUBTITULO' |
  'IMAGEM'

export const TIPOS_TRECHO_FICHA: EnumObjectWithId<TipoTrechoFicha, {}> = {
  CITACAO_FATO: {
    id: 'CITACAO_FATO',
  },
  PARAGRAFO: {
    id: 'PARAGRAFO',
  },
  TITULO: {
    id: 'TITULO',
  },
  SUBTITULO: {
    id: 'SUBTITULO',
  },
  IMAGEM: {
    id: 'IMAGEM',
  },
}
