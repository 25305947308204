import React from 'react'
import { cenarioThunkCreators } from '../../../../store/actions/cenarioActions'
import { useTypedDispatch } from '../../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSwan } from '../../../../infra/libs/redux-swans/useSwan'
import { modalExcluirCenarioReducer } from './modalExcluirCenarioReducer'
import { ModalWithActionsComponent } from '../../../ui-components/modal/ModalWithActionsComponent'
import { IconRemoveComponent } from '../../../ui-components/icons/IconRemoveComponent'
import { modalExcluirCenarioActionCreators } from './modalExcluirCenarioActions'


export function ModalExcluirCenarioContainer() {

  const dispatch = useTypedDispatch()
  const state = useSwan(modalExcluirCenarioReducer)
  const modalEstaFechada = !state
  const idCenario = state?.idCenario ?? 0
  const nomeCenario = state?.nomeCenario

  async function onAction() {
    await dispatch(cenarioThunkCreators.excluirCenario(idCenario ?? ''))
  }

  function onCancel() {
    dispatch(modalExcluirCenarioActionCreators.fecharModal())
  }

  if (modalEstaFechada) return null
  return (
    <ModalWithActionsComponent
      icon={<IconRemoveComponent fill="#e35555" height="74" width="74" />}
      primaryColor="red"
      title="Excluir o cenário"
      text={`Você está prestes a excluir o cenário ${nomeCenario}. Essa ação não poderá ser desfeita.`}
      actionButtonText="Excluir cenário"
      onAction={onAction}
      cancelButtonText="Cancelar"
      onCancel={onCancel}
    />
  )
}
