import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  EventoSigiloso: {
    background: '#e35555',
    color: '#fff',
    padding: '4px 8px',
    borderRadius: '3px',
    fontWeight: 500,
    fontSize: '14px',
  },
})

export function TagSigilosoComponent({ className }: { className?: string }) {
  const classes = useStyles()
  return (
    <span className={className}>
      <span className={classes.EventoSigiloso}>Sigiloso</span>
    </span>
  )
}
