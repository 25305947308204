import React from 'react'
import { cenarioThunkCreators } from '../../../../store/actions/cenarioActions'
import { modalArquivarCenarioActionCreators } from './modalArquivarCenarioActions'
import { useTypedDispatch } from '../../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSwan } from '../../../../infra/libs/redux-swans/useSwan'
import { modalArquivarCenarioReducer } from './modalArquivarCenarioReducer'
import { useSpinningPromise } from '../../../../infra/libs/react-swearing/SpinnerScreenProvider'
import { ModalWithActionsComponent } from '../../../ui-components/modal/ModalWithActionsComponent'
import { IconArquivarComponent } from '../../../ui-components/icons/IconArquivarComponent'


export function ModalArquivarCenarioContainer() {

  const dispatch = useTypedDispatch()
  const state = useSwan(modalArquivarCenarioReducer)
  const modalEstaFechada = !state
  const idCenario = state?.idCenario ?? 0

  const { run } = useSpinningPromise((id: number) => dispatch(cenarioThunkCreators.arquivarCenario(id)))

  const onAction = () => run(idCenario)
  const onCancel = () => dispatch(modalArquivarCenarioActionCreators.fecharModal())

  if (modalEstaFechada) return null
  return (
    <ModalWithActionsComponent
      icon={<IconArquivarComponent fill="#e35555" height="74" width="74" />}
      primaryColor="red"
      title="Deseja arquivar o cenário?"
      text="Você poderá voltar a ver os últimos acontecimentos reativando o cenário na página Meus Cenários"
      actionButtonText="Arquivar cenário"
      onAction={onAction}
      cancelButtonText="Cancelar"
      onCancel={onCancel}
    />
  )
}
