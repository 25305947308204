/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Redirect } from 'react-router-dom'
import { BasicSwitch, MapaRoute } from '../components/containers/infra/BasicSwitch'
import { EmptyLayout } from '../components/layouts/EmptyLayout'
import { HeaderLayout } from '../components/layouts/HeaderLayout'
import { emptyLayoutRoutes } from './emptyLayoutRoutes'
import { headerLayoutRoutes } from './headerLayoutRoutes'
import { menuHeaderLayoutRoutes } from './menuHeaderLayoutRoutes'
import { MenuHeaderLayout } from '../components/layouts/MenuHeaderLayout'

export const layoutRoutes: MapaRoute[] = [
  {
    path: getPathsFromRoutes(emptyLayoutRoutes),
    render: () => renderLayout(EmptyLayout, emptyLayoutRoutes),
  },
  {
    path: getPathsFromRoutes(headerLayoutRoutes),
    render: () => renderLayout(HeaderLayout, headerLayoutRoutes),
  },
  {
    path: getPathsFromRoutes(menuHeaderLayoutRoutes),
    render: () => renderLayout(MenuHeaderLayout, menuHeaderLayoutRoutes),
  },
  {
    // se os de cima não derem match, vai esse por default
    render: () => <Redirect to="/404" />,
  },
]

function renderLayout(LayoutComponent: React.ComponentType<any>, routes: MapaRoute[]) {
  return (
    <LayoutComponent>
      <BasicSwitch routes={routes} />
    </LayoutComponent>
  )
}

function getPathsFromRoutes(routes: MapaRoute[]) {
  const paths: string[] = []
  routes.forEach((r) => {
    if (Array.isArray(r.path)) {
      paths.push(...r.path)
    } else {
      paths.push(r.path as string)
    }
  })
  return paths
}
