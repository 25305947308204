import { stakeholdersApi } from '../../api/stakeholdersApi'
import { temasApi } from '../../api/temasApi'
import { TIPOS_STAKEHOLDER } from '../../domain/TipoStakeholder'
import { SugestaoDeBusca } from '../../domain/SugestaoDeBusca'
import { TipoSugestaoDeBusca } from '../../domain/TipoSugestaoDeBusca'
import { servicoFoneticoApi } from '../../api/servicoFoneticoApi'

export async function recuperarSugestoesBusca(word: string, tipoPesquisa?: TipoSugestaoDeBusca) {
  let listaStakeholders: any[] = []
  let listaTemas: any[] = []
  if (!tipoPesquisa) {
    const { respostaStakeholders, respostaTemas } = await recuperarTodasSugestoesBusca(word)
    listaStakeholders = respostaStakeholders
    listaTemas = respostaTemas
  } else if (tipoPesquisa === 'TEMA') {
    listaTemas = await recuperarSugestoesTemas(word)
  } else {
    listaStakeholders = await recuperarSugestoesAtorOuEntidade(word, tipoPesquisa)
  }
  return mapearParaSugestaoDeBusca(listaStakeholders, listaTemas)
}

async function recuperarTodasSugestoesBusca(word: string) {
  const respostaStakeholders = await recuperarSugestoesStakeholders(word)
  const respostaTemas = await recuperarSugestoesTemas(word)
  return { respostaStakeholders, respostaTemas }
}

async function recuperarSugestoesTemas(word: string) {
  const { body: respostaIndiceTemas } = await temasApi.buscarTemasParaSugestaoBuscaPorPedacoNome(word)
  return respostaIndiceTemas.hits.hits
}

async function recuperarSugestoesAtorOuEntidade(word: string, tipoPesquisa: TipoSugestaoDeBusca) {
  const respostaStakeholders = await recuperarSugestoesStakeholders(word)
  return respostaStakeholders.filter(stakeholder => stakeholder._source.tipo_stakeholder === tipoPesquisa)
}

async function recuperarSugestoesStakeholders(word: string) {
  const { data: listaPalavras } = await servicoFoneticoApi.converterTextoParaTextoFonetico(word)
  const { body: respostaIndiceStakeholders } = await stakeholdersApi.buscarStakeholdersParaSugestaoBuscaPorPedacoNome(word, listaPalavras.join(' '))
  return respostaIndiceStakeholders.hits.hits
}

export async function recuperarSugestoesStakeholdersComFoto(word: string, limite: number) {
  const { data: listaPalavras } = await servicoFoneticoApi.converterTextoParaTextoFonetico(word)
  const { body: respostaIndiceStakeholders } = await stakeholdersApi.buscarStakeholdersPorPedacoNomeComFoto(word, listaPalavras.join(' '), limite)
  return respostaIndiceStakeholders.hits.hits
}

function mapearParaSugestaoDeBusca(listaStakeholders: any[], listaTemas: any[]) {
  const tipoSugestaoTema: TipoSugestaoDeBusca = 'TEMA'
  const listaResultado: SugestaoDeBusca[] = []
  listaResultado.push(...listaTemas.map(tema => ({
    id: tema._id,
    tipo: tipoSugestaoTema,
    nome: tema._source.funcao,
    papel: 'Tema',
  })))
  listaResultado.push(...listaStakeholders.map(stakeholder => ({
    id: stakeholder._id,
    tipo: stakeholder._source.tipo_stakeholder,
    nome: mapearTipoStakeholder(stakeholder, stakeholder._source.nome, stakeholder._source.nome_completo),
    papel: mapearTipoStakeholder(stakeholder, stakeholder._source.cargo, stakeholder._source.nome),
  })))
  return listaResultado
}

function mapearTipoStakeholder(stakeholder, respostaCasoAtor: string, respostaCasoEntidade: string) {
  if (stakeholder._source.tipo_stakeholder === TIPOS_STAKEHOLDER.ATOR.id) {
    return respostaCasoAtor
  }
  return respostaCasoEntidade
}
