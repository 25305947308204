import React from 'react'
import { useSwan } from '../../../infra/libs/redux-swans/useSwan'
import { createReducer } from '../../../infra/libs/redux-hardtyped/createReducer'
import { defineActionCreators } from '../../../infra/libs/redux-hardtyped/defineActionCreators'
import { AlterarDescricaoFichaForm } from '../ficha/AlterarDescricaoFichaForm'
import { useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSpinningPromise } from '../../../infra/libs/react-swearing/SpinnerScreenProvider'
import { fichaActionCreators, fichaThunkCreators } from '../../../store/actions/fichaActions'


export const modalAlterarDescricaoFichaActions = defineActionCreators('modalAlterarDescricaoFicha', {
  abrirModal: (fichaId: number, descricaoFicha?: string) => ({ descricaoFicha, fichaId }),
  fecharModal: () => ({}),
})

export type ModalAlterarDescricaoFichaState = null | {
  fichaId: number
  descricaoFicha?: string
}

const initialState = null

const reducer = createReducer<ModalAlterarDescricaoFichaState>(initialState)(handle => ([
  handle(modalAlterarDescricaoFichaActions.abrirModal, (state, action) => {
    const { fichaId, descricaoFicha } = action
    return { fichaId, descricaoFicha }
  }),
  handle(modalAlterarDescricaoFichaActions.fecharModal, () => null),
  handle(fichaActionCreators.descricaoAlterada, () => null),
]))

export function ModalAlterarDescricaoFichaContainer() {
  const dispatch = useTypedDispatch()
  const state = useSwan(reducer)
  const modalEstaAberta = !!state?.fichaId
  const fichaId = state?.fichaId ?? 0
  const descricaoFicha = state?.descricaoFicha
  
  const { run: onConfirm } = useSpinningPromise(
    (novaDescricaoFicha: string) => dispatch(fichaThunkCreators.editarDescricaoFicha(fichaId, novaDescricaoFicha)),
    [fichaId],
  )

  
  const { run: onLimparDescricao } = useSpinningPromise(
    () => dispatch(fichaThunkCreators.editarDescricaoFicha(fichaId, '')),
    [fichaId],
  )
  
  const onCancel = () => dispatch(modalAlterarDescricaoFichaActions.fecharModal())

  if (!modalEstaAberta) return null
  return (
    <AlterarDescricaoFichaForm 
      onConfirm={onConfirm}
      onCancel={onCancel} 
      descricaoInicial={descricaoFicha || ''} 
      onLimparDescricao={onLimparDescricao}
    />
  )
}
