import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { IconEditComponent } from '../icons/IconEditComponent'

const useStyles = makeStyles({
  box: {
    cursor: 'pointer',
  },
  texto: {
    color: '#214CA2',
    fontSize: '14px',
    marginBottom: 0,
    marginLeft: '16px',
  },
  alignItens: {
    display: 'flex',
    alignItems: 'center',
  },

})

interface AdicionarDescricaoFichaComponentProps{
  textoDescricao?: string
  onClick: () => void
}

export function DescricaoFichaComponent({ textoDescricao, onClick }: AdicionarDescricaoFichaComponentProps) {
  const classes = useStyles()
  return (
    <span
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={onClick}
      className={classes.box}
    >
      <div className={classes.alignItens}>
        <div>
          <IconEditComponent fill="#214CA2" height="17" width="17" />
        </div>
        <div>
          <p className={classes.texto}>
            {textoDescricao || 'Clique aqui para adicionar uma descrição à ficha'}
          </p>
        </div>
      </div>
    </span>
  )
}
