import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { FatoCompletoBodyComponent } from '../fato/FatoCompletoBodyComponent'
import { useDestacarTermos } from '../../containers/fato/useDestacarTermos'
import { MapaColor } from '../../../style/theme'

const useStyles = makeStyles({
  titulo: {
    fontWeight: 500,
  },
  textoWrapper: {
    color: MapaColor.neutralDark,
    lineHeight: '25px',
    fontSize: '16px',
    overflow: 'hidden',
    '&:focus': {
      outline: 'none',
    },
    '& *': {
      maxWidth: '100%',
    },
  },
})

interface InformacaoAdicionalFatoComponentProps {
  titulo?: string
  texto: string
  className?: string
  termosParaDestacar?: string[]
}

export function InformacaoAdicionalFatoComponent({ titulo, texto, className, termosParaDestacar }: InformacaoAdicionalFatoComponentProps) {
  const classes = useStyles()
  const textoComMarcacoes = useDestacarTermos(texto, termosParaDestacar)
  
  if (!textoComMarcacoes) {
    return null
  }
  
  return (
    <div className={className}>
      {
        titulo ? (
          <span className={classes.titulo}>
            {`${titulo}: `}
          </span>
        ) : null 
      }
      <FatoCompletoBodyComponent texto={textoComMarcacoes} />
    </div>
  )
}
