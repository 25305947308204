import React, { useState, MouseEvent } from 'react'
import { Chip, IconButton, Popover } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import { KeyboardArrowDown } from '@material-ui/icons'
import { TIPOS_CONECTORES_OU } from 'bff/src/main/domain/TipoConectorOu'
import atorIcon from '../../../assets/atorIcon.svg'
import entidadeIcon from '../../../assets/entidadeIcon.svg'
import { ButtonSpanComponent } from './ButtonSpanComponent'
import { ListaTagPorCampoComponent } from './ListaTagsPorCampoComponent'
import { ConectorComponent } from './ConectorComponent'
import { MapaColor } from '../../../style/theme'

const useTagsStyles = makeStyles({
  box: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    borderColor: MapaColor.neutralMediumLight,
    border: 'solid',
    borderWidth: '1px 1px 1px 0px',
    '&:hover': {
      border: '1px solid #D4D4D4',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    backgroundColor: MapaColor.neutralLight,
  },
  botaoLimpar: {
    color: MapaColor.primary,
  },
  tag: {
    margin: '0 5px 0 0',
    height: '28px',
    maxWidth: 'none',
  },
  tagIcon: {
    marginLeft: '6px !important',
    height: '24px',
    fontSize: '24px',
    color: 'inherit',
  },
  tagDeleteIcon: {
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
      opacity: 0.8,
    },
  },
  tagIconTema: {
    fontSize: '17px',
    top: '4px',
  },
  tagLimparPesquisa: {
    fontWeight: 700,
    marginTop: '4px',
    color: MapaColor.infoMedium,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  listaTags: {
    padding: '13px',
    maxWidth: '500px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    lineHeight: '33px',
  },
  alinhamentoTagLimpar: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
})

interface ListaTagsComponentProps {
  tags: Tag[]
  onClear: () => void
  conectoresOu: string[]
}

interface Tag {
  id: string
  tipo: string
  nome: string
}

export function ListaTagsComponent({ tags, onClear, conectoresOu }: ListaTagsComponentProps) {
  const classes = useTagsStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const primeiraTag = tags[0]
  const tagsAtores = filtraTagsPorTipo(tags, TIPOS_CONECTORES_OU.ATOR.id)
  const tagsEntidades = filtraTagsPorTipo(tags, TIPOS_CONECTORES_OU.ENTIDADE.id)
  const tagsTemas = filtraTagsPorTipo(tags, TIPOS_CONECTORES_OU.TEMA.id)
  const tagsKeywords = filtraTagsPorTipo(tags, TIPOS_CONECTORES_OU.KEYWORD.id)

  if (!primeiraTag) {
    return null
  }

  if (!primeiraTag.nome) {
    return null
  }

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'listaTagsSelecionadasPopover' : undefined

  const iconComponents = {
    ATOR: <img src={atorIcon} alt="Ator" />,
    ENTIDADE: <img src={entidadeIcon} alt="Entidade" />,
    TEMA: <i className={`fas fa-tags fa-fw ${classes.tagIconTema}`} />,
    KEYWORD: <SearchIcon />,
  }


  return (
    <div className={classes.box}>
      {
        montaPrimeiraTag(
          classes,
          iconComponents,
          handleClick,
          primeiraTag,
          tags.length,
          conectoresOu,
        )
      }
      {
        tags.length > 1 ? (
          <>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <div className={classes.listaTags}>
                <ListaTagPorCampoComponent
                  tags={tagsAtores}
                  conectoresOu={conectoresOu}
                  isConectorEntreCampos={
                    isConectorEntreCampos(tagsAtores, [tagsEntidades, tagsTemas, tagsKeywords])
                  }
                />
                <ListaTagPorCampoComponent
                  tags={tagsEntidades}
                  conectoresOu={conectoresOu}
                  isConectorEntreCampos={
                    isConectorEntreCampos(tagsEntidades, [tagsTemas, tagsKeywords])
                  }
                />
                <ListaTagPorCampoComponent
                  tags={tagsTemas}
                  conectoresOu={conectoresOu}
                  isConectorEntreCampos={
                    isConectorEntreCampos(tagsTemas, [tagsKeywords])
                  }
                />
                <ListaTagPorCampoComponent
                  tags={tagsKeywords}
                  conectoresOu={conectoresOu}
                  isConectorEntreCampos={false}
                />
              </div>
              <div className={classes.alinhamentoTagLimpar}>
                <ButtonSpanComponent onClick={onClear}>
                  <Chip
                    classes={{
                      root: classes.tagLimparPesquisa,
                    }}
                    variant="outlined"
                    label="Limpar pesquisa"
                  />
                </ButtonSpanComponent>
              </div>
            </Popover>
          </>

        ) : null
      }
      {
        tags.length > 0 ? <IconButton size="small" test-id="icon-clear" className={classes.botaoLimpar} onClick={onClear}><CloseIcon /></IconButton> : null
      }
    </div>
  )
}

function montaPrimeiraTag(classes, iconComponents, handleClick, primeiraTag, qtdTags, conectoresOu) {
  if (qtdTags === 1) {
    return (
      <Chip
        classes={{
          root: `${classes.tag}`,
          icon: classes.tagIcon,
          deleteIcon: classes.tagDeleteIcon,
        }}
        key={primeiraTag.id}
        variant="outlined"
        icon={iconComponents[primeiraTag.tipo]}
        label={primeiraTag.nome}
      />
    )
  }
  return (
    <Chip
      classes={{
        root: classes.tag,
        icon: classes.tagIcon,
        deleteIcon: classes.tagDeleteIcon,
      }}
      key={primeiraTag.id}
      variant="outlined"
      label={montaLabelPrimeiraTag(primeiraTag, qtdTags - 1, conectoresOu)}
      icon={iconComponents[primeiraTag.tipo]}
      deleteIcon={<KeyboardArrowDown />}
      onDelete={handleClick}
      test-id="see-more-button"
    />
  )
}

function montaLabelPrimeiraTag(tag, qtdTags, conectoresOu) {
  return (
    <span>
      {`${tag.nome} `}
      <ConectorComponent
        isConectorOu={conectoresOu.includes(tag.tipo)}
        nomeFuncao="conector-primeira-tag"
      />
      {`+ ${qtdTags}`}
    </span>
  )
}

function isConectorEntreCampos(criterioAtual, proximosCriterios) {
  let isRenderConector = false
  if (criterioAtual.length === 0) {
    return isRenderConector
  }
  proximosCriterios.forEach((criterio) => {
    if (criterio.length > 0) {
      isRenderConector = true
    }
  })
  return isRenderConector
}

function filtraTagsPorTipo(tags, filtro) {
  return tags.filter(tag => tag.tipo.includes(filtro))
}
