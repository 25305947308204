import React, { useEffect, useState } from 'react'
import { History } from 'history'
import { useHistory } from 'react-router-dom'
import moment, { Moment } from 'moment'
import { removePropertyFromQueryParams, updatePropertyInQueryParams } from 'bff/src/main/util/urlUtil'
import { useQueryParamAsMoment, useQueryParamAsString } from '../../../infra/libs/react-location-hooks/routerHooks'
import { PeriodoFilterNovoComponent, FiltroData } from '../../ui-components/filtro/PeriodoFilterNovoComponent'
import { QUERY_PARAM_FILTRO_DATA_FIM, QUERY_PARAM_FILTRO_DATA_INICIO, QUERY_PARAM_FILTRO_PERIODO } from '../../../util/constantesQueryParams'


export function FiltroPeriodoContainer() {

  const history = useHistory()
  const filtroDtInicio = useQueryParamAsMoment(QUERY_PARAM_FILTRO_DATA_INICIO)
  const filtroDtFim = useQueryParamAsMoment(QUERY_PARAM_FILTRO_DATA_FIM)
  const filtroPeriodo = useQueryParamAsString(QUERY_PARAM_FILTRO_PERIODO)
  const [label, setLabel] = useState<string | undefined>()

  useEffect(() => {
    if (filtroPeriodo) {
      const novaLabel = getOptions().find(option => option.key === filtroPeriodo)?.label
      return setLabel(novaLabel)
    }
    if (filtroDtInicio && filtroDtFim) {
      const novaLabel = getOptions().find(option => option.key === 'SELECIONAR_DATA')?.label
      return setLabel(novaLabel)
    }
    const novaLabel = getOptions().find(option => option.key === '')?.label
    return setLabel(novaLabel)
  }, [filtroDtInicio, filtroDtFim, filtroPeriodo])

  return (
    <PeriodoFilterNovoComponent
      dtInicio={filtroDtInicio}
      dtFim={filtroDtFim}
      label={label}
      options={getOptions()}
      onChange={(dtInicioNewValue, dtFimNewValue, periodo) => selecionarPeriodo(history, dtInicioNewValue, dtFimNewValue, periodo)}
    />
  )
}

function getOptions(): FiltroData[] {
  return [
    { key: '', label: 'Qualquer data' },
    { key: 'ULTIMOS_SETE_DIAS', label: 'Últimos 7 dias', dtInicio: moment().subtract(7, 'days'), dtFim: moment() },
    { key: 'ULTIMOS_TRINTA_DIAS', label: 'Últimos 30 dias', dtInicio: moment().subtract(1, 'months'), dtFim: moment() },
    { key: 'ULTIMO_ANO', label: 'Último ano', dtInicio: moment().subtract(1, 'years'), dtFim: moment() },
    { key: 'SELECIONAR_DATA', label: 'Selecionar data' },
  ]
}

function selecionarPeriodo(history: History, dtInicioNewValue?: Moment, dtFimNewValue?: Moment, periodo?: string) {
  if (periodo !== undefined) {
    atualizarFiltroPeriodoNoLocation(history, periodo)
  }
  atualizarFiltroDtInicioDtFimNoLocation(
    history,
    dtInicioNewValue?.format('YYYY-MM-DD'),
    dtFimNewValue?.format('YYYY-MM-DD'),
  )
}

function atualizarFiltroDtInicioDtFimNoLocation(history: History, filterDtInicio?: string, filterDtFim?: string) {
  const { pathname, search } = history.location
  let stringfiedQueryParams = updatePropertyInQueryParams(QUERY_PARAM_FILTRO_DATA_INICIO, filterDtInicio, search)
  stringfiedQueryParams = updatePropertyInQueryParams(QUERY_PARAM_FILTRO_DATA_FIM, filterDtFim, stringfiedQueryParams)
  const url = `${pathname}?${stringfiedQueryParams}`
  history.push(url)
}

function atualizarFiltroPeriodoNoLocation(history: History, periodo: string) {
  const { pathname, search } = history.location
  let stringfiedQueryParams = search
  if (periodo === '') {
    stringfiedQueryParams = removePropertyFromQueryParams(QUERY_PARAM_FILTRO_PERIODO, stringfiedQueryParams)
  } else {
    stringfiedQueryParams = updatePropertyInQueryParams(QUERY_PARAM_FILTRO_PERIODO, periodo, stringfiedQueryParams)
  }
  const url = `${pathname}?${stringfiedQueryParams}`
  history.push(url)
}
