import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ClosableLabelComponent } from '../comum/ClosableLabelComponent'

const useStyles = makeStyles({
  limparFiltros: {
    fontSize: '14px',
    color: '#2f5dc9',
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
})

export interface FiltrosSelecionadosProps {
  filtros: Filtro[]
  handleLimparTodosFiltros: () => void
}

export interface Filtro {
  selecoes: SelecaoDoFiltro[]
}

export interface SelecaoDoFiltro {
  label: string
  onClose: () => void
}

export function FiltrosSelecionadosComponent({ filtros, handleLimparTodosFiltros }: FiltrosSelecionadosProps) {
  const classes = useStyles()
  if (filtros.length < 1) return null
  return (
    <div>
      {filtros.map(filtro => renderFiltroSelecionado(filtro))}
      <br />
      <span
        className={classes.limparFiltros}
        role="button"
        tabIndex={0}
        onClick={handleLimparTodosFiltros}
        onKeyPress={handleLimparTodosFiltros}
      >
        Limpar filtros
      </span>
    </div>
  )
}

function renderFiltroSelecionado({ selecoes }: Filtro) {
  if (selecoes.length < 1) return null
  return selecoes.map(selecao => (
    <ClosableLabelComponent
      key={selecao.label}
      label={selecao.label}
      onClose={selecao.onClose}
    />
  ))
}
