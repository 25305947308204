import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { IconNovaFichaComponent } from '../icons/IconNovaFichaComponent'
import { AppTheme } from '../../../style/theme'
import { ButtonSpanComponent } from './ButtonSpanComponent'
import { IconMinhasFichasComponent } from '../icons/IconMinhasFichasComponent'

const useStyles = makeStyles((theme: AppTheme) => ({
  contextMenu: {
    position: 'absolute',
    width: '310px',
    backgroundColor: '#ffffff',
    borderRadius: '3px',
    border: '1px solid',
    boxShadow: '0 1px 4px 0px',
    borderColor: '#ffffff',
    zIndex: theme.zIndex.tooltip,
    padding: '15px 20px',
  },
  contextMenuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '45px',
    margin: '4px',
  },
  contextMenuLabel: {
    marginLeft: '16px',
  },
}))

interface ContextMenuComponentProps {
  xPosition: number
  yPosition: number
  onCriarFicha: () => void
  onAdicionarTrechoNovaFicha: () => void
}


export function ContextMenuComponent(props: ContextMenuComponentProps) {
  const classes = useStyles()
  return (
    <div
      className={classes.contextMenu}
      style={{
        top: props.yPosition,
        left: props.xPosition,
      }}
    >
      <ButtonSpanComponent onClick={props.onCriarFicha} className={classes.contextMenuItem}>
        <IconNovaFichaComponent fill="#2F5DC9" width="24" height="24" />
        <span className={classes.contextMenuLabel}>Nova ficha</span>
      </ButtonSpanComponent>
      <ButtonSpanComponent onClick={props.onAdicionarTrechoNovaFicha} className={classes.contextMenuItem}>
        <IconMinhasFichasComponent fill="#2F5DC9" width="24" height="24" />
        <span className={classes.contextMenuLabel}>Adicionar a ficha</span>
      </ButtonSpanComponent>
    </div>
  )
}
