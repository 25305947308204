import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Link,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import background from '../../../assets/bg_inicial.jpg'
import logoMapa from '../../../assets/logo.png'
import logoTcu from '../../../assets/logotcu.svg'
import { ButtonComponent } from '../comum/ButtonComponent'

const useStyles = makeStyles({
  root: {
    margin: '0 auto',
    padding: '50px 80px',
    width: '960px',
  },
  bg: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  bgImage: {
    width: '100%',
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '36px',
    paddingRight: '20px',
  },
  logoMapa: {
    flex: 1,
    height: '65px',
    marginRight: '70px',
  },
  title: {
    margin: '0px',
    fontSize: '22px',
    color: '#434343',
  },
  description: {
    color: '#434343',
    marginBottom: '40px',
  },
  subtitle: {
    fontSize: '18px',
    color: '#434343',
  },
  accordion: {
    padding: '15px 10px',
    border: '1px solid #c4c4c4',
    boxShadow: 'none',
  },
  accordionSummary: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#434343',
  },
  accordionContent: {
    flexGrow: 1,
    margin: '0 auto',
    padding: '30px 8px 0 8px',
    borderTop: '1px solid #c4c4c4',
    fontWeight: 500,
    '& > *': {
      display: 'block',
      marginBottom: '15px',
    },
  },
  checkboxControl: {
    margin: '0 auto',
    textAlign: 'center',
    marginTop: '50px',
    marginBottom: '30px',
  },
  checkboxLabel: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#434343',
  },
  buttonControl: {
    margin: '0 auto',
    textAlign: 'center',
    marginBottom: '100px',
  },
  logoTcu: {
    display: 'block',
    margin: '0 auto',
    marginBottom: '10px',
  },
})

export interface LgpdComponentProps {
  onAceitarTermo: () => void
}

export function LgpdComponent(props: LgpdComponentProps) {
  const classes = useStyles()
  const [concordo, setConcordo] = useState(false)
  return (
    <div className={classes.root}>
      <div className={classes.bg}>
        <img className={classes.bgImage} src={background} alt="" />
      </div>
      <div className={classes.header}>
        <img className={classes.logoMapa} src={logoMapa} alt="Logo MAPA" />
        <h4 className={classes.title}>
          TERMO DE RESPONSABILIDADE PARA ACESSO A INFORMAÇÕES DE PROPRIEDADE SOB CUSTÓDIA DO TCU.
        </h4>
      </div>
      <div>
        {renderDescription(classes)}
        {renderAccordion(classes)}
        {renderControl(classes, concordo, setConcordo, props.onAceitarTermo)}
        <img className={classes.logoTcu} src={logoTcu} alt="Logo TCU" />
      </div>
    </div>
  )
}

function renderControl(
  classes: ReturnType<typeof useStyles>,
  concordo: boolean,
  setConcordo: (v: boolean) => void,
  onAceitarTermo: () => void,
) {
  return (
    <>
      <div className={classes.checkboxControl}>
        <FormControlLabel
          label={(
            <span className={classes.checkboxLabel}>
              Li e concordo com as condições apresentadas neste termo.
            </span>
          )}
          control={(
            <Checkbox
              color="primary"
              checked={concordo}
              onChange={event => setConcordo(event.target.checked)}
            />
          )}
        />
      </div>
      <div className={classes.buttonControl}>
        <ButtonComponent
          buttonText="Concordo com o termo"
          buttonTypeColor="blue"
          disabled={!concordo}
          onClick={onAceitarTermo}
        />
      </div>
    </>
  )
}

function renderDescription(classes: ReturnType<typeof useStyles>) {
  return (
    <div className={classes.description}>
      <p>
        Declaro ter ciência inequívoca da legislação sobre o tratamento da informação não pública e
        comprometo-me a preservar sua confidêncialidade, assim como:

      </p>
      <p>
        <b>a) </b>
        tratar adequadamente as informações produzidas ou custodiadas pelo TCU, cujo acesso me foi
        concedido por meio de solução eletrônica, e preservar sua confidencialidade nos termos da legislação vigente;
      </p>
      <p>
        <b>b) </b>
        não praticar ou facilitar a prática de quaisquer atos que possam afetar a confidencialidade ou a
        integridade das informações produzidas ou custodiadas pelo TCU;
      </p>
      <p>
        <b>c) </b>
        não copiar, reproduzir ou dar conhecimento a terceiro, por qualquer meio ou modo, no todo ou
        em parte, informações produzidas ou custodiadas pelo TCU às quais eu tenha acesso por meio da
        solução eletrônica, independente do grau de confidêncialidade, salvo mediante autorização
        expressa do Tribunal de Contas da União;
      </p>
      <p>
        <b>d) </b>
        não utilizar as informações produzidas ou custodiadas pelo TCU às quais eu tenha acesso por meio
        de solução eletrônica para fins diversos daqueles atinentes às atribuições de meu cargo ou
        função no serviço público.
      </p>
      <br />
      <h5 className={classes.subtitle}>Ainda declaro estar ciente e concordar que:</h5>
      <br />
      <p>
        <b>a) </b>
        dados produzidos ou custodiados pelo TCU e disponibilizados por meio de solução eletrônica passam
        por processos de filtragem por parâmetros pré-definidos e rankeamento, com o objetivo de potencializar
        a relevância das informações apresentadas para o contexto do TCU e que, por isso, podem não corresponder
        exatamente aos contextos registrados em suas fontes primárias, sendo, portanto, vedada sua utilização
        como evidência em processo administrativo, judicial ou de controle externo.
      </p>
      <p>
        <b>b) </b>
        os dados pessoais disponibilizados podem ser acessados exclusivamente para executar as competências
        legais ou cumprir as atribuições legais da instituição da qual faço parte.
      </p>
      <p>
        <b>c) </b>
        devo informar, imediatamente, ao Tribunal de Contas da União, qualquer violação de regra de sigilo
        estabelecida neste termo, da qual tenha conhecimento, independentemente da existência de dolo, bem
        como qualquer divulgação ou reprodução de informações abrangidas por este termo decorrente de exigência
        por autoridade competente, mediante ordem judicial ou administrativa;
      </p>
      <p>
        <b>d) </b>
        qualquer descumprimento, por ação ou omissão, de dispositivo constante deste termo sujeitar-me-á às
        sanções cabíveis nas esferas administrativa, civil e penal, nos termos da legislação em vigor,
        assegurados o contraditório e a ampla defesa;
      </p>
      <p>
        <b>e) </b>
        o presente termo tem natureza irrevogável e irretratável, permanecendo em vigor a partir da minha
        assinatura e enquanto perdurar a necessidade de acesso às informações, bem como após a cessação
        do acesso à informação;
      </p>
      <br />
      <p>
        Fica abrangida por este termo toda informação produzida ou custodiada pelo TCU sob a forma eletrônica,
        escrita, oral ou qualquer outro modo de apresentação, tangível ou intangível, tais como estudos, projetos,
        relatório e soluções de tecnologia da informação.
      </p>
    </div>
  )
}

function renderAccordion(classes: ReturnType<typeof useStyles>) {
  return (
    <div>
      <Accordion className={classes.accordion}>
        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
          Veja a legislação aplicável
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.accordionContent}>
            <Link target="_blank" href="http://www.planalto.gov.br/ccivil_03/leis/l8443.htm">
              •&nbsp; Art. 86, inciso IV, da Lei nº 8.443, de 16 de julho de 1992;
            </Link>
            <Link target="_blank" href="http://www.planalto.gov.br/ccivil_03/leis/l8112compilado.htm">
              •&nbsp; Art. 116, inciso VIII, da Lei nº 8.112, de 11 de dezembro de 1990;
            </Link>
            <Link target="_blank" href="http://www.planalto.gov.br/ccivil_03/LEIS/L8429.htm">
              •&nbsp; Art. 11 da Lei nº 8.429, de 2 de junho de 1992;
            </Link>
            <Link target="_blank" href="http://www.planalto.gov.br/ccivil_03/decreto-lei/del2848.htm">
              •&nbsp; Art. 325 do Decreto-Lei n° 2.848, de 7 de dezembro de 1940 – Código Penal;
            </Link>
            <Link target="_blank" href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709.htm">
              •&nbsp; Lei nº 13.709 , de 14 de agosto de 2018 – Lei Geral de Proteção de Dados Pessoais (LGPD);
            </Link>
            <Link target="_blank" href="http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2011/lei/l12527.htm">
              •&nbsp; Lei nº 12.527, de 18 de novembro de 2011 – Lei de Acesso à Informação;
            </Link>
            <Link target="_blank" href="https://portal.tcu.gov.br/biblioteca-digital/resolucao-tcu-n-249-de-2-de-maio-de-2012.htm">
              •&nbsp; Resolução TCU n° 249, de 2012 - Dispõe sobre o acesso à informação e a aplicação da Lei nº 12.527,
              de 18 de novembro de 2011, no âmbito do TCU.
            </Link>
            <Link target="_blank" href="http://www.tcu.gov.br/Consultas/Juris/Docs/judoc%5CPORTN%5C20140307%5CPRT2013-242.doc">
              •&nbsp; Portaria TCU n° 242, de 2013 – Dispõe sobre procedimentos para classificação, quanto à
              confidencialidade, de informações de controle externo.
            </Link>
            <Link target="_blank" href="https://portal.tcu.gov.br/lumis/portal/file/fileDownload.jsp?fileId=8A81881F62B15ED20162FDDDFF2E0DA8&inline=1">
              •&nbsp; Resolução TCU nº 294, de 2018 – Dispõe sobre a classificação da informação quanto à
              confidencialidade no âmbito do TCU;
            </Link>
            <Link target="_blank" href="https://portal.tcu.gov.br/lumis/portal/file/fileDownload.jsp?fileId=FF8080816364D7980163EF29F55C3BD1">
              •&nbsp; Resolução TCU nº 261, de 2014 – Dispõe sobre a Política de Segurança Institucional (PSI/TCU) e o
              Sistema de Gestão de Segurança Institucional do Tribunal de Contas da União (SGSIN/TCU);
            </Link>
            <Link target="_blank" href="https://portal.tcu.gov.br/lumis/portal/file/fileDownload.jsp?fileId=8A8182A15232A3790152A7A0D04A2D93">
              •&nbsp; Portaria TCU nº 210, de 2014 - Dispõe sobre a Política de Segurança Institucional (PSI/TCU) e o
              Sistema de Gestão de Segurança Institucional do Tribunal de Contas da União (SGSIN/TCU);
            </Link>
            <Link target="_blank" href="http://www.tcu.gov.br/Consultas/Juris/Docs/judoc%5CPORTN%5C20141208%5CPRT2014-329.doc">
              •&nbsp; Portaria TCU nº 329, de 2014, que dispõe sobre procedimentos de segurança e controles
              administrativos e tecnológicos afetos à classificação quanto à confidencialidade das informações
              produzidas ou custodiadas pelo Tribunal de Contas da União.
            </Link>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
