import { processosApi } from '../../../api/processosApi'
import { dateUtil } from '../../../util/dateUtil'
import { TIPOS_FATO } from '../../../domain/TipoFato'
import { ambienteUtil } from '../../../util/ambienteUtil'
import { stakeholdersApi } from '../../../api/stakeholdersApi'
import { marcarStakeholdersNaLista } from '../../stakeholder/highlightNosStakeholders'
import { recuperarFatosRelacionadosDoProcesso } from './recuperarFatosRelacionadosDoProcesso'
import { montarResumoFato } from '../montarResumoFato'
import { Processo } from '../../../domain/Processo'


export async function recuperarProcessoPorCod(cod: number) {
  const { body: respostaIndiceProcessos } = await processosApi.buscarProcessosPorCodigo(cod.toString())
  const processo = respostaIndiceProcessos.hits.hits[0]
  const { body: respostaIndiceAutoridades } = await stakeholdersApi.buscarStakeholdersParaHighlight()
  const stakeholders = respostaIndiceAutoridades.hits.hits
  const stakeholdersEncontrados = verificarStakeholdersNoProcesso(processo, stakeholders)
  const fatosRelacionados = await recuperarFatosRelacionadosDoProcesso(processo._source.numeroCompleto)
  const relatorId = getRelatorProcesso(processo, stakeholders)
  return montarProcesso(processo, stakeholdersEncontrados, fatosRelacionados, relatorId)
}

function montarProcesso(processo, stakeholdersEncontrados: any[], fatosRelacionados: any[], relatorId): Processo {
  return {
    id: processo._id,
    numero: processo._source.numeroCompleto,
    titulo: `${processo._source.numeroCompleto} ${processo._source.descricaoTipoProcesso.toLowerCase()}`,
    tipoFato: TIPOS_FATO.PROCESSO.id,
    urlFonte: montarUrlFonte(processo),
    dataAutuacao: `Autuado em ${dateUtil.formatarData(processo._source.dataAutuacao)}`,
    relatorId,
    nomeUnidadeTecnica: processo._source.descricaoUnidadeResponsavelTecnica,
    situacao: processo._source.descricaoSituacao,
    fase: processo._source.fase,
    assunto: processo._source.assunto,
    unidadesJurisdicionadas: marcarStakeholdersNaLista(
      processo._source.unidadesJurisdicionadas?.map(u => u.nome) ?? [],
      stakeholdersEncontrados
        .map(stakeholder => ({ id: stakeholder._id, aliases: stakeholder._source.aliases, tipoStakeholder: stakeholder._source.tipo_stakeholder })),
    ),
    interessados: processo._source.interessados?.map(i => i.nome) ?? [],
    responsaveis: marcarStakeholdersNaLista(
      processo._source.responsaveis?.map(r => r.nome) ?? [],
      stakeholdersEncontrados
        .map(stakeholder => ({ id: stakeholder._id, aliases: stakeholder._source.aliases, tipoStakeholder: stakeholder._source.tipo_stakeholder })),
    ),
    representantesLegais: processo._source.representantesLegais
      ?.map(r => ({ nomeRepresentante: r.nomeRepresentante, nomeRepresentado: r.nomeRepresentado })) ?? [],
    stakeholdersIds: stakeholdersEncontrados.map(stakeholder => stakeholder._id),
    fatosRelacionados: fatosRelacionados.map(fato => montarResumoFato(fato, fato._source.tipo_fato)),
    isSigiloso: processo._source.sigiloso === 'true',
  }
}

function montarUrlFonte(processo) {
  const urlBaseSiga = getUrlBaseSiga()
  return `${urlBaseSiga}/sso/SvlCentralizador?contexto=egestao&URL=/Web/EGestao/VisualizaProcesso/VisualizaProcesso.faces?codPapel=${processo._id}`
}

function getUrlBaseSiga() {
  if (ambienteUtil.isAmbienteProducao()) {
    return 'https://siga.apps.tcu.gov.br'
  }
  return 'http://siga.desenvol.rancher.tcu.gov.br'
}

function verificarStakeholdersNoProcesso(processo, stakeholders: any[]) {
  const stakeholdersEncontrados: any[] = []
  const listaNomesResponsaveis = processo._source.responsaveis?.map(i => i.nome)
  const listaNomesUnidadesJurisdicionadas = processo._source.unidadesJurisdicionadas?.map(u => u.nome)
  stakeholders.forEach((stakeholder) => {
    if (stakeholder._source.nome_completo) {
      listaNomesResponsaveis.forEach((responsavel) => {
        if (responsavel.toLowerCase() === stakeholder._source.nome_completo.toLowerCase()) {
          stakeholdersEncontrados.push(stakeholder)
        }
      })
      listaNomesUnidadesJurisdicionadas.forEach((unidade) => {
        if (unidade.toLowerCase() === stakeholder._source.nome_completo.toLowerCase()) {
          stakeholdersEncontrados.push(stakeholder)
        }
      })
    }
  })
  return stakeholdersEncontrados
}

function getRelatorProcesso(processo, stakeholders) {
  let relatorProcesso
  stakeholders.forEach((stakeholder) => {
    if (stakeholder._source.nome && stakeholder._source.orgao === 'TCU') {
      if (processo._source.nomeRelator && processo._source.nomeRelator.toLowerCase() === stakeholder._source.nome.toLowerCase()) {
        relatorProcesso = stakeholder
      }
    }
  })
  return relatorProcesso ? relatorProcesso._id : undefined
}
