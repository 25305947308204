import QueryString from 'query-string'

const QUERY_STRING_OPTIONS = { arrayFormat: 'bracket' } as any

export function parseQueryString(query: string) {
  return QueryString.parse(query, QUERY_STRING_OPTIONS)
}

export function stringifyToQueryString(object) {
  return QueryString.stringify(object, QUERY_STRING_OPTIONS)
}

export function updatePropertyInQueryParams(queryParamName: string, queryParamValue: any[] | any, urlQueryParams: string) {
  const queryParamsAtual = parseQueryString(urlQueryParams)
  const queryParamsResultante = {
    ...queryParamsAtual,
    [queryParamName]: queryParamValue,
  }
  return stringifyToQueryString(queryParamsResultante)
}

export function removePropertyFromQueryParams(queryParamName: string, urlQueryParams: string) {
  const queryParamsAtual = parseQueryString(urlQueryParams)
  delete queryParamsAtual[queryParamName]
  return stringifyToQueryString(queryParamsAtual)
}

export function removeValueFromArrayInQueryParams(valueToRemove: string, arrayQueryParamName: string, urlQueryParams: string) {
  const queryParamsAtual = parseQueryString(urlQueryParams)
  const arrayQueryParamValue = queryParamsAtual[arrayQueryParamName] as any[]
  queryParamsAtual[arrayQueryParamName] = arrayQueryParamValue?.filter(item => item !== valueToRemove)
  return stringifyToQueryString(queryParamsAtual)
}
