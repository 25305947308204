import { MapaRoute } from '../components/containers/infra/BasicSwitch'
import { MeusCenariosView } from '../components/views/MeusCenariosView'
import { NovoCenarioView } from '../components/views/NovoCenarioView'
import { CenarioView } from '../components/views/CenarioView'
import { InicioView } from '../components/views/InicioView'
import { EditarCenarioView } from '../components/views/EditarCenarioView'
import { ListaLeituraView } from '../components/views/ListaLeituraView'
import { MinhasFichasView } from '../components/views/MinhasFichasView'
import { ResultadosStakeholdersView } from '../components/views/ResultadosStakeholdersView'

export const menuHeaderLayoutRoutes: MapaRoute[] = [
  {
    path: '/',
    component: InicioView,
  },
  {
    path: '/cenarios',
    component: MeusCenariosView,
  },
  {
    path: '/resultados-stakeholders',
    component: ResultadosStakeholdersView,
  },
  {
    path: '/cenarios/novo',
    component: NovoCenarioView,
  },
  {
    path: '/cenarios/editar/:id',
    component: EditarCenarioView,
  },
  {
    path: '/cenarios/:id',
    component: CenarioView,
  },
  {
    path: '/lista-leitura',
    component: ListaLeituraView,
  },
  {
    path: '/fichas',
    component: MinhasFichasView,
  },
]
