import React from 'react'
import { useHistory } from 'react-router-dom'
import { CriarCenarioEndpoint } from 'bff/src/main/controllers/cenario/cenariosEndpoints'
import { cenarioThunkCreators } from '../../../store/actions/cenarioActions'
import { useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSpinningPromise } from '../../../infra/libs/react-swearing/SpinnerScreenProvider'
import { CenarioForm } from './CenarioForm'
import { useBuscaAutocomplete } from '../busca/useBuscaAutocomplete'


export function NovoCenarioContainer() {
  const dispatch = useTypedDispatch()
  const history = useHistory()

  const { atorProps, entidadeProps, temaProps, keywordProps } = useBuscaAutocomplete()

  const { run: onConfirm } = useSpinningPromise((command: CriarCenarioEndpoint.Body) => dispatch(cenarioThunkCreators.criarCenario(command, 'tela_novo_cenario')))

  const onCancel = () => history.goBack()

  return (
    <CenarioForm
      onConfirm={onConfirm}
      onCancel={onCancel}
      atorProps={atorProps}
      temaProps={temaProps}
      entidadeProps={entidadeProps}
      keywordProps={keywordProps}
    />
  )
}
