import { SnackbarKey } from 'notistack'
import { SnackbarNotification } from './SnackbarNotification'

type NotistackSelector<S> = (state: S) => NotistackState

// eslint-disable-next-line @typescript-eslint/naming-convention,import/no-mutable-exports
export let _notistackSelector: NotistackSelector<any>

export function createNotistackReducer<S>(notistackSelector: NotistackSelector<S>) {
  _notistackSelector = notistackSelector
  return notistackReducer
}

export type NotistackState = Notification[]

interface Notification extends SnackbarNotification {
  key: SnackbarKey
  dismissed?: boolean
}

const initialState: Notification[] = []

function notistackReducer(state = initialState, action) {
  switch (action.type) {
    case '@notistack/enqueueSnackbar':
      return [
        ...state,
        {
          key: action.key,
          ...action.notification,
        },
      ]

    case '@notistack/closeSnackbar':
      return state.map(notification => (action.dismissAll || notification.key === action.key
        ? { ...notification, dismissed: true }
        : { ...notification }))

    case '@notistack/removeSnackbar':
      return state.filter(notification => notification.key !== action.key)

    default:
      return state
  }
}
