import { ApiResponse } from '@elastic/elasticsearch/lib/Transport'
import { RequestParams } from '@elastic/elasticsearch'
import { SearchResponse } from './elasticTypes'
import { elasticClient } from './elasticClient'

interface SearchOnIndexParams extends RequestParams.Search {
  index: string
  body: any
}

type SearchOnIndexResult<ResponseBody = any> = Promise<ApiResponse<SearchResponse<ResponseBody>>>

export function searchOnIndex<ResponseBody = any>(params: SearchOnIndexParams): SearchOnIndexResult<ResponseBody> {
  return elasticClient.search(params)
}
