import {
  AdicionarNaListaDeLeituraEndpoint, RecuperarFatosDaListaDeLeituraEndpoint, RemoverDaListaDeLeituraEndpoint,
  VerificarSeFatoEstaNaListaDeLeituraEndpoint,
} from 'bff/src/main/controllers/lista-leitura/listaLeituraEndpoints'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'
import { alertActionCreators } from './alertActions'
import { defineActionCreators } from '../../infra/libs/redux-hardtyped/defineActionCreators'

export const listaLeituraActionCreators = defineActionCreators('listaLeitura', {
  fatoAdicionado: () => ({}),
  fatoRemovido: () => ({}),
})

export const listaLeituraThunkCreators = {

  recuperarFatosDaListaDeLeitura: () => createBffRequestThunk({
    request: {
      endpoint: RecuperarFatosDaListaDeLeituraEndpoint.definition,
    },
    actions: {
      error: () => alertActionCreators.showError('Não foi possível recuperar os fatos da lista de leitura.'),
    },
  }),

  verificarSeFatoEstaNaListaDeLeitura: (fatoId: string) => createBffRequestThunk({
    request: {
      endpoint: VerificarSeFatoEstaNaListaDeLeituraEndpoint.definition,
      params: { fatoId },
    },
    actions: {
      error: () => alertActionCreators.showError('Não foi possível verificar se o fato já consta na lista de leitura.'),
    },
  }),

  adicionarNaListaDeLeitura: (fatoId: string) => createBffRequestThunk({
    request: {
      endpoint: AdicionarNaListaDeLeituraEndpoint.definition,
      body: { fatoId },
    },
    actions: {
      success: () => [
        listaLeituraActionCreators.fatoAdicionado(),
        alertActionCreators.showSuccess('Fato adicionado à sua lista de leitura.'),
      ],
      error: () => alertActionCreators.showError('Não foi possível adicionar o fato à lista de leitura.'),
    },
  }),
  
  removerDaListaDeLeitura: (fatoId: string) => createBffRequestThunk({
    request: {
      endpoint: RemoverDaListaDeLeituraEndpoint.definition,
      body: { fatoId },
    },
    actions: {
      success: () => [
        listaLeituraActionCreators.fatoRemovido(),
        alertActionCreators.showSuccess('Fato removido da sua lista de leitura.'),
      ],
      error: () => alertActionCreators.showError('Não foi possível remover o fato da lista de leitura.'),
    },
  }),

}
