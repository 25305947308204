import { EnumObjectWithId } from '../util/EnumObject'

export type GrauSigilo = 'PUBLICO' | 'SIGILOSO'

export const GRAUS_SIGILO: EnumObjectWithId<GrauSigilo, { label: string }> = {
  PUBLICO: {
    id: 'PUBLICO',
    label: 'Público',
  },
  SIGILOSO: {
    id: 'SIGILOSO',
    label: 'Sigiloso',
  },
}
