import { useEffectOnce } from '../../../hooks/useEffectOnce'

export function ScrollToTopOnMount() {
  
  useEffectOnce(() => {
    window.scrollTo(0, 0)
  })
  
  return null

}
