import { useDispatch } from 'react-redux'
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'


/**
 * Esse hook é uma versão tipada do dispatch do store do Redux.
 * Faz a mesma coisa. Assume o uso do Thunk Middleware.
 *
 * A vantagem é que o dispatch retornado por esse hook faz a checagem de tipo do parâmetro
 * (que pode ser uma action ou um thunk) e também faz a inferência do tipo do retorno com base no parâmetro.
 *
 * O parâmetro do dispatch retornado pode ser uma action (objeto com type) ou
 * um thunk (função async com parâmetros 'dispatch' e 'getState').
 * Se o parâmetro passado para o dispatch for uma action, retorna a própria action.
 * Se for um thunk, retorna o retorno do thunk.
 *
 * @see https://github.com/reduxjs/redux-thunk
 */
export function useTypedDispatch() {
  const dispatch: TypedDispatch = useDispatch()
  return dispatch
}

export type TypedDispatch =
  <X extends T | ThunkAction<any, any, any, Action<string>>, T extends Action<string>> (actionOrThunk: X) =>
  X extends ThunkAction<infer R, any, any, Action<string>> ? R : T
