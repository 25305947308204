import React from 'react'
import { stakeholderThunkCreators } from '../../../store/actions/stakeholderActions'
import { TwitterPlaceholderComponent } from '../../ui-components/stakeholder/twitter/TwitterPlaceholderComponent'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { TwitterProfileTimelineComponent } from '../../ui-components/stakeholder/twitter/TwitterProfileTimelineComponent'


export function StakeholdersTweetsContainer({ idsStakeholders }: { idsStakeholders: string[] }) {

  const dispatch = useTypedDispatch()
  const { data: stakeholders, isLoading } = usePromiseEffect(recuperarTwitterStakeholders, [dispatch, idsStakeholders])

  if (isLoading) {
    return <TwitterPlaceholderComponent />
  }
  if (!stakeholders || stakeholders.length === 0) {
    return null
  }
  return (
    <div>
      {stakeholders.map(stakeholder => (
        stakeholder.perfilTwitter && (
          <TwitterProfileTimelineComponent
            key={stakeholder.id}
            profile={stakeholder.perfilTwitter} 
            name={stakeholder.nome}
            height={450}
          />
        )
      ))}
    </div>
  )
}

async function recuperarTwitterStakeholders(dispatch: TypedDispatch, idsStakeholders: string[]) {
  if (idsStakeholders.length < 1) {
    return []
  }
  try {
    const { data: stakeholders } = await dispatch(stakeholderThunkCreators.buscarStakeholdersPorId(idsStakeholders))
    return stakeholders
  } catch (e) {
    console.warn(e)
  }
  return []
}
