import React, { useState } from 'react'
import { IconButton, makeStyles, Popover } from '@material-ui/core'
import { IconOpcoesVerticalComponent } from '../icons/IconOpcoesVerticalComponent'
import { ButtonSpanComponent } from '../comum/ButtonSpanComponent'


const useStyles = makeStyles({
  iconButton: {
    padding: '0px',
  },
  opcao: {
    padding: '10px',
  },
})

interface OpcoesFichaComponentProps {
  className?: string
  onExcluir: () => void
}

export function OpcoesFichaComponent({ className, onExcluir }: OpcoesFichaComponentProps) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simplee-popover' : undefined

  return (
    <div className={className}>
      <IconButton onClick={handleClick} className={classes.iconButton}>
        <IconOpcoesVerticalComponent width="30" height="30" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ButtonSpanComponent onClick={onExcluir} className={classes.opcao}>
          Excluir ficha
        </ButtonSpanComponent>
      </Popover>
    </div>
  )
}
