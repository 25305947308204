import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
  animacao: {
    background: 'linear-gradient(45deg, #c7c7c7, #8a8a8a26, #cbcbcb24)',
    backgroundSize: '400% 400%',
    animation: '$gradient 3s ease infinite',
  },
  root: {
    marginTop: '30px',
  },
  linha: {
    height: '25px',
    marginTop: '35px',
    marginRight: '15px',
    marginBottom: '15px',
    width: '35%',
    borderRadius: '5px',
  },
  linhaMenor: {
    height: '18px',
    marginRight: '15px',
    marginBottom: '10px',
    width: '20%',
    borderRadius: '5px',
  },
  usuario: {
    borderRadius: '100%',
    height: '187px',
    width: '187px',
  },
})

export function PerfilStakeholderHeaderPlaceholderComponent() {
  const classes = useStyles()
  return (
    <div className={`row ${classes.root}`}>
      <div className="col-md-2">
        <div className={`${classes.usuario} ${classes.animacao}`} />
      </div>
      <div className="col-md-10">
        <div className={`${classes.linha} ${classes.animacao}`} />
        <div className={`${classes.linhaMenor} ${classes.animacao}`} />
        <div className={`${classes.linhaMenor} ${classes.animacao}`} />
      </div>
    </div>
  )
}
