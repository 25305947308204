import React from 'react'
import { DescricaoFichaComponent } from '../../ui-components/ficha/DescricaoFichaComponent'
import { useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { modalAlterarDescricaoFichaActions } from '../modal/ModalAlterarDescricaoFichaContainer'

interface DescricaoFichaContainerProps {
  fichaId: number
  descricao?: string
}

export function DescricaoFichaContainer({ fichaId, descricao }: DescricaoFichaContainerProps) {
  const dispatch = useTypedDispatch()

  function onClick() {
    dispatch(modalAlterarDescricaoFichaActions.abrirModal(fichaId, descricao))
  }

  return <DescricaoFichaComponent textoDescricao={descricao} onClick={onClick} />
}
