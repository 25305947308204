import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ProposicaoLegislativa } from 'bff/src/main/domain/ProposicaoLegislativa'
import { ListaStakeholdersContainer } from '../containers/stakeholder/ListaStakeholdersContainer'
import {
  useParam,
  useQueryParamAsArrayOfString,
  useQueryParamAsString,
} from '../../infra/libs/react-location-hooks/routerHooks'
import { FatoCompletoHeaderPlaceholderComponent } from '../ui-components/fato/FatoCompletoHeaderPlaceholderComponent'
import { FatoCompletoBodyPlaceholderComponent } from '../ui-components/fato/FatoCompletoBodyPlaceholderComponent'
import { ScrollToTopOnMount } from '../containers/util/ScrollToTopOnMount'
import { FatoCompletoHeaderComponent } from '../ui-components/fato/FatoCompletoHeaderComponent'
import { proposicaoLegislativaThunkCreators } from '../../store/actions/proposicaoLegislativaActions'
import { FatosRelacionadosComponent } from '../ui-components/fato/FatosRelacionadosComponent'
import { TitleComponent } from '../ui-components/head/TitleComponent'
import { InformacaoAdicionalFatoComponent } from '../ui-components/comum/InformacaoAdicionalFatoComponent'
import { PaginaDeErroHttpComponent } from '../ui-components/erro/PaginaDeErroHttpComponent'
import { useTypedDispatch, TypedDispatch } from '../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../infra/libs/react-swearing/usePromiseEffect'
import { QUERY_PARAM_KEYWORDS, QUERY_PARAM_TEMAS, QUERY_PARAM_TEXTO_LIVRE } from '../../util/constantesQueryParams'


const useStyles = makeStyles({
  root: {
    marginTop: '40px',
    paddingBottom: '100px',
  },
  header: {
    marginBottom: '48px',
  },
  linkApensados: {
    color: 'inherit',
    textDecoration: 'underline',
  },
  ementa: {
    marginBottom: '40px',
  },
  situacao: {
    marginBottom: '24px',
  },
  autores: {
    marginBottom: '24px',
  },
})

export function ProposicaoLegislativaView() {
  const classes = useStyles()
  const dispatch = useTypedDispatch()
  const id = useParam('id')
  const keywords = useQueryParamAsArrayOfString(QUERY_PARAM_KEYWORDS)
  const temas = useQueryParamAsArrayOfString(QUERY_PARAM_TEMAS)
  const textoLivre = useQueryParamAsString(QUERY_PARAM_TEXTO_LIVRE)
  const { data: proposicao, isLoading } = usePromiseEffect(pegarProposicao, [dispatch, id])

  const termosParaDestacar = [...keywords, ...temas]
  if (textoLivre) {
    termosParaDestacar.push(...textoLivre.split(' '))
  }

  if (!isLoading && !proposicao) {
    return (
      <PaginaDeErroHttpComponent
        mensagemDeErro="Tivemos um problema para exibir o fato"
      />
    )
  }
  return render(classes, isLoading, termosParaDestacar, proposicao)
}

function render(classes, isLoading: boolean, termosParaDestacar: string[], proposicaoLegislativa?: ProposicaoLegislativa) {
  return (
    <div className={`container ${classes.root}`}>
      <TitleComponent title={proposicaoLegislativa?.titulo} />
      <ScrollToTopOnMount />
      <div className={classes.header}>
        {renderFatoHeader(isLoading, proposicaoLegislativa)}
      </div>
      <div className="row">
        <div className="col-md-5">
          {renderFatoContent(classes, isLoading, termosParaDestacar, proposicaoLegislativa)}
        </div>
        <div className="col-md-5 offset-md-2">
          <ListaStakeholdersContainer
            stakeholdersIds={proposicaoLegislativa?.stakeholdersIds || []}
            tituloAutoridadesTcu="Autoridades do TCU citadas no fato"
            tituloStakeholders="Stakeholders citados no fato"
          />
          <FatosRelacionadosComponent fatos={proposicaoLegislativa?.fatosRelacionados ?? []} />
        </div>
      </div>
    </div>
  )
}

async function pegarProposicao(dispatch: TypedDispatch, id: string) {
  const { data: proposicaoLegislativa } = await dispatch(proposicaoLegislativaThunkCreators.buscarProposicaoLegislativaPorId(id))
  return proposicaoLegislativa
}

function renderFatoHeader(isLoading: boolean, proposicaoLegislativa?: ProposicaoLegislativa) {
  if (isLoading) {
    return <FatoCompletoHeaderPlaceholderComponent />
  }
  if (!proposicaoLegislativa) {
    return null
  }
  return (
    <FatoCompletoHeaderComponent
      tipoFato={proposicaoLegislativa.tipoFato}
      titulo={proposicaoLegislativa.titulo}
      urlFonte={proposicaoLegislativa.urlFonte}
      infoAdicional={proposicaoLegislativa.origem}
      dataHora={proposicaoLegislativa.dataApresentacao}
    />
  )
}

function renderFatoContent(classes, isLoading: boolean, termosParaDestacar: string[], proposicao?: ProposicaoLegislativa) {
  if (isLoading) {
    return <FatoCompletoBodyPlaceholderComponent />
  }
  if (!proposicao) {
    return null
  }
  return (
    <div>
      <InformacaoAdicionalFatoComponent className={classes.ementa} texto={proposicao?.ementa} termosParaDestacar={termosParaDestacar} />
      <InformacaoAdicionalFatoComponent className={classes.situacao} titulo="Situação" texto={proposicao?.situacao} termosParaDestacar={termosParaDestacar} />
      <InformacaoAdicionalFatoComponent
        className={classes.autores}
        titulo={pegarTituloAutor(proposicao.nomeAutor)}
        texto={proposicao?.nomeAutor}
        termosParaDestacar={termosParaDestacar}
      />
      {renderApensados(proposicao, classes)}
    </div>
  )
}

function renderApensados(proposicaoLegislativa: ProposicaoLegislativa, classes) {
  if (proposicaoLegislativa.proposicoesApensadas.length < 1) {
    return null
  }
  return (
    <div>
      <span>Apensados: </span>
      {proposicaoLegislativa.proposicoesApensadas?.map((p, index) => (
        <>
          <span key={p.titulo}>
            <a key={p.titulo} className={classes.linkApensados} href={p.urlOrigem} target="_blank" rel="noopener noreferrer">{p.titulo}</a>
          </span>
          <span>
            {renderVirgulaOuTermina(proposicaoLegislativa.proposicoesApensadas[index + 1])}
          </span>
        </>
      ))}
    </div>
  )
}

function renderVirgulaOuTermina(item) {
  return item ? ', ' : '.'
}

function pegarTituloAutor(autor: string) {
  if (autor && autor.includes(',')) {
    return 'Autores'
  }
  return 'Autor'
}
