import React from 'react'
import { criaStakeholdersComFavoritos } from 'bff/src/main/app/stakeholder/stakeholderUtil'
import { StakeholdersPesquisadosComponent } from '../../ui-components/stakeholder/StakeholdersPesquisadosComponent'
import { useQueryParamAsArrayOfString } from '../../../infra/libs/react-location-hooks/routerHooks'
import { stakeholderThunkCreators } from '../../../store/actions/stakeholderActions'
import { StakeholdersRelacionadosPlaceholderComponent } from '../../ui-components/stakeholder/StakeholdersRelacionadosPlaceholderComponent'
import { QUERY_PARAM_ATORES, QUERY_PARAM_ENTIDADES } from '../../../util/constantesQueryParams'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { favoritoThunkCreators } from '../../../store/actions/favoritoActions'
import { TipoFavorito } from '../../ui-components/favorito/TipoFavorito'


export function StakeholdersPesquisadosContainer() {

  const dispatch = useTypedDispatch()
  const idsAtores = useQueryParamAsArrayOfString(QUERY_PARAM_ATORES)
  const idsEntidades = useQueryParamAsArrayOfString(QUERY_PARAM_ENTIDADES)

  const { data: stakeholders, isLoading } = usePromiseEffect(recuperarStakeholdersPesquisados, [dispatch, idsAtores, idsEntidades])

  if (isLoading) {
    return <StakeholdersRelacionadosPlaceholderComponent qntFiguras={3} />
  }
  if (!stakeholders || stakeholders.length === 0) {
    return null
  }
  return <StakeholdersPesquisadosComponent listaStakeholders={stakeholders || []} titulo="Atores e entidades pesquisados" />
}

async function recuperarStakeholdersPesquisados(dispatch: TypedDispatch, idsAtores: string[], idsEntidades: string[]) {
  try {
    const { data: stakeholders } = await dispatch(stakeholderThunkCreators.buscarStakeholdersPorId([...idsAtores, ...idsEntidades]))
    const { data: favoritos } = await dispatch(favoritoThunkCreators.recuperarMeusFavoritos(TipoFavorito.STAKEHOLDER))

    return criaStakeholdersComFavoritos(stakeholders, favoritos)
  } catch (e) {
    console.warn(e)
  }
  return []
}
