import { BuscarEventoEndpoint } from 'bff/src/main/controllers/fato/eventosEndpoints'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'


export const eventoThunkCreators = {

  buscarEventoPorId: (id: string) => createBffRequestThunk({
    request: {
      endpoint: BuscarEventoEndpoint.definition,
      params: { id },
    },
  }),

}
