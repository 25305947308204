import React from 'react'
import { Cenario } from 'bff/src/main/domain/Cenario'
import { Ordenacao, ORDENACOES } from 'bff/src/main/domain/Ordenacao'
import { BuscarFatosResumidosEndpoint } from 'bff/src/main/controllers/fato/fatosEndpoints'
import { ListaFatosComponent } from '../../ui-components/fato/ListaFatosComponent'
import { fatoThunkCreators } from '../../../store/actions/fatoActions'
import { ListaFatosPlaceholderComponent } from '../../ui-components/fato/ListaFatosPlaceholderComponent'
import { useQueryParamAsString } from '../../../infra/libs/react-location-hooks/routerHooks'
import { QUERY_PARAM_ORDENACAO } from '../../../util/constantesQueryParams'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { favoritoThunkCreators } from '../../../store/actions/favoritoActions'
import { TipoFavorito } from '../../ui-components/favorito/TipoFavorito'
import { criaFatosComFavoritos } from './ListaFatosContainer'


const size = 40

export function ListaFatosCenarioContainer({ cenario }: { cenario: Cenario }) {

  const dispatch = useTypedDispatch()
  const ordenacao = useQueryParamAsString(QUERY_PARAM_ORDENACAO) as Ordenacao
  const { data: listaFatos, isLoading } = usePromiseEffect(recuperarFatos, [dispatch, cenario, ordenacao])

  if (isLoading) {
    return <ListaFatosPlaceholderComponent />
  }
  return (
    <ListaFatosComponent
      fatos={listaFatos || []}
      mostrarOrdenacao
      mostrarTitulo
      titulo={cenario.nome}
    />
  )
}

async function recuperarFatos(dispatch: TypedDispatch, cenario: Cenario, ordenacao?: Ordenacao) {
  const idsAtores = cenario.consulta.stakeholders ? cenario.consulta.stakeholders.filter(s => !s.includes('ENTIDADE')) : []
  const idsEntidades = cenario.consulta.stakeholders ? cenario.consulta.stakeholders.filter(s => s.includes('ENTIDADE')) : []
  try {
    const payload: BuscarFatosResumidosEndpoint.Query = {
      size,
      ordenacao: ordenacao || ORDENACOES.MAIS_RELEVANTE.id,
      idsAtores,
      idsEntidades,
      keywords: cenario.consulta.keywords,
      temas: cenario.consulta.temas,
      tiposFato: cenario.consulta.filtrosTipoFato,
      dtInicio: cenario.consulta.dataBuscaAPartirDe,
      textoLivre: cenario.consulta.textoLivre,
      listaConectoresOu: cenario.consulta.conectoresOu,
    }
    const { data: listaFatos } = await dispatch(fatoThunkCreators.buscarFatosResumidos(payload))
    const { data: favoritos } = await dispatch(favoritoThunkCreators.recuperarMeusFavoritos(TipoFavorito.FATO))

    return criaFatosComFavoritos(listaFatos, favoritos)
  } catch (e) {
    console.warn(e)
  }
  return []
}
