
export function montaNomeFuncaoConector(prefixo, tipoConector, contadorFuncao) {
  return prefixo + tipoConector?.toString().toLowerCase() + contadorFuncao.toString()
}

export function isConectorEntreCampos(criterioAtual, proximosCriterios, textoLivre) {
  let isConector = false
  if (criterioAtual.length === 0) {
    return isConector
  }
  proximosCriterios.forEach((criterio) => {
    if (criterio.length > 0) {
      isConector = true
    }
  })
  if (!isConector && textoLivre) {
    isConector = true
  }
  return isConector
}
