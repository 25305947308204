import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TitleComponent } from '../ui-components/head/TitleComponent'
import { EditarCenarioContainer } from '../containers/cenario/EditarCenarioContainer'


const useStyles = makeStyles({
  root: {
    marginTop: '40px',
    marginBottom: '80px',
  },
})

export function EditarCenarioView() {
  const classes = useStyles()
  return (
    <div className={`row ${classes.root}`}>
      <div className="col-md-8">
        <TitleComponent title="Editar cenário" />
        <EditarCenarioContainer />
      </div>
    </div>
  )
}
