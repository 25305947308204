import { BuscarMinistrosPorListaCodigoEndpoint } from 'bff/src/main/controllers/fato/ministrosEndpoints'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'

export const ministroThunkCreators = {

  buscarMinistrosPorListaCodigo: (queryParams: BuscarMinistrosPorListaCodigoEndpoint.Query) => createBffRequestThunk({
    request: {
      endpoint: BuscarMinistrosPorListaCodigoEndpoint.definition,
      queryParams,
    },
  }),

}
