import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { OnBoardingStepComponent, OnBoardingStepComponentProps } from '../../ui-components/comum/OnBoardingStepComponent'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { isOnBoardingStepActiveSelector } from '../../../store/reducers/onboardings/onBoardingsSelectors'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { usePromise } from '../../../infra/libs/react-swearing/usePromise'
import { onBoardingActionCreators, onBoardingThunkCreators } from '../../../store/actions/onBoardingActions'


export function createOnBoardingStepContainer(step: Omit<OnBoardingStepContainerProps, 'children'>) {
  return ({ children }: { children: any }) => (
    <OnBoardingStepContainer
      name={step.name}
      urlRegex={step.urlRegex}
      position={step.position}
      title={step.title}
      text={step.text}
      labelButtonNext={step.labelButtonNext}
      labelButtonBack={step.labelButtonBack}
      labelStep={step.labelStep}
      currentStep={step.currentStep}
      totalSteps={step.totalSteps}
    >
      {children}
    </OnBoardingStepContainer>
  )
}

export interface OnBoardingStepContainerProps extends Omit<OnBoardingStepComponentProps, 'visible' | 'onButtonNext' | 'onButtonBack'> {
  name: string
  urlRegex: string | RegExp
}

function OnBoardingStepContainer(props: OnBoardingStepContainerProps) {

  const dispatch = useTypedDispatch()
  const location = useLocation()
  const isThisStepActive = useSelector(isOnBoardingStepActiveSelector(props.name, props.currentStep)) && !!location.pathname.match(props.urlRegex)

  usePromiseEffect(iniciarOnBoardingSePrimeiroStepEUsuarioNaoTiverFeito, [dispatch, props.name, props.totalSteps, props.currentStep])

  const { run: concluirOnBoarding } = usePromise(() => dispatch(onBoardingThunkCreators.concluirOnBoardingProUsuario(props.name)))

  function onButtonNext() {
    if (props.currentStep < props.totalSteps) {
      dispatch(onBoardingActionCreators.goNext(props.name))
    } else {
      concluirOnBoarding()
    }
  }

  function onButtonBack() {
    if (props.currentStep > 1) {
      dispatch(onBoardingActionCreators.goBack(props.name))
    }
  }

  return (
    <OnBoardingStepComponent
      visible={isThisStepActive}
      position={props.position}
      title={props.title}
      text={props.text}
      labelButtonNext={props.labelButtonNext}
      onButtonNext={onButtonNext}
      labelButtonBack={props.labelButtonBack}
      onButtonBack={onButtonBack}
      labelStep={props.labelStep}
      totalSteps={props.totalSteps}
      currentStep={props.currentStep}
    >
      {props.children}
    </OnBoardingStepComponent>
  )
}

async function iniciarOnBoardingSePrimeiroStepEUsuarioNaoTiverFeito(
  dispatch: TypedDispatch,
  name: string,
  totalSteps: number,
  currentStep: number,
) {
  if (currentStep !== 1) return
  try {
    await dispatch(onBoardingThunkCreators.iniciarOnBoardingSeUsuarioNaoTiverFeito(name, totalSteps))
  } catch (e) {
    console.warn(e)
  }
}
