import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  boxNaoEncontrados: {
    textAlign: 'left',
    color: '#434343',
    marginLeft: '15px',
  },
  tituloLeituraVazia: {
    fontSize: '24px',
    color: '#434343',
    fontWeight: 500,
  },
  subTituloLeituraVazia: {
    fontSize: '18px',
    fontWeight: 500,
    marginTop: '24px',
  },
  textoLeituraVazia: {
    fontSize: '16px',
    color: '#7e7e7e',
  },
})

export function ListaLeituraVaziaComponent() {
  const classes = useStyles()
  return (
    <div className={classes.boxNaoEncontrados}>
      <div className="col-lg-6 col-xs-12">
        <h2 className={classes.tituloLeituraVazia}>Lista de Leitura</h2>
        <h4 className={classes.subTituloLeituraVazia}>Adicione fatos à sua lista de leitura e leia depois</h4>
        <span className={classes.textoLeituraVazia}>
          Leia os principais fatos encontrados quando quiser, basta adicioná-los à sua lista de leitura.
        </span>
      </div>
    </div>
  )
}
