/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
  TextField, OutlinedTextFieldProps,
  Checkbox, CheckboxProps,
  FormControl, FormControlProps,
  FormControlLabel, FormControlLabelProps,
  FormGroup, FormGroupProps,
  FormLabel, FormLabelProps,
} from '@material-ui/core'
import { removerAcentuacoes } from '../../../util/removerAcentuacoes'

interface CheckboxesProps {
  label: string
  value?: any[]
  options: CheckboxesOption[]
  onChange?: (newValue: any[]) => void
  withOptionsFilter?: boolean
  formControlProps?: Partial<FormControlProps>
  formLabelProps?: Partial<FormLabelProps>
  optionsFilterProps?: Partial<OutlinedTextFieldProps>
  formGroupProps?: Partial<FormGroupProps>
  formControlLabelProps?: Partial<FormControlLabelProps>
  checkboxProps?: Partial<CheckboxProps>
}

export interface CheckboxesOption {
  value: string
  label: string
  disabled?: boolean
}

export function CheckboxesComponent({
  value = [],
  label, options, onChange, withOptionsFilter,
  formControlProps, formLabelProps, optionsFilterProps,
  formGroupProps, formControlLabelProps, checkboxProps,
}: CheckboxesProps) {

  const [currentValue, setCurrentValue] = useState(value)
  const [optionsFilterValue, setOptionsFilterValue] = useState('')

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  function getFilteredOptions() {
    if (!optionsFilterValue) return options
    return options.filter((option) => {
      const rawOptionLabel = removerAcentuacoes(option.label.toLowerCase())
      const rawOptionsFilterValue = removerAcentuacoes(optionsFilterValue)
      return rawOptionLabel.includes(rawOptionsFilterValue)
    })
  }

  function onCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const checkboxValue = event.target.value
    const checkboxChecked = event.target.checked
    let newValue
    if (checkboxChecked) {
      newValue = [...currentValue, checkboxValue]
    } else {
      newValue = currentValue.filter(v => v !== checkboxValue)
    }
    setCurrentValue(newValue)
    onChange?.(newValue)
  }

  return (
    <FormControl {...formControlProps}>
      <FormLabel {...formLabelProps}>
        {label}
      </FormLabel>
      {renderOptionsFilter(optionsFilterValue, setOptionsFilterValue, withOptionsFilter, optionsFilterProps)}
      <FormGroup {...formGroupProps}>
        {getFilteredOptions().map(option => renderOption(option, currentValue, onCheckboxChange, formControlLabelProps, checkboxProps))}
      </FormGroup>
    </FormControl>
  )
}

function renderOptionsFilter(
  value: string, setValue: (s: string) => void,
  withOptionsFilter?: boolean, optionsFilterProps?: Partial<OutlinedTextFieldProps>,
) {
  if (!withOptionsFilter) return null
  return (
    <TextField
      variant="outlined"
      value={value}
      onChange={event => setValue(event.target.value)}
      {...optionsFilterProps}
    />
  )
}

function renderOption(
  { label, value, disabled = false }: CheckboxesOption, currentValue: any[], onCheckboxChange,
  formControlLabelProps?: Partial<FormControlLabelProps>, checkboxProps?: Partial<CheckboxProps>,
) {
  const checked = currentValue.includes(value)
  return (
    <FormControlLabel
      key={value}
      label={label}
      control={(
        <Checkbox
          checked={checked}
          value={value}
          onChange={onCheckboxChange}
          disabled={disabled}
          {...checkboxProps}
        />
      )}
      {...formControlLabelProps}
    />
  )
}
