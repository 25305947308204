import { fatosApi } from '../../../api/fatosApi'
import { dateUtil } from '../../../util/dateUtil'
import { montarResumoProcesso } from '../processo/montarResumoProcesso'
import { recuperarProcessosRelacionadosDoFato } from '../processo/recuperarProcessosRelacionadosDoFato'
import { marcarStakeholdersNoTexto } from '../../stakeholder/highlightNosStakeholders'
import { ProposicaoLegislativa } from '../../../domain/ProposicaoLegislativa'


export async function recuperarProposicaoLegislativaPorId(id: string) {
  const { body: respostaIndiceFatos } = await fatosApi.buscarFatosPorId(id)
  const proposicao = respostaIndiceFatos.hits.hits[0]
  const processosRelacionados = await recuperarProcessosRelacionadosDoFato(proposicao)
  return mapearProposicao(proposicao, processosRelacionados)
}

function mapearProposicao(proposicao, processosRelacionados: any[]): ProposicaoLegislativa {
  return {
    id: proposicao._id,
    titulo: proposicao._source.titulo.toUpperCase(),
    tipoFato: proposicao._source.tipo_fato,
    urlFonte: proposicao._source.link_origem,
    dataApresentacao: `Apresentada em ${dateUtil.formatarData(proposicao._source.data_publicacao)}`,
    nomeAutor: marcarStakeholdersNoTexto(
      'autor',
      proposicao._source.autor, proposicao._source.marcacoes_stakeholders_relacionados,
      proposicao._source.stakeholders_relacionados,
    ),
    nomePartido: proposicao._source.uf_autor ? `${proposicao._source.partido_autor} - ${proposicao._source.uf_autor}` : proposicao._source.partido_autor,
    origem: proposicao._source.origem,
    ementa: marcarStakeholdersNoTexto(
      'texto',
      proposicao._source.texto, proposicao._source.marcacoes_stakeholders_relacionados,
      proposicao._source.stakeholders_relacionados,
    ),
    situacao: proposicao._source.situacao,
    proposicoesApensadas:
      proposicao._source.proposicoes_apensadas ? proposicao._source.proposicoes_apensadas.map(p => ({ titulo: p.nome, urlOrigem: p.link_casa })) : [],
    stakeholdersIds: proposicao._source.stakeholders_relacionados,
    fatosRelacionados: processosRelacionados.map(montarResumoProcesso),
    marcacoesStakeholders: proposicao._source.marcacoes_stakeholders_relacionados,
  }

}
