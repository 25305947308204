import { useLocation, useParams } from 'react-router-dom'
import moment from 'moment'
import { useMemo } from 'react'
import { parseQueryString } from 'bff/src/main/util/urlUtil'

export function useParam(paramName: string) {
  return useParams()[paramName] as string
}

export function useParamAsInt(paramName) {
  const param = useParam(paramName)
  return useMemo(() => {
    if (param === undefined || param === null) {
      return param
    }
    return parseInt(param)
  }, [param])
}

export function useQueryParam<T>(queryParamName: string) {
  return useQueryParams()[queryParamName] as unknown as (T | undefined)
}

export function useQueryParamAsString(queryParamName: string) {
  return useQueryParam<string>(queryParamName)
}

export function useQueryParamAsInt(queryParamName: string) {
  const queryParam = useQueryParam<string>(queryParamName)
  return useMemo(() => {
    if (queryParam === undefined || queryParam === null) {
      return queryParam
    }
    return parseInt(queryParam)
  }, [queryParam])
}

export function useQueryParamAsMoment(queryParamName: string) {
  const queryParam = useQueryParam<string>(queryParamName)
  return useMemo(() => {
    if (queryParam) {
      return moment(queryParam)
    }
    return undefined
  }, [queryParam])
}

export function useQueryParamAsDDMMYYYY(queryParamName: string) {
  const queryParam = useQueryParamAsMoment(queryParamName)
  return useMemo(() => queryParam?.format('DD/MM/YYYY') || undefined, [queryParam])
}

export function useQueryParamAsArrayOfInt(queryParamName: string) {
  const queryParam = useQueryParam<string[]>(queryParamName)
  return useMemo(() => {
    if (Array.isArray(queryParam)) {
      return queryParam.map(e => parseInt(e))
    }
    return []
  }, [queryParam])
}

export function useQueryParamAsArrayOfString(queryParamName: string) {
  const queryParam = useQueryParam<string[]>(queryParamName)
  return useMemo(() => {
    if (Array.isArray(queryParam)) {
      return queryParam
    }
    return []
  }, [queryParam])
}

function useQueryParams() {
  const { search: rawQueryParams } = useLocation()
  return useMemo(() => (
    parseQueryString(rawQueryParams)
  ), [rawQueryParams])
}
