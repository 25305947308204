import { createAxios } from './createAxios'
import { createAuthAxiosInterceptor } from '../security/createAuthAxiosInterceptor'
import { RecursoComputacional } from '../security/RecursoComputacional'


export function createAxiosWithAuth(recursoComputacional: RecursoComputacional) {
  return createAxios({
    baseUrlCloudConfigProp: recursoComputacional.baseUrlCloudConfigProp,
    customRequestInterceptor: createAuthAxiosInterceptor(recursoComputacional.codigoRecursoComputacional.toString()),
  })
}
