import { BuscarSugestoesDeBuscaEndpoint } from 'bff/src/main/controllers/sugestao-de-busca/sugestoesDeBuscaEndpoints'
import { TipoSugestaoDeBusca } from 'bff/src/main/domain/TipoSugestaoDeBusca'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'


export const sugestoesDeBuscaThunkCreators = {

  buscarSugestoesDeBusca: (word: string, tipoSugestao?: TipoSugestaoDeBusca) => createBffRequestThunk({
    request: {
      endpoint: BuscarSugestoesDeBuscaEndpoint.definition,
      params: { word },
      queryParams: { tipoSugestao },
    },
  }),

}
