import { stringifyToQueryString } from 'bff/src/main/util/urlUtil'
import { useHistory } from 'react-router-dom'
import { useEffect, useState, MouseEvent } from 'react'
import { TipoSugestaoDeBusca } from 'bff/src/main/domain/TipoSugestaoDeBusca'
import { TIPOS_CONECTORES_OU } from 'bff/src/main/domain/TipoConectorOu'
import { ID_ENTIDADE_TCU } from 'bff/src/main/util/stakeholdersUtil'
import { BuscaAutocompleteInputOption } from '../../ui-components/busca/BuscaAutocompleteOptionComponent'
import { TypedDispatch, useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { sugestoesDeBuscaThunkCreators } from '../../../store/actions/sugestoesDeBuscaActions'
import {
  useStakeholdersTemasKeywordsTextoLivreConectoresDaUrl,
} from './useStakeholdersTemasKeywordsTextoLivreConectoresDaUrl'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { QUERY_PARAM_FILTRO_GRAUS_SIGILO, QUERY_PARAM_FILTRO_TIPO_FATOS } from '../../../util/constantesQueryParams'
import { TiposFatoCheckbox } from '../cenario/TiposFatoCheckboxContainer'
import { OutrosFiltrosOptions } from './OutrosFiltrosCheckboxContainer'


interface QueryParamsBuscaPrincipal {
  atores: string[]
  entidades: string[]
  temas: string[]
  keywords: string[]
  [QUERY_PARAM_FILTRO_TIPO_FATOS]: string[]
  [QUERY_PARAM_FILTRO_GRAUS_SIGILO]: string[]
  conectoresOu: string[]
  textoLivre?: string
}


export function useBuscaPrincipalContainer() {
  const history = useHistory()
  const dispatch = useTypedDispatch()
  const { selectedOptions, textoLivre, conectoresOu } = useStakeholdersTemasKeywordsTextoLivreConectoresDaUrl()
  const [buscaAvancadaAnchorEl, setBuscaAvancadaAnchorEl] = useState<HTMLButtonElement | null>(null)
  
  // texto livre
  const [text, setText] = useState('')
  const { data: sugestoesTextoLivre } = usePromiseEffect(pegarSugestoes, [dispatch, text])

  // ator
  const [atorWord, setAtorWord] = useState('')
  const [atoresSelecionados, setAtoresSelecionados] = useState<BuscaAutocompleteInputOption[]>([])
  const [conectorOuAtores, setConectorOuAtores] = useState(conectoresOu.includes(TIPOS_CONECTORES_OU.ATOR.id))
  const { data: sugestoesAtores } = usePromiseEffect(pegarSugestoes, [dispatch, atorWord, 'ATOR'])

  // entidade
  const [entidadeWord, setEntidadeWord] = useState('')
  const [entidadesSelecionadas, setEntidadesSelecionadas] = useState<BuscaAutocompleteInputOption[]>([])
  const [conectorOuEntidades, setConectorOuEntidades] = useState(conectoresOu.includes(TIPOS_CONECTORES_OU.ENTIDADE.id))
  const { data: sugestoesEntidades } = usePromiseEffect(pegarSugestoes, [dispatch, entidadeWord, 'ENTIDADE'])

  // tema
  const [temaWord, setTemaWord] = useState('')
  const [temasSelecionados, setTemasSelecionados] = useState<BuscaAutocompleteInputOption[]>([])
  const [conectorOuTemas, setConectorOuTemas] = useState(conectoresOu.includes(TIPOS_CONECTORES_OU.TEMA.id))
  const { data: sugestoesTemas } = usePromiseEffect(pegarSugestoes, [dispatch, temaWord, 'TEMA'])

  // keyword
  const [keywords, setKeywords] = useState('')
  const [conectorOuKeywords, setConectorOuKeywords] = useState(conectoresOu.includes(TIPOS_CONECTORES_OU.KEYWORD.id))

  // outros parametros
  const [tiposFato, setTiposFato] = useState<TiposFatoCheckbox[]>([])
  const [outrosFiltros, setOutrosFiltros] = useState<OutrosFiltrosOptions[]>([])

  useEffect(() => {
    setAtoresSelecionados(selectedOptions.filter(s => s.tipo === 'ATOR'))
    setEntidadesSelecionadas(selectedOptions.filter(s => s.tipo === 'ENTIDADE'))
    setTemasSelecionados(selectedOptions.filter(s => s.tipo === 'TEMA'))
    setKeywords(selectedOptions.filter(s => s.tipo === 'KEYWORD').map(k => k.nome).join(', '))
    setConectorOuAtores(conectoresOu.includes(TIPOS_CONECTORES_OU.ATOR.id))
    setConectorOuEntidades(conectoresOu.includes(TIPOS_CONECTORES_OU.ENTIDADE.id))
    setConectorOuTemas(conectoresOu.includes(TIPOS_CONECTORES_OU.TEMA.id))
    setConectorOuKeywords(conectoresOu.includes(TIPOS_CONECTORES_OU.KEYWORD.id))
    setText(textoLivre || '')
  }, [selectedOptions, textoLivre, conectoresOu])

  function onCloseBuscaAvancada() {
    setBuscaAvancadaAnchorEl(null)
  }

  function onOpenBuscaAvancada(event: MouseEvent<HTMLButtonElement>) {
    setBuscaAvancadaAnchorEl(event.currentTarget)
  }

  function handleLimparTags() {
    setAtoresSelecionados([])
    setEntidadesSelecionadas([])
    setTemasSelecionados([])
    setKeywords('')
    montarUrl()
    history.push('/')
  }

  function onPesquisarTextoLivre() {
    const url = montarUrl(text)
    history.push(url)
  }

  function onPesquisarSelecionado(options: BuscaAutocompleteInputOption[]) {
    const url = montarUrl(options[0].nome)
    history.push(url)
  }

  function montarUrl(texto?: string) {
    const queryParams: QueryParamsBuscaPrincipal = {
      atores: [],
      entidades: [],
      temas: [],
      keywords: [],
      [QUERY_PARAM_FILTRO_TIPO_FATOS]: [],
      [QUERY_PARAM_FILTRO_GRAUS_SIGILO]: [],
      conectoresOu: [],
    }
    queryParams.atores.push(...atoresSelecionados.map(ator => ator.id))
    queryParams.entidades.push(...entidadesSelecionadas.map(entidade => entidade.id))
    queryParams.temas.push(...temasSelecionados.map(tema => tema.nome))
    queryParams[QUERY_PARAM_FILTRO_TIPO_FATOS].push(...tiposFato.filter(t => t !== 'TODOS_OS_TIPOS'))
    if (conectorOuAtores || conectorOuEntidades || conectorOuTemas || conectorOuKeywords) {
      if (conectorOuAtores) {
        queryParams.conectoresOu.push(TIPOS_CONECTORES_OU.ATOR.id)
      }
      if (conectorOuEntidades) {
        queryParams.conectoresOu.push(TIPOS_CONECTORES_OU.ENTIDADE.id)
      }
      if (conectorOuTemas) {
        queryParams.conectoresOu.push(TIPOS_CONECTORES_OU.TEMA.id)
      }
      if (conectorOuKeywords) {
        queryParams.conectoresOu.push(TIPOS_CONECTORES_OU.KEYWORD.id)
      }
    }
    if (keywords) {
      queryParams.keywords.push(...keywords.split(',').map(k => k.trim()).filter(k => !!k))
    }
    if (outrosFiltros.includes('CITA_TCU') && !queryParams.entidades.includes(ID_ENTIDADE_TCU)) {
      queryParams.entidades.push(ID_ENTIDADE_TCU)
    }
    if (outrosFiltros.includes('SIGILOSO')) {
      queryParams[QUERY_PARAM_FILTRO_GRAUS_SIGILO].push('SIGILOSO')
    }
    if (texto) {
      queryParams.textoLivre = texto
    }
    const stringifiedQueryParams = stringifyToQueryString(queryParams)
    return `/resultados?${stringifiedQueryParams}`
  }

  function redirecionarParaPaginaDeResultados() {
    const url = montarUrl(text)
    onCloseBuscaAvancada()
    history.push(url)
  }

  function limparPesquisa() {
    setAtoresSelecionados([])
    setEntidadesSelecionadas([])
    setTemasSelecionados([])
    setKeywords('')
    setTiposFato([])
    setOutrosFiltros([])
    setConectorOuAtores(false)
    setConectorOuEntidades(false)
    setConectorOuTemas(false)
    setConectorOuKeywords(false)
    setText('')
  }


  const atorProps = {
    atoresSelecionados,
    atoresOptions: sugestoesAtores ?? [],
    onAtoresChange: setAtoresSelecionados,
    onAtorWordChange: setAtorWord,
    conectorOuAtores,
    onConectorOuAtoresChange: setConectorOuAtores,
  }
  const entidadeProps = {
    entidadesSelecionadas,
    entidadesOptions: sugestoesEntidades ?? [],
    onEntidadesChange: setEntidadesSelecionadas,
    onEntidadeWordChange: setEntidadeWord,
    conectorOuEntidades,
    onConectorOuEntidadesChange: setConectorOuEntidades,
  }
  const temaProps = {
    temasSelecionados,
    temasOptions: sugestoesTemas ?? [],
    onTemasChange: setTemasSelecionados,
    onTemaWordChange: setTemaWord,
    conectorOuTemas,
    onConectorOuTemasChange: setConectorOuTemas,
  }
  const keywordProps = {
    keywordsSelecionadas: keywords,
    onKeywordsChange: setKeywords,
    conectorOuKeywords,
    onConectorOuKeywordsChange: setConectorOuKeywords,
  }

  return {
    buscaAvancadaAnchorEl,
    onCloseBuscaAvancada,
    onOpenBuscaAvancada,
    atorProps,
    entidadeProps,
    temaProps,
    keywordProps,
    setTiposFato,
    tiposFato,
    setOutrosFiltros,
    outrosFiltros,
    redirecionarParaPaginaDeResultados,
    limparPesquisa,
    sugestoesTextoLivre,
    handleLimparTags,
    onPesquisarTextoLivre,
    onPesquisarSelecionado,
    setText,
    text,
    conectoresOu,
  }
}

async function pegarSugestoes(dispatch: TypedDispatch, text: string, tipoPesquisa?: TipoSugestaoDeBusca) {
  if (text) {
    try {
      const { data: sugestoes } = await dispatch(sugestoesDeBuscaThunkCreators.buscarSugestoesDeBusca(text, tipoPesquisa))
      return sugestoes
    } catch (e) { console.warn(e) }
  }
  return []
}
