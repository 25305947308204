import React from 'react'
import { createReducer } from '../../../../infra/libs/redux-hardtyped/createReducer'
import { TypedDispatch, useTypedDispatch } from '../../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSwan } from '../../../../infra/libs/redux-swans/useSwan'
import { fichaActionCreators, fichaThunkCreators } from '../../../../store/actions/fichaActions'
import {
  useSpinningPromise,
} from '../../../../infra/libs/react-swearing/SpinnerScreenProvider'
import { defineActionCreators } from '../../../../infra/libs/redux-hardtyped/defineActionCreators'
import { AdicionarTrechoFatoFichaExistenteComponent } from '../../../ui-components/ficha/AdicionarTrechoFatoFichaExistenteComponent'
import { usePromiseEffect } from '../../../../infra/libs/react-swearing/usePromiseEffect'
import { ModalComponent } from '../../../ui-components/modal/ModalComponent'
import { ModalAddTrechoFichasPlaceHolderComponent } from '../../../ui-components/ficha/ModalAddTrechoFichasPlaceHolderComponent'

export const modalAdicionarTrechoFatoFichaExistenteActionCreators = defineActionCreators('modalAdicionarTrechoFatoFichaExistente', {
  abrirModal: (textoSelecionado: string, fatoId: string | number) => ({ textoSelecionado, fatoId }),
  fecharModal: () => ({}),
})

export type ModalAdicionarTrechoFatoFichaExistenteState = null | {
  fatoId?: string | number
  textoSelecionado?: string
  modalEstaAberta: boolean
}

const initialState = null

const reducer = createReducer<ModalAdicionarTrechoFatoFichaExistenteState>(initialState)(handle => ([

  handle(modalAdicionarTrechoFatoFichaExistenteActionCreators.abrirModal, (state, action) => {
    const { textoSelecionado, fatoId } = action
    return { textoSelecionado, fatoId, modalEstaAberta: true }
  }),

  handle(modalAdicionarTrechoFatoFichaExistenteActionCreators.fecharModal, () => null),

  handle(fichaActionCreators.trechoFatoAdicionado, () => null),

]))

export function ModalAdicionarTrechoFatoFichaExistenteContainer() {
  const dispatch = useTypedDispatch()
  const state = useSwan(reducer)
  const modalEstaAberta = state?.modalEstaAberta
  const fatoId = state?.fatoId
  const texto = state?.textoSelecionado

  const { data: fichas, isRejected, isLoading } = usePromiseEffect(recuperarFichas, [dispatch, fatoId, texto], [fatoId, texto])
  
  const { run: onConfirm } = useSpinningPromise(
    (fichaSelecionadaId?: number) => dispatch(fichaThunkCreators.adicionarTrechoFatoFichaExistente(
      // @ts-ignore
      fichaSelecionadaId, { fatoCitado: fatoId, conteudo: texto },
    )),
    [fatoId, texto],
  )

  const onCancel = () => dispatch(modalAdicionarTrechoFatoFichaExistenteActionCreators.fecharModal())

  if (isLoading) {
    return (
      <ModalComponent>
        <ModalAddTrechoFichasPlaceHolderComponent />
      </ModalComponent>
    ) 
  }

  if (isRejected) {
    onCancel()
    return null
  }


  if (!modalEstaAberta) return null
  return (
    <AdicionarTrechoFatoFichaExistenteComponent
      onCancel={onCancel}
      onConfirm={onConfirm}
      fichas={fichas ?? []}
    />
  )

}


async function recuperarFichas(dispatch: TypedDispatch, fatoId?: string | number, texto?: string) {
  if (fatoId && texto) {
    try {
      const { data: fichas } = await dispatch(fichaThunkCreators.recuperarMinhasFichas())
      return fichas
    } catch (e) {
      console.warn(e)
      throw e
    }
  }
  return []
}
