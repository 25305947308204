import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { IconFichaComponent } from '../icons/IconFichaComponent'


const useStyles = makeStyles({
  box: {
    width: '100%',
    display: 'flex',
    alignItems: 'baseline',
    padding: '15px 5px',
  },
  tituloListaFicha: {
    fontSize: '18px',
    marginBottom: '4px',
  },
  espacoIcon: {
    marginRight: '16px',
  },
  active: {
    background: '#f6f6f6',
  },

})

interface ItemListaFichaComponentProps{
  tituloFicha: string
  descricaoFicha?: string
  isActive: boolean
}

export function ItemListaFichaComponent({ tituloFicha, descricaoFicha, isActive }: ItemListaFichaComponentProps) {
  const classes = useStyles()
  return (
    <div className={`${classes.box} ${isActive ? classes.active : ''}`}>
      <div className={classes.espacoIcon}>
        <IconFichaComponent fill={isActive ? '#2F5DC9' : '#7E7E7E'} height="32" width="32" />
      </div>
      <div>
        <h3 className={classes.tituloListaFicha}>{tituloFicha}</h3>
        <span>
          {descricaoFicha}
        </span>
      </div>
    </div>
  )
}
