import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { ImagemComBordaContainer } from '../../containers/comum/ImagemComBordaContainer'
import { MapaColor } from '../../../style/theme'

const useStyles = makeStyles({
  linkStakeholder: {
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  imagem: {
    '@media screen and (max-width:1460px)': {
      width: '66px',
      height: '66px',
    },
    '@media screen and (max-width:1340px)': {
      width: '56px',
      height: '56px',
    },
    '@media screen and (max-width:1215px)': {
      width: '66px',
      height: '66px',
    },
    width: '66px',
    height: '66px',
  },
  nomeStakeholder: {
    display: '-webkit-box',
    width: '100%',
    textAlign: 'left',
    fontSize: '14px',
    color: MapaColor.neutralDark,
    overflow: 'hidden',
    marginBottom: 0,
  },
  cargoOrgao: {
    color: MapaColor.neutralMedium,
    fontSize: '12px',
  },
  boxStakeholder: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
})

export interface CardStakeholderFotoNomeProps {
  nome: string
  papel: string
  urlImagem: string
  urlDestino: string
  stakeholderInterno: boolean
}

export function CardStakeholderFotoNomeComponent({
  nome, papel, urlImagem, urlDestino, stakeholderInterno,
}: CardStakeholderFotoNomeProps) {
  const classes = useStyles()
  return (
    <div className={classes.boxStakeholder}>
      <Link className={classes.linkStakeholder} to={urlDestino}>
        <div className={classes.imagem}>
          <ImagemComBordaContainer urlImagem={urlImagem} papel={papel} stakeholderInterno={stakeholderInterno} />
        </div>
        <div>
          <h4 className={classes.nomeStakeholder}>{ nome }</h4>
          <span className={classes.cargoOrgao}>
            {papel}
          </span>
        </div>
      </Link>
    </div>
    
  )
}
