import React from 'react'

export function IconArquivarComponent({ fill = '#7E7E7E', height = '17', width = '17' }: { fill?: string, height?: string, width?: string }) {
  return (
    <svg height={height} viewBox="0 0 48 48" width={width} xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} d="M38 6H9.98c-2.21 0-3.96 1.79-3.96 4L6 38c0 2.21 1.77 4 3.98 4H38c2.21 0 4-1.79 4-4V10c0-2.21-1.79-4-4-4zm0 24h-8c0 3.31-2.69 6-6 6s-6-2.69-6-6H9.98V10H38v20zm-6-10h-4v-6h-8v6h-4l8 8 8-8z" />
      <path d="M0 0h48v48H0V0z" fill="none" />
    </svg>
  )
}
