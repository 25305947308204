import { BuscarNoticiaClippingEndpoint } from 'bff/src/main/controllers/fato/noticiasClippingEndpoints'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'


export const noticiaClippingThunkCreators = {

  buscarNoticiaClippingPorId: (id: string) => createBffRequestThunk({
    request: {
      endpoint: BuscarNoticiaClippingEndpoint.definition,
      params: { id },
    },
  }),

}
