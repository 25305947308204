import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { AtividadesFicha } from 'bff/src/main/domain/Ficha'
import Avatar from '@material-ui/core/Avatar'
import example_avatar from '../../../assets/example_avatar.jpeg'
import { IconLinkFichaComponent } from '../icons/IconLinkFichaComponent'

const useStyles = makeStyles({
  boxAtividade: {
    paddingTop: '32px',
    borderBottom: '1px solid #9e9e9e',
    paddingBottom: '40px',
    marginBottom: '24px',
  },
  avatarAtividade: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
  },
  TetxAtividade: {
    fontSize: '14px',
  },
  styleAvatar: {
    marginRight: '8px',
  },
  linkTexto: {
    marginLeft: '52px',
    marginTop: '27px',
    display: 'flex',
    alignItems: 'center',
  },
  iconLink: {
    marginRight: '19px',
  },
})

interface ListaAtividadesFichaComponentProps {
  listaAtividades: AtividadesFicha[]
}

export function ListaAtividadesFichaComponent({ listaAtividades }: ListaAtividadesFichaComponentProps) {
  const classes = useStyles()
  return (
    <div>
      {
        listaAtividades.map(atividade => montarBoxAtividade(classes, atividade))
      }
    </div>
  )
}

function montarBoxAtividade(classes, atividade: AtividadesFicha) {
  return (
    <div className={classes.boxAtividade} key={atividade.id}>
      <span className={classes.TetxAtividade}>{atividade.dataAtividade}</span>
      <div className={classes.avatarAtividade}>
        <div>
          <Avatar className={classes.styleAvatar} alt="Remy Sharp" src={example_avatar} />
        </div>
        <div>
          <span className={classes.TetxAtividade}>
            {`${atividade.usuario} ${atividade.tipoAtividade}`}
          </span>
        </div>
      </div>
      <div className={classes.linkTexto}>
        <div className={classes.iconLink}>
          <IconLinkFichaComponent fill="#2F5DC9" height="19" width="19" />
        </div>
        <div>
          {atividade.texto}
        </div>
      </div>
    </div>
  )
}
