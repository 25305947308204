import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { MapaColor } from '../../../style/theme'

const useStyles = makeStyles({
  error: {
    fontWeight: 500,
    '& span': {
      color: MapaColor.suport02,
    },
    '& .MuiInputBase-root': {
      border: '1px solid',
      borderColor: MapaColor.suport02,
    },
    '& label': {
      color: MapaColor.suport02,
    },
  },
  message: {

  },
})

interface FieldErrorComponentProps {
  children: React.ReactNode
  error: boolean
  message?: string
  className?: string
}

export function FieldErrorComponent({ children, error, message, className }: FieldErrorComponentProps) {
  const classes = useStyles()
  return (
    <div className={`row ${className} ${error ? classes.error : ''}`}>
      <div className="col-md-12">
        {children}
      </div>
      {
        error ? <span className={`col-md-12 ${classes.message}`}>{message}</span> : null
      }
    </div>
  )
}
