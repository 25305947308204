import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { MontarQueryRecuperarFatosPayload } from '../../api/queries/MontarQueryRecuperarFatosPayload'
import { recuperarContadoresDeTiposFatos } from '../../app/contador/recuperarContadoresDeTiposFatos'
import { TipoFato } from '../../domain/TipoFato'
import { TipoEvento } from '../../domain/TipoEvento'
import { TipoProcesso } from '../../domain/TipoProcesso'
import { MontarQueryRecuperarContadorFatosPayload } from '../../api/queries/MontarQueryRecuperarContadorFatosPayload'
import { recuperarContadorFatos } from '../../app/contador/recuperarContadorFatos'
import { ContadoresTiposDeFatos } from '../../domain/ContadoresTiposDeFatos'
import { GrauSigilo } from '../../domain/GrauSigilo'
import { recuperarContadoresTelaInicio } from '../../app/contador/recuperarContadoresTelaInicio'
import { EstadoProcesso } from '../../domain/EstadoProcesso'
import { TipoConectorOu } from '../../domain/TipoConectorOu'


export namespace BuscarContadorFatosEndpoint {
  export type Body = undefined
  export type Params = undefined
  export type Query = {
    idsAtores?: string[]
    idsEntidades?: string[]
    keywords?: string[]
    temas?: string[]
    textoLivre?: string
    tiposFato?: TipoFato[]
    tiposEvento?: TipoEvento[]
    tiposProcesso?: TipoProcesso[]
    estadosProcesso?: EstadoProcesso[]
    relatores?: string[]
    dtInicio?: string
    dtFim?: string
    grausSigilo?: GrauSigilo[]
    listaConectoresOu?: TipoConectorOu[]
  }
  export type ResponseBody = number
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: 'contadores/fatos',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { query } = request
      const payload: MontarQueryRecuperarContadorFatosPayload = {
        idsAtores: query.idsAtores ?? [],
        idsEntidades: query.idsEntidades ?? [],
        keywords: query.keywords ?? [],
        temas: query.temas ?? [],
        textoLivre: query.textoLivre,
        tiposFato: query.tiposFato ?? [],
        tiposEvento: query.tiposEvento ?? [],
        tiposProcesso: query.tiposProcesso ?? [],
        estadosProcesso: query.estadosProcesso ?? [],
        relatores: query.relatores ?? [],
        grausSigilo: query.grausSigilo ?? [],
        dtInicio: query.dtInicio,
        dtFim: query.dtFim,
        listaConectoresOu: query.listaConectoresOu ?? [],
      }
      const contadorFatos = await recuperarContadorFatos(payload)
      response.json(contadorFatos)
    },
  })
}

export namespace BuscarContadoresTiposDeFatos {
  export type Body = undefined
  export type Params = undefined
  export type Query = {
    idsAtores?: string[]
    idsEntidades?: string[]
    keywords?: string[]
    temas?: string[]
    textoLivre?: string
    dtInicio?: string
    dtFim?: string
    listaConectoresOu?: TipoConectorOu[]
  }
  export type ResponseBody = ContadoresTiposDeFatos
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/contadores/tipos-fatos',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { query } = request
      const payload: MontarQueryRecuperarFatosPayload = {
        idsAtores: query.idsAtores ?? [],
        idsEntidades: query.idsEntidades ?? [],
        keywords: query.keywords ?? [],
        temas: query.temas ?? [],
        textoLivre: query.textoLivre,
        tiposFato: [],
        tiposEvento: [],
        tiposProcesso: [],
        estadosProcesso: [],
        relatores: [],
        grausSigilo: [],
        dtInicio: query.dtInicio,
        dtFim: query.dtFim,
        size: 0,
        listaConectoresOu: query.listaConectoresOu ?? [],
      }
      const contadores = await recuperarContadoresDeTiposFatos(payload)
      response.json(contadores)
    },
  })
}

export namespace BuscarContadoresTelaInicio {
  export type Body = undefined
  export type Params = undefined
  export type Query = undefined
  export type ResponseBody = {
    totalProcessos: number
    totalClipping: number
    totalUniao: number
    totalEventos: number
    totalProposicoes: number
    totalAtores: number
    totalEntidades: number
  }
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/contadores/tela-inicial',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const contadores = await recuperarContadoresTelaInicio()
      response.json(contadores)
    },
  })
}
