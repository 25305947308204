import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Chip } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { BuscaAutocompleteInputOption } from '../busca/BuscaAutocompleteOptionComponent'
import atorIcon from '../../../assets/atorIcon.svg'
import entidadeIcon from '../../../assets/entidadeIcon.svg'
import { ConectorComponent } from './ConectorComponent'

const useStyles = makeStyles({
  tag: {
    margin: '0 5px 0 0',
    height: '28px',
    maxWidth: 'none',
  },
  tagIcon: {
    marginLeft: '6px !important',
    height: '24px',
    fontSize: '24px',
    color: 'inherit',
  },
  tagIconTema: {
    fontSize: '17px',
    top: '4px',
  },
  conjuntoCampo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  tagDeleteIcon: {
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
      opacity: 0.8,
    },
  },
})

export interface ListaTagPorCampoComponentProps {
  tags: BuscaAutocompleteInputOption[]
  conectoresOu: string[]
  isConectorEntreCampos: boolean
}

export function ListaTagPorCampoComponent({ tags, conectoresOu, isConectorEntreCampos } : ListaTagPorCampoComponentProps) {
  const classes = useStyles()
  const iconComponents = {
    ATOR: <img src={atorIcon} alt="Ator" />,
    ENTIDADE: <img src={entidadeIcon} alt="Entidade" />,
    TEMA: <i className={`fas fa-tags fa-fw ${classes.tagIconTema}`} />,
    KEYWORD: <SearchIcon />,
  }
  return (
    <div>
      <div className={classes.conjuntoCampo}>
        {
          tags.map((tag, index) => (
            <span key={tag.id}>
              <Chip
                classes={{
                  root: `${classes.tag}`,
                  icon: classes.tagIcon,
                }}
                variant="outlined"
                icon={iconComponents[tag.tipo]}
                label={tag.nome}
              />
              {tags[index + 1] && (
                <ConectorComponent
                  isConectorOu={conectoresOu.includes(tag.tipo)}
                  nomeFuncao={montaNomeFuncaoConector('conector-interno-campo-', tag.tipo, index + 1)}
                />
              )}
            </span>
          ))
        }
      </div>
      <div>
        {isConectorEntreCampos && (
          <ConectorComponent
            isConectorOu={false}
            nomeFuncao={montaNomeFuncaoConector('conector-entre-campos-', tags[0].tipo, 1)}
          />
        )}
      </div>
    </div>
  )
}

function montaNomeFuncaoConector(prefixo, tipoConector, contadorFuncao) {
  return prefixo + tipoConector?.toString().toLowerCase() + contadorFuncao.toString()
}
