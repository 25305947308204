import React from 'react'
import { TIPOS_PROCESSO } from 'bff/src/main/domain/TipoProcesso'
import { CheckboxesOption } from '../../ui-components/comum/CheckboxesComponent'
import { CheckboxesFilterContainer } from './CheckboxesFilterContainer'
import { ordernarListaAlfabeticamentePorPropriedade } from '../../../util/sortUtil'
import { QUERY_PARAM_FILTRO_TIPO_PROCESSOS } from '../../../util/constantesQueryParams'

export function FiltroTiposDeProcessosContainer() {
  return (
    <CheckboxesFilterContainer
      filterName={QUERY_PARAM_FILTRO_TIPO_PROCESSOS}
      filterLabel="Tipos de Processos"
      options={getOptions()}
      withOptionsFilter
    />
  )
}

function getOptions(): CheckboxesOption[] {
  const listaTiposProcesso = Object.values(TIPOS_PROCESSO).map(
    tipoProcesso => ({ label: tipoProcesso.label, value: tipoProcesso.id }),
  )
  return ordernarListaAlfabeticamentePorPropriedade(listaTiposProcesso, 'label')
}
