import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Cenario } from 'bff/src/main/domain/Cenario'
import { TitleComponent } from '../ui-components/head/TitleComponent'
import { useTypedDispatch, TypedDispatch } from '../../infra/libs/redux-hardtyped/useTypedDispatch'
import { cenarioThunkCreators } from '../../store/actions/cenarioActions'
import { StakeholdersPresentesNosCenariosContainer } from '../containers/stakeholder/StakeholdersPresentesNosCenariosContainer'
import { usePromiseEffect } from '../../infra/libs/react-swearing/usePromiseEffect'
import { StakeholdersTweetsTelaInicioContainer } from '../containers/stakeholder/StakeholdersTweetsTelaInicioContainer'
import { FatosMeusCenariosContainer } from '../containers/cenario/FatosMeusCenariosContainer'
import { OnBoardingTelaInicial } from '../containers/onboarding/OnBoardingTelaInicial'
import { ScrollToTopOnMount } from '../containers/util/ScrollToTopOnMount'
import { StatusSistemaContainer } from '../containers/comum/StatusSistemaContainer'


const useStyles = makeStyles({
  root: {
    marginTop: '30px',
    marginBottom: '80px',
  },
  tituloFatos: {
    marginBottom: '30px',
    color: '#231f20',
    fontWeight: 500,
    fontSize: '24px',
  },
  stakeholdersRelacionados: {
    marginBottom: '56px',
  },
  boxInicio: {
    maxWidth: '100%',
  },
})

export function InicioView() {
  const dispatch = useTypedDispatch()
  const { data: cenarios } = usePromiseEffect(recuperarMeusCenariosDaTelaInicial, [dispatch])
  const classes = useStyles()
  return render(classes, cenarios)
}

function render(classes, cenarios?: Cenario[]) {
  return (
    <div className={classes.root}>
      <TitleComponent title="Início" />
      <ScrollToTopOnMount />
      <div className={`${classes.boxInicio}`}>
        <div className="row">
          <div className="col-lg-8 col-md-12 col-ls-12">
            <OnBoardingTelaInicial.Step3>
              <h3 className={classes.tituloFatos}>Últimos acontecimentos</h3>
              <FatosMeusCenariosContainer />
            </OnBoardingTelaInicial.Step3>
          </div>
          <div className="col-lg-4 col-md-12 col-ls-12">
            <OnBoardingTelaInicial.Step4>
              <div className={classes.stakeholdersRelacionados}>
                <StakeholdersPresentesNosCenariosContainer cenarios={cenarios} />
              </div>
              <StakeholdersTweetsTelaInicioContainer cenarios={cenarios} />
            </OnBoardingTelaInicial.Step4>
            <StatusSistemaContainer />
          </div>
        </div>
      </div>
    </div>
  )
}

async function recuperarMeusCenariosDaTelaInicial(dispatch: TypedDispatch) {
  try {
    const { data: cenariosRecuperados } = await dispatch(cenarioThunkCreators.recuperarMeusCenariosDaTelaInicial())
    return cenariosRecuperados
  } catch (e) {
    console.warn(e)
  }
  return [] as Cenario[]
}
