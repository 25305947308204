import { fatosApi } from '../../api/fatosApi'
import { processosApi } from '../../api/processosApi'
import { recuperarAliasStakeholderPorId } from '../stakeholder/recuperarAliasStakeholderPorId'
import { CountResponse } from '../../infra/elastic/elasticTypes'
import { MontarQueryRecuperarContadorFatosPayload } from '../../api/queries/MontarQueryRecuperarContadorFatosPayload'
import { TipoFato } from '../../domain/TipoFato'

export async function recuperarContadorFatos(payload: MontarQueryRecuperarContadorFatosPayload) {
  const stakeholdersComAlias = await recuperarAliasStakeholderPorId(payload.idsAtores, payload.idsEntidades)
  const [
    { body: respostaIndiceFatos },
    { body: respostaIndiceProcessos },
  ] = await Promise.all([
    fatosApi.buscarContadorFatosPorFiltro(payload, stakeholdersComAlias.atores, stakeholdersComAlias.entidades),
    processosApi.buscarContadorProcessosPorFiltro(payload, stakeholdersComAlias.atores, stakeholdersComAlias.entidades),
  ])
  return montarViewModel(respostaIndiceFatos, respostaIndiceProcessos, payload.tiposFato)
}

function montarViewModel(respostaIndiceFatos: CountResponse, respostaIndiceProcessos: CountResponse, tiposFato: TipoFato[]) {
  const totalFatos = respostaIndiceFatos.count
  const totalProcessos = respostaIndiceProcessos.count
  return getTotalFatos(totalFatos, totalProcessos, tiposFato)
}

function getTotalFatos(totalFatos: number, totalProcessos: number, tiposFato: TipoFato[]) {
  if (tiposFato.length === 1 && tiposFato.includes('PROCESSO')) {
    return totalProcessos
  }
  if (tiposFato.length > 0 && !tiposFato.includes('PROCESSO')) {
    return totalFatos
  }
  return totalProcessos + totalFatos
}
