import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Entidade } from 'bff/src/main/domain/Entidade'
import { useParam } from '../../infra/libs/react-location-hooks/routerHooks'
import { entidadeThunkCreators } from '../../store/actions/entidadeActions'
import { PerfilEntidadeHeaderComponent } from '../ui-components/stakeholder/entidade/PerfilEntidadeHeaderComponent'
import { PerfilStakeholderHeaderPlaceholderComponent } from '../ui-components/stakeholder/PerfilStakeholderHeaderPlaceholderComponent'
import { PerfilEntidadeAbasContainer } from '../containers/stakeholder/PerfilEntidadeAbasContainer'
import { ScrollToTopOnMount } from '../containers/util/ScrollToTopOnMount'
import { TitleComponent } from '../ui-components/head/TitleComponent'
import { PaginaDeErroHttpComponent } from '../ui-components/erro/PaginaDeErroHttpComponent'
import { useTypedDispatch, TypedDispatch } from '../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../infra/libs/react-swearing/usePromiseEffect'
import { TwitterProfileTimelineComponent } from '../ui-components/stakeholder/twitter/TwitterProfileTimelineComponent'

const useStyles = makeStyles({
  perfilEntidadeBody: {
    marginTop: '50px',
    paddingBottom: '64px',
  },
})

export function PerfilEntidadeView() {
  const classes = useStyles()
  const dispatch = useTypedDispatch()
  const idEntidade = useParam('id')
  const { data: entidade, isLoading } = usePromiseEffect(pegarEntidade, [dispatch, idEntidade])
  if (!isLoading && !entidade) {
    return (
      <PaginaDeErroHttpComponent
        mensagemDeErro="Tivemos um problema para exibir o perfil da entidade"
      />
    )
  }
  return render(classes, isLoading, entidade)
}

async function pegarEntidade(dispatch: TypedDispatch, idEntidade: string) {
  const { data: entidade } = await dispatch(entidadeThunkCreators.buscarEntidadePorId(idEntidade))
  return entidade
}

function render(classes, isLoading: boolean, entidade? : Entidade) {
  return (
    <div className="container">
      <TitleComponent title={entidade?.nomeApresentacao} />
      <ScrollToTopOnMount />
      {renderPerfilEntidadeHeader(isLoading, entidade)}
      {renderPerfilEntidadeContent(classes, isLoading, entidade)}
    </div>
  )
}

function renderPerfilEntidadeHeader(isLoading: boolean, entidade?: Entidade) {
  if (isLoading) {
    return <PerfilStakeholderHeaderPlaceholderComponent />
  }
  if (!entidade) {
    return null
  }
  return (
    <PerfilEntidadeHeaderComponent
      nomeApresentacao={entidade.nomeApresentacao}
      nomeCompleto={entidade.nomeCompleto}
      sigla={entidade.sigla}
      stakeholderInterno={entidade.stakeholderInterno}
      urlImagem={entidade.urlImagem}
      idsStakeholdersRelacionados={entidade.idsStakeholdersRelacionados}
    />
  )
}

function renderPerfilEntidadeContent(classes, isLoading: boolean, entidade?: Entidade) {
  if (!isLoading && !entidade) {
    return null
  }
  return (
    <div className={`row ${classes.perfilEntidadeBody}`}>
      <div className="col-md-8">
        <PerfilEntidadeAbasContainer />
      </div>
      {
        entidade?.perfilTwitter && (
          <div className="col-md-4">
            <TwitterProfileTimelineComponent
              profile={entidade.perfilTwitter}
              name={entidade.nomeApresentacao}
              height={950}
            />
          </div>
        )
      }
    </div>
  )
}
