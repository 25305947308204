import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Ficha } from 'bff/src/main/domain/Ficha'
import moment from 'moment'
import { HeaderCenariosComponent } from '../comum/HeaderCenariosComponent'
import { NovaFichaComponent } from './NovaFichaComponent'
import { BoxFichaComponent } from './BoxFichaComponent'

const useStyles = makeStyles({
  header: {
    marginBottom: '48px',
  },
  body: {
    paddingLeft: '15px',
  },
  boxFicha: {
    marginBottom: '40px',
  },
})


interface MinhasFichasComponentProps {
  fichas: Ficha[]
  onClickNovaFicha: () => void
}

export function MinhasFichasComponent(props: MinhasFichasComponentProps) {
  const classes = useStyles()

  /* if (props.fichas.length < 1) {
    return (
      <div className="row">
        nenhuma ficha
      </div>
    )
  } */
  
  return (
    <div>
      <div className="row">
        <div className={`${classes.header} col-md-12`}>
          <HeaderCenariosComponent
            titulo="Minhas fichas"
            descricao="Crie, edite, compartilhe ou gerencie suas fichas."
          />
        </div>
        <div className={`${classes.body} col-md-11 row`}>
          <div className={`${classes.boxFicha} col-lg-4 col-md-4 col-xl-3 col-sm-6`}>
            <NovaFichaComponent onClick={props.onClickNovaFicha} />
          </div>
          {renderFichas(classes, props.fichas)}  
        </div>
        
      </div>
    </div>
  )
}

function renderFichas(classes, fichas: Ficha[]) {
  return fichas.map(ficha => (
    <div key={ficha.id} className={`${classes.boxFicha} col-lg-4 col-md-4 col-xl-3 col-sm-6`}>
      <BoxFichaComponent fichaId={ficha.id} tituloFicha={ficha.titulo} dataLabelUltimoEvento={moment(ficha.dataHoraAtualizacao).format('[Atualizado em] LL')} />
    </div>
  ))
}
