import { createReducer } from '../../../infra/libs/redux-hardtyped/createReducer'
import { RequestsState } from './RequestsState'
import { bffActionCreators } from '../../../infra/libs/redux-bff-rpc-actions/bffActions'

const initialState: RequestsState = {
  requestsQuantity: 0,
  finishedRequestsQuantity: 0,
}

export const requestsReducer = createReducer(initialState)(handle => ([

  handle(bffActionCreators.request, state => ({
    ...state,
    requestsQuantity: state.requestsQuantity + 1,
  })),

  handle(bffActionCreators.finish, state => ({
    ...state,
    finishedRequestsQuantity: state.finishedRequestsQuantity + 1,
  })),

]))
