import React from 'react'
import { ClickAwayListener } from '@material-ui/core'
import { useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useParam } from '../../../infra/libs/react-location-hooks/routerHooks'
import { ContextMenuComponent } from '../../ui-components/comum/ContextMenuComponent'
import { modalNovaFichaActionCreators } from '../modal/modal-nova-ficha/modalNovaFichaActions'
import { modalAdicionarTrechoFatoFichaExistenteActionCreators } from '../modal/modal-adicionar-trecho-fato-ficha-existente/ModalAdicionarTrechoFatoFichaExistenteContainer'

interface ContextMenuContainerProps {
  textoSelecionado: string
  xPosition: number
  yPosition: number
  onClickAway: () => void
}

export function ContextMenuContainer({ textoSelecionado, xPosition, yPosition, onClickAway }: ContextMenuContainerProps) {
  const dispatch = useTypedDispatch()
  const fatoId = useParam('id')
  const numeroProcesso = useParam('codigoProcesso')

  if (!textoSelecionado) {
    return null
  }

  function adicionarTrechoNovaFicha() {
    dispatch(modalNovaFichaActionCreators.abrirModalTelaFato(textoSelecionado, fatoId ?? numeroProcesso))
    onClickAway()
  }

  function adicionarTrechoFichaExistente() {
    dispatch(modalAdicionarTrechoFatoFichaExistenteActionCreators.abrirModal(textoSelecionado, fatoId ?? numeroProcesso))
    onClickAway()
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div>
        <ContextMenuComponent
          xPosition={xPosition}
          yPosition={yPosition}
          onCriarFicha={adicionarTrechoNovaFicha}
          onAdicionarTrechoNovaFicha={adicionarTrechoFichaExistente}
        />
      </div>
    </ClickAwayListener>
  )
}
