import {
  AdicionarTrechoFatoFichaExistenteEndpoint,
  AdicionarTrechoTextualFichaEndpoint,
  EditarConteudoTrechoTextualEndpoint,
  AlterarTipoTrechoTextualEndpoint,
  BuscarFichaPorIdEndpoint,
  CriarFichaEndpoint,
  EditarDescricaoFichaEndpoint,
  ExcluirFichaEndpoint,
  RecuperarMinhasFichasEndpoint,
  RemoverTrechoFichaEndpoint,
  AlterarOrdemTechoEndpoint,
  EditarTituloFichaEndpoint,
} from 'bff/src/main/controllers/ficha/fichasEndpoints'
import { Ficha } from 'bff/src/main/domain/Ficha'
import { TipoTrechoFicha } from 'bff/src/main/domain/TipoTrechoFicha'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'
import { defineActionCreators } from '../../infra/libs/redux-hardtyped/defineActionCreators'
import { alertActionCreators } from './alertActions'

export const fichaActionCreators = defineActionCreators('ficha', {
  fichaCriada: (data: Ficha) => data,
  descricaoAlterada: (data: Ficha) => data,
  tituloAlterado: (data: Ficha) => data,
  fichaExcluida: () => ({}),
  trechoFatoAdicionado: (data: Ficha) => data,
  trechoTextualAdicionado: (data: Ficha) => data,
  trechoFichaRemovido: () => ({}),
  tipoTrechoTextualFichaAlterado: () => ({}),
  textoTrechoAlterado: () => ({}),
  ordemTrechoAlterada: () => ({}),
})

export const fichaThunkCreators = {

  recuperarMinhasFichas: () => createBffRequestThunk({
    request: {
      endpoint: RecuperarMinhasFichasEndpoint.definition,
    },
    actions: {
      error: () => alertActionCreators.showError('Ocorreu um erro ao recuperar as fichas.'),
    },
  }),

  recuperarFichaPorId: (id: number) => createBffRequestThunk({
    request: {
      endpoint: BuscarFichaPorIdEndpoint.definition,
      params: { id },
    },
    actions: {
      error: () => alertActionCreators.showError('Ocorreu um erro ao recuperar a ficha.'),
    },
  }),

  criarFicha: (body: CriarFichaEndpoint.Body) => createBffRequestThunk({
    request: {
      endpoint: CriarFichaEndpoint.definition,
      body,
    },
    actions: {
      success: response => fichaActionCreators.fichaCriada(response.data),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar criar a ficha.'),
    },
  }),

  editarDescricaoFicha: (id: number, descricao: string) => createBffRequestThunk({
    request: {
      endpoint: EditarDescricaoFichaEndpoint.definition,
      params: { id },
      body: { descricao },
    },
    actions: {
      success: response => fichaActionCreators.descricaoAlterada(response.data),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar editar a descrição da ficha.'),
    },
  }),

  editarTituloFicha: (id: number, titulo: string) => createBffRequestThunk({
    request: {
      endpoint: EditarTituloFichaEndpoint.definition,
      params: { id },
      body: { titulo },
    },
    actions: {
      success: response => fichaActionCreators.tituloAlterado(response.data),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar editar o titulo da ficha.'),
    },
  }),

  excluirFicha: (id: number) => createBffRequestThunk({
    request: {
      endpoint: ExcluirFichaEndpoint.definition,
      params: { id },
    },
    actions: {
      success: () => fichaActionCreators.fichaExcluida(),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar excluir a ficha.'),
    },
  }),

  adicionarTrechoFatoFichaExistente: (fichaId: number, body: AdicionarTrechoFatoFichaExistenteEndpoint.Body) => createBffRequestThunk({
    request: {
      endpoint: AdicionarTrechoFatoFichaExistenteEndpoint.definition,
      params: { id: fichaId },
      body,
    },
    actions: {
      success: response => fichaActionCreators.trechoFatoAdicionado(response.data),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar adicionar trecho a ficha.'),
    },
  }),

  adicionarTrechoTextualFicha: (fichaId: number, body: AdicionarTrechoTextualFichaEndpoint.Body) => createBffRequestThunk({
    request: {
      endpoint: AdicionarTrechoTextualFichaEndpoint.definition,
      params: { id: fichaId },
      body,
    },
    actions: {
      success: response => fichaActionCreators.trechoTextualAdicionado(response.data),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar adicionar trecho a ficha.'),
    },
  }),

  removerTrechoFicha: (fichaId: number, trechoId: number) => createBffRequestThunk({
    request: {
      endpoint: RemoverTrechoFichaEndpoint.definition,
      params: { fichaId },
      body: { trechoId },
    },
    actions: {
      success: () => fichaActionCreators.trechoFichaRemovido(),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar remover o trecho da ficha.'),
    },
  }),

  alterarTipoTrechoTextual: (fichaId: number, trechoId: number, tipo: TipoTrechoFicha) => createBffRequestThunk({
    request: {
      endpoint: AlterarTipoTrechoTextualEndpoint.definition,
      params: { fichaId },
      body: { trechoId, tipoTrechoTextual: tipo },
    },
    actions: {
      success: () => fichaActionCreators.tipoTrechoTextualFichaAlterado(),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar alterar o tipo do trecho.'),
    },
  }),

  editarConteudoTrechoTextual: (fichaId: number, trechoId: number, texto: string) => createBffRequestThunk({
    request: {
      endpoint: EditarConteudoTrechoTextualEndpoint.definition,
      params: { fichaId },
      body: { novoConteudo: texto, trechoId },
    },
    actions: {
      success: () => fichaActionCreators.textoTrechoAlterado(),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar editar o texto do trecho.'),
    },
  }),

  alterarOrdemTecho: (fichaId: number, trechoId: number, novaOrdemTrecho: number) => createBffRequestThunk({
    request: {
      endpoint: AlterarOrdemTechoEndpoint.definition,
      params: { fichaId },
      body: { novaOrdemTrecho, trechoId },
    },
    actions: {
      success: () => fichaActionCreators.ordemTrechoAlterada(),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar alterar a ordem do trecho.'),
    },
  }),

}
