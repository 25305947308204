import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Switch, TextField } from '@material-ui/core'
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'
import moment, { Moment } from 'moment'
import { DatePicker } from '@material-ui/pickers'
import { TipoFato, TIPOS_FATO } from 'bff/src/main/domain/TipoFato'
import { Cenario } from 'bff/src/main/domain/Cenario'
import { CriarCenarioEndpoint } from 'bff/src/main/controllers/cenario/cenariosEndpoints'
import { TipoConectorOu, TIPOS_CONECTORES_OU } from 'bff/src/main/domain/TipoConectorOu'
import { HeaderCenariosComponent } from '../../ui-components/comum/HeaderCenariosComponent'
import { FieldErrorComponent } from '../../ui-components/comum/FieldErrorComponent'
import { TiposFatoCheckbox, TiposFatoCheckboxContainer } from './TiposFatoCheckboxContainer'
import { ComboboxComponent, ComboboxComponentItemProps } from '../../ui-components/comum/ComboboxComponent'
import { ButtonComponent } from '../../ui-components/comum/ButtonComponent'
import { BuscaAutocompleteInputOption } from '../../ui-components/busca/BuscaAutocompleteOptionComponent'
import { BuscaAutocompleteInputComponent } from '../../ui-components/busca/BuscaAutocompleteInputComponent'
import {
  AtorAutocompleteProps,
  EntidadeAutocompleteProps, KeywordSearchProps,
  TemaAutocompleteProps,
} from '../busca/useBuscaAutocomplete'
import { SwitchConectorOUComponent } from '../../ui-components/conector/SwitchConectorOUComponent'


const useStyles = makeStyles({
  margin: {
    marginBottom: '32px',
  },
  labelCenario: {
    color: '#434343',
    fontWeight: 500,
    fontSize: '16px',
  },
  datepicker: {
    '&:first-child': {
      marginRight: '5px',
    },
    '& .MuiInputBase-input': {
      padding: '13px 12px',
    },
  },
  comboboxDatas: {
    width: '202px',
  },
  cancelButtom: {
    marginRight: '16px',
  },
  mostrarTelaInicio: {
    display: 'flex',
    alignItems: 'center',
  },
  textoFatosRecentes: {
    marginBottom: 0,
  },
  espacamentoSwitch: {
    marginLeft: '20px',
  },
  alinharSwitch: {
    marginLeft: '-8px',
  },
  inputComSwitch: {
    display: 'flex',
  },
  inputKeywordTextoLivre: {
    borderRadius: '8px 0px 0px 8px',
    borderColor: '#EAEAEA',
    borderStyle: 'solid',
    borderWidth: '1px 0px 1px 1px',
    '&:hover': {
      borderStyle: 'solid',
      borderColor: '#EAEAEA',
      borderWidth: '1px 0px 1px 1px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    backgroundColor: '#fdfdfd',
  },
})

interface CenarioFormProps {
  cenarioInicial?: Cenario
  onConfirm: (criarCenarioCommand: CriarCenarioEndpoint.Body) => void
  onCancel: () => void
  atorProps: AtorAutocompleteProps
  entidadeProps: EntidadeAutocompleteProps
  temaProps: TemaAutocompleteProps
  keywordProps: KeywordSearchProps
}

interface Fields {
  nomeCenario: string
  atoresSelecionados: BuscaAutocompleteInputOption[]
  entidadesSelecionadas: BuscaAutocompleteInputOption[]
  temasSelecionados: BuscaAutocompleteInputOption[]
  keywords?: string
  textoLivre?: string
  periodoSelecionado: ComboboxComponentItemProps
  dataInicio: Moment
  tiposFato: TiposFatoCheckbox[]
  visualizarTelaInicio: boolean
}

export function CenarioForm({
  cenarioInicial, onConfirm, onCancel,
  temaProps, atorProps, entidadeProps,
  keywordProps,
}: CenarioFormProps) {
  const classes = useStyles()

  const {
    register, handleSubmit, errors, control, watch,
  } = useForm<Fields>({ defaultValues: getDefaultValues(cenarioInicial) })

  const watchPeriodoSelecionado = watch('periodoSelecionado')

  function realizarAcao(formValues: Fields) {
    const command: CriarCenarioEndpoint.Body = {
      nome: formValues.nomeCenario,
      stakeholders: [...formValues.atoresSelecionados.map(item => item.id), ...formValues.entidadesSelecionadas.map(item => item.id)],
      keywords: formValues.keywords?.split(',').map(k => k.trim()).filter(k => !!k),
      temas: formValues.temasSelecionados.map(item => item.nome),
      textoLivre: formValues.textoLivre || undefined,
      dataBuscaAPartirDe: tratarDataInicio(formValues.periodoSelecionado, formValues.dataInicio),
      visivelEmTelaInicial: formValues.visualizarTelaInicio,
      filtrosTipoFato: tratarTipos(formValues.tiposFato),
      conectoresOu: getConectoresOu(
        atorProps.conectorOuAtores,
        entidadeProps.conectorOuEntidades,
        temaProps.conectorOuTemas,
        keywordProps.conectorOuKeywords,
      ),
    }
    onConfirm(command)
  }

  function cancelar() {
    onCancel()
  }

  return (
    <form className={classes.margin} onSubmit={handleSubmit(realizarAcao)}>
      <HeaderCenariosComponent
        breadcrumb="Meus cenários/"
        titulo={cenarioInicial ? 'Editar Cenário' : 'Novo Cenário'}
        descricao={
          cenarioInicial
            ? 'Altere as informações sobre o cenário criado.'
            : 'Configure a pesquisa para acompanhar os últimos contecimentos relacionados ao cenário.'
        }
      />
      <FieldErrorComponent className={classes.margin} error={!!errors.nomeCenario} message="Defina um nome para o cenário">
        <span className={classes.labelCenario}>Nome do cenário</span>
        <TextField
          inputRef={register({ required: true })}
          name="nomeCenario"
          variant="outlined"
          fullWidth
        />
      </FieldErrorComponent>
      <span className={classes.labelCenario}>Buscar fatos sobre:</span>
      <div className={`${classes.margin} ${classes.inputComSwitch}`}>
        <Controller
          name="atoresSelecionados"
          control={control}
          render={(props: ControllerRenderProps) => (
            <BuscaAutocompleteInputComponent
              selectedOptions={atorProps.atoresSelecionados}
              options={atorProps.atoresOptions ?? []}
              onInputChange={atorProps.onAtorWordChange}
              onSelectedOptionsChange={(options: BuscaAutocompleteInputOption[]) => {
                atorProps.onAtoresChange(options)
                props.onChange(options)
              }}
              inputPlaceholder="Buscar atores"
              actionOnEnter="selectFirst"
              ignoreSuggestedKeyword
              layoutBuscaAvancada
            />
          )}
        />
        <SwitchConectorOUComponent value={atorProps.conectorOuAtores} onChange={atorProps.onConectorOuAtoresChange} />
      </div>
      <div className={`${classes.margin} ${classes.inputComSwitch}`}>
        <Controller
          name="entidadesSelecionadas"
          control={control}
          render={(props: ControllerRenderProps) => (
            <BuscaAutocompleteInputComponent
              selectedOptions={entidadeProps.entidadesSelecionadas}
              options={entidadeProps.entidadesOptions ?? []}
              onInputChange={entidadeProps.onEntidadeWordChange}
              onSelectedOptionsChange={(options: BuscaAutocompleteInputOption[]) => {
                entidadeProps.onEntidadesChange(options)
                props.onChange(options)
              }}
              inputPlaceholder="Buscar entidades"
              actionOnEnter="selectFirst"
              ignoreSuggestedKeyword
              layoutBuscaAvancada
            />
          )}
        />
        <SwitchConectorOUComponent value={entidadeProps.conectorOuEntidades} onChange={entidadeProps.onConectorOuEntidadesChange} />
      </div>
      <div className={`${classes.margin} ${classes.inputComSwitch}`}>
        <Controller
          name="temasSelecionados"
          control={control}
          render={(props: ControllerRenderProps) => (
            <BuscaAutocompleteInputComponent
              selectedOptions={temaProps.temasSelecionados}
              options={temaProps.temasOptions ?? []}
              onInputChange={temaProps.onTemaWordChange}
              onSelectedOptionsChange={(options: BuscaAutocompleteInputOption[]) => {
                temaProps.onTemasChange(options)
                props.onChange(options)
              }}
              inputPlaceholder="Buscar temas"
              actionOnEnter="selectFirst"
              ignoreSuggestedKeyword
              layoutBuscaAvancada
            />
          )}
        />
        <SwitchConectorOUComponent value={temaProps.conectorOuTemas} onChange={temaProps.onConectorOuTemasChange} />
      </div>
      <div className={`${classes.margin} ${classes.inputComSwitch}`}>
        <TextField
          className={classes.inputKeywordTextoLivre}
          inputRef={register()}
          placeholder="Palavras Chave (separe cada termo por virgula)"
          name="keywords"
          variant="outlined"
          fullWidth
        />
        <SwitchConectorOUComponent value={keywordProps.conectorOuKeywords} onChange={keywordProps.onConectorOuKeywordsChange} />
      </div>
      <div className={classes.margin}>
        <TextField
          inputRef={register()}
          placeholder="Texto livre"
          name="textoLivre"
          variant="outlined"
          fullWidth
        />
      </div>
      <div className={classes.margin}>
        <span className={classes.labelCenario}>Pesquisar fatos a partir de:</span>
        <Controller 
          name="periodoSelecionado"
          control={control}
          render={(props: ControllerRenderProps) => (
            <ComboboxComponent 
              className={classes.comboboxDatas} 
              options={getDatasOptions()} 
              selectedOption={props.value}
              onChange={selected => props.onChange(selected)}
            />
          )}
        />
        <br />
        {
          watchPeriodoSelecionado.key === 'SELECIONAR_DATA'
            && (
              <Controller
                name="dataInicio"
                control={control}
                render={(props: ControllerRenderProps) => (
                  <DatePicker
                    className={classes.datepicker}
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    autoOk
                    placeholder="__/__/____"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={props.value}
                    onChange={selected => props.onChange(selected)}
                  />
                )}
              />
            )
        }
      </div>
      <FieldErrorComponent className={classes.margin} error={!!errors.tiposFato} message="Selecione ao menos um tipo de fato">
        <Controller
          control={control}
          name="tiposFato"
          rules={{ validate: value => value.length > 0 }}
          render={(props: ControllerRenderProps) => (
            <TiposFatoCheckboxContainer
              onChange={selected => props.onChange(selected)}
              selected={props.value}
            />
          )}
        />
      </FieldErrorComponent>
      <div className={classes.margin}>
        <div className={classes.mostrarTelaInicio}>
          <div className={classes.alinharSwitch}>
            <Controller
              name="visualizarTelaInicio"
              control={control}
              type="checkbox"
              render={(props: ControllerRenderProps) => (
                <Switch
                  checked={props.value}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  onChange={event => props.onChange(event.target.checked)}
                />
              )}
            />
          </div>
          <div className={classes.espacamentoSwitch}>
            <span className={classes.labelCenario}>Apresentar na página Início</span>
            <p className={classes.textoFatosRecentes}>Os fatos mais recentes sobre o cenário serão apresentados na página inicial.</p>
          </div>
        </div>
        
      </div>
      <ButtonComponent
        buttonText="Cancelar"
        buttonTypeColor="white"
        onClick={cancelar}
        className={classes.cancelButtom}
      />
      <ButtonComponent
        buttonText={cenarioInicial ? 'Salvar alterações' : 'Criar cenário'}
        buttonTypeColor="blue"
        type="submit"
      />
    </form>
  )
}

function getDefaultValues(cenario?: Cenario): Fields {
  if (cenario) {
    const atores: BuscaAutocompleteInputOption[] = []
    const entidades: BuscaAutocompleteInputOption[] = []
    const temas: BuscaAutocompleteInputOption[] = []
    let keywords

    if (cenario.consulta.stakeholders) {
      atores.push(...cenario.consulta.stakeholders.filter(s => !s.includes('ENTIDADE')).map(a => ({ id: a, tipo: 'ATOR' } as BuscaAutocompleteInputOption)))
      entidades.push(...cenario.consulta.stakeholders.filter(s => s.includes('ENTIDADE')).map(e => ({ id: e, tipo: 'ENTIDADE' } as BuscaAutocompleteInputOption)))
    }
    if (cenario.consulta.keywords) {
      keywords = cenario.consulta.keywords.join(', ')
    }
    if (cenario.consulta.temas) {
      temas.push(...cenario.consulta.temas.map(t => ({ id: t, tipo: 'TEMA', nome: t } as BuscaAutocompleteInputOption)))
    }
    return {
      nomeCenario: cenario.nome,
      textoLivre: cenario.consulta.textoLivre,
      atoresSelecionados: atores,
      entidadesSelecionadas: entidades,
      temasSelecionados: temas,
      keywords,
      periodoSelecionado: cenario.consulta.dataBuscaAPartirDe
        ? { label: 'Selecionar data', key: 'SELECIONAR_DATA', value: null }
        : { label: 'Qualquer data', key: 'QUALQUER_DATA', value: moment('1970-01-01').format('YYYY-MM-DD') },
      dataInicio: cenario.consulta.dataBuscaAPartirDe ? moment(cenario.consulta.dataBuscaAPartirDe) : moment(),
      tiposFato: cenario.consulta.filtrosTipoFato,
      visualizarTelaInicio: cenario.visivelEmTelaInicial,
    }
  }
  return {
    nomeCenario: '',
    atoresSelecionados: [],
    entidadesSelecionadas: [],
    temasSelecionados: [],
    keywords: undefined,
    periodoSelecionado: { label: 'Qualquer data', key: 'QUALQUER_DATA', value: moment('1970-01-01').format('YYYY-MM-DD') },
    dataInicio: moment(),
    tiposFato: [] as TipoFato[],
    visualizarTelaInicio: true,
  }
}

function getDatasOptions(): ComboboxComponentItemProps[] {
  return [
    { label: 'Qualquer data', key: 'QUALQUER_DATA', value: moment('1970-01-01').format('YYYY-MM-DD') },
    { label: 'Hoje', key: 'HOJE', value: moment().format('YYYY-MM-DD') },
    { label: 'Semana passada', key: 'SEMANA_PASSADA', value: moment().subtract(7, 'days').format('YYYY-MM-DD') },
    { label: 'Mês passado', key: 'MES_PASSADO', value: moment().subtract(1, 'months').format('YYYY-MM-01') },
    { label: 'Último ano', key: 'ULTIMO_ANO', value: moment().subtract(1, 'years').format('YYYY-01-01') },
    { label: 'Selecionar data', key: 'SELECIONAR_DATA', value: null },
  ]
}

function tratarTipos(tipos: TiposFatoCheckbox[]): TipoFato[] {
  if (tipos.find(tipo => tipo === 'TODOS_OS_TIPOS')) {
    return Object.values(TIPOS_FATO).map(tipoFato => tipoFato.id)
  }
  return tipos as TipoFato[]
}

function tratarDataInicio(periodoSelecionado: ComboboxComponentItemProps, dataInicio: moment.Moment): string {
  return periodoSelecionado.key === 'SELECIONAR_DATA' ? dataInicio.format('YYYY-MM-DD') : periodoSelecionado.value
}

function getConectoresOu(atorOu: boolean, entidadeOu: boolean, temaOu: boolean, keywordOu: boolean) {
  const conectores: TipoConectorOu[] = []
  if (atorOu) {
    conectores.push(TIPOS_CONECTORES_OU.ATOR.id)
  }
  if (entidadeOu) {
    conectores.push(TIPOS_CONECTORES_OU.ENTIDADE.id)
  }
  if (temaOu) {
    conectores.push(TIPOS_CONECTORES_OU.TEMA.id)
  }
  if (keywordOu) {
    conectores.push(TIPOS_CONECTORES_OU.KEYWORD.id)
  }
  return conectores
}
