import React from 'react'

export function IconNovaFichaComponent({ fill = '#7E7E7E', height = '17', width = '17' }: { fill?: string, height?: string, width?: string }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} d="M10 4L12 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V18C22 18.5304 21.7893 19.0391 21.4142 19.4142C21.0391 19.7893 20.5304 20 20 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V6C2 4.89 2.89 4 4 4H10ZM15 9V12H12V14H15V17H17V14H20V12H17V9H15Z" />
    </svg>

  )
}
