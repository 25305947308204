import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { NoticiaUniao } from 'bff/src/main/domain/NoticiaUniao'
import { ListaStakeholdersContainer } from '../containers/stakeholder/ListaStakeholdersContainer'
import {
  useParam,
  useQueryParamAsArrayOfString,
  useQueryParamAsString,
} from '../../infra/libs/react-location-hooks/routerHooks'
import { noticiaUniaoThunkCreators } from '../../store/actions/noticiaUniaoActions'
import { FatoCompletoHeaderPlaceholderComponent } from '../ui-components/fato/FatoCompletoHeaderPlaceholderComponent'
import { FatoCompletoBodyPlaceholderComponent } from '../ui-components/fato/FatoCompletoBodyPlaceholderComponent'
import { ScrollToTopOnMount } from '../containers/util/ScrollToTopOnMount'
import { FatoCompletoHeaderComponent } from '../ui-components/fato/FatoCompletoHeaderComponent'
import { FatosRelacionadosComponent } from '../ui-components/fato/FatosRelacionadosComponent'
import { TitleComponent } from '../ui-components/head/TitleComponent'
import { PaginaDeErroHttpComponent } from '../ui-components/erro/PaginaDeErroHttpComponent'
import { useTypedDispatch, TypedDispatch } from '../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../infra/libs/react-swearing/usePromiseEffect'
import { InformacaoAdicionalFatoComponent } from '../ui-components/comum/InformacaoAdicionalFatoComponent'
import { QUERY_PARAM_KEYWORDS, QUERY_PARAM_TEMAS, QUERY_PARAM_TEXTO_LIVRE } from '../../util/constantesQueryParams'


const useStyles = makeStyles({
  root: {
    marginTop: '40px',
    paddingBottom: '100px',
  },
  header: {
    marginBottom: '48px',
  },
})

export function NoticiaUniaoView() {
  const classes = useStyles()
  const dispatch = useTypedDispatch()
  const idFato = useParam('id')
  const keywords = useQueryParamAsArrayOfString(QUERY_PARAM_KEYWORDS)
  const temas = useQueryParamAsArrayOfString(QUERY_PARAM_TEMAS)
  const textoLivre = useQueryParamAsString(QUERY_PARAM_TEXTO_LIVRE)
  const { data: noticiaUniao, isLoading } = usePromiseEffect(pegarFato, [dispatch, idFato])

  const termosParaDestacar = [...keywords, ...temas]
  if (textoLivre) {
    termosParaDestacar.push(...textoLivre.split(' '))
  }

  if (!isLoading && !noticiaUniao) {
    return (
      <PaginaDeErroHttpComponent
        mensagemDeErro="Tivemos um problema para exibir o fato"
      />
    )
  }
  return render(classes, isLoading, termosParaDestacar, noticiaUniao)
}

function render(classes, isLoading: boolean, termosParaDestacar: string[], noticiaUniao?: NoticiaUniao) {
  return (
    <div className={`container ${classes.root}`}>
      <TitleComponent title={noticiaUniao?.titulo} />
      <ScrollToTopOnMount />
      <div className={classes.header}>
        {renderFatoHeader(isLoading, noticiaUniao)}
      </div>
      <div className="row">
        <div className="col-md-5">
          {renderFatoBody(isLoading, termosParaDestacar, noticiaUniao)}
        </div>
        <div className="col-md-5 offset-md-2">
          <ListaStakeholdersContainer
            stakeholdersIds={noticiaUniao?.stakeholdersIds || []}
            tituloAutoridadesTcu="Autoridades do TCU citadas no fato"
            tituloStakeholders="Stakeholders citados no fato"
          />
          <FatosRelacionadosComponent fatos={noticiaUniao?.fatosRelacionados ?? []} />
        </div>
      </div>
    </div>
  )
}

async function pegarFato(dispatch: TypedDispatch, idFato: string) {
  const { data: fato } = await dispatch(noticiaUniaoThunkCreators.buscarNoticiaUniaoPorId(idFato))
  return fato
}

function renderFatoHeader(isLoading: boolean, noticiaUniao?: NoticiaUniao) {
  if (isLoading) {
    return <FatoCompletoHeaderPlaceholderComponent />
  }
  if (!noticiaUniao) {
    return null
  }
  return (
    <FatoCompletoHeaderComponent
      tipoFato={noticiaUniao.tipoFato}
      titulo={noticiaUniao.titulo}
      urlFonte={noticiaUniao.urlFonte}
      dataHora={noticiaUniao.dataHora}
    />
  )
}

function renderFatoBody(isLoading: boolean, termosParaDestacar: string[], noticiaUniao?: NoticiaUniao) {
  if (isLoading) {
    return <FatoCompletoBodyPlaceholderComponent />
  }
  if (!noticiaUniao) {
    return null
  }
  return <InformacaoAdicionalFatoComponent texto={noticiaUniao.texto} termosParaDestacar={termosParaDestacar} />
}
