import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Evento } from 'bff/src/main/domain/Evento'
import { ListaStakeholdersContainer } from '../containers/stakeholder/ListaStakeholdersContainer'
import {
  useParam,
  useQueryParamAsArrayOfString,
  useQueryParamAsString,
} from '../../infra/libs/react-location-hooks/routerHooks'
import { eventoThunkCreators } from '../../store/actions/eventoActions'
import { FatoCompletoHeaderPlaceholderComponent } from '../ui-components/fato/FatoCompletoHeaderPlaceholderComponent'
import { FatoCompletoBodyPlaceholderComponent } from '../ui-components/fato/FatoCompletoBodyPlaceholderComponent'
import { ScrollToTopOnMount } from '../containers/util/ScrollToTopOnMount'
import { FatoCompletoHeaderComponent } from '../ui-components/fato/FatoCompletoHeaderComponent'
import { TituloListaComponent } from '../ui-components/comum/TituloListaComponent'
import { TitleComponent } from '../ui-components/head/TitleComponent'
import { InformacaoAdicionalFatoComponent } from '../ui-components/comum/InformacaoAdicionalFatoComponent'
import { PaginaDeErroHttpComponent } from '../ui-components/erro/PaginaDeErroHttpComponent'
import { useTypedDispatch, TypedDispatch } from '../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../infra/libs/react-swearing/usePromiseEffect'
import { QUERY_PARAM_KEYWORDS, QUERY_PARAM_TEMAS, QUERY_PARAM_TEXTO_LIVRE } from '../../util/constantesQueryParams'

const useStyles = makeStyles({
  root: {
    marginTop: '40px',
    paddingBottom: '100px',
  },
  header: {
    marginBottom: '48px',
  },
  descricao: {
    marginBottom: '40px',
  },
  resultado: {
    marginBottom: '24px',
  },
  local: {
    marginBottom: '56px',
  },
})

export function EventoView() {
  const classes = useStyles()
  const dispatch = useTypedDispatch()
  const idFato = useParam('id')
  const keywords = useQueryParamAsArrayOfString(QUERY_PARAM_KEYWORDS)
  const temas = useQueryParamAsArrayOfString(QUERY_PARAM_TEMAS)
  const textoLivre = useQueryParamAsString(QUERY_PARAM_TEXTO_LIVRE)
  const { data: fato, isLoading } = usePromiseEffect(pegarFato, [dispatch, idFato])

  const termosParaDestacar = [...keywords, ...temas]
  if (textoLivre) {
    termosParaDestacar.push(...textoLivre.split(' '))
  }

  if (!isLoading && !fato) {
    return (
      <PaginaDeErroHttpComponent
        mensagemDeErro="Tivemos um problema para exibir o fato"
      />
    )
  }
  return render(classes, isLoading, termosParaDestacar, fato)
}

function render(classes, isLoading: boolean, termosParaDestacar: string[], fato?: Evento) {
  return (
    <div className={`container ${classes.root}`}>
      <TitleComponent title={fato?.titulo} />
      <ScrollToTopOnMount />
      <div className={classes.header}>
        {renderFatoHeader(isLoading, fato)}
      </div>
      <div className="row">
        <div className="col-md-5">
          {renderFatoContent(classes, isLoading, termosParaDestacar, fato)}
        </div>
        <div className="col-md-5 offset-md-2">
          <ListaStakeholdersContainer
            stakeholdersIds={fato?.stakeholdersIds || []}
            tituloAutoridadesTcu="Autoridades do TCU citadas no fato"
            tituloStakeholders="Stakeholders citados no fato"
          />
        </div>
      </div>
    </div>
  )
}

async function pegarFato(dispatch: TypedDispatch, idFato: string) {
  const { data: fato } = await dispatch(eventoThunkCreators.buscarEventoPorId(idFato))
  return fato
}

function renderFatoHeader(isLoading: boolean, fato?: Evento) {
  if (isLoading) {
    return <FatoCompletoHeaderPlaceholderComponent />
  }
  if (!fato) {
    return null
  }
  return (
    <FatoCompletoHeaderComponent
      tipoFato={fato.tipoFato}
      titulo={fato.titulo}
      urlFonte={fato.urlFonte}
      infoAdicional={fato.tipoEvento}
      dataHora={fato.dataHora}
      isSigiloso={fato.isSigiloso}
    />
  )
}

function renderFatoContent(classes, isLoading: boolean, termosParaDestacar: string[], fato?: Evento) {
  if (isLoading) {
    return <FatoCompletoBodyPlaceholderComponent />
  }
  if (!fato) {
    return null
  }
  return (
    <div>
      <InformacaoAdicionalFatoComponent className={classes.descricao} titulo="Objetivo" texto={fato.texto} termosParaDestacar={termosParaDestacar} />
      <InformacaoAdicionalFatoComponent className={classes.resultado} titulo="Resultado" texto={fato.resultadoEvento} termosParaDestacar={termosParaDestacar} />
      <InformacaoAdicionalFatoComponent className={classes.local} titulo="Local" texto={fato.localEvento} termosParaDestacar={termosParaDestacar} />
      <TituloListaComponent titulo="Participantes do Evento" lista={fato?.participantesEvento} exibirContador={false} />
    </div>
  )
}
