import { stakeholdersApi } from '../../api/stakeholdersApi'
import { mapearParaStakeholders } from './stakeholderUtil'

export async function recuperarStakeholdersResumidosPorIds(listaIds: string[]) {
  if (!listaIds || listaIds.length === 0) {
    return []
  }
  const { body: respostaIndice } = await stakeholdersApi.buscarStakeholdersPorIds(listaIds)
  const listaStakeholders = respostaIndice.hits.hits
  return mapearParaStakeholders(listaStakeholders)
}
