import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  titulosBody: {
    color: '#434343',
    fontWeight: 500,
    fontSize: '18px',
  },
})

export function TituloComponent({ titulo, complemento, className }: { titulo: string, complemento?: ReactNode, className?: string }) {
  const classes = useStyles()
  return (
    <div className={className}>
      <h4 className={classes.titulosBody}>
        {titulo}
        {complemento}
      </h4>
    </div>
  )
}
