import React, { useState } from 'react'
import { Dialog } from '@material-ui/core'
import { CriarCenarioEndpoint } from 'bff/src/main/controllers/cenario/cenariosEndpoints'
import { TIPOS_FATO } from 'bff/src/main/domain/TipoFato'
import { ButtonComponent } from '../../ui-components/comum/ButtonComponent'
import { useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useMemoObject } from '../../../hooks/useMemoObject'
import {
  useQueryParamAsArrayOfString,
  useQueryParamAsString,
} from '../../../infra/libs/react-location-hooks/routerHooks'
import {
  QUERY_PARAM_ATORES, QUERY_PARAM_CONECTOR_OU,
  QUERY_PARAM_ENTIDADES, QUERY_PARAM_FILTRO_TIPO_FATOS,
  QUERY_PARAM_KEYWORDS,
  QUERY_PARAM_TEMAS, QUERY_PARAM_TEXTO_LIVRE,
} from '../../../util/constantesQueryParams'
import { useSpinningPromise } from '../../../infra/libs/react-swearing/SpinnerScreenProvider'
import { cenarioThunkCreators } from '../../../store/actions/cenarioActions'
import { ModalCriarCenarioForm } from './ModalCriarCenarioForm'

export function BotaoCriarCenarioTelaResultadoContainer() {
  const dispatch = useTypedDispatch()
  const [open, setOpen] = useState(false)

  const params = useMemoObject({
    idsAtores: useQueryParamAsArrayOfString(QUERY_PARAM_ATORES),
    idsEntidades: useQueryParamAsArrayOfString(QUERY_PARAM_ENTIDADES),
    keywords: useQueryParamAsArrayOfString(QUERY_PARAM_KEYWORDS),
    temas: useQueryParamAsArrayOfString(QUERY_PARAM_TEMAS),
    tiposFato: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_TIPO_FATOS),
    textoLivre: useQueryParamAsString(QUERY_PARAM_TEXTO_LIVRE),
    conectoresOu: useQueryParamAsArrayOfString(QUERY_PARAM_CONECTOR_OU),
  })

  const { run: onConfirm } = useSpinningPromise(async (command: CriarCenarioEndpoint.Body) => {
    try {
      await dispatch(cenarioThunkCreators.criarCenario(command, 'tela_resultado'))
    } catch (e) {
      console.error(e)
    } finally {
      setOpen(false)
    }
  })
  const onCancel = () => setOpen(false)

  function handleClose() {
    setOpen(false)
  }

  return (
    <>
      <ButtonComponent buttonText="Criar cenário" buttonTypeColor="blue" onClick={() => setOpen(true)} />
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick
      >
        <ModalCriarCenarioForm
          idsStakeholders={[...params.idsAtores, ...params.idsEntidades]}
          keywords={params.keywords}
          temas={params.temas}
          textoLivre={params.textoLivre}
          conectoresOu={params.conectoresOu}
          tiposFato={escolherTiposFato(params.tiposFato)}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </Dialog>
    </>
  )
}

function escolherTiposFato(tiposFato: string[]) {
  return tiposFato.length > 0 ? tiposFato : Object.values(TIPOS_FATO).map(tipoFato => tipoFato.id)
}
