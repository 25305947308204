import { createReducer } from '../../../../infra/libs/redux-hardtyped/createReducer'
import { cenarioActionCreators } from '../../../../store/actions/cenarioActions'
import { modalExcluirCenarioActionCreators } from './modalExcluirCenarioActions'


export type ModalExcluirCenarioState = null | {
  idCenario: number
  nomeCenario: string
}

const initialState = null

export const modalExcluirCenarioReducer = createReducer<ModalExcluirCenarioState>(initialState)(handle => ([

  handle(modalExcluirCenarioActionCreators.abrirModal, (state, action) => {
    const { id, nome } = action
    return { idCenario: id, nomeCenario: nome }
  }),

  handle(modalExcluirCenarioActionCreators.fecharModal, fecharModal),

  handle(cenarioActionCreators.cenarioExcluido, fecharModal),

]))

function fecharModal(): ModalExcluirCenarioState {
  return null
}
