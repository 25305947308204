import { AuthParams } from '../../infra/security/AuthParams'
import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { obterTokenJwtDoUsuario } from '../../infra/security/obterTokenJwtDoUsuario'

export namespace ObterTokenJwtPeloTokenPortalTcuEndpoint {
  export type Body = { tokenPortalTcu: string }
  export type Params = undefined
  export type Query = undefined
  export type ResponseBody = AuthParams
  export const definition = defineEndpoint({
    httpMethod: 'POST',
    url: '/auth/obter-token-jwt-pelo-token-portal-tcu',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { tokenPortalTcu } = request.body
      try {
        const { data } = await obterTokenJwtDoUsuario(tokenPortalTcu)
        response.json(data)
      } catch (e) {
        response.status(401).send(`Falha na autenticação. ${e.message}` as any)
      }
    },
  })
}
