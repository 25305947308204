import { createReducer } from '../../../infra/libs/redux-hardtyped/createReducer'
import { authActionCreators } from '../../actions/authActions'
import { SessionState } from './SessionState'

const initialState = null

export const sessionReducer = createReducer<SessionState>(initialState)(handle => ([

  handle(authActionCreators.logado, (state, action) => {
    const { authParams } = action
    return { authParams }
  }),

]))
