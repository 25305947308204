import { Request } from 'express'
import httpContext from 'express-http-context'
import jwtDecode from 'jwt-decode'
import { PerfilMapa } from '../security/PerfilMapa'
import { AuthParams, DecodedToken } from '../security/AuthParams'


const requestId = 'request'

export const requestScope = {

  saveRequest: (request: Request) => httpContext.set(requestId, request),

  getRequest: (): Request => httpContext.get(requestId),

  getAuthParams(): AuthParams {
    const request = this.getRequest()
    const tokenJwt = extrairBearerTokenDoRequest(request)
    const userFingerPrint = extrairUserFingerPrintDoRequest(request)
    return { tokenJwt, userFingerPrint }
  },

  getDecodedTokenJwt() {
    const { tokenJwt } = this.getAuthParams()
    return tokenJwt ? jwtDecode<DecodedToken>(tokenJwt) : undefined
  },

  getRolesIds() {
    const decodedToken = this.getDecodedTokenJwt()
    return decodedToken?.roles ?? []
  },

  getUserId() {
    return this.getDecodedTokenJwt()?.sub ?? ''
  },

  hasRole(role: PerfilMapa) {
    return this.getRolesIds().find(r => r === role.roleId)
  },

}


function extrairBearerTokenDoRequest(request: Request) {
  const authorizationHeader = request.headers.authorization
  if (temBearerToken(authorizationHeader)) {
    return authorizationHeader?.split(' ')[1]
  }
  return undefined
}

function extrairUserFingerPrintDoRequest(request: Request) {
  const xufpHeader = request.headers['x-ufp']
  if (typeof xufpHeader === 'string') {
    return xufpHeader
  }
  return undefined
}

function temBearerToken(authorizationHeader) {
  return authorizationHeader && authorizationHeader.split(' ')[0] === 'Bearer'
}
