import React, { ReactNode } from 'react'
import { Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'


const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  box: {
    borderRadius: '5px',
    padding: '30px',
    backgroundColor: '#fff',
    '@media screen and (max-width:567px)': {
      width: '90%',
    },
  },
})

interface ModalComponentProps {
  children: ReactNode | ReactNode[]
}

export function ModalComponent({ children }: ModalComponentProps) {
  const classes = useStyles()

  return (
    <div>
      <Modal
        className={classes.modal}
        open
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className={classes.box}>
          {children}
        </div>
      </Modal>
    </div>
  )
}
