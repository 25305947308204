import React from 'react'
import { Helmet } from 'react-helmet'

export interface TitleProps {
  title?: string
}

export function TitleComponent({ title }: TitleProps) {
  if (title) {
    return (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    )
  }
  return null
}
