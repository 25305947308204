import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { ResumoFato } from '../../domain/ResumoFato'
import { MontarQueryRecuperarFatosPayload } from '../../api/queries/MontarQueryRecuperarFatosPayload'
import { recuperarFatosResumidos } from '../../app/fato/recuperarFatosResumidos'
import { TipoFato } from '../../domain/TipoFato'
import { TipoProcesso } from '../../domain/TipoProcesso'
import { TipoEvento } from '../../domain/TipoEvento'
import { Ordenacao } from '../../domain/Ordenacao'
import { recuperarFatosTelaInicial } from '../../app/cenario/recuperarFatosTelaInicial'
import { GrauSigilo } from '../../domain/GrauSigilo'
import { FatoTelaInicial } from '../../domain/FatoTelaInicial'
import { EstadoProcesso } from '../../domain/EstadoProcesso'
import { TipoConectorOu } from '../../domain/TipoConectorOu'


export namespace BuscarFatosResumidosEndpoint {
  export type Body = undefined
  export type Params = undefined
  export type Query = {
    idsAtores?: string[]
    idsEntidades?: string[]
    keywords?: string[]
    temas?: string[]
    tiposFato?: TipoFato[]
    tiposProcesso?: TipoProcesso[]
    estadosProcesso?: EstadoProcesso[]
    relatores?: string[]
    tiposEvento?: TipoEvento[]
    dtInicio?: string
    dtFim?: string
    ordenacao?: Ordenacao
    size?: number
    grausSigilo?: GrauSigilo[]
    textoLivre?: string
    listaConectoresOu?: TipoConectorOu[]
  }
  export type Response = ResumoFato[]
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/fatos-resumidos',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<Response>) => {
      const { query } = request
      const payload: MontarQueryRecuperarFatosPayload = {
        idsAtores: query.idsAtores ?? [],
        idsEntidades: query.idsEntidades ?? [],
        keywords: query.keywords ?? [],
        temas: query.temas ?? [],
        textoLivre: query.textoLivre,
        tiposFato: query.tiposFato ?? [],
        tiposEvento: query.tiposEvento ?? [],
        tiposProcesso: query.tiposProcesso ?? [],
        estadosProcesso: query.estadosProcesso ?? [],
        relatores: query.relatores ?? [],
        grausSigilo: query.grausSigilo ?? [],
        dtInicio: query.dtInicio,
        dtFim: query.dtFim,
        ordenacao: query.ordenacao,
        listaConectoresOu: query.listaConectoresOu ?? [],
        size: parseInt(query.size ?? '10'),
      }
      const fatos = await recuperarFatosResumidos(payload)
      response.json(fatos)
    },
  })
}

export namespace BuscarFatosParaTelaInicialEndpoint {
  export type Body = undefined
  export type Params = undefined
  export type Query = undefined
  export type Response = FatoTelaInicial[]
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/fatos-tela-inicial',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<Response>) => {
      const data = await recuperarFatosTelaInicial()
      response.json(data)
    },
  })
}
