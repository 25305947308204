import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Link, useLocation } from 'react-router-dom'

const useStyles = makeStyles({
  itemMenu: {
    '@media screen and (max-width:1215px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'initial',
    },
    padding: '14px 14px 14px 32px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '14px',
    marginTop: '-3px',
  },
  novo: {
    color: '#769bf3',
    fontSize: '14px',
    marginLeft: '16px',
  },
  active: {
    background: '#f6f6f6',
  },
  grey: {
    color: '#434343',
  },
  blue: {
    color: '#2F5DC9',
  },
})

interface ItemMenuComponentProps {
  label: string
  url: string
  icon?: string | ReactNode
  isNew?: boolean
  className?: string
  color?: 'grey' | 'blue'
}

export function ItemMenuComponent({
  label, url, isNew = false, icon, className, color = 'grey',
}: ItemMenuComponentProps) {
  const location = useLocation()
  const isActive = location.pathname === url
  const classes = useStyles()
  return (
    <Link to={url}>
      <div className={className}>
        <li className={`${classes.itemMenu} ${isActive ? classes.active : ''} ${classes[color]}`}>
          <span className={classes.icon}>
            {
              typeof icon === 'string'
                ? <img src={icon} alt={label} />
                : icon ?? null
            }
          </span>
          {label}
          {isNew && <span className={classes.novo}>Novo</span>}
        </li>
      </div>
    </Link>
  )
}
