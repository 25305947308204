import { MontarQueryRecuperarFatosPayload } from './MontarQueryRecuperarFatosPayload'
import { createClauseBoolMust, createClauseBoolShould, createClauseMatchPhrase } from '../../util/elasticSearchQueryUtil'
import { TipoFato, TIPOS_FATO } from '../../domain/TipoFato'
import { Ordenacao, ORDENACOES } from '../../domain/Ordenacao'
import { TipoEvento, TIPOS_EVENTO } from '../../domain/TipoEvento'
import { montarSubqueryDeEventosSigilososBaseadaEmPerfilEFiltro } from './montarSubqueryDeEventosSigilososBaseadaEmPerfil'
import { MontarQueryRecuperarContadorFatosPayload } from './MontarQueryRecuperarContadorFatosPayload'
import { MontarQueryRecuperarFatosBasePayload } from './MontarQueryRecuperarFatosBasePayload'
import { TipoConectorOu, TIPOS_CONECTORES_OU } from '../../domain/TipoConectorOu'
import { StakeholdersComIdEAlias } from '../../app/stakeholder/recuperarAliasStakeholderPorId'

const CAMPOS_PARA_PESQUISAR: string[] = ['titulo', 'autor', 'resumo', 'texto', 'tags', 'complemento', 'participantes_evento', 'partido_autor']

export function montarQueryRecuperarFatos(
  payload: MontarQueryRecuperarFatosPayload,
  atores: StakeholdersComIdEAlias[],
  entidades: StakeholdersComIdEAlias[],
) {
  const query: any = montarQuery(payload, atores, entidades)
  query.sort = montarOrdenacao(payload.ordenacao)
  query.size = payload.tiposFato.length === 1 && payload.tiposFato.includes('PROCESSO') ? 0 : payload.size
  query.aggs = montarAggregationPorTipoDeFato()
  return query
}

export function montarQueryRecuperarContadorFatos(
  payload: MontarQueryRecuperarContadorFatosPayload,
  atores: StakeholdersComIdEAlias[],
  entidades: StakeholdersComIdEAlias[],
) {
  return montarQuery(payload, atores, entidades)
}

function montarQuery(
  payload: MontarQueryRecuperarFatosBasePayload,
  atores: StakeholdersComIdEAlias[],
  entidades: StakeholdersComIdEAlias[],
) {
  return {
    query: {
      bool: {
        must: [
          ...montarSubqueriesStakeholders(payload.listaConectoresOu.includes(TIPOS_CONECTORES_OU.ATOR.id), atores),
          ...montarSubqueriesStakeholders(payload.listaConectoresOu.includes(TIPOS_CONECTORES_OU.ENTIDADE.id), entidades),
          ...montarSubquerieParaTemas(payload.temas, payload.listaConectoresOu),
          ...montarSubqueriesParaKeywords(payload.keywords, payload.listaConectoresOu, payload.textoLivre),
          ...montarSubqueriesParaFiltros(payload.tiposFato, payload.tiposEvento, payload.dtInicio, payload.dtFim),
          montarSubqueryDeEventosSigilososBaseadaEmPerfilEFiltro(payload.grausSigilo),
        ],
      },
    },
  }
}

function montarSubqueriesStakeholders(conectorOu: boolean, stakeholders: StakeholdersComIdEAlias[]) {
  if (conectorOu) {
    const estruturaOr = createClauseBoolShould()
    estruturaOr.bool.should.push(...stakeholders.map(montarSubqueryParaStakeholder))
    return [estruturaOr]
  }
  return stakeholders.map(montarSubqueryParaStakeholder)
}

function montarAggregationPorTipoDeFato() {
  return {
    fontes_fatos: {
      terms: {
        field: 'tipo_fato',
      },
    },
  }
}

function montarOrdenacao(ordenacao: Ordenacao | undefined) {
  const ordenacaoRecuperada = ORDENACOES[ordenacao ?? '']
  if (ordenacaoRecuperada) {
    return ordenacaoRecuperada.clause
  }
  return ORDENACOES.MAIS_RELEVANTE.clause
}

function montarSubqueryParaStakeholder(stakeholder: StakeholdersComIdEAlias) {
  const estruturaOr = createClauseBoolShould()
  estruturaOr.bool.should.push(getMatchPhrasePorPropriedade('stakeholders_relacionados', stakeholder.id))
  stakeholder.listaNomesFoneticos.forEach((nomeFonetico) => {
    if (nomeFonetico) {
      estruturaOr.bool.should.push(getMatchPhrasePorPropriedade('aliases_foneticos_stakeholders', nomeFonetico))
    }
  })
  return estruturaOr
}

function montarSubquerieParaTemas(temas: string[], listaConectoresOu: TipoConectorOu[]) {
  const conectorOuKeywords = listaConectoresOu.includes(TIPOS_CONECTORES_OU.TEMA.id)

  if (conectorOuKeywords) {
    const estruturaOr = createClauseBoolShould()
    estruturaOr.bool.should.push(...temas.map(tema => getMatchPhrasePorPropriedade('temas', tema)))
    return [estruturaOr]
  }

  return temas.map(tema => getMatchPhrasePorPropriedade('temas', tema))
}

function montarSubqueriesParaKeywords(keywords: string[], listaConectoresOu: TipoConectorOu[], textoLivre?: string) {
  const conectorOuKeywords = listaConectoresOu.includes(TIPOS_CONECTORES_OU.KEYWORD.id)
  const palavrasParaPesquisar: string[] = []
  if (textoLivre) {
    palavrasParaPesquisar.push(...textoLivre.split(' '))
  }
  if (keywords.length > 0) {
    palavrasParaPesquisar.push(...keywords)
  }
  if (conectorOuKeywords) {
    const estruturaOr = createClauseBoolShould()
    estruturaOr.bool.should.push(...palavrasParaPesquisar.map(montarSubqueryParaKeyword))
    return [estruturaOr]
  }
  return palavrasParaPesquisar.map(montarSubqueryParaKeyword)
}

function montarSubqueryParaKeyword(keyword: string) {
  const estruturaOr = createClauseBoolShould()
  estruturaOr.bool.should.push(...montarCondicoesOrPorCampoPesquisado(keyword))
  return estruturaOr
}

function montarCondicoesOrPorCampoPesquisado(valor: string) {
  return CAMPOS_PARA_PESQUISAR.map(campo => getMatchPhrasePorPropriedade(campo, valor))
}

function getMatchPhrasePorPropriedade(propriedade: string, valor: any) {
  const obj = { match_phrase: {} }
  obj.match_phrase[propriedade] = { query: valor }
  return obj
}

function montarSubqueriesParaFiltros(tiposFato: TipoFato[], tiposEvento: TipoEvento[], dtInicio?: string, dtFim?: string) {
  const listaFiltroAnd: any[] = []
  if (dtInicio || dtFim) {
    listaFiltroAnd.push(montarFiltroIntervalo(dtInicio, dtFim))
  }

  const objetoPesquisaOr = createClauseBoolShould()
  const objetoMatchPhraseTipoFatoNoticiaClipping = createClauseMatchPhrase('tipo_fato', TIPOS_FATO.NOTICIA_CLIPPING.id)
  const objetoMatchPhraseTipoFatoNoticiaUniao = createClauseMatchPhrase('tipo_fato', TIPOS_FATO.NOTICIA_UNIAO.id)
  const objetoMatchPhraseTipoFatoProposicaoLegislativa = createClauseMatchPhrase('tipo_fato', TIPOS_FATO.PROPOSICAO_LEGISLATIVA.id)
  if (tiposFato.includes('NOTICIA_CLIPPING')) {
    objetoPesquisaOr.bool.should.push(objetoMatchPhraseTipoFatoNoticiaClipping)
  }
  if (tiposFato.includes('NOTICIA_UNIAO')) {
    objetoPesquisaOr.bool.should.push(objetoMatchPhraseTipoFatoNoticiaUniao)
  }
  if (tiposFato.includes('PROPOSICAO_LEGISLATIVA')) {
    objetoPesquisaOr.bool.should.push(objetoMatchPhraseTipoFatoProposicaoLegislativa)
  }
  if (tiposFato.includes('EVENTO_RELACIONA')) {
    objetoPesquisaOr.bool.should.push(adicionarFiltroEventosETipoDeEvento(tiposEvento))
  }
  if (tiposFato.length === 0) {
    objetoPesquisaOr.bool.should.push(objetoMatchPhraseTipoFatoNoticiaClipping)
    objetoPesquisaOr.bool.should.push(objetoMatchPhraseTipoFatoNoticiaUniao)
    objetoPesquisaOr.bool.should.push(objetoMatchPhraseTipoFatoProposicaoLegislativa)
    objetoPesquisaOr.bool.should.push(adicionarFiltroEventosETipoDeEvento(tiposEvento))
  }
  listaFiltroAnd.push(objetoPesquisaOr)
  return listaFiltroAnd
}

function montarFiltroIntervalo(dtInicio?: string, dtFim?: string) {
  return {
    range: {
      data_publicacao: {
        gte: dtInicio,
        lte: dtFim,
        boost: 2.0,
      },
    },
  }
}

function adicionarFiltroEventosETipoDeEvento(tiposEvento: TipoEvento[]) {
  const objetoPesquisaAnd = createClauseBoolMust()
  const objetoPesquisaOrTipoDeEvento = createClauseBoolShould()
  tiposEvento.forEach((tipoEvento) => {
    const clauseMatchPhrase = createClauseMatchPhrase('tipo_evento', TIPOS_EVENTO[tipoEvento].key)
    objetoPesquisaOrTipoDeEvento.bool.should.push(clauseMatchPhrase)
  })
  objetoPesquisaAnd.bool.must.push(
    createClauseMatchPhrase('tipo_fato', TIPOS_FATO.EVENTO_RELACIONA.id),
    objetoPesquisaOrTipoDeEvento,
  )
  return objetoPesquisaAnd
}
