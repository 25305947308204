import React from 'react'
import { IconButton, makeStyles, Popover } from '@material-ui/core'
import { TIPOS_TRECHO_FICHA, TipoTrechoFicha } from 'bff/src/main/domain/TipoTrechoFicha'
import Divider from '@material-ui/core/Divider'
import { IconTituloTextoComponent } from '../icons/IconTituloTextoComponent'
import { IconTextoAlignComponent } from '../icons/IconTextoAlignComponent'
import { IconRemoveComponent } from '../icons/IconRemoveComponent'
import { IconLinkFichaComponent } from '../icons/IconLinkFichaComponent'

const useStyles = makeStyles({
  box: {
    display: 'flex',
    justifyContent: 'center',
  },
  acoesBox: {
    backgroundColor: '#434343',
    display: 'grid',
  },
  linha: {
    backgroundColor: '#fff',
    marginLeft: '10px',
    marginRight: '10px',  
  },
})

const trechosMap = {
  [TIPOS_TRECHO_FICHA.CITACAO_FATO.id]: {
    icon: <IconLinkFichaComponent fill="#9E9E9E" height="18" width="18" />,
  },
  [TIPOS_TRECHO_FICHA.TITULO.id]: {
    icon: <IconTituloTextoComponent fill="#9E9E9E" width="20" height="20" />,
  },
  [TIPOS_TRECHO_FICHA.SUBTITULO.id]: {
    icon: <IconTituloTextoComponent fill="#9E9E9E" height="15" width="15" />,
  },
  [TIPOS_TRECHO_FICHA.PARAGRAFO.id]: {
    icon: <IconTextoAlignComponent fill="#9E9E9E" height="20" width="20" />,
  },
}

interface BotaoAcoesTrechoFichaComponentProps {
  tipoTrecho: TipoTrechoFicha
  onDelete: () => void
  onAlterarTipoTrechoTextual: (tipoTrecho: TipoTrechoFicha) => void
  onRedirecionarParaFato: () => void
}

export function BotaoAcoesTrechoFichaComponent(
  { onDelete, tipoTrecho, onAlterarTipoTrechoTextual, onRedirecionarParaFato }
  : BotaoAcoesTrechoFichaComponentProps,
) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simplee-popover' : undefined
  return (
    <div className={classes.box}>

      <IconButton onClick={handleClick}>
        {trechosMap[tipoTrecho].icon}
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {renderAcoes(classes, onDelete, tipoTrecho, onAlterarTipoTrechoTextual, onRedirecionarParaFato)}
      </Popover>
    </div>
  )
}

function renderAcoes(classes, onDelete, tipoTrecho: TipoTrechoFicha, onAlterarTipoTrechoTextual, onRedirecionarParaFato) {
  if (tipoTrecho === TIPOS_TRECHO_FICHA.CITACAO_FATO.id) {
    return (
      <div className={classes.acoesBox}>
        <IconButton onClick={onRedirecionarParaFato}>
          <IconLinkFichaComponent fill="#fff" width="20" height="20" />
        </IconButton>
        <Divider className={classes.linha} variant="middle" light="false" />
        <IconButton onClick={onDelete}>
          <IconRemoveComponent fill="#fff" height="18" width="18" />
        </IconButton>
      </div>
    )
  }
  return (
    <div className={classes.acoesBox}>
      <IconButton
        onClick={() => onAlterarTipoTrechoTextual(TIPOS_TRECHO_FICHA.TITULO.id)}
        disabled={tipoTrecho === TIPOS_TRECHO_FICHA.TITULO.id}
      >
        <IconTituloTextoComponent fill={tipoTrecho === TIPOS_TRECHO_FICHA.TITULO.id ? '#4fbe83' : '#fff'} width="20" height="20" />
      </IconButton>
      <IconButton
        onClick={() => onAlterarTipoTrechoTextual(TIPOS_TRECHO_FICHA.SUBTITULO.id)}
        disabled={tipoTrecho === TIPOS_TRECHO_FICHA.SUBTITULO.id}
      >
        <IconTituloTextoComponent fill={tipoTrecho === TIPOS_TRECHO_FICHA.SUBTITULO.id ? '#4fbe83' : '#fff'} width="15" height="15" />
      </IconButton>
      <IconButton
        onClick={() => onAlterarTipoTrechoTextual(TIPOS_TRECHO_FICHA.PARAGRAFO.id)}
        disabled={tipoTrecho === TIPOS_TRECHO_FICHA.PARAGRAFO.id}
      >
        <IconTextoAlignComponent fill={tipoTrecho === TIPOS_TRECHO_FICHA.PARAGRAFO.id ? '#4fbe83' : '#fff'} width="20" height="20" />
      </IconButton>
      <Divider className={classes.linha} variant="middle" light="false" />
      <IconButton onClick={onDelete}>
        <IconRemoveComponent fill="#fff" height="18" width="18" />
      </IconButton>
    </div>
  )
}

