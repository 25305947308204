import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { ProposicaoLegislativa } from '../../domain/ProposicaoLegislativa'
import { recuperarProposicaoLegislativaPorId } from '../../app/fato/proposicao/recuperarProposicaoLegislativaPorId'


export namespace BuscarProposicaoLegislativaEndpoint {
  export type Body = undefined
  export type Params = { id: string }
  export type Query = undefined
  export type Response = ProposicaoLegislativa
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/proposicoes-legislativas/:id',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<Response>) => {
      const { id } = request.params
      const proposicao = await recuperarProposicaoLegislativaPorId(id)
      response.json(proposicao)
    },
  })
}
