import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { mapaApi } from '../../api/mapaApi'
import { TipoFato } from '../../domain/TipoFato'
import { Cenario } from '../../domain/Cenario'


export namespace BuscarMeusCenariosEndpoint {
  export type Body = undefined
  export type Params = undefined
  export type Query = {
    visivelEmTelaInicial?: boolean
  }
  export type ResponseBody = Cenario[]
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/meus-cenarios',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { data } = await mapaApi.recuperarMeusCenarios(request.query)
      response.json(data)
    },
  })
}

export namespace BuscarCenarioEndpoint {
  export type Body = undefined
  export type Params = { id: number }
  export type Query = undefined
  export type ResponseBody = Cenario
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/cenarios/:id',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { id } = request.params
      const { data } = await mapaApi.recuperarCenarioPorId(id)
      response.json(data)
    },
  })
}

export namespace CriarCenarioEndpoint {
  export type Body = {
    nome: string
    stakeholders?: string[]
    keywords?: string[]
    temas?: string[]
    textoLivre?: string
    conectoresOu?: string[]
    filtrosTipoFato?: TipoFato[]
    dataBuscaAPartirDe?: string
    visivelEmTelaInicial: boolean
  }
  export type Params = undefined
  export type Query = undefined
  export type ResponseBody = Cenario
  export const definition = defineEndpoint({
    httpMethod: 'POST',
    url: '/cenarios',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { body } = request
      const { data } = await mapaApi.criarCenario(body)
      response.json(data)
    },
  })
}

export namespace EditarCenarioEndpoint {
  export type Body = Partial<CriarCenarioEndpoint.Body>
  export type Params = { id: number }
  export type Query = undefined
  export type Response = Cenario
  export const definition = defineEndpoint({
    httpMethod: 'PATCH',
    url: '/cenarios/:id',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<Response>) => {
      const { id } = request.params
      const { body } = request
      const { data } = await mapaApi.editarCenario(id, body)
      response.json(data)
    },
  })
}

export namespace ArquivarCenarioEndpoint {
  export type Body = undefined
  export type Params = { id: number }
  export type Query = undefined
  export type Response = Cenario
  export const definition = defineEndpoint({
    httpMethod: 'POST',
    url: '/cenarios/arquivar/:id',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<Response>) => {
      const { id } = request.params
      const { data } = await mapaApi.arquivarCenario(id)
      response.json(data)
    },
  })
}

export namespace DesarquivarCenarioEndpoint {
  export type Body = undefined
  export type Params = { id: number }
  export type Query = undefined
  export type Response = Cenario
  export const definition = defineEndpoint({
    httpMethod: 'POST',
    url: '/cenarios/desarquivar/:id',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<Response>) => {
      const { id } = request.params
      const { data } = await mapaApi.desarquivarCenario(id)
      response.json(data)
    },
  })
}

export namespace ExcluirCenarioEndpoint {
  export type Body = undefined
  export type Params = { id: number }
  export type Query = undefined
  export type Response = number
  export const definition = defineEndpoint({
    httpMethod: 'DELETE',
    url: '/cenarios/excluir/:id',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<Response>) => {
      const { id } = request.params
      const { data } = await mapaApi.excluirCenario(id)
      response.json(data)
    },
  })
}
