import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  icon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

interface IconButtonComponentProps {
  children: ReactNode
  onClick: (event) => void
  className?: string
}

export function IconButtonComponent({ children, onClick, className }: IconButtonComponentProps) {
  const classes = useStyles()

  function handleClick(event) {
    onClick(event)
  }

  if (!children) {
    return null
  }
  return (
    <span
      tabIndex={0}
      role="button"
      className={`${classes.icon} ${className}`}
      onClick={handleClick}
      onKeyPress={handleClick}
    >
      {children}
    </span>
  )
}
