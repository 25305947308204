import { AnyAction } from 'redux'
import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { swansReducersMap } from './swansReducersMap'


export interface SwansState {
  [props: string]: any
}

// eslint-disable-next-line @typescript-eslint/naming-convention
let _swansSelector: Selector<any, any>

export const createSwanSelector = swanName => createSelector(_swansSelector, swans => swans[swanName])


// TODO: fazer documentação
export function createSwansReducer<S, TProps>(swansSelector: Selector<S, TProps>) {
  _swansSelector = swansSelector
  return swansReducer
}

function swansReducer(state: SwansState = {}, action: AnyAction): SwansState {
  if (action.type === '@swans/initSwan') return initSwanHandler(state, action)
  if (action.type === '@swans/destroySwan') return destroySwanHandler(state, action)
  return defaultHandler(state, action)
}

function initSwanHandler(state: SwansState, action: AnyAction): SwansState {
  const { swanName, reducer, initialState } = action
  swansReducersMap[swanName] = reducer
  return { ...state, [swanName]: initialState === undefined ? reducer(undefined, action) : initialState }
}

function destroySwanHandler(state: SwansState, action: AnyAction): SwansState {
  const { swanName } = action
  delete swansReducersMap[swanName]
  const newState = { ...state }
  delete newState[swanName]
  return newState
}

function defaultHandler(state: SwansState, action: AnyAction) {
  const newState = {}
  Object.keys(state).forEach((swanName) => {
    newState[swanName] = anyActionSwanHandler(swanName, state, action)
  })
  return newState
}

function anyActionSwanHandler(swanName: string, state: SwansState, action: AnyAction): SwansState {
  const swanState = state[swanName]
  const swanReducer = swansReducersMap[swanName]
  return swanReducer(swanState, action)
}
