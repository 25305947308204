import { combineReducers, Reducer } from 'redux'
import { RootState } from './RootState'
import { requestsReducer as requests } from '../requests/requestsReducer'
import { sessionReducer as session } from '../session/sessionReducer'
import { alertsReducer as alerts } from '../alerts/alertsReducer'
import { swansReducer as swans } from '../swans/swansReducer'
import { onBoardingsReducer as onBoardings } from '../onboardings/onBoardingsReducer'

export const rootReducer: Reducer<RootState> = combineReducers({
  requests,
  session,
  alerts,
  swans,
  onBoardings,
})
