import React from 'react'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { RootErrorBoundary } from './components/containers/infra/RootErrorBoundary'
import { Providers } from './Providers'
import { Gates } from './Gates'
import { BasicSwitch } from './components/containers/infra/BasicSwitch'
import { layoutRoutes } from './routing/layoutRoutes'
import { ModalsContainer } from './components/containers/modal/ModalsContainer'
import { NotistackController } from './infra/libs/redux-notistack/NotistackController'

export const App = () => {

  const gtmParams = {
    id: 'GTM-W5H56SB',
  }

  return (
    <RootErrorBoundary>
      <Providers>
        <Gates>
          <GTMProvider state={gtmParams}>
            <BasicSwitch routes={layoutRoutes} />
          </GTMProvider>
        </Gates>
        <ModalsContainer />
        <NotistackController maxSnack={3} />
      </Providers>
    </RootErrorBoundary>
  )
}
