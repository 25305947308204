import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { mapaApi } from '../../api/mapaApi'
import { Favorito } from '../../domain/Favorito'

export namespace CriarFavoritoEndpoint {
  export type Body = {
    itemFavorito: string
    tipoFavorito: string
  }
  export type Params = undefined
  export type Query = undefined
  export type ResponseBody = Favorito
  export const definition = defineEndpoint({
    httpMethod: 'POST',
    url: '/favoritos',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { body } = request
      const { data } = await mapaApi.criarFavorito(body)
      response.json(data)
    },
  })
}

export namespace ExcluirFavoritoEndpoint {
  export type Body = undefined
  export type Params = { idItemFavorito: string }
  export type Query = undefined
  export type Response = number
  export const definition = defineEndpoint({
    httpMethod: 'DELETE',
    url: '/favoritos/:idItemFavorito',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<Response>) => {
      const { idItemFavorito } = request.params
      const { data } = await mapaApi.excluirFavorito(idItemFavorito)
      response.json(data)
    },
  })
}

export namespace BuscarMeusFavoritosEndpoint {
  export type Body = undefined
  export type Params = { tipoFavorito: string }
  export type Query = undefined
  export type ResponseBody = Favorito[]
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/meus-favoritos/:tipoFavorito',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { tipoFavorito } = request.params
      const { data } = await mapaApi.recuperarMeusFavoritos(tipoFavorito)
      response.json(data)
    },
  })
}

export namespace ContarMeusFavoritosEndpoint {
  export type Body = undefined
  export type Params = undefined
  export type Query = undefined
  export type ResponseBody = number
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/conta-meus-favoritos',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { data } = await mapaApi.contarMeusFavoritos()
      response.json(data)
    },
  })
}
