import Cookies from 'js-cookie'
import { isAceite, isDesenvol } from '../../../util/ambienteUtil'
import { authThunkCreators } from '../../../store/actions/authActions'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSpinningPromiseEffect } from '../../../infra/libs/react-swearing/SpinnerScreenProvider'


export interface AuthGateProps {
  children: any
}

export function AuthGate({ children }: AuthGateProps) {
  const dispatch = useTypedDispatch()
  const { isFulfilled } = useSpinningPromiseEffect(obterTokenJwtAPartirDoTkn, [dispatch])
  return isFulfilled ? children : null
}

async function obterTokenJwtAPartirDoTkn(dispatch: TypedDispatch) {
  try {
    const tokenPortalTcu = pegarTokenPortalTcuDoCookie()
    await dispatch(authThunkCreators.gerarTokenJwt(tokenPortalTcu))
    return await Promise.resolve()
  } catch (e) {
    console.warn('Não foi possível autenticar o token do portal do TCU. Logo, será redirecionado para tela do Siga.')
    console.warn(e)
    // TODO: tirar remoção (por enquanto tá assim por conta do caso de token expirado, pois o Siga não atualiza o token)
    removerTokenPortalTcuDoCookie()
    redirecionarParaLogin()
    return Promise.reject()
  }
}

function pegarTokenPortalTcuDoCookie() {
  const cookieName = getTokenPortalTcuCookieName()
  return Cookies.get(cookieName)
}

function removerTokenPortalTcuDoCookie() {
  const cookieName = getTokenPortalTcuCookieName()
  Cookies.remove(cookieName)
  Cookies.remove(cookieName, { path: '', domain: '.tcu.gov.br' })
}

function getTokenPortalTcuCookieName() {
  const cookieName = '__tcu_uid'
  if (isDesenvol()) return `DESENVOL${cookieName}`
  if (isAceite()) return `ACEITE${cookieName}`
  return cookieName
}

function redirecionarParaLogin() {
  const mapaUrl = window.location.href
  const sigaBaseUrl = getSigaBaseUrl()
  // TODO: tirar contexto
  const sigaUrl = `${sigaBaseUrl}/sso/SvlCentralizador?contexto=tcu&URL=${mapaUrl}`
  window.location.replace(sigaUrl)
}

function getSigaBaseUrl() {
  if (isDesenvol()) return 'http://siga.desenvol.rancher.tcu.gov.br'
  if (isAceite()) return 'http://siga.aceite.rancher.tcu.gov.br'
  return 'https://siga.apps.tcu.gov.br'
}
