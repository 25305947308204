
interface MapaOcorrencias {
  termo: string
  inicioOcorrencia: number
  fimOcorrencia: number
}

export function useDestacarTermos(texto?: string, listaTermos?: string[]) {

  if (!texto) {
    return null
  }

  if (!listaTermos || listaTermos.length < 1) {
    return texto
  }

  // @ts-ignore
  const termosNaoRepetidos = [...new Set(listaTermos)].filter(t => t.length > 2)
  const mapaOcorrencias: MapaOcorrencias[] = []

  termosNaoRepetidos.forEach((t) => {
    const tamanhoTexto = t.length
    // @ts-ignore
    const result = [...texto.matchAll(new RegExp(t, 'gi'))].map(a => a.index)
    if (result.length > 0) {
      result.forEach((item) => {
        mapaOcorrencias.push({ termo: t, inicioOcorrencia: item, fimOcorrencia: item + tamanhoTexto })  
      })
      
    }
  })
  
  mapaOcorrencias.sort((a, b) => a.inicioOcorrencia - b.inicioOcorrencia)
  mapaOcorrencias.reverse()

  let textoComMarcacoes = texto

  mapaOcorrencias.forEach((ocorrencia) => {
    textoComMarcacoes = adicionarStringNaPosicao(
      textoComMarcacoes,
      ocorrencia.fimOcorrencia,
      '</span>',
    )
    textoComMarcacoes = adicionarStringNaPosicao(
      textoComMarcacoes,
      ocorrencia.inicioOcorrencia,
      '<span class="detaqueTermos">',
    )
  })

  return textoComMarcacoes
}

function adicionarStringNaPosicao(stringCompleta, index, stringParaAdicionar) {
  if (index > 0) {
    return stringCompleta.substring(0, index) + stringParaAdicionar + stringCompleta.substring(index)
  }
  return stringParaAdicionar + stringCompleta
}
