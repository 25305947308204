import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TipoFato } from 'bff/src/main/domain/TipoFato'
import { Link } from 'react-router-dom'
import { LabelTipoFatoComponent } from './LabelTipoFatoComponent'


const useStyles = makeStyles({
  tituloFatosRelacionados: {
    fontSize: '20px',
    color: '#434343',
    margin: '96px 0 35px 0',
  },
  header: {
    display: 'flex',
    marginBottom: '25px',
    alignItems: 'center',
  },
  titulo: {
    color: '#434343',
    fontSize: '16px',
    fontWeight: 500,
    display: 'inline',
    marginLeft: '14px',
    marginBottom: 0,
  },
  tipo: {
    display: 'inline-table',
    width: '83px',
    textAlign: 'right',
  },
})

export interface FatosRelacionadosProps {
  fatos: FatoRelacionadoProps[]
}

export interface FatoRelacionadoProps {
  tipoFato: TipoFato
  titulo: string
  urlDestinoFato: string
}

export function FatosRelacionadosComponent({ fatos }: FatosRelacionadosProps) {
  const classes = useStyles()
  if (!fatos || fatos.length === 0) {
    return null
  }
  return (
    <div>
      <h2 className={classes.tituloFatosRelacionados}>Estes fatos podem estar relacionados</h2>
      {fatos.map(fato => renderFatoRelacionado(fato, classes))}
    </div>
  )
}

function renderFatoRelacionado(fato: FatoRelacionadoProps, classes) {
  return (
    <Link key={fato.urlDestinoFato} className={classes.header} to={fato.urlDestinoFato}>
      <span className={classes.tipo}>
        <LabelTipoFatoComponent tipo={fato.tipoFato} />
      </span>
      <h5 className={classes.titulo}>
        {fato.titulo}
      </h5>
    </Link>
  )
}
