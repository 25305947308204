import { searchOnIndex } from '../infra/elastic/searchOnIndex'

const search = body => searchOnIndex({ body, index: 'mapa-stakeholders' })

export const stakeholdersApi = {

  buscarStakeholdersPorIds(listaIds: string[]) {
    return search({
      size: 10000,
      query: {
        ids: {
          values: listaIds,
        },
      },
    })
  },

  buscarStakeholdersParaSugestaoBuscaPorPedacoNome(word: string, stringFonetica: string) {
    return search({
      _source: { includes: ['nome', 'nome_completo', 'aliases', 'aliases_foneticos', 'cargo', 'orgao_origem', 'orgao', 'fonte_dados', 'tipo_stakeholder'] },
      size: 2000,
      query: {
        bool: {
          should: [
            { match_phrase_prefix: { aliases: word } },
            { match: { aliases_foneticos: stringFonetica } },
          ],
        },
      },
    })
  },

  buscarStakeholdersPorPedacoNomeComFoto(word: string, stringFonetica: string, limite: number) {
    const size = 10
    const body = {
      _source: { includes: ['nome', 'nome_completo', 'aliases', 'aliases_foneticos', 'cargo', 'orgao_origem', 'orgao', 'fonte_dados', 'tipo_stakeholder', 'foto_url', 'foto', 'foto_base64', 'descricao', 'perfil'] },
      query: {
        bool: {
          should: [
            { match_phrase_prefix: { aliases: word } },
            { match: { aliases_foneticos: stringFonetica } },
          ],
        },
      },
      size,
    }

    body.size = limite

    return search(body)
  },

  buscarStakeholdersParaHighlight() {
    return search({
      _source: { includes: ['nome', 'nome_completo', 'aliases', 'cargo', 'orgao_origem', 'orgao', 'tipo_stakeholder'] },
      size: 10000,
    })
  },

  buscarStakeholdersPorFonteDadosECodigo(fonteDados: string, listaCodigo: string[]) {
    return search({
      size: 10000,
      query: {
        bool: {
          must: [
            { term: { fonte_dados: fonteDados } },
            { bool: { should: listaCodigo.map(codigo => ({ match_phrase: { codigo } })) } },
          ],
        },
      },
    })
  },

  buscarTotalDivididoPorTipo() {
    return search({
      size: 0,
      aggs: {
        count_by_type: {
          terms: {
            field: 'tipo_stakeholder',
          },
        },
      },
    })
  },

}
