import { EnumObjectWithId } from '../util/EnumObject'

export type SituacaoCenario = 'ATIVO' | 'ARQUIVADO'

export const SITUACAO_CENARIO: EnumObjectWithId<SituacaoCenario, { label: string }> = {
  ATIVO: {
    id: 'ATIVO',
    label: 'Ativo',
  },
  ARQUIVADO: {
    id: 'ARQUIVADO',
    label: 'Arquivado',
  },
}
