import React from 'react'
import { useHistory } from 'react-router-dom'
import { History } from 'history'
import { TIPOS_FATO } from 'bff/src/main/domain/TipoFato'
import { GRAUS_SIGILO } from 'bff/src/main/domain/GrauSigilo'
import { TIPOS_PROCESSO } from 'bff/src/main/domain/TipoProcesso'
import { TIPOS_EVENTO } from 'bff/src/main/domain/TipoEvento'
import { TIPOS_ATOR } from 'bff/src/main/domain/TipoAtor'
import { removePropertyFromQueryParams, removeValueFromArrayInQueryParams } from 'bff/src/main/util/urlUtil'
import { ESTADOS_PROCESSO } from 'bff/src/main/domain/EstadoProcesso'
import { Ministro } from 'bff/src/main/domain/Ministro'
import { useQueryParamAsArrayOfString, useQueryParamAsDDMMYYYY } from '../../../infra/libs/react-location-hooks/routerHooks'
import { FiltrosSelecionadosComponent, Filtro, SelecaoDoFiltro } from '../../ui-components/filtro/FiltrosSelecionadosComponent'
import {
  QUERY_PARAM_FILTRO_DATA_FIM,
  QUERY_PARAM_FILTRO_DATA_INICIO,
  QUERY_PARAM_FILTRO_PERIODO,
  QUERY_PARAM_FILTRO_TIPO_ATORES,
  QUERY_PARAM_FILTRO_TIPO_EVENTOS,
  QUERY_PARAM_FILTRO_TIPO_FATOS,
  QUERY_PARAM_FILTRO_GRAUS_SIGILO,
  QUERY_PARAM_FILTRO_TIPO_PROCESSOS,
  QUERY_PARAM_FILTRO_ESTADO_PROCESSO,
  QUERY_PARAM_FILTRO_RELATOR,
} from '../../../util/constantesQueryParams'
import { TypedDispatch, useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { ministroThunkCreators } from '../../../store/actions/ministroActions'


interface PossiveisSelecoes {
  [props: string]: {
    label: string
  }
}

export function FiltrosSelecionadosContainer() {

  const history = useHistory()
  const dispatch = useTypedDispatch()
  const filtroTiposAtores = useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_TIPO_ATORES)
  const filtroTiposFatos = useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_TIPO_FATOS)
  const grausSigilo = useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_GRAUS_SIGILO)
  const filtroTiposEventos = useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_TIPO_EVENTOS)
  const filtroTiposProcessos = useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_TIPO_PROCESSOS)
  const filtroRelatores = useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_RELATOR)
  const filtroEstadosProcesso = useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_ESTADO_PROCESSO)
  const filtroDtInicio = useQueryParamAsDDMMYYYY(QUERY_PARAM_FILTRO_DATA_INICIO)
  const filtroDtFim = useQueryParamAsDDMMYYYY(QUERY_PARAM_FILTRO_DATA_FIM)

  const { data: ministrosRelatores, isFulfilled } = usePromiseEffect(recuperarRelatores, [dispatch, filtroRelatores], [filtroRelatores])

  function gerarFiltros() {
    const filtros: (Filtro | null)[] = [
      gerarFiltroDeMultiplasSelecoes(
        handleCloseDeFiltroDeMultiplasSelecoes, history, QUERY_PARAM_FILTRO_TIPO_ATORES, 'Tipos de atores', filtroTiposAtores, TIPOS_ATOR,
      ),
      gerarFiltroDeMultiplasSelecoes(
        handleCloseDeFiltroTipoFatos, history, QUERY_PARAM_FILTRO_TIPO_FATOS, 'Tipos de fatos', filtroTiposFatos, TIPOS_FATO,
      ),
      gerarFiltroDeMultiplasSelecoes(
        handleCloseDeFiltroDeMultiplasSelecoes, history, QUERY_PARAM_FILTRO_GRAUS_SIGILO, 'Grau de sigilo do Fato', grausSigilo, GRAUS_SIGILO,
      ),
      gerarFiltroDeMultiplasSelecoes(
        handleCloseDeFiltroDeMultiplasSelecoes, history, QUERY_PARAM_FILTRO_TIPO_EVENTOS, 'Tipos de eventos', filtroTiposEventos, TIPOS_EVENTO,
      ),
      gerarFiltroDeMultiplasSelecoes(
        handleCloseDeFiltroDeMultiplasSelecoes, history, QUERY_PARAM_FILTRO_TIPO_PROCESSOS, 'Tipos de processos', filtroTiposProcessos, TIPOS_PROCESSO,
      ),
      gerarFiltroDeMultiplasSelecoes(
        handleCloseDeFiltroDeMultiplasSelecoes, history, QUERY_PARAM_FILTRO_ESTADO_PROCESSO, 'Estados do processos', filtroEstadosProcesso, ESTADOS_PROCESSO,
      ),
      gerarFiltroDePeriodo(
        history, QUERY_PARAM_FILTRO_DATA_INICIO, QUERY_PARAM_FILTRO_DATA_FIM, 'Período', filtroDtInicio, filtroDtFim,
      ),
    ]
    if (ministrosRelatores && isFulfilled) {
      filtros.push(gerarFiltroDeMultiplasSelecoes(
        handleCloseDeFiltroDeMultiplasSelecoes, history, QUERY_PARAM_FILTRO_RELATOR, 'Relator', filtroRelatores, getRelatores(ministrosRelatores),
      ))
    }
    return filtros.filter(f => !!f) as Filtro[]
  }

  function handleLimparFiltrosSelecionados() {
    limparFiltrosSelecionados([
      QUERY_PARAM_FILTRO_TIPO_ATORES,
      QUERY_PARAM_FILTRO_TIPO_FATOS,
      QUERY_PARAM_FILTRO_TIPO_EVENTOS,
      QUERY_PARAM_FILTRO_DATA_INICIO,
      QUERY_PARAM_FILTRO_DATA_FIM,
      QUERY_PARAM_FILTRO_PERIODO,
      QUERY_PARAM_FILTRO_GRAUS_SIGILO,
      QUERY_PARAM_FILTRO_TIPO_PROCESSOS,
      QUERY_PARAM_FILTRO_ESTADO_PROCESSO,
      QUERY_PARAM_FILTRO_RELATOR,
    ], history)
  }

  return <FiltrosSelecionadosComponent filtros={gerarFiltros()} handleLimparTodosFiltros={handleLimparFiltrosSelecionados} />
}

async function recuperarRelatores(dispatch: TypedDispatch, ministrosIds: string[]) {
  if (ministrosIds.length < 1) {
    return undefined
  }
  try {
    const { data: ministros } = await dispatch(ministroThunkCreators.buscarMinistrosPorListaCodigo({ ministrosIds }))
    return ministros
  } catch (e) {
    console.warn(e)
  }
  return undefined
}

function limparFiltrosSelecionados(listaFiltros: string[], history: History) {
  const { pathname, search } = history.location
  let stringfiedQueryParams = search
  listaFiltros.forEach((filtro) => {
    stringfiedQueryParams = removePropertyFromQueryParams(filtro, stringfiedQueryParams)
  })
  const url = `${pathname}?${stringfiedQueryParams}`
  history.push(url)
}

function gerarFiltroDeMultiplasSelecoes(
  onClose: (nomeFiltro: string, selecao: any, history: any) => void,
  history: History,
  nomeDoFiltro: string,
  labelDoFiltro: string,
  valorDoFiltro: any[],
  possiveisSelecoes: PossiveisSelecoes,
): Filtro | null {
  if (valorDoFiltro.length < 1) return null
  const selecoes: SelecaoDoFiltro[] = valorDoFiltro.map(selecao => ({
    label: possiveisSelecoes[selecao]?.label,
    onClose: () => onClose(nomeDoFiltro, selecao, history),
  }))
  return { selecoes }
}

function gerarFiltroDePeriodo(
  history: History, nomeDoFiltroDeInicio: string, nomeDoFiltroDeFim: string, labelDoFiltro: string,
  valorDoFiltroDeInicio?: string, valorDoFiltroDeFim?: string,
) {
  if (!valorDoFiltroDeInicio && !valorDoFiltroDeFim) return null
  let labelDaSelecao = `de ${valorDoFiltroDeInicio} a ${valorDoFiltroDeFim}`
  if (!valorDoFiltroDeFim) labelDaSelecao = `a partir de ${valorDoFiltroDeInicio}`
  if (!valorDoFiltroDeInicio) labelDaSelecao = `até ${valorDoFiltroDeFim}`
  const selecoes: SelecaoDoFiltro[] = [{
    label: labelDaSelecao,
    onClose: () => {
      handleCloseDeFiltroDePeriodo(nomeDoFiltroDeInicio, nomeDoFiltroDeFim, history)
    },
  }]
  return { titulo: labelDoFiltro, selecoes }
}

function handleCloseDeFiltroDeMultiplasSelecoes(nomeDoFiltro: string, valorDaSelecao: any, history: History) {
  const { pathname, search } = history.location
  const stringfiedQueryParams = removeValueFromArrayInQueryParams(valorDaSelecao, nomeDoFiltro, search)
  const url = `${pathname}?${stringfiedQueryParams}`
  history.push(url)
}

function handleCloseDeFiltroTipoFatos(nomeDoFiltro: string, valorDaSelecao: any, history: History) {
  const { pathname, search } = history.location
  let stringfiedQueryParams = removeValueFromArrayInQueryParams(valorDaSelecao, nomeDoFiltro, search)
  if (valorDaSelecao === TIPOS_FATO.EVENTO_RELACIONA.id) {
    Object.getOwnPropertyNames(TIPOS_EVENTO).forEach((property) => {
      stringfiedQueryParams = removeValueFromArrayInQueryParams(property, QUERY_PARAM_FILTRO_TIPO_EVENTOS, stringfiedQueryParams)
    })
  }
  if (valorDaSelecao === TIPOS_FATO.PROCESSO.id) {
    stringfiedQueryParams = removePropertyFromQueryParams(QUERY_PARAM_FILTRO_TIPO_PROCESSOS, stringfiedQueryParams)
    stringfiedQueryParams = removePropertyFromQueryParams(QUERY_PARAM_FILTRO_RELATOR, stringfiedQueryParams)
    stringfiedQueryParams = removePropertyFromQueryParams(QUERY_PARAM_FILTRO_ESTADO_PROCESSO, stringfiedQueryParams)
  }
  const url = `${pathname}?${stringfiedQueryParams}`
  history.push(url)
}

function handleCloseDeFiltroDePeriodo(nomeDoFiltroDeInicio: string, nomeDoFiltroDeFim: string, history: History) {
  const { pathname, search } = history.location
  let stringfiedQueryParams = removePropertyFromQueryParams(QUERY_PARAM_FILTRO_DATA_INICIO, search)
  stringfiedQueryParams = removePropertyFromQueryParams(QUERY_PARAM_FILTRO_DATA_FIM, stringfiedQueryParams)
  stringfiedQueryParams = removePropertyFromQueryParams(QUERY_PARAM_FILTRO_PERIODO, stringfiedQueryParams)
  const url = `${pathname}?${stringfiedQueryParams}`
  history.push(url)
}

function getRelatores(ministrosRelatores?: Ministro[]): PossiveisSelecoes {
  if (!ministrosRelatores || ministrosRelatores.length < 1) {
    return {}
  }
  const relatores = {}
  ministrosRelatores.forEach((ministro) => {
    relatores[ministro.codigo] = { label: ministro.nomeApresentacao }
  })
  return relatores
}
