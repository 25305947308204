import React from 'react'
import { makeStyles } from '@material-ui/styles'
import SearchIcon from '@material-ui/icons/Search'
import { CheckboxesComponent, CheckboxesOption } from '../comum/CheckboxesComponent'

const useStyles = makeStyles({
  filter: {
    display: 'flex',
    borderRadius: '4px',
  },
  filterTitle: {
    top: '-6px',
    color: '#231f20',
    backgroundColor: '#fff',
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '17px',
  },
  optionsFilter: {
    margin: '10px 0',
    '& fieldset': {
      border: '1px solid #919191',
    },
  },
  optionsFilterInput: {
    padding: '12px',
  },
  optionsFilterIconWrapper: {
    left: '5px',
    transform: 'rotate(90deg)',
  },
  optionsFilterIcon: {
    fontSize: '30px',
  },
  optionsDirection: ({ direction }: { direction: CheckboxesDirection }) => ({
    flexDirection: direction,
  }),
  filterScrollArea: {
    maxHeight: '190px',
    paddingLeft: '11px',
    marginLeft: '-11px',
    overflowY: 'auto',
    flexWrap: 'nowrap',
    '&::-webkit-scrollbar-track': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      borderRadius: '20px',
      backgroundColor: '#eee',
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      borderRadius: '20px',
      backgroundColor: '#eee',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
      backgroundColor: '#CBCBCB',
    },
  },
  formControlLabel: {
    marginBottom: '0',
  },
  checkbox: {
    padding: '7px',
    marginLeft: '2px',
  },
})

interface CheckboxesFilterProps {
  label: string
  value?: any[]
  options: CheckboxesOption[]
  onChange?: (newValue: any[]) => void
  withOptionsFilter?: boolean
  withScrollArea?: boolean
  optionsDirection?: CheckboxesDirection
}

export type CheckboxesDirection = 'row' | 'column'

export function CheckboxesDefaultStyleComponent(props: CheckboxesFilterProps) {
  const classes = useStyles({ direction: props.optionsDirection ?? 'column' })

  return (
    <CheckboxesComponent
      label={props.label}
      value={props.value}
      options={props.options}
      onChange={props.onChange}
      withOptionsFilter={props.withOptionsFilter}
      formControlProps={{ classes: { root: classes.filter } }}
      formLabelProps={{ classes: { root: classes.filterTitle } }}
      optionsFilterProps={{
        className: classes.optionsFilter,
        inputProps: { className: classes.optionsFilterInput },
        InputProps: {
          endAdornment: (
            <div className={classes.optionsFilterIconWrapper}>
              <SearchIcon className={classes.optionsFilterIcon} htmlColor="#919191" />
            </div>
          ),
        },
      }}
      formGroupProps={{ classes: { root: props.withScrollArea ? classes.filterScrollArea : classes.optionsDirection } }}
      formControlLabelProps={{ classes: { root: classes.formControlLabel } }}
      checkboxProps={{ classes: { root: classes.checkbox }, color: 'primary' }}
    />
  )
}
