import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  usuario: {
    marginLeft: '4vw',
    flexGrow: 0.02,
    display: 'flex',
    alignItems: 'center',
    '@media screen and (max-width:580px)': {
      display: 'none',
    },
  },
  nomeUsuario: {
    fontSize: '14px',
    fontWeight: 500,
    display: 'block',
    color: '#9e9e9e',
    textTransform: 'capitalize',
  },
  UserNomeUsuario: {
    fontSize: '12px',
    fontWeight: 900,
    display: 'block',
    color: '#9e9e9e',
    textTransform: 'uppercase',
  },
  iconeUsuario: {
    fontSize: '40px',
    marginRight: '11px',
    color: '#434343',
  },  
})

interface LoginUserComponentProps {
  nome: string
  login: string
}

export function LoginUserComponent({ nome, login } : LoginUserComponentProps) {
  const classes = useStyles()
  return (   
    <div className={classes.usuario}>
      <div>
        <i className={`fa fa-user-circle ${classes.iconeUsuario} `} />
      </div>
      <div>
        <span className={classes.nomeUsuario}>{nome}</span>
        <span className={classes.UserNomeUsuario}>{login}</span>
      </div>
    </div>
  )
}
