import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { springCloudConfig } from '../spring-cloud-config/springCloudConfig'
import { createApiCallerProxy } from './createApiCallerProxy'

const baseConfig = {
  headers: { 'Content-Type': 'application/json' },
  timeout: 1800000, // 30 minutos
}

interface CreateAxiosParams {
  baseUrlCloudConfigProp?: string
  customRequestInterceptor?: any
}

export function createAxios({ baseUrlCloudConfigProp, customRequestInterceptor }: CreateAxiosParams): AxiosInstance {
  return createApiCallerProxy(() => createAxiosInstance(baseUrlCloudConfigProp, customRequestInterceptor))
}

function createAxiosInstance(baseUrlCloudConfigProp?: string, customRequestInterceptor?) {
  const axiosInstance = axios.create(baseConfig)
  if (baseUrlCloudConfigProp) {
    const baseUrlSetterInterceptor = createBaseUrlSetterInterceptor(baseUrlCloudConfigProp)
    adicionarRequestInterceptor(axiosInstance, baseUrlSetterInterceptor)
  }
  adicionarRequestInterceptor(axiosInstance, customRequestInterceptor)
  return axiosInstance
}

function adicionarRequestInterceptor(axiosInstance: AxiosInstance, requestInterceptor) {
  if (requestInterceptor) {
    axiosInstance.interceptors.request.use(
      requestInterceptor,
      error => Promise.reject(error),
    )
  }
}

function createBaseUrlSetterInterceptor(baseUrlCloudConfigProp: string) {
  return (config: AxiosRequestConfig) => {
    const baseURL = getBaseUrl(baseUrlCloudConfigProp)
    return { ...config, baseURL }
  }
}

function getBaseUrl(baseUrlCloudConfigProp) {
  let baseUrl = springCloudConfig.getProp(baseUrlCloudConfigProp)
  if (!baseUrl) {
    baseUrl = baseUrlCloudConfigProp
  }
  return baseUrl
}
