import React from 'react'
import { useHistory } from 'react-router-dom'
import { MeusCenariosComponent } from '../../ui-components/cenario/MeusCenariosComponent'
import { MeusCenariosPlaceholderComponent } from '../../ui-components/cenario/MeusCenariosPlaceholderComponent'
import { cenarioActionCreators, cenarioThunkCreators } from '../../../store/actions/cenarioActions'
import { createCounterReducer } from '../../../infra/libs/redux-hardtyped/createCounterReducer'
import { useSwan } from '../../../infra/libs/redux-swans/useSwan'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'


const reducer = createCounterReducer([
  cenarioActionCreators.cenarioExcluido,
  cenarioActionCreators.cenarioArquivado,
  cenarioActionCreators.cenarioDesarquivado,
])

export function MeusCenariosContainer() {

  const dispatch = useTypedDispatch()
  const counter = useSwan(reducer)
  const history = useHistory()

  const { data: cenarios, isLoading } = usePromiseEffect(recuperarCenarios, [dispatch], [counter])

  function criarCenario() {
    history.push('/cenarios/novo')
  }

  if (isLoading) {
    return <MeusCenariosPlaceholderComponent />
  }

  return <MeusCenariosComponent cenarios={cenarios ?? []} onClickNovoCenario={criarCenario} />
}

async function recuperarCenarios(dispatch: TypedDispatch) {
  try {
    const { data: cenarios } = await dispatch(cenarioThunkCreators.recuperarMeusCenarios())
    return cenarios
  } catch (e) {
    console.warn(e)
  }
  return []
}
