import moment from 'moment'
import { mapaApi } from '../../api/mapaApi'
import { recuperarFatosResumidos } from '../fato/recuperarFatosResumidos'
import { ORDENACOES } from '../../domain/Ordenacao'
import { MontarQueryRecuperarFatosPayload } from '../../api/queries/MontarQueryRecuperarFatosPayload'
import { Cenario } from '../../domain/Cenario'
import { recuperarContadorFatos } from '../contador/recuperarContadorFatos'
import { ResumoFato } from '../../domain/ResumoFato'
import { FatoTelaInicial } from '../../domain/FatoTelaInicial'
import { ID_ENTIDADE_TCU } from '../../util/stakeholdersUtil'


interface CenarioComListaDeFatos {
  idCenario: number
  nomeCenario: string
  listaDeFatos: ResumoFato[]
}

const QUANTIDADE_FATOS_POR_CENARIO = 5

export async function recuperarFatosTelaInicial(): Promise<FatoTelaInicial[]> {
  const cenariosParaPesquisar = await recuperarCenariosParaPesquisar()
  const resultadoCenariosComFatos = await recuperarFatosDosCenarios(cenariosParaPesquisar)
  const listaFinal = retirarFatosRepetidosEmMaisDeUmCenario(resultadoCenariosComFatos)
  return ordernarFatosPorMaisRecentes(listaFinal)
}

async function recuperarCenariosParaPesquisar() {
  const { data: cenarios } = await mapaApi.recuperarMeusCenarios({ visivelEmTelaInicial: true })
  let cenariosParaPesquisar: Cenario[]
  if (await existemFatosNosCenariosDaTelaInicial(cenarios)) {
    cenariosParaPesquisar = cenarios
  } else {
    cenariosParaPesquisar = getCenariosBase()
  }
  return cenariosParaPesquisar
}

async function recuperarFatosDosCenarios(cenariosParaPesquisar: Cenario[]): Promise<CenarioComListaDeFatos[]> {
  return Promise.all(
    cenariosParaPesquisar.map((cenario) => {
      const payload = montarPayloadConsultaCenario(cenario)
      return recuperarFatosDoCenario(cenario, payload)
    }),
  )
}

function retirarFatosRepetidosEmMaisDeUmCenario(resultadoCenariosComFatos: CenarioComListaDeFatos[]) {
  const listaFinal: FatoTelaInicial[] = []
  resultadoCenariosComFatos.forEach((cenarioComFatos) => {
    cenarioComFatos.listaDeFatos.forEach((fato) => {
      let fatoJaExisteNaLista = false
      listaFinal.forEach((fatoNaListaFinal) => {
        if (fatoNaListaFinal.id === fato.id) {
          fatoJaExisteNaLista = true
          fatoNaListaFinal.cenarios.push({ id: cenarioComFatos.idCenario, nome: cenarioComFatos.nomeCenario })
        }
      })
      if (!fatoJaExisteNaLista) {
        listaFinal.push(
          montarObjetoFatoResultado(fato, cenarioComFatos.idCenario, cenarioComFatos.nomeCenario),
        )
      }
    })
  })
  return listaFinal
}

function ordernarFatosPorMaisRecentes(listaFinal: FatoTelaInicial[]) {
  return listaFinal.sort((a, b) => moment(String(a.data)).format('YYYYMMDDHHmm') as any - (moment(String(b.data)).format('YYYYMMDDHHmm') as any))
    .reverse()
}

function montarObjetoFatoResultado(fato, idCenario: number, nomeCenario: string): FatoTelaInicial {
  return {
    cenarios: [{ id: idCenario, nome: nomeCenario }],
    ...fato,
  }
}

async function recuperarFatosDoCenario(cenario: Cenario, payload: MontarQueryRecuperarFatosPayload): Promise<CenarioComListaDeFatos> {
  const fatos = await recuperarFatosResumidos(payload)
  return { idCenario: cenario.id, nomeCenario: cenario.nome, listaDeFatos: fatos }
}

async function recuperarContadoresFatosDoCenario(cenario: Cenario, payload: MontarQueryRecuperarFatosPayload) {
  const totalFatos = await recuperarContadorFatos(payload)
  return { idCenario: cenario.id, nomeCenario: cenario.nome, quantidadeFatos: totalFatos }
}

async function existemFatosNosCenariosDaTelaInicial(cenarios: Cenario[]): Promise<boolean> {
  const resultadoCenariosComFatos = await Promise.all(
    cenarios.map((cenario) => {
      const payload = montarPayloadConsultaCenario(cenario)
      return recuperarContadoresFatosDoCenario(cenario, payload)
    }),
  )
  const quantidadesDeFatosDosCenarios = resultadoCenariosComFatos.map(c => c.quantidadeFatos)
  const totalDeFatosDeTodosOsCenarios = quantidadesDeFatosDosCenarios.length === 0 ? 0 : quantidadesDeFatosDosCenarios.reduce((a, b) => a + b)
  return totalDeFatosDeTodosOsCenarios > 0
}

function montarPayloadConsultaCenario(cenario: Cenario) {
  const idsAtores = cenario.consulta.stakeholders ? cenario.consulta.stakeholders.filter(s => !s.includes('ENTIDADE')) : []
  const idsEntidades = cenario.consulta.stakeholders ? cenario.consulta.stakeholders.filter(s => s.includes('ENTIDADE')) : []
  const payload: MontarQueryRecuperarFatosPayload = {
    idsAtores,
    idsEntidades,
    keywords: cenario.consulta.keywords ?? [],
    temas: cenario.consulta.temas ?? [],
    textoLivre: cenario.consulta.textoLivre,
    tiposFato: cenario.consulta.filtrosTipoFato,
    dtInicio: cenario.consulta.dataBuscaAPartirDe,
    tiposEvento: [],
    tiposProcesso: [],
    estadosProcesso: [],
    relatores: [],
    grausSigilo: [],
    ordenacao: ORDENACOES.MAIS_RECENTE.id,
    listaConectoresOu: cenario.consulta.conectoresOu ?? [],
    size: QUANTIDADE_FATOS_POR_CENARIO,
  }
  return payload
}

function getCenariosBase() {
  const cenarios: Cenario[] = []
  cenarios.push({
    id: 123456789,
    nome: '',
    situacao: 'ATIVO',
    visivelEmTelaInicial: true,
    consulta: {
      filtrosTipoFato: ['NOTICIA_CLIPPING'],
      stakeholders: [ID_ENTIDADE_TCU],
    },
    loginAutor: '',
  })
  cenarios.push({
    id: 1234567891,
    nome: '',
    situacao: 'ATIVO',
    visivelEmTelaInicial: true,
    consulta: {
      filtrosTipoFato: ['NOTICIA_UNIAO'],
    },
    loginAutor: '',
  })
  cenarios.push({
    id: 12345678123,
    nome: '',
    situacao: 'ATIVO',
    visivelEmTelaInicial: true,
    consulta: {
      filtrosTipoFato: ['EVENTO_RELACIONA'],
    },
    loginAutor: '',
  })
  return cenarios
}
