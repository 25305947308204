import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { StakeholderComFavorito } from 'bff/src/main/domain/ResumoStakeholder'
import { ItemResultadoStakeholderComponent } from './ItemResultadoStakeholderComponent'


const useStyles = makeStyles({
  tituloStakeholderSel: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#434343',
    position: 'absolute',
  },
  stakeholdersEnvolvidos: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    width: '100%',
  },
})

export interface StakeholdersEnvolvidosProps {
  listaStakeholders: StakeholderComFavorito[]
}

export function ListaResultadoStakeholdersComponent(props: StakeholdersEnvolvidosProps) {
  const classes = useStyles()

  return render(props, classes)
}

function render(props: StakeholdersEnvolvidosProps, classes) {
  return props.listaStakeholders.length < 1 ? null : renderStakeholders(props, classes)
}

function renderStakeholders(props: StakeholdersEnvolvidosProps, classes) {
  return (
    <div className="row">
      <h6 className="col-md-12">
        Resultado da Pesquisa de Stakeholders
      </h6>
      <div className={`${classes.stakeholdersEnvolvidos} col-md-12 row`}>
        {
          props.listaStakeholders.map(item => (
            <div key={item.resumoStakeholder.id}>
              <ItemResultadoStakeholderComponent
                id={item.resumoStakeholder.id}
                isFavorito={item.isFavorito}
                nome={item.resumoStakeholder.nome}
                papel={item.resumoStakeholder.papel}
                urlImagem={item.resumoStakeholder.urlImagem}
                urlDestino={item.resumoStakeholder.urlDestino}
                stakeholderInterno={item.resumoStakeholder.stakeholderInterno}
                orgao={item.resumoStakeholder.orgao ?? ''}
                descricao={item.resumoStakeholder.descricao ?? ''}
              />
            </div>
          ))
        }
      </div>
    </div>
  )
}
