import { dateUtil } from '../../../util/dateUtil'
import { frontendRoutesUtil } from '../../../util/frontendRoutesUtil'
import { retirarTagsHtml } from '../retirarTagsHtml'
import { TIPOS_FATO } from '../../../domain/TipoFato'
import { ResumoFato } from '../../../domain/ResumoFato'

export function montarResumoNoticiaUniao(noticiaUniao): ResumoFato {
  const tipoFato = TIPOS_FATO.NOTICIA_UNIAO.id
  return {
    id: noticiaUniao._id,
    titulo: noticiaUniao._source.titulo,
    tipoFato,
    imagemNoticia: noticiaUniao._source.url_imagem_associada,
    resumo: retirarTagsHtml(noticiaUniao._source.texto),
    urlDestinoFato: frontendRoutesUtil.montarUrlDetalhamentoFato(noticiaUniao._id, tipoFato),
    informacaoAdicionalMaisRelevante: 'União',
    informacaoAdicionalMenosRelevante: dateUtil.formatarData(noticiaUniao._source.data_publicacao) ?? '',
    data: noticiaUniao._source.data_publicacao,
  }
}
