import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { ContextMenuContainer } from '../../containers/comum/ContextMenuContainer'

const useStyles = makeStyles({
  textoWrapper: {
    color: '#434343',
    lineHeight: '25px',
    fontSize: '16px',
    overflow: 'hidden',
    '&:focus': {
      outline: 'none',
    },
    '& *': {
      maxWidth: '100%',
    },
  },

})

export interface FatoCompletoBodyProps {
  texto: string
}

export function FatoCompletoBodyComponent({ texto }: FatoCompletoBodyProps) {
  const history = useHistory()
  const classes = useStyles()
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [seletedText, setSelectedText] = useState<string | undefined>('')
  const [xPos, setXPos] = useState<number>(0)
  const [yPos, setYPos] = useState<number>(0)

  function handleClick(e) {
    if (e.target.dataset.url) {
      history.push(e.target.dataset.url)
    }
  }

  function handleSelectText(e) {
    if (window.getSelection()?.toString()) {
      e.preventDefault()
      const rect = e.target.getBoundingClientRect()
      // @ts-ignore
      setXPos(e.pageX - rect.left - window.frames.scrollX)
      // @ts-ignore
      setYPos(e.pageY - rect.top - window.frames.scrollY)
      setSelectedText(window.getSelection()?.toString())
      setShowMenu(true)
    }
  }

  
  return (
    <div>
      {
        showMenu
          && (
            <ContextMenuContainer
              textoSelecionado={seletedText || ''}
              xPosition={xPos}
              yPosition={yPos}
              onClickAway={() => setShowMenu(false)}
            />
          )
      }
      <div
        tabIndex={0}
        role="link"
        aria-label="Close"
        onClick={handleClick}
        onMouseUp={handleSelectText}
        onKeyPress={handleClick}
        className={classes.textoWrapper}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: texto }}
        id="textReference"
      />
    </div>
  )
}
