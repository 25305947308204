import React, { useState } from 'react'
import { makeStyles, Menu, MenuItem } from '@material-ui/core'
import { IconOpcoesVerticalComponent } from '../../ui-components/icons/IconOpcoesVerticalComponent'
import { BotaoAdicionarRemoverListaLeituraContainer } from './BotaoAdicionarRemoverListaLeituraContainer'

const useStyles = makeStyles({
  iconWrapper: {
    height: '100px',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
  },
})

export function OpcoesFatoContainer({ className, fatoId }: { className?: string, fatoId: string }) {
  const classes = useStyles()
  const [openMenu, setOpenMenu] = useState(null)

  function handleClick(event) {
    setOpenMenu(event.currentTarget)
  }
  function handleClose() {
    setOpenMenu(null)
  }

  return (
    <>
      <span
        className={`${classes.iconWrapper} ${className}`}
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyPress={handleClick}
      >
        <IconOpcoesVerticalComponent />
      </span>
      <Menu
        anchorEl={openMenu}
        open={Boolean(openMenu)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <BotaoAdicionarRemoverListaLeituraContainer fatoId={fatoId} />
        </MenuItem>
      </Menu>
    </>
  )
}
