import React from 'react'
import { HeaderCenariosComponent } from '../comum/HeaderCenariosComponent'
import { ButtonComponent } from '../comum/ButtonComponent'

interface MeusCenariosHeaderComponentProps {
  onClickNovoCenario: () => void
  mostrarBotaoNovoCenario: boolean
}

export function MeusCenariosHeaderComponent({ onClickNovoCenario, mostrarBotaoNovoCenario }: MeusCenariosHeaderComponentProps) {
  
  function handleClick() {
    onClickNovoCenario()
  }
  
  return (
    
    <div className="row col-md-12">
      <div className="col-md-6">
        <HeaderCenariosComponent
          titulo="Meus cenários"
        />
      </div>
      <div className="col-md-3 offset-md-3">
        {
          mostrarBotaoNovoCenario 
            ? <ButtonComponent buttonText="Criar novo cenário" buttonTypeColor="blue" onClick={handleClick} />
            : null
        }
      </div>
    </div>
    
  )
}
