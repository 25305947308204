import { EnumObjectWithId } from '../util/EnumObject'

export type TipoAtor = 'EXTERNO' | 'TCU'

export const TIPOS_ATOR: EnumObjectWithId<TipoAtor, { label: string }> = {
  EXTERNO: {
    id: 'EXTERNO',
    label: 'Externo',
  },
  TCU: {
    id: 'TCU',
    label: 'TCU',
  },
}
