import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSwan } from '../../../infra/libs/redux-swans/useSwan'
import { createReducer } from '../../../infra/libs/redux-hardtyped/createReducer'
import { defineActionCreators } from '../../../infra/libs/redux-hardtyped/defineActionCreators'
import { ModalWithActionsComponent } from '../../ui-components/modal/ModalWithActionsComponent'
import { fichaActionCreators } from '../../../store/actions/fichaActions'
import { IconFichaNovaComponent } from '../../ui-components/icons/IconFichaNovaComponent'

const modalMap = {
  FICHA_CRIADA: {
    titulo: 'Ficha criada',
    texto: 'Agora você já pode começar a incluir trechos importantes ou adicionar comentários e imagens à sua ficha.',
    labelBotaoCancelar: 'Mais tarde',
    labelBotaoAction: 'Editar ficha',
  },
  FICHA_CRIADA_COM_TRECHO: {
    titulo: 'Ficha criada',
    texto: 'Adicionamos o trecho selecionado à sua nova ficha.',
    labelBotaoCancelar: 'Continuar lendo',
    labelBotaoAction: 'Ir para ficha',
  },
  TRECHO_ADICIONADO: {
    titulo: 'Trecho adicionado',
    texto: 'Adicionamos o trecho selecionado à ficha',
    labelBotaoCancelar: 'Continuar lendo',
    labelBotaoAction: 'Ir para ficha',
  },
}

export const modalFichaCriadaETrechoAdicionadoActions = defineActionCreators('modalFichaCriadaETrechoAdicionado', {
  fecharModal: () => ({}),
})

export type ModalFichaCriadaETrechoAdicionadoState = null | {
  idFicha: number
  tipoModal: 'FICHA_CRIADA' | 'TRECHO_ADICIONADO'
}

const initialState = null

const reducer = createReducer<ModalFichaCriadaETrechoAdicionadoState>(initialState)(handle => ([
  handle(fichaActionCreators.fichaCriada, (state, action) => {
    const { id } = action
    return { idFicha: id, tipoModal: 'FICHA_CRIADA' }
  }),
  handle(fichaActionCreators.trechoFatoAdicionado, (state, action) => {
    const { id } = action
    return { idFicha: id, tipoModal: 'TRECHO_ADICIONADO' }
  }),
  handle(modalFichaCriadaETrechoAdicionadoActions.fecharModal, () => null),
]))

export function ModalFichaCriadaETrechoAdicionadoContainer() {
  const history = useHistory()
  const dispatch = useTypedDispatch()
  const state = useSwan(reducer)
  const modalEstaAberta = !!state?.idFicha
  const idFicha = state?.idFicha
  const tipoModal = state?.tipoModal ?? ''

  function onAction() {
    history.push(`/fichas/${idFicha}`)
    dispatch(modalFichaCriadaETrechoAdicionadoActions.fecharModal())
  }

  function onCancel() {
    dispatch(modalFichaCriadaETrechoAdicionadoActions.fecharModal())
  }

  if (!modalEstaAberta) return null

  return (
    <ModalWithActionsComponent
      icon={<IconFichaNovaComponent fill="#2f5dc9" height="74" width="74" />}
      primaryColor="blue"
      title={modalMap[tipoModal].titulo}
      text={modalMap[tipoModal].texto}
      actionButtonText={modalMap[tipoModal].labelBotaoAction}
      onCancel={onCancel}
      cancelButtonText={modalMap[tipoModal].labelBotaoCancelar}
      onAction={onAction}
    />
  )
}
