import { createReducer } from '../../../../infra/libs/redux-hardtyped/createReducer'
import { cenarioActionCreators } from '../../../../store/actions/cenarioActions'
import { modalArquivarCenarioActionCreators } from './modalArquivarCenarioActions'

export type ModalArquivarCenarioState = null | {
  idCenario: number
}

const initialState = null

export const modalArquivarCenarioReducer = createReducer<ModalArquivarCenarioState>(initialState)(handle => ([

  handle(modalArquivarCenarioActionCreators.abrirModal, (state, action) => {
    const { id } = action
    return { idCenario: id }
  }),

  handle(modalArquivarCenarioActionCreators.fecharModal, fecharModalHandler),

  handle(cenarioActionCreators.cenarioArquivado, fecharModalHandler),

]))

function fecharModalHandler(): ModalArquivarCenarioState {
  return null
}
