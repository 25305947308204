import { Cenario } from 'bff/src/main/domain/Cenario'
import {
  ArquivarCenarioEndpoint,
  BuscarCenarioEndpoint,
  BuscarMeusCenariosEndpoint,
  CriarCenarioEndpoint, DesarquivarCenarioEndpoint,
  EditarCenarioEndpoint, ExcluirCenarioEndpoint,
} from 'bff/src/main/controllers/cenario/cenariosEndpoints'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'
import { defineActionCreators } from '../../infra/libs/redux-hardtyped/defineActionCreators'
import { alertActionCreators } from './alertActions'


export const cenarioActionCreators = defineActionCreators('cenario', {
  cenarioCriadoPelaTelaNovoCenario: (data: Cenario) => data,
  cenarioCriadoPelaTelaResultado: (data: Cenario) => data,
  cenarioAlterado: (data: Cenario) => data,
  cenarioArquivado: (data: Cenario) => data,
  cenarioDesarquivado: (data: Cenario) => data,
  cenarioExcluido: () => ({}),
})

export const cenarioThunkCreators = {

  recuperarMeusCenarios: () => createBffRequestThunk({
    request: {
      endpoint: BuscarMeusCenariosEndpoint.definition,
    },
  }),

  recuperarMeusCenariosDaTelaInicial: () => createBffRequestThunk({
    request: {
      endpoint: BuscarMeusCenariosEndpoint.definition,
      queryParams: { visivelEmTelaInicial: true },
    },
  }),

  buscarCenarioPorId: (id: number) => createBffRequestThunk({
    request: {
      endpoint: BuscarCenarioEndpoint.definition,
      params: { id },
    },
  }),

  criarCenario: (body: CriarCenarioEndpoint.Body, pontoPartida: 'tela_novo_cenario' | 'tela_resultado') => createBffRequestThunk({
    request: {
      endpoint: CriarCenarioEndpoint.definition,
      body,
    },
    actions: {
      success: pontoPartida === 'tela_novo_cenario'
        ? response => cenarioActionCreators.cenarioCriadoPelaTelaNovoCenario(response.data)
        : response => cenarioActionCreators.cenarioCriadoPelaTelaResultado(response.data),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar criar o novo cenário.'),
    },
  }),

  alterarCenario: (id: number, body: EditarCenarioEndpoint.Body) => createBffRequestThunk({
    request: {
      endpoint: EditarCenarioEndpoint.definition,
      params: { id },
      body,
    },
    actions: {
      success: response => cenarioActionCreators.cenarioAlterado(response.data),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar alterar o cenário.'),
    },
  }),

  arquivarCenario: (id: number) => createBffRequestThunk({
    request: {
      endpoint: ArquivarCenarioEndpoint.definition,
      params: { id },
    },
    actions: {
      success: response => cenarioActionCreators.cenarioArquivado(response.data),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar arquivar o cenário.'),
    },
  }),

  desarquivarCenario: (id: number) => createBffRequestThunk({
    request: {
      endpoint: DesarquivarCenarioEndpoint.definition,
      params: { id },
    },
    actions: {
      success: response => cenarioActionCreators.cenarioDesarquivado(response.data),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar desarquivar o cenário.'),
    },
  }),

  excluirCenario: (id: number) => createBffRequestThunk({
    request: {
      endpoint: ExcluirCenarioEndpoint.definition,
      params: { id },
    },
    actions: {
      success: () => cenarioActionCreators.cenarioExcluido(),
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar excluir o cenário.'),
    },
  }),

  alterarVisualizacaoTelaInicio: (id: number, isVisivel: boolean) => createBffRequestThunk({
    request: {
      endpoint: EditarCenarioEndpoint.definition,
      params: { id },
      body: { visivelEmTelaInicial: isVisivel },
    },
    actions: {
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar alterar a visualização do cenário na tela inicial.'),
    },
  }),

}
