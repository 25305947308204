
export const ID_ENTIDADE_TCU = 'ENTIDADE_5096'

export function isAutoridadeTCU(isStakeholderInterno: boolean, papel: string) {
  return (isStakeholderInterno && papel === 'Ministro')
    || (isStakeholderInterno && papel === 'Ministra')
    || (isStakeholderInterno && papel === 'Ministro-Substituto')
    || (isStakeholderInterno && papel === 'Procuradora-Geral')
    || (isStakeholderInterno && papel === 'Subprocurador-Geral')
    || (isStakeholderInterno && papel === 'Procurador')
}

export function isAtorInterno(autoridade): boolean {
  return autoridade._source.fonte_dados === 'TCU'
}
