import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'
import { BuscaAutocompleteInputOption } from './BuscaAutocompleteOptionComponent'
import { BuscaAutocompleteInputComponent } from './BuscaAutocompleteInputComponent'
import { SwitchConectorOUComponent } from '../conector/SwitchConectorOUComponent'

const useStyles = makeStyles({
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: '14px',
  },
  box100: {
    width: '100%',
    display: 'flex',
  },
  switchBox: {
    display: 'flex',
    alignItems: 'center',
    borderColor: '#EAEAEA',
    paddingRight: '4px',
    paddingLeft: '4px',
    borderStyle: 'solid',
    borderWidth: '1px 1px 1px 0px',
    borderRadius: '0px 8px 8px 0px',
    backgroundColor: '#fdfdfd',
  },
  inputKeyword: {
    borderRadius: '8px 0px 0px 8px',
    borderColor: '#EAEAEA',
    borderStyle: 'solid',
    borderWidth: '1px 0px 1px 1px',
    '&:hover': {
      borderStyle: 'solid',
      borderColor: '#EAEAEA',
      borderWidth: '1px 0px 1px 1px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    backgroundColor: '#fdfdfd',
  },
  autocomplete: {
    width: '92%',
  },  
})

type ClassesType = ReturnType<typeof useStyles>

interface AtorProps {
  onAtorWordChange: (atorWord: string) => any
  atoresOptions: BuscaAutocompleteInputOption[]
  onAtoresChange: (selectedOptions: BuscaAutocompleteInputOption[]) => any
  atoresSelecionados: BuscaAutocompleteInputOption[]
  conectorOuAtores: boolean
  onConectorOuAtoresChange: (value: boolean) => any
}

interface EntidadeProps {
  onEntidadeWordChange: (entidadeWord: string) => any
  entidadesOptions: BuscaAutocompleteInputOption[]
  onEntidadesChange: (selectedOptions: BuscaAutocompleteInputOption[]) => any
  entidadesSelecionadas: BuscaAutocompleteInputOption[]
  conectorOuEntidades: boolean
  onConectorOuEntidadesChange: (value: boolean) => any
}

interface TemaProps {
  onTemaWordChange: (temaWord: string) => any
  temasOptions: BuscaAutocompleteInputOption[]
  onTemasChange: (selectedOptions: BuscaAutocompleteInputOption[]) => any
  temasSelecionados: BuscaAutocompleteInputOption[]
  conectorOuTemas: boolean
  onConectorOuTemasChange: (value: boolean) => any
}

interface KeywordProps {
  onKeywordsChange: (newString: string) => any
  keywordsSelecionadas: string
  conectorOuKeywords: boolean
  onConectorOuKeywordsChange: (value: boolean) => any
}

interface BuscaAvancadaStakeholdersTemasKeywordsComponentProps {
  atorProps: AtorProps
  entidadeProps: EntidadeProps
  temaProps: TemaProps
  keywordProps: KeywordProps
}

export function BuscaAvancadaStakeholdersTemasKeywordsComponent(props :BuscaAvancadaStakeholdersTemasKeywordsComponentProps) {
  const classes = useStyles()
  return (
    <div className={classes.inputGroup}>
      <div className={classes.box100}>
        {renderAutocomplete(
          classes,
          props.atorProps.atoresOptions,
          props.atorProps.onAtorWordChange,
          props.atorProps.onAtoresChange,
          'Atores',
          props.atorProps.atoresSelecionados,
        )}
        <SwitchConectorOUComponent value={props.atorProps.conectorOuAtores} onChange={props.atorProps.onConectorOuAtoresChange} />
      </div>
      <div className={classes.box100}>
        {renderAutocomplete(
          classes, 
          props.entidadeProps.entidadesOptions, 
          props.entidadeProps.onEntidadeWordChange,
          props.entidadeProps.onEntidadesChange, 
          'Entidades', 
          props.entidadeProps.entidadesSelecionadas,
        )}
        <SwitchConectorOUComponent value={props.entidadeProps.conectorOuEntidades} onChange={props.entidadeProps.onConectorOuEntidadesChange} />
      </div>
      <div className={classes.box100}>
        {renderAutocomplete(
          classes,
          props.temaProps.temasOptions,
          props.temaProps.onTemaWordChange,
          props.temaProps.onTemasChange, 
          'Temas', props.temaProps.temasSelecionados,
        )}
        <SwitchConectorOUComponent value={props.temaProps.conectorOuTemas} onChange={props.temaProps.onConectorOuTemasChange} />
      </div>
      <div className={classes.box100}>
        <TextField
          className={classes.inputKeyword}
          placeholder="Palavras Chave (separe cada termo por vírgula)"
          variant="outlined"
          fullWidth
          value={props.keywordProps.keywordsSelecionadas}
          onChange={event => props.keywordProps.onKeywordsChange(event.target.value)}
        />
        <SwitchConectorOUComponent value={props.keywordProps.conectorOuKeywords} onChange={props.keywordProps.onConectorOuKeywordsChange} />
      </div>
    </div>
  )
}

function renderAutocomplete(
  classes: ClassesType, options: BuscaAutocompleteInputOption[], onInputChange: (word: string) => any,
  onSelectOption: (selectedOptions: BuscaAutocompleteInputOption[]) => any, placeholder: string,
  selecionados: BuscaAutocompleteInputOption[],
) {
  return (
    <div className={classes.autocomplete}>
      <BuscaAutocompleteInputComponent
        options={options}
        inputPlaceholder={placeholder}
        onInputChange={onInputChange}
        ignoreSuggestedKeyword
        onSelectedOptionsChange={onSelectOption}
        selectedOptions={selecionados}
        actionOnEnter="selectFirst"
        layoutBuscaAvancada
      />
    </div>

  )
}

