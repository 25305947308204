import React from 'react'
import { TipoFato } from 'bff/src/main/domain/TipoFato'
import { BuscarRelatoresDeProcessosPresentesNaPesquisa } from 'bff/src/main/controllers/fato/processosEndpoints'
import { TipoProcesso } from 'bff/src/main/domain/TipoProcesso'
import { EstadoProcesso } from 'bff/src/main/domain/EstadoProcesso'
import { GrauSigilo } from 'bff/src/main/domain/GrauSigilo'
import { Ministro } from 'bff/src/main/domain/Ministro'
import { CheckboxesFilterContainer } from './CheckboxesFilterContainer'
import {
  useQueryParamAsArrayOfString,
  useQueryParamAsString,
} from '../../../infra/libs/react-location-hooks/routerHooks'
import {
  QUERY_PARAM_ATORES,
  QUERY_PARAM_ENTIDADES,
  QUERY_PARAM_FILTRO_DATA_FIM,
  QUERY_PARAM_FILTRO_DATA_INICIO,
  QUERY_PARAM_FILTRO_ESTADO_PROCESSO,
  QUERY_PARAM_FILTRO_GRAUS_SIGILO,
  QUERY_PARAM_FILTRO_RELATOR,
  QUERY_PARAM_FILTRO_TIPO_FATOS,
  QUERY_PARAM_FILTRO_TIPO_PROCESSOS,
  QUERY_PARAM_KEYWORDS,
  QUERY_PARAM_TEMAS,
} from '../../../util/constantesQueryParams'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { useMemoObject } from '../../../hooks/useMemoObject'
import { processoThunkCreators } from '../../../store/actions/processoActions'
import { CheckboxesOption } from '../../ui-components/comum/CheckboxesComponent'
import { ordernarListaAlfabeticamentePorPropriedade } from '../../../util/sortUtil'


export function FiltroRelatorProcessoContainer() {

  const dispatch = useTypedDispatch()
  const payload = useMemoObject({
    idsAtores: useQueryParamAsArrayOfString(QUERY_PARAM_ATORES),
    idsEntidades: useQueryParamAsArrayOfString(QUERY_PARAM_ENTIDADES),
    keywords: useQueryParamAsArrayOfString(QUERY_PARAM_KEYWORDS),
    temas: useQueryParamAsArrayOfString(QUERY_PARAM_TEMAS),
    tiposFato: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_TIPO_FATOS) as TipoFato[],
    tiposProcesso: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_TIPO_PROCESSOS) as TipoProcesso[],
    estadosProcesso: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_ESTADO_PROCESSO) as EstadoProcesso[],
    grausSigilo: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_GRAUS_SIGILO) as GrauSigilo[],
    dtInicio: useQueryParamAsString(QUERY_PARAM_FILTRO_DATA_INICIO),
    dtFim: useQueryParamAsString(QUERY_PARAM_FILTRO_DATA_FIM),
  })

  const { data: ministros } = usePromiseEffect(recuperarRelatores, [dispatch, payload])

  if (!ministros || ministros.length < 1) {
    return null
  }

  return (
    <CheckboxesFilterContainer
      filterName={QUERY_PARAM_FILTRO_RELATOR}
      filterLabel="Relator"
      options={getOptions(ministros ?? [])}
      withOptionsFilter
    />
  )
}

async function recuperarRelatores(dispatch: TypedDispatch, payload: BuscarRelatoresDeProcessosPresentesNaPesquisa.Query) {
  try {
    const { data: ministros } = await dispatch(processoThunkCreators.buscarRelatoresDeProcessosPresentesNaPesquisa(payload))
    return ministros
  } catch (e) {
    console.warn(e)
  }
  return undefined
}


function getOptions(ministros: Ministro[]): CheckboxesOption[] {
  const listaRelatores = ministros.map(ministro => ({ label: ministro.nomeApresentacao, value: ministro.codigo.toString() }))
  return ordernarListaAlfabeticamentePorPropriedade(listaRelatores, 'label')
}

