import {
  BuscarFatosParaTelaInicialEndpoint,
  BuscarFatosResumidosEndpoint,
} from 'bff/src/main/controllers/fato/fatosEndpoints'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'


export const fatoThunkCreators = {

  buscarFatosResumidos: (queryParams: BuscarFatosResumidosEndpoint.Query) => createBffRequestThunk({
    request: {
      endpoint: BuscarFatosResumidosEndpoint.definition,
      queryParams,
    },
  }),

  recuperarFatosTelaInicial: () => createBffRequestThunk({
    request: {
      endpoint: BuscarFatosParaTelaInicialEndpoint.definition,
    },
  }),

}
