import React from 'react'
import { Cenario } from 'bff/src/main/domain/Cenario'
import { BuscarContadorFatosEndpoint } from 'bff/src/main/controllers/contador/contadoresEndpoints'
import { SITUACAO_CENARIO } from 'bff/src/main/domain/SituacaoCenario'
import { contadorThunkCreators } from '../../../store/actions/contadorActions'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import {
  ContadorQuantidadeFatosCenarioComponent,
} from '../../ui-components/cenario/ContadorQuantidadeFatosCenarioComponent'


export function ContadorQuantidadeFatosCenarioContainer({ cenario }:{ cenario: Cenario }) {
  const dispatch = useTypedDispatch()
  const { data: total } = usePromiseEffect(recuperarContador, [dispatch, cenario])
  return <ContadorQuantidadeFatosCenarioComponent quantidadeFatos={total} isCenarioArquivado={cenario.situacao === SITUACAO_CENARIO.ARQUIVADO.id} />
}

async function recuperarContador(dispatch: TypedDispatch, cenario: Cenario) {
  const idsAtores = cenario.consulta.stakeholders ? cenario.consulta.stakeholders.filter(s => !s.includes('ENTIDADE')) : []
  const idsEntidades = cenario.consulta.stakeholders ? cenario.consulta.stakeholders.filter(s => s.includes('ENTIDADE')) : []
  try {
    const payload: BuscarContadorFatosEndpoint.Query = {
      idsAtores,
      idsEntidades,
      keywords: cenario.consulta.keywords,
      temas: cenario.consulta.temas,
      tiposFato: cenario.consulta.filtrosTipoFato,
      dtInicio: cenario.consulta.dataBuscaAPartirDe,
      textoLivre: cenario.consulta.textoLivre,
    }
    const { data: total } = await dispatch(contadorThunkCreators.buscarContadorFatos(payload))
    return total
  } catch (e) {
    console.warn(e)
  }
  return 0
}
