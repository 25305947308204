import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Ator } from 'bff/src/main/domain/Ator'
import { PerfilAtorHeaderComponent } from '../ui-components/stakeholder/ator/PerfilAtorHeaderComponent'
import { useParam } from '../../infra/libs/react-location-hooks/routerHooks'
import { atorActionCreators } from '../../store/actions/atorActions'
import { PerfilStakeholderHeaderPlaceholderComponent } from '../ui-components/stakeholder/PerfilStakeholderHeaderPlaceholderComponent'
import { PerfilAtorAbasContainer } from '../containers/stakeholder/PerfilAtorAbasContainer'
import { ScrollToTopOnMount } from '../containers/util/ScrollToTopOnMount'
import { TitleComponent } from '../ui-components/head/TitleComponent'
import { PaginaDeErroHttpComponent } from '../ui-components/erro/PaginaDeErroHttpComponent'
import { useTypedDispatch, TypedDispatch } from '../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../infra/libs/react-swearing/usePromiseEffect'
import { TwitterProfileTimelineComponent } from '../ui-components/stakeholder/twitter/TwitterProfileTimelineComponent'


const useStyles = makeStyles({
  perfilAtorBody: {
    marginTop: '50px',
    paddingBottom: '64px',
  },
})

export function PerfilAtorView() {
  const classes = useStyles()
  const dispatch = useTypedDispatch()
  const idAtor = useParam('id')
  const { data: ator, isLoading } = usePromiseEffect(pegarAtor, [dispatch, idAtor])
  if (!isLoading && !ator) {
    return (
      <PaginaDeErroHttpComponent
        mensagemDeErro="Tivemos um problema para exibir o perfil do ator"
      />
    )
  }
  return render(classes, isLoading, ator)
}

async function pegarAtor(dispatch: TypedDispatch, idAtor: string) {
  const { data: ator } = await dispatch(atorActionCreators.buscarAtorPorId(idAtor))
  return ator
}

function render(classes, isLoading: boolean, ator? : Ator) {
  return (
    <div className="container">
      <TitleComponent title={ator?.nome} />
      <ScrollToTopOnMount />
      {renderPerfilAtorHeader(isLoading, ator)}
      {renderPerfilAtorContent(classes, isLoading, ator)}
    </div>
  )
}

function renderPerfilAtorHeader(isLoading: boolean, ator?: Ator) {
  if (isLoading) {
    return <PerfilStakeholderHeaderPlaceholderComponent />
  }
  if (!ator) {
    return null
  }
  return (
    <PerfilAtorHeaderComponent
      nome={ator.nome}
      nomeCompleto={ator.nomeCompleto}
      stakeholderInterno={ator.stakeholderInterno}
      cargo={ator.papel}
      orgao={ator.orgao}
      urlImagem={ator.urlImagem}
      descricao={ator.descricao}
      email={ator.email}
      dataNascimento={ator.dataNascimento}
      telefone={ator.telefone}
      endereco={ator.endereco}
      cep={ator.cep}
    />
  )
}

function renderPerfilAtorContent(classes, isLoading: boolean, ator?: Ator) {
  if (!isLoading && !ator) {
    return null
  }
  return (
    <div className={`row ${classes.perfilAtorBody}`}>
      <div className="col-lg-12 col-md-12 col-xl-8">
        <PerfilAtorAbasContainer />
      </div>
      {
        ator?.perfilTwitter && (
          <div className="col-md-12 col-xl-4 col-lg-12">
            <TwitterProfileTimelineComponent
              profile={ator.perfilTwitter}
              name={ator.nome}
              height={900}
            />
          </div>
        )
      }
    </div>
  )
}
