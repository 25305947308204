import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/styles'
import { MapaColor } from '../../../style/theme'

const useStyles = makeStyles({
  titulo: {
    fontSize: '14px',
    color: MapaColor.neutralMediumDark,
  },
  informacao: {
    '@media screen and (max-width:1280px)': {
      fontSize: '12px',
    },
    fontSize: '14px',
    fontWeight: 400,
    color: MapaColor.neutralMediumDark,
    marginLeft: '10px',
  },
})

export interface InformacaoAdicionalPerfilHeaderProps {
  titulo?: string
  texto?: string | ReactNode
  email?: boolean
  whatsapp?: boolean
}

export function InformacaoAdicionalPerfilHeaderComponent({ titulo, texto, email, whatsapp }: InformacaoAdicionalPerfilHeaderProps) {
  const classes = useStyles()
  let informacao: ReactNode
  if (!texto) {
    return null
  }
  if (email) {
    informacao = <a href={`mailto:${texto}`} className={classes.informacao}>{texto}</a>
  }
  if (whatsapp) {
    informacao = <a href={getUrlWhatsappWeb(texto as string)} className={classes.informacao} target="_blank" rel="noopener noreferrer">{texto}</a>
  }
  if (!email && !whatsapp) {
    informacao = <span className={classes.informacao}>{texto}</span>
  }
  return render(classes, informacao, titulo)
}

function render(classes, informacao: ReactNode, titulo?: string) {
  return (
    <>
      {titulo ? <h4 className={classes.titulo}>{titulo}</h4> : null}
      {informacao}
    </>
  )
}

function getUrlWhatsappWeb(numero: string) {
  const numeroSemMascara = numero?.replace(/\D/g, '')
  return `https://wa.me/${numeroSemMascara}`
}
