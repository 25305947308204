import { EnumObjectWithId } from '../util/EnumObject'

export type TipoStakeholder = 'ATOR' | 'ENTIDADE'

export const TIPOS_STAKEHOLDER: EnumObjectWithId<TipoStakeholder, { label: string }> = {
  ATOR: {
    id: 'ATOR',
    label: 'Ator',
  },
  ENTIDADE: {
    id: 'ENTIDADE',
    label: 'Entidade',
  },
}
