import React from 'react'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import { Switch, Tooltip } from '@material-ui/core'
import { Cenario } from 'bff/src/main/domain/Cenario'
import { TIPOS_FATO } from 'bff/src/main/domain/TipoFato'
import { SituacaoCenario, SITUACAO_CENARIO } from 'bff/src/main/domain/SituacaoCenario'
import { TagFiltrosSelecionadosCenarioComponet } from '../comum/TagFiltrosSelecionadosCenarioComponet'
import { IconEditComponent } from '../icons/IconEditComponent'
import { IconArquivarComponent } from '../icons/IconArquivarComponent'
import { IconButtonComponent } from '../comum/IconButtonComponent'
import { IconDesarquivarComponent } from '../icons/IconDesarquivarComponent'
import { ItensBuscadosComConectoresComponent } from '../busca/ItensBuscadosComConectoresComponent'


const useStyles = makeStyles({
  espacamento: {
    marginBottom: '24px',
  },
  boxConfig: {
    padding: 16,
    border: '1px solid #c4c4c4',
    borderRadius: '3px',
  },
  titulo: {
    color: '#434343',
    fontWeight: 500,
    fontSize: '16px',
  },
  line: {
    borderTopColor: '#c4c4c4',
  },
  acoes: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  espacamentoSwitch: {
    marginTop: '31px',
  },
  icon: {
    marginLeft: '5px',
    display: 'inline',
  },
  iconsBox: {
    display: 'flex',
    margin: 'auto 0',
  },
  arquivado: {
    opacity: 0.4,
  },
})

interface ConfiguracaoCenarioComponentProps {
  cenario: Cenario
  onEditar: (id: number) => void
  onArquivar: (id: number) => void
  onDesarquivar: (id: number) => void
  onClickVisualizarPaginaInicio: (id: number, isVisivel: boolean) => void
  visivelTelaInicial: boolean
  atores: string[]
  entidades: string[]
}

export function ConfiguracaoCenarioComponent(props: ConfiguracaoCenarioComponentProps) {
  const classes = useStyles()

  function handleClickVisualizarPaginaInicio() {
    props.onClickVisualizarPaginaInicio(props.cenario.id, !props.visivelTelaInicial)
  }

  function handleClickEditar() {
    props.onEditar(props.cenario.id)
  }

  function handleClickArquivar() {
    props.onArquivar(props.cenario.id)
  }

  function handleClickDesarquivar() {
    props.onDesarquivar(props.cenario.id)
  }
  
  return (
    <div>
      <div className={classes.boxConfig}>
        <div className={classes.acoes}>
          <h4 className={classes.titulo}>Configurações do Cenário</h4>
          {renderAcoes(classes, props.cenario.situacao, handleClickEditar, handleClickArquivar, handleClickDesarquivar)}
        </div>
        <hr className={classes.line} />
        <div className={`${classes.espacamento} ${props.cenario.situacao === SITUACAO_CENARIO.ARQUIVADO.id ? classes.arquivado : ''}`}>
          <ItensBuscadosComConectoresComponent
            atores={props.atores}
            entidades={props.entidades}
            temas={props.cenario.consulta.temas || []}
            keywords={props.cenario.consulta.keywords || []}
            conectoresOu={props.cenario.consulta.conectoresOu || []}
            textoLivre={props.cenario.consulta.textoLivre}
          />
          <hr className={classes.line} />
          {
            getFiltros(props.cenario).map(f => <TagFiltrosSelecionadosCenarioComponet key={f} label={f} />)
          }
          <div className={classes.espacamentoSwitch}>
            <Switch
              checked={props.visivelTelaInicial}
              disabled={props.cenario.situacao === SITUACAO_CENARIO.ARQUIVADO.id}
              color="primary"
              name="mostrarTelaInicial"
              onChange={handleClickVisualizarPaginaInicio}
            />
            <span>Apresentar na página Início</span>
          </div>
        </div>
      </div>
    </div>
  )
}

function renderAcoes(classes, situacaoCenario: SituacaoCenario, handleClickEditar, handleClickArquivar, handleClickDesarquivar) {
  if (situacaoCenario === SITUACAO_CENARIO.ATIVO.id) {
    return (
      <div className={classes.iconsBox}>
        <div className={classes.icon}>
          <Tooltip title="Editar">
            <span>
              <IconButtonComponent onClick={handleClickEditar}>
                <IconEditComponent width="15" height="15" fill="#434343" />
              </IconButtonComponent>  
            </span>
          </Tooltip>
        </div>
        <div className={classes.icon}>
          <Tooltip title="Arquivar">
            <span>
              <IconButtonComponent onClick={handleClickArquivar}>
                <IconArquivarComponent width="20" height="20" fill="#434343" />
              </IconButtonComponent>  
            </span>
          </Tooltip>
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className={classes.icon}>
        <Tooltip title="Desarquivar">
          <span>
            <IconButtonComponent onClick={handleClickDesarquivar}>
              <IconDesarquivarComponent width="20" height="20" fill="#434343" />
            </IconButtonComponent>
          </span>
        </Tooltip>
      </div>
    </div>
  )
}

function getFiltros(cenario: Cenario) {
  const filtros: string[] = []
  filtros.push(...cenario.consulta.filtrosTipoFato.map(c => getTipoFato(c)))
  if (cenario.consulta.dataBuscaAPartirDe) {
    const data = `Buscar fatos a partir de: ${moment(cenario.consulta.dataBuscaAPartirDe).format('DD/MM/YYYY')}`
    filtros.push(data)
  }
  return filtros
}

function getTipoFato(tipo: string) {
  return TIPOS_FATO[tipo].label
}
