import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { ModalComponent } from '../../ui-components/modal/ModalComponent'
import { HeaderCenariosComponent } from '../../ui-components/comum/HeaderCenariosComponent'
import { ButtonComponent } from '../../ui-components/comum/ButtonComponent'

const useStyles = makeStyles({
  box: {
    width: '583px',
  },
  margin: {
    marginBottom: '32px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButtom: {
    marginRight: '16px',
  },
})

interface Fields {
  descricaoFicha: string
}

interface AlterarDescricaoFichaFormProps {
  onConfirm: (descricaoFicha: string) => void
  onCancel: () => void
  onLimparDescricao: () => void
  descricaoInicial: string
}

export function AlterarDescricaoFichaForm(propsModal: AlterarDescricaoFichaFormProps) {
  const classes = useStyles()
  const {
    register, handleSubmit,
  } = useForm<Fields>({ defaultValues: {
    descricaoFicha: propsModal.descricaoInicial,
  } })

  function realizarAcao(formValues: Fields) {
    propsModal.onConfirm(formValues.descricaoFicha)
  }

  return (
    <ModalComponent>
      <form className={classes.box} onSubmit={handleSubmit(realizarAcao)}>
        <HeaderCenariosComponent
          titulo="Descrição da ficha"
          descricao="Insira uma descrição que te ajude a identificar o propósito da criação da ficha."
        />
        <TextField
          inputRef={register()}
          multiline
          name="descricaoFicha"
          id="outlined-size-normal"
          variant="outlined"
          fullWidth
          className={classes.margin}
        />
        <div className={classes.buttons}>
          <ButtonComponent
            buttonText="Cancelar"
            buttonTypeColor="white"
            onClick={propsModal.onCancel}
            className={classes.cancelButtom}
          />
          <ButtonComponent
            buttonText="Salvar"
            buttonTypeColor="blue"
            type="submit"
          />
        </div>
      </form>
    </ModalComponent>
  )
}
