/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { BuscaAutocompleteOptionComponent, BuscaAutocompleteInputOption } from './BuscaAutocompleteOptionComponent'
import { BuscaAutocompleteTagComponent } from './BuscaAutocompleteTagComponent'


const useInputStyles = makeStyles({
  box: {
    display: 'flex',
    borderRadius: '6px',
    width: '100%',
  },

  root: ({ layoutBuscaAvancada }: BuscaAutocompleteInputProps) => ({
    width: '100%',
    overflow: 'hidden',
    borderRadius: layoutBuscaAvancada ? '8px 0px 0px 8px' : '8px',
    borderColor: '#EAEAEA',
    borderWidth: layoutBuscaAvancada ? '1px 0px 1px 1px' : '1px',
    borderStyle: 'solid',
    '&:hover': {
      borderColor: layoutBuscaAvancada ? '#EAEAEA' : '#D4D4D4',
      borderStyle: 'solid',
      borderWidth: layoutBuscaAvancada ? '1px 0px 1px 1px' : '1px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    backgroundColor: '#fdfdfd',
  }),
  rootFocused: {
    border: '1px solid #0071BC !important',
    '&:hover': {
      border: '1px solid #0071BC !important',
    },
  },
  inputRoot: {
    paddingRight: '70px !important',
    height: '54px',
    flexWrap: 'nowrap',
  },
  input: {
    padding: '4px !important',
    flexWrap: 'nowrap',
  },
})

export interface BuscaAutocompleteInputProps {
  selectedOptions?: BuscaAutocompleteInputOption[]
  options: BuscaAutocompleteInputOption[]
  onInputChange?: (word: string) => any
  onSelectedOptionsChange?: (selectedOptions: BuscaAutocompleteInputOption[]) => any
  onSubmit?: (selectedOptions: BuscaAutocompleteInputOption[]) => any
  disabled?: boolean
  inputPlaceholder: string
  ignoreSuggestedKeyword?: boolean
  actionOnEnter: 'selectFirst' | 'search'
  inputValue?: string
  layoutBuscaAvancada?: boolean
  modoPesquisaLivre?:boolean
}

export function BuscaAutocompleteInputComponent(props: BuscaAutocompleteInputProps) {
  const inputClasses = useInputStyles(props)
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState<BuscaAutocompleteInputOption[]>([])
  const [currentInputValue, setCurrentInputValue] = useState('')

  useEffect(() => {
    if (props.selectedOptions) {
      setCurrentSelectedOptions(props.selectedOptions)
    }
    if (props.inputValue) {
      setCurrentInputValue(props.inputValue)
    }
  }, [props.selectedOptions, props.inputValue])

  function onInputChange(event, value) {
    setCurrentInputValue(value)
    props.onInputChange?.(value)
  }

  function onSelectedOptionsChange(event, value: (BuscaAutocompleteInputOption | string)[]) {
    if (props.actionOnEnter === 'selectFirst') {
      const newSelectedOptions: BuscaAutocompleteInputOption[] = []
      let setChange = true
      value.forEach((v) => {
        if (typeof v === 'string') {
          const firstOption = getOptions()[0]
          if (firstOption) {
            newSelectedOptions.push(firstOption)
          } else {
            setCurrentInputValue(currentInputValue)
            setChange = false
          }
        } else {
          newSelectedOptions.push(v)
        }
      })
      if (setChange) {
        setCurrentSelectedOptions(newSelectedOptions)
        props.onSelectedOptionsChange?.(newSelectedOptions)
      }
    }
    if (props.actionOnEnter === 'search') {
      const newSelectedOptions: BuscaAutocompleteInputOption[] = []
      if (typeof value[0] === 'string') {
        onSubmit()
        return
      }
      newSelectedOptions.push(value[0])
      setCurrentSelectedOptions([])
      props.onSelectedOptionsChange?.(newSelectedOptions)
    }
  }

  function onSubmit() {
    props.onSubmit?.(currentSelectedOptions)
  }

  function getOptions() {
    if (currentInputValue && !props.ignoreSuggestedKeyword) {
      const keyword: BuscaAutocompleteInputOption = { tipo: 'KEYWORD', nome: currentInputValue, papel: 'Palavra-chave' }
      return [keyword, ...props.options]
    }
    return [...props.options]
  }

  return (
    <div className={inputClasses.box}>
      <Autocomplete
        value={currentSelectedOptions}
        inputValue={currentInputValue}
        onChange={onSelectedOptionsChange}
        onInputChange={onInputChange}
        disabled={props.disabled}
        multiple
        freeSolo
        disableClearable
        filterSelectedOptions
        filterOptions={options => options}
        options={getOptions()}
        getOptionLabel={(option: BuscaAutocompleteInputOption) => option.nome}
        renderInput={params => renderInput(params, currentSelectedOptions, onSubmit, props.inputPlaceholder)}
        renderOption={option => <BuscaAutocompleteOptionComponent option={option} modoPesquisaLivre={props.modoPesquisaLivre} />}
        renderTags={
          (tags: BuscaAutocompleteInputOption[], getTagProps) => (
            <BuscaAutocompleteTagComponent getTagProps={getTagProps} tags={tags} />
          )
        }
        classes={{
          root: inputClasses.root,
          focused: inputClasses.rootFocused,
          inputRoot: inputClasses.inputRoot,
          input: inputClasses.input,
        }}
      />
    </div>
  )
}

function renderInput(params, selectedOptions: BuscaAutocompleteInputOption[], onFinish, inputPlaceholder: string) {
  return (
    <TextField
      {...params}
      variant="outlined"
      placeholder={selectedOptions.length > 0 ? '' : inputPlaceholder}
      onKeyPress={({ key }) => key === 'Enter' && onFinish()}
    />
  )
}
