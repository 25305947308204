import React, { ReactNode } from 'react'
import { TipoConectorOu, TIPOS_CONECTORES_OU } from 'bff/src/main/domain/TipoConectorOu'
import { makeStyles } from '@material-ui/core/styles'
import { ConectorComponent } from '../comum/ConectorComponent'
import { generateKey } from '../../../util/util'
import { isConectorEntreCampos, montaNomeFuncaoConector } from '../../../util/conectoresUtil'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  row: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    border: 'solid 1px #91bade',
    width: 'fit-content',
    padding: '3px 15px',
    borderRadius: '8px',
    flexWrap: 'wrap',
  },
  elemento: {
    whiteSpace: 'nowrap',
  },
})

interface ItensBuscadosComConectoresComponentProps {
  atores: string[]
  entidades: string[]
  temas: string[]
  keywords: string[]
  textoLivre?: string
  conectoresOu: TipoConectorOu[]
}

export function ItensBuscadosComConectoresComponent(props: ItensBuscadosComConectoresComponentProps) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {renderListaItensMesmoTipo(
        classes,
        TIPOS_CONECTORES_OU.ATOR.id,
        props.atores,
        props.conectoresOu?.includes(TIPOS_CONECTORES_OU.ATOR.id),
      )}
      {renderConectorEntreCampos(
        TIPOS_CONECTORES_OU.ATOR.id,
        isConectorEntreCampos(
          props.atores,
          [props.entidades, props.keywords, props.temas],
          props.textoLivre,
        ),
      )}
      {renderListaItensMesmoTipo(
        classes,
        TIPOS_CONECTORES_OU.ENTIDADE.id,
        props.entidades,
        props.conectoresOu?.includes(TIPOS_CONECTORES_OU.ENTIDADE.id),
      )}
      {renderConectorEntreCampos(
        TIPOS_CONECTORES_OU.ENTIDADE.id,
        isConectorEntreCampos(
          props.entidades,
          [props.keywords, props.temas],
          props.textoLivre,
        ),
      )}
      {renderListaItensMesmoTipo(
        classes,
        TIPOS_CONECTORES_OU.TEMA.id,
        props.temas,
        props.conectoresOu?.includes(TIPOS_CONECTORES_OU.TEMA.id),
      )}
      {renderConectorEntreCampos(
        TIPOS_CONECTORES_OU.TEMA.id,
        isConectorEntreCampos(
          props.temas,
          [props.keywords],
          props.textoLivre,
        ),
      )}
      {renderListaItensMesmoTipo(
        classes,
        TIPOS_CONECTORES_OU.KEYWORD.id,
        props.keywords,
        props.conectoresOu?.includes(TIPOS_CONECTORES_OU.KEYWORD.id),
      )}
      {renderConectorEntreCampos(
        TIPOS_CONECTORES_OU.KEYWORD.id,
        isConectorEntreCampos(
          props.keywords,
          [],
          props.textoLivre,
        ),
      )}
      {props.textoLivre}
    </div>
  )
}

function renderListaItensMesmoTipo(classes, tipoConector: TipoConectorOu, listaItens: string[], adicionarConectorOuEntreItens: boolean) {
  const resultado: ReactNode[] = []
  function adicionarConector(index) {
    return (
      <ConectorComponent
        isConectorOu={adicionarConectorOuEntreItens}
        nomeFuncao={montaNomeFuncaoConector('conector-interno-campo-', tipoConector, index + 1)}
      />
    )
  }
  if (listaItens.length < 1) {
    return null
  }
  listaItens.forEach((item, index) => {
    if (item) {
      resultado.push(item)
    }
    if (listaItens[index + 1]) {
      resultado.push(adicionarConector(index))
    }
  })
  return (
    <div className={classes.row}>
      {resultado.map((item, index) => (<span key={generateKey(index)} className={classes.elemento}>{item}</span>))}
    </div>
  )
}

function renderConectorEntreCampos(tipoConector: TipoConectorOu, adicionarConectorEntreCampos: boolean) {
  return adicionarConectorEntreCampos ? <ConectorComponent isConectorOu={false} nomeFuncao={montaNomeFuncaoConector('conector-entre-campos-', tipoConector, 1)} /> : null
}
