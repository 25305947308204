import React, { useState } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { Cenario } from 'bff/src/main/domain/Cenario'
import { TIPOS_FATO } from 'bff/src/main/domain/TipoFato'
import { LinhaCenarioComponent } from '../../ui-components/cenario/LinhaCenarioComponent'
import { stakeholderThunkCreators } from '../../../store/actions/stakeholderActions'
import { cenarioThunkCreators } from '../../../store/actions/cenarioActions'
import { modalArquivarCenarioActionCreators } from '../modal/modal-arquivar-cenario-container/modalArquivarCenarioActions'
import { modalExcluirCenarioActionCreators } from '../modal/modal-excluir-cenario-container/modalExcluirCenarioActions'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { useSpinningPromise } from '../../../infra/libs/react-swearing/SpinnerScreenProvider'
import { LinhaCenarioSkeletonComponent } from '../../ui-components/cenario/LinhaCenarioSkeletonComponent'


interface LinhaCenarioContainerProps {
  cenario: Cenario
}

export function LinhaCenarioContainer({ cenario }: LinhaCenarioContainerProps) {

  const dispatch = useTypedDispatch()
  const history = useHistory()
  const [visivelTelaInicial, setVisivelTelaInicial] = useState<boolean>(cenario.visivelEmTelaInicial)
  const { data: stakeholders, isLoading } = usePromiseEffect(recuperarStakeholders, [dispatch, cenario])

  const { run: onClickDesarquivar } = useSpinningPromise((id: number) => dispatch(cenarioThunkCreators.desarquivarCenario(id)))

  const { run: onClickVisualizarPaginaInicio } = useSpinningPromise(async (id: number, isVisivel: boolean) => {
    try {
      await dispatch(cenarioThunkCreators.alterarVisualizacaoTelaInicio(id, isVisivel))
      setVisivelTelaInicial(isVisivel)
    } catch {
      setVisivelTelaInicial(!isVisivel)
    }
  })

  const onClickEditar = () => { history.push(`/cenarios/editar/${cenario.id}`) }
  const onClickArquivar = (id: number) => dispatch(modalArquivarCenarioActionCreators.abrirModal(id))
  const onClickExcluir = (id: number, nome: string) => dispatch(modalExcluirCenarioActionCreators.abrirModal(id, nome))

  if (isLoading) {
    return <LinhaCenarioSkeletonComponent />
  }

  return (
    <LinhaCenarioComponent
      idCenario={cenario.id}
      nomeCenario={cenario.nome}
      situacaoCenario={cenario.situacao}
      isCenarioVisivel={visivelTelaInicial}
      atores={stakeholders?.atores || []}
      entidades={stakeholders?.entidades || []}
      temas={cenario.consulta.temas || []}
      keywords={cenario.consulta.keywords || []}
      textoLivre={cenario.consulta.textoLivre}
      conectoresOu={cenario.consulta.conectoresOu || []}
      filtros={getFiltros(cenario)}
      onClickVisualizarPaginaInicio={onClickVisualizarPaginaInicio}
      onClickEditar={onClickEditar}
      onClickArquivar={onClickArquivar}
      onClickExcluir={onClickExcluir}
      onClickDesarquivar={onClickDesarquivar}
    />
  )
}

async function recuperarStakeholders(dispatch: TypedDispatch, cenario: Cenario) {
  if (cenario.consulta.stakeholders) {
    const atores = cenario.consulta.stakeholders.filter(s => !s.includes('ENTIDADE'))
    const entidades = cenario.consulta.stakeholders.filter(s => s.includes('ENTIDADE'))
    try {
      const { data: atoresRecuperados } = await dispatch(stakeholderThunkCreators.buscarStakeholdersPorId(atores))
      const { data: entidadesRecuperadas } = await dispatch(stakeholderThunkCreators.buscarStakeholdersPorId(entidades))
      return {
        atores: atoresRecuperados.map(a => a.nome),
        entidades: entidadesRecuperadas.map(e => e.nome),
      }
    } catch (e) {
      console.warn(e)
    }
  }
  return { atores: [], entidades: [] }
}

function getFiltros(cenario: Cenario) {
  const filtros: string[] = []
  filtros.push(...cenario.consulta.filtrosTipoFato.map(c => getTipoFato(c)))
  if (cenario.consulta.dataBuscaAPartirDe) {
    const data = `Buscar fatos a partir de: ${moment(cenario.consulta.dataBuscaAPartirDe).format('DD/MM/YYYY')}`
    filtros.push(data)
  }
  return filtros
}

function getTipoFato(tipo: string) {
  return TIPOS_FATO[tipo].label
}
