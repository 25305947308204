import {
  BuscarStakeholdersResumidosPorIdsEndpoint,
  BuscarStakeholdersResumidosPorTextoEndpoint,
} from 'bff/src/main/controllers/stakeholder/stakeholdersEndpoints'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'
import { alertActionCreators } from './alertActions'

export const stakeholderThunkCreators = {

  buscarStakeholdersPorId: (ids: string[]) => createBffRequestThunk({
    request: {
      endpoint: BuscarStakeholdersResumidosPorIdsEndpoint.definition,
      queryParams: { ids },
    },
    actions: {
      error: () => alertActionCreators.showError('Não foi possível consultar os stakeholders.'),
    },
  }),

  buscarStakeholdersPorTexto: (textoLivre: string, limite: number) => createBffRequestThunk({
    request: {
      endpoint: BuscarStakeholdersResumidosPorTextoEndpoint.definition,
      queryParams: { textoLivre, limite: String(limite) },
    },
    actions: {
      error: () => alertActionCreators.showError('Não foi possível consultar os stakeholders.'),
    },
  }),

}
