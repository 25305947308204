import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  box: {
    width: '55px',
    height: '27px',
    background: 'white',
    borderRadius: '25px',
    border: '2px solid #d4d4d4',
    position: 'relative',
  },
  input: {
    zIndex: 3,
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    opacity: '0',
    cursor: 'pointer',
  },
  bolinha: {
    zIndex: 2,
    content: '""',
    display: 'block',
    width: '17px',
    height: '17px',
    position: 'absolute',
    background: '#ababab',
    borderRadius: '50%',
    top: '3px',
    transitionProperty: 'left',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease-in-out',
  },
  on: {
    left: '31px',
  },
  off: {
    left: '4px',
  },
  label: {
    zIndex: 1,
    transitionProperty: 'padding',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease-in-out',
  },
  labelOn: {
    padding: '0px 0px 0px 12px',
  },
  labelOff: {
    padding: '0px 0px 0px 25px',
  },
})

interface SwitchWithLabelComponentProps {
  labelOn: string
  labelOff: string
  onChange: (value: boolean) => void
  value?: boolean
}

export function SwitchWithLabelComponent({ labelOn, labelOff, value, onChange }: SwitchWithLabelComponentProps) {
  const classes = useStyles()
  const [checked, setChecked] = useState(value || false)

  useEffect(() => {
    if (value !== undefined) {
      setChecked(value)
    }
  }, [value])

  function handleClick() {
    setChecked(!checked)
    onChange(!checked)
  }

  return (
    <div className={classes.box}>
      <input type="checkbox" checked={checked} id="aqui" onChange={handleClick} className={classes.input} />
      <div className={`${classes.bolinha} ${checked ? classes.on : classes.off}`} />
      <label htmlFor="aqui" className={`${classes.label} ${checked ? classes.labelOn : classes.labelOff}`}>{checked ? labelOn : labelOff}</label>
    </div>
  )
}
