import {
  createClauseBoolMust,
  createClauseBoolMustNot,
  createClauseBoolShould,
  createClauseMatch,
  createClauseMatchPhrase,
} from '../../util/elasticSearchQueryUtil'
import { requestScope } from '../../infra/express/requestScope'
import { PerfilMapa } from '../../infra/security/PerfilMapa'
import { TIPOS_FATO } from '../../domain/TipoFato'
import { GRAUS_SIGILO } from '../../domain/GrauSigilo'
import { ambienteUtil } from '../../util/ambienteUtil'

export function montarSubqueryDeEventosSigilososBaseadaEmPerfilEFiltro(grausSigilo: string[]) {
  return montarSubquery(grausSigilo)
}

export function montarSubqueryDeEventosSigilososBaseadaEmPerfil() {
  return montarSubquery([])
}

function montarSubquery(grausSigilo: string[]) {
  return ambienteUtil.isAmbienteProducao() ? montarSubqueryProducao(grausSigilo) : montarSubqueryDesenvol(grausSigilo)
}

function montarSubqueryProducao(grausSigilo: string[]) {
  const estruturaNand = createClauseBoolMustNot()
  if (requestScope.hasRole(PerfilMapa.DIRIGENTE)) {
    if (grausSigilo.length === 1 && grausSigilo[0] === GRAUS_SIGILO.SIGILOSO.id) {
      return { match: { sigiloso: 'true' } }
    }
    if (grausSigilo.length === 1 && grausSigilo[0] === GRAUS_SIGILO.PUBLICO.id) {
      estruturaNand.bool.must_not.push(removerEventosSigilosos())
      return estruturaNand
    }
    return estruturaNand
  }
  if (grausSigilo.length === 1 && grausSigilo[0] === GRAUS_SIGILO.SIGILOSO.id) {
    estruturaNand.bool.must_not.push(removerFatosNaoSigilosos())
    return estruturaNand
  }
  estruturaNand.bool.must_not.push(removerEventosSigilosos())
  return estruturaNand
}

function montarSubqueryDesenvol(grausSigilo: string[]) {
  const estruturaNand = createClauseBoolMustNot()
  if (requestScope.hasRole(PerfilMapa.DIRIGENTE)) {
    if (grausSigilo.length === 1 && grausSigilo[0] === GRAUS_SIGILO.SIGILOSO.id) {
      estruturaNand.bool.must_not.push(removerFatosNaoSigilosos())
      return estruturaNand
    }
  }
  if (grausSigilo.length === 1 && grausSigilo[0] === GRAUS_SIGILO.SIGILOSO.id) {
    estruturaNand.bool.must_not.push(removerFatosNaoSigilosos())
    return estruturaNand
  }
  estruturaNand.bool.must_not.push(removerEventosSigilosos())
  return estruturaNand
}

function removerEventosSigilosos() {
  const estruturaAnd = createClauseBoolMust()
  estruturaAnd.bool.must.push(createClauseMatchPhrase('tipo_fato', TIPOS_FATO.EVENTO_RELACIONA.id))
  estruturaAnd.bool.must.push(createClauseMatch('sigiloso', true))
  return estruturaAnd
}

function removerFatosNaoSigilosos() {
  const estruturaOr = createClauseBoolShould()
  estruturaOr.bool.should.push(createClauseMatchPhrase('tipo_fato', TIPOS_FATO.EVENTO_RELACIONA.id))
  estruturaOr.bool.should.push(createClauseMatchPhrase('tipo_fato', TIPOS_FATO.NOTICIA_CLIPPING.id))
  estruturaOr.bool.should.push(createClauseMatchPhrase('tipo_fato', TIPOS_FATO.NOTICIA_UNIAO.id))
  estruturaOr.bool.should.push(createClauseMatchPhrase('tipo_fato', TIPOS_FATO.PROPOSICAO_LEGISLATIVA.id))
  return estruturaOr
}
