import { MontarQueryRecuperarFatosPayload } from './MontarQueryRecuperarFatosPayload'
import { StakeholdersComIdEAlias } from '../../app/stakeholder/recuperarAliasStakeholderPorId'
import { createClauseBoolShould, createClauseMatchPhrase } from '../../util/elasticSearchQueryUtil'
import { TIPOS_STAKEHOLDER } from '../../domain/TipoStakeholder'
import { GRAUS_SIGILO } from '../../domain/GrauSigilo'
import { MontarQueryRecuperarFatosBasePayload } from './MontarQueryRecuperarFatosBasePayload'
import { MontarQueryRecuperarContadorFatosPayload } from './MontarQueryRecuperarContadorFatosPayload'
import { TipoProcesso, TIPOS_PROCESSO } from '../../domain/TipoProcesso'
import { EstadoProcesso } from '../../domain/EstadoProcesso'
import { TIPOS_FATO } from '../../domain/TipoFato'
import { TipoConectorOu, TIPOS_CONECTORES_OU } from '../../domain/TipoConectorOu'

const CAMPOS_PARA_PESQUISAR_KEYWORDS: string[] = ['responsaveis.nome', 'unidadesJurisdicionadas.nome', 'representantesLegais.nomeRepresentante', 'numeroCompleto', 'assunto']
const CAMPOS_PARA_PESQUISAR_STAKEHOLDERS: string[] = ['responsaveis.nome', 'unidadesJurisdicionadas.nome', 'representantesLegais.nomeRepresentante']

export function montarQueryRecuperarProcessos(
  payload: MontarQueryRecuperarFatosPayload,
  atores: StakeholdersComIdEAlias[],
  entidades: StakeholdersComIdEAlias[],
) {
  const query: any = montarQuery(payload, atores, entidades)
  query.size = payload.tiposFato.length > 0 && !payload.tiposFato.includes(TIPOS_FATO.PROCESSO.id) ? 0 : payload.size
  query.sort = { dataAutuacao: 'desc' }
  query.aggs = montarAgreggationsProcessos()
  return query
}

export function montarQueryRecuperarContadorProcessos(
  payload: MontarQueryRecuperarContadorFatosPayload,
  atores: StakeholdersComIdEAlias[],
  entidades: StakeholdersComIdEAlias[],
) {
  return montarQuery(payload, atores, entidades)
}

function montarQuery(
  payload: MontarQueryRecuperarFatosBasePayload,
  atores: StakeholdersComIdEAlias[],
  entidades: StakeholdersComIdEAlias[],
) {
  return {
    query: {
      bool: {
        must: [
          ...montarSubqueriesParaStakeholders(
            payload.listaConectoresOu.includes(TIPOS_CONECTORES_OU.ATOR.id), atores,
          ),
          ...montarSubqueriesParaStakeholders(
            payload.listaConectoresOu.includes(TIPOS_CONECTORES_OU.ENTIDADE.id), entidades,
          ),
          ...montarSubqueriesParaKeywords(payload.keywords, payload.listaConectoresOu, payload.textoLivre),
          ...montarSubqueriesParaTemas(payload.temas, payload.listaConectoresOu),
          ...montarSubqueriesParaFiltros(
            payload.grausSigilo, payload.tiposProcesso, payload.estadosProcesso, payload.relatores, payload.dtInicio, payload.dtFim,
          ),
        ],
      },
    },
  }
}

function montarSubqueriesParaStakeholders(conectorOu: boolean, stakeholders: StakeholdersComIdEAlias[]) {
  if (conectorOu) {
    const estruturaOr = createClauseBoolShould()
    estruturaOr.bool.should.push(...stakeholders.map(stakeholder => montarSubqueryParaStakeholder(stakeholder)))
    return [estruturaOr]
  }
  return stakeholders.map(stakeholder => montarSubqueryParaStakeholder(stakeholder))
}

function montarSubqueriesParaKeywords(keywords: string[], listaConectoresOu: TipoConectorOu[], textoLivre?: string) {
  const conectorOuKeywords = listaConectoresOu.includes(TIPOS_CONECTORES_OU.KEYWORD.id)
  const palavrasParaPesquisar: string[] = []
  if (textoLivre) {
    palavrasParaPesquisar.push(...textoLivre.split(' '))
  }
  if (keywords.length > 0) {
    palavrasParaPesquisar.push(...keywords)
  }
  if (conectorOuKeywords) {
    const estruturaOr = createClauseBoolShould()
    estruturaOr.bool.should.push(...palavrasParaPesquisar.map(montarSubqueryParaKeywordOuTema))
    return [estruturaOr]
  }
  return palavrasParaPesquisar.map(montarSubqueryParaKeywordOuTema)
}

function montarSubqueriesParaTemas(temas: string[], listaConectoresOu: TipoConectorOu[]) {
  const conectorOuTema = listaConectoresOu.includes(TIPOS_CONECTORES_OU.TEMA.id)

  if (conectorOuTema) {
    const estruturaOr = createClauseBoolShould()
    estruturaOr.bool.should.push(...temas.map(montarSubqueryParaKeywordOuTema))
    return [estruturaOr]
  }
  return temas.map(montarSubqueryParaKeywordOuTema)
}

function montarSubqueryParaStakeholder(stakeholder: StakeholdersComIdEAlias) {
  const estruturaOr = createClauseBoolShould()
  switch (stakeholder.tipoStakeholder) {
    case TIPOS_STAKEHOLDER.ATOR.id:
      estruturaOr.bool.should.push(...montarCondicoesOrPorCampoPesquisado(stakeholder.nomeCompleto, CAMPOS_PARA_PESQUISAR_STAKEHOLDERS))
      break
    case TIPOS_STAKEHOLDER.ENTIDADE.id:
      stakeholder.listaNomes.forEach((nome) => {
        if (nome) {
          estruturaOr.bool.should.push(...montarCondicoesOrPorCampoPesquisado(nome, CAMPOS_PARA_PESQUISAR_STAKEHOLDERS))
        }
      })
      break
    default: 
      break
  }
  return estruturaOr
}

function montarSubqueryParaKeywordOuTema(keywordOuTema: string) {
  const estruturaOr = createClauseBoolShould()
  estruturaOr.bool.should.push(...montarCondicoesOrPorCampoPesquisado(keywordOuTema, CAMPOS_PARA_PESQUISAR_KEYWORDS))
  return estruturaOr
}

function montarCondicoesOrPorCampoPesquisado(valor: string, listaParaPesquisar: string[]) {
  return listaParaPesquisar.map(campo => getMatchPhrasePorPropriedade(campo, valor))
}

function getMatchPhrasePorPropriedade(propriedade: string, valor: any) {
  let obj: any
  if (propriedade.includes('.')) {
    obj = {
      nested: {
        query: {
          bool: {
            must: [createClauseMatchPhrase(propriedade, valor)],
          },
        },
      },
    }
    obj.nested.path = propriedade.substring(0, propriedade.indexOf('.'))
  } else {
    obj = createClauseMatchPhrase(propriedade, valor)
  }
  return obj
}

function montarSubqueriesParaFiltros(
  grausSigilo: string[], tiposProcesso: TipoProcesso[], estadosProcesso: EstadoProcesso[],
  relatores: string[], dtInicio?: string, dtFim?: string,
) {
  const filtros: any[] = []
  if (dtInicio || dtFim) {
    filtros.push(montarFiltroIntervalo(dtInicio, dtFim))
  }
  if (grausSigilo.length === 1) {
    filtros.push(montarFiltroSigiloso(grausSigilo))
  }
  if (tiposProcesso.length > 0) {
    filtros.push(adicionarFiltroTipoProcesso(tiposProcesso))
  }
  if (estadosProcesso.length > 0) {
    filtros.push(adicionarFiltroEstadoProcesso(estadosProcesso))
  }
  if (relatores.length > 0) {
    filtros.push(adicionarFiltroRelatoresProcesso(relatores))
  }

  return filtros
}

function adicionarFiltroTipoProcesso(tiposProcesso: TipoProcesso[]) {
  const objetoPesquisaOrTipoProcesso = createClauseBoolShould()
  tiposProcesso.forEach((tipoProcesso) => {
    const clauseMatchPhrase = createClauseMatchPhrase('siglaTipoProcesso', TIPOS_PROCESSO[tipoProcesso].id)
    objetoPesquisaOrTipoProcesso.bool.should.push(clauseMatchPhrase)
  })
  return objetoPesquisaOrTipoProcesso
}

function adicionarFiltroEstadoProcesso(estadosProcesso: EstadoProcesso[]) {
  const objetoPesquisaOrEstadosProcesso = createClauseBoolShould()
  estadosProcesso.forEach((estado) => {
    const clauseMatchPhrase = createClauseMatchPhrase('descricaoEstado', estado)
    objetoPesquisaOrEstadosProcesso.bool.should.push(clauseMatchPhrase)
  })
  return objetoPesquisaOrEstadosProcesso
}

function adicionarFiltroRelatoresProcesso(relatores: string[]) {
  const objetoPesquisaOrRelatores = createClauseBoolShould()
  relatores.forEach((relator) => {
    const clauseMatchPhrase = createClauseMatchPhrase('codigoRelator', relator)
    objetoPesquisaOrRelatores.bool.should.push(clauseMatchPhrase)
  })
  return objetoPesquisaOrRelatores
}

function montarFiltroSigiloso(grausSigilo: string[]) {
  return { match: { sigiloso: grausSigilo[0] === GRAUS_SIGILO.SIGILOSO.id } }
}

function montarFiltroIntervalo(dtInicio?: string, dtFim?: string) {
  return {
    range: {
      dataAutuacao: {
        gte: dtInicio,
        lte: dtFim,
        boost: 2.0,
      },
    },
  }
}

function montarAgreggationsProcessos() {
  return {
    relatores: {
      terms: {
        size: 100,
        field: 'codigoRelator',
      },
    },
  }
}
