import React from 'react'
import { useSelector } from 'react-redux'
import { LoginUserComponent } from '../../ui-components/comum/LoginUserComponent'
import { userLoginSelector, userNameSelector } from '../../../store/reducers/session/sessionSelectors'

export function LoginUserContainer() {
  const nome = useSelector(userNameSelector) ?? ''
  const login = useSelector(userLoginSelector) ?? ''
  const nomeTratado = nome.toLowerCase()
  return <LoginUserComponent nome={nomeTratado} login={login} />
}
