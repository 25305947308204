import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TrechoFato } from 'bff/src/main/domain/Ficha'
import { TIPOS_TRECHO_FICHA } from 'bff/src/main/domain/TipoTrechoFicha'
import { BotaoAcoesTrechoFichaContainer } from '../../containers/ficha/BotaoAcoesTrechoFichaContainer'
import { ButtonSpanComponent } from '../comum/ButtonSpanComponent'

const useStyles = makeStyles({
  texto: {
    fontWeight: 500,
    color: '#434343',
    marginBottom: 0,
  },
  titulo: {
    fontSize: '24px',
  },
  subtitulo: {
    fontSize: '18px',
  },
  margin: {
    marginRight: '19px',
  },
  trechoFicha: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
  },
})

interface TrechoTituloComponentProps {
  trechoFato: TrechoFato
  onClickTexto: () => void
  fichaId: number
}

export function TrechoTituloSubtituloComponent({ trechoFato, onClickTexto, fichaId }: TrechoTituloComponentProps) {
  const classes = useStyles()
  return (
    <div className={classes.trechoFicha}>
      <div className={classes.margin}>
        <BotaoAcoesTrechoFichaContainer
          fichaId={fichaId}
          trechoFichaId={trechoFato.id}
          tipoTrecho={trechoFato.tipo}
          fatoCitadoId={trechoFato.fatoCitado}
        />
      </div>
      <ButtonSpanComponent onClick={onClickTexto}>
        <h2 className={`${classes.texto} ${trechoFato.tipo === TIPOS_TRECHO_FICHA.TITULO.id ? classes.titulo : classes.subtitulo}`}>
          {trechoFato.conteudo}
        </h2>
      </ButtonSpanComponent>
    </div>
  )
}
