import React from 'react'
import { Ficha } from 'bff/src/main/domain/Ficha'
import { DropResult } from 'react-beautiful-dnd'
import { ConteudoFichaComponent } from '../../ui-components/ficha/ConteudoFichaComponent'
import { useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSpinningPromise } from '../../../infra/libs/react-swearing/SpinnerScreenProvider'
import { fichaThunkCreators } from '../../../store/actions/fichaActions'

interface ConteudoFichaContainerProps { 
  ficha: Ficha
}

export function ConteudoFichaContainer({ ficha }: ConteudoFichaContainerProps) {
  const dispatch = useTypedDispatch()
  const [tipoAcaoTitulo, setTipoAcaoTitulo] = React.useState<'EDITAR' | 'VISUALIZAR'>('VISUALIZAR')
  const { run: handleDragAndDrop } = useSpinningPromise(async (dropResult: DropResult) => {
    const { draggableId, destination } = dropResult
    const trechoId = parseInt(draggableId)
    const trecho = ficha.trechos.find(t => t.id === trechoId)
    if (destination && trecho?.ordemTrecho !== destination.index) {
      const novaOrdem = destination.index
      await dispatch(fichaThunkCreators.alterarOrdemTecho(ficha.id, trechoId, novaOrdem))
    }
  })
  const onCancelarEdicaoTitulo = () => setTipoAcaoTitulo('VISUALIZAR')
  const onAbrirEdicaoTitulo = () => setTipoAcaoTitulo('EDITAR')

  const { run: onEditarTitulo } = useSpinningPromise(
    (novoTexto: string) => dispatch(fichaThunkCreators.editarTituloFicha(ficha.id, novoTexto)),
    [ficha.id],
  )

  return (
    <ConteudoFichaComponent
      ficha={ficha}
      onDragAndDrop={handleDragAndDrop}
      acaoTitulo={tipoAcaoTitulo}
      onEditarTitulo={onEditarTitulo}
      onAbrirEdicaoTitulo={onAbrirEdicaoTitulo}
      onCancelarEdicaoTitulo={onCancelarEdicaoTitulo}
    />
  )
}
