import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSwan } from '../../../infra/libs/redux-swans/useSwan'
import { createReducer } from '../../../infra/libs/redux-hardtyped/createReducer'
import { cenarioActionCreators } from '../../../store/actions/cenarioActions'
import { defineActionCreators } from '../../../infra/libs/redux-hardtyped/defineActionCreators'
import { ModalWithActionsComponent } from '../../ui-components/modal/ModalWithActionsComponent'
import { IconCenarioComponent } from '../../ui-components/icons/IconCenarioComponent'


export const modalCenarioAlteradoActions = defineActionCreators('modalCenarioAlterado', {
  fecharModal: () => ({}),
})

export type ModalCenarioAlteradoState = null | {
  idCenario: number
}

const initialState = null

const reducer = createReducer<ModalCenarioAlteradoState>(initialState)(handle => ([
  handle(cenarioActionCreators.cenarioAlterado, (state, action) => {
    const { id } = action
    return { idCenario: id }
  }),
  handle(modalCenarioAlteradoActions.fecharModal, () => null),
]))

export function ModalCenarioAlteradoContainer() {
  const history = useHistory()
  const dispatch = useTypedDispatch()
  const state = useSwan(reducer)
  const modalEstaAberta = !!state?.idCenario
  const idCenario = state?.idCenario

  function onAction() {
    history.push(`/cenarios/${idCenario}`)
    dispatch(modalCenarioAlteradoActions.fecharModal())
  }

  if (!modalEstaAberta) return null
  return (
    <ModalWithActionsComponent
      icon={<IconCenarioComponent fill="#2f5dc9" height="74" width="74" />}
      primaryColor="blue"
      title="Alterações realizadas"
      text="Acesse a página do cenário para visualizar os fatos encontrados."
      actionButtonText="Ir para a página do cenário"
      onAction={onAction}
    />
  )
}
