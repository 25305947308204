import { ResumoStakeholder, StakeholderComFavorito } from '../../domain/ResumoStakeholder'
import { TIPOS_STAKEHOLDER } from '../../domain/TipoStakeholder'
import { isAtorInterno } from '../../util/stakeholdersUtil'
import { frontendRoutesUtil } from '../../util/frontendRoutesUtil'
import { fotoUtil } from '../../util/fotoUtil'
import { Favorito } from '../../domain/Favorito'


const STAKEHOLDER_HANDLER_MOUNT_MAP = {
  [TIPOS_STAKEHOLDER.ATOR.id]: mapearAtor,
  [TIPOS_STAKEHOLDER.ENTIDADE.id]: mapearEntidade,
}

function mapearAtor(stakeholder): ResumoStakeholder {
  return {
    id: stakeholder._id,
    idFavorito: 0,
    tipo: TIPOS_STAKEHOLDER.ATOR.id,
    papel: stakeholder._source.cargo,
    nome: stakeholder._source.nome,
    stakeholderInterno: isAtorInterno(stakeholder),
    urlDestino: frontendRoutesUtil.montarUrlPerfilStakeholder(stakeholder._id, stakeholder._source.tipo_stakeholder),
    urlImagem: fotoUtil.tratarFotoAtor(stakeholder),
    perfilTwitter: stakeholder._source.twitter,
    orgao: stakeholder._source.orgao,
    descricao: tratarDescricao(stakeholder._source.perfil),
  }
}

function mapearEntidade(stakeholder): ResumoStakeholder {
  return {
    id: stakeholder._id,
    idFavorito: 0,
    tipo: TIPOS_STAKEHOLDER.ENTIDADE.id,
    papel: 'Entidade',
    nome: stakeholder._source.nome_completo,
    stakeholderInterno: false,
    urlDestino: frontendRoutesUtil.montarUrlPerfilStakeholder(stakeholder._id, stakeholder._source.tipo_stakeholder),
    urlImagem: fotoUtil.tratarFotoEntidade(stakeholder),
    perfilTwitter: stakeholder._source.twitter,
    orgao: stakeholder._source.orgao,
    descricao: stakeholder._source.perfil,
  }
}

export function mapearParaStakeholders(listaStakeholders): ResumoStakeholder[] {
  return listaStakeholders.map((stakeholder) => {
    const tipo = stakeholder._source.tipo_stakeholder
    return STAKEHOLDER_HANDLER_MOUNT_MAP[tipo](stakeholder)
  })
}


export function tratarDescricao(descricao: string) {
  if (descricao && descricao.length > 400) {
    return `${descricao.substring(0, 400)}...`
  }
  return descricao
}

export function criaStakeholdersComFavoritos(stakeholders: ResumoStakeholder[], favoritos: Favorito[]) {
  const stakeholdersComFavoritos: StakeholderComFavorito[] = []
  stakeholders.forEach((resumoStakeholder) => {
    const stakeComFavorito: StakeholderComFavorito = { resumoStakeholder, isFavorito: false }

    const result = favoritos.filter(favorito => resumoStakeholder.id === favorito.itemFavorito)
    if (result.length > 0) {
      stakeComFavorito.isFavorito = true
    }

    stakeholdersComFavoritos.push(stakeComFavorito)
  })

  return stakeholdersComFavoritos
}

