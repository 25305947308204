import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { IconRemoverListaLeituraComponent } from '../icons/IconRemoverListaLeituraComponent'
import { IconListaLeituraComponent } from '../icons/IconListaLeituraComponent'


const useStyles = makeStyles({
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  texto: {
    fontWeight: 500,
    marginLeft: '7px',
  },
})

interface AdicionarRemoverListaLeituraComponentProps {
  fatoJaAdicionadoALista?: boolean
  onAdicionar: () => void
  onRemover: () => void
}

export function BotaoAdicionarRemoverListaLeituraComponent(
  { fatoJaAdicionadoALista = false, onAdicionar, onRemover } :AdicionarRemoverListaLeituraComponentProps,
) {

  function handleClick() {
    return fatoJaAdicionadoALista ? onRemover() : onAdicionar()
  }

  const classes = useStyles()
  return (
    <span
      className={classes.textWrapper}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyPress={handleClick}
    >
      {
        fatoJaAdicionadoALista
          ? <IconRemoverListaLeituraComponent />
          : <IconListaLeituraComponent />
      }
      <span className={classes.texto}>{ fatoJaAdicionadoALista ? 'Remover da lista de leitura' : 'Adicionar à lista de leitura' }</span>
    </span>
  )
}
