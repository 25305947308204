import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { IconFichaNovaComponent } from '../icons/IconFichaNovaComponent'


const useStyles = makeStyles({
  boxFichas: {
    width: '100%',
    height: '100%',
    borderRadius: '3px',
    background: '#D9E3FA',
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',  
    padding: '16px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconBox: {
    marginBottom: '12px',
  },
  tituloNovaFicha: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#2F5DC9',
  },
  linkNovaFicha: {
    display: 'inline-block',
  },
})

interface NovaFichaComponentProps {
  onClick: () => void
}

export function NovaFichaComponent({ onClick }: NovaFichaComponentProps) {
  const classes = useStyles()
  return (
    <div className={classes.boxFichas} onClick={onClick} role="button" tabIndex={0} onKeyPress={onClick}>
      <div className={classes.iconBox}>
        <IconFichaNovaComponent fill="#2F5DC9" height="40" width="40" />
      </div>
      <div>
        <h4 className={classes.tituloNovaFicha}>Criar nova ficha</h4>
      </div>
    </div>
  )
}
