import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  label: {
    fontSize: '14px',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})

export interface BreadCrumbItem {
  label: string
  url: string
}

interface BreadCrumbComponentProps {
  itens: BreadCrumbItem[]
}

export function BreadCrumbComponent({ itens }: BreadCrumbComponentProps) {
  const classes = useStyles()
  return (
    <div>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {
          itens.map(item => (
            <Link key={item.url} color="inherit" to={item.url}>
              <Typography className={classes.label} color="textPrimary">{item.label}</Typography>
            </Link>
          ))
        }
      </Breadcrumbs>
    </div>
  )
}
