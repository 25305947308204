import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { mapaApi } from '../../api/mapaApi'


export namespace ChecarSeUsuarioAceitouLgpdEndpoint {
  export type Body = undefined
  export type Params = undefined
  export type Query = undefined
  export type ResponseBody = boolean
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/checar-se-usuario-aceitou-lgpd',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { data } = await mapaApi.verificarSeUsuarioLogadoAceitouLgpd()
      response.json(data)
    },
  })
}

export namespace AceitarLgpdEndpoint {
  export type Body = undefined
  export type Params = undefined
  export type Query = undefined
  export type ResponseBody = undefined
  export const definition = defineEndpoint({
    httpMethod: 'POST',
    url: '/aceite-lgpd',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      await mapaApi.aceitarLgpd()
      response.status(201)
      response.json()
    },
  })
}
