import React from 'react'
import { ButtonIconContadorFavoritosComponent } from '../../ui-components/comum/ButtonIconContadorFavoritosComponent'
import { TypedDispatch, useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { favoritoThunkCreators } from '../../../store/actions/favoritoActions'

export function ContadorFavoritosContainer() {

  const dispatch = useTypedDispatch()

  const { data: favoritos } = usePromiseEffect(contarFavoritos, [dispatch])

  return <ButtonIconContadorFavoritosComponent quantidadeFavoritos={favoritos} />
}

async function contarFavoritos(dispatch: TypedDispatch) {
  try {
    const { data: favoritos } = await dispatch(favoritoThunkCreators.contarMeusFavoritos())
    return favoritos
  } catch (e) {
    console.warn(e)
  }
  return undefined
}
