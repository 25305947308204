import { createAxiosWithAuth } from '../infra/axios/createAxiosWithAuth'
import { RecursoComputacional } from '../infra/security/RecursoComputacional'
import { stringifyToQueryString } from '../util/urlUtil'

const axiosInstance = createAxiosWithAuth(RecursoComputacional.MAPA)

export const mapaApi = {

  criarFavorito(body) {
    const url = '/api/v1/favoritos'
    return axiosInstance.post(url, body)
  },

  excluirFavorito(idItemFavorito: string) {
    const url = `/api/v1/favoritos/${idItemFavorito}`
    return axiosInstance.delete(url)
  },

  recuperarMeusFavoritos(tipoFavorito: string) {
    const url = `/api/v1/meus-favoritos?tipoFavorito=${tipoFavorito}`
    return axiosInstance.get(url)
  },

  contarMeusFavoritos() {
    const url = '/api/v1/conta-meus-favoritos'
    return axiosInstance.get(url)
  },

  criarCenario(body) {
    const url = '/api/v1/cenarios'
    return axiosInstance.post(url, body)
  },

  editarCenario(id, body) {
    const url = `/api/v1/cenarios/${id}`
    return axiosInstance.patch(url, body)
  },

  arquivarCenario(id: string) {
    const url = `/api/v1/cenarios/${id}/arquivar`
    return axiosInstance.post(url)
  },

  desarquivarCenario(id: string) {
    const url = `/api/v1/cenarios/${id}/desarquivar`
    return axiosInstance.post(url)
  },

  excluirCenario(id: string) {
    const url = `/api/v1/cenarios/${id}`
    return axiosInstance.delete(url)
  },

  recuperarCenarioPorId(id: string) {
    const url = `/api/v1/cenarios/${id}`
    return axiosInstance.get(url)
  },

  recuperarMeusCenarios(queryParams) {
    const queryParamsString = stringifyToQueryString(queryParams)
    const url = `/api/v1/meus-cenarios?${queryParamsString}`
    return axiosInstance.get(url)
  },

  recuperarMeusCenarioAtivos() {
    const url = '/api/v1/meus-cenarios?situacao=ATIVO'
    return axiosInstance.get(url)
  },

  recuperarMeusCenarioVisiveisTelaInicial() {
    const url = '/api/v1/meus-cenarios?visivelEmTelaInicial=true'
    return axiosInstance.get(url)
  },

  verificarSeUsuarioLogadoAceitouLgpd() {
    const url = '/api/v1/checar-se-usuario-aceitou-lgpd'
    return axiosInstance.get(url)
  },

  aceitarLgpd() {
    const url = '/api/v1/aceite-lgpd'
    return axiosInstance.post(url)
  },

  checarSeUsuarioVisualizouOnBoarding(name: string) {
    const url = `/api/v1/checar-se-usuario-visualizou-on-boarding/${name}`
    return axiosInstance.get(url)
  },

  registrarVisualizacaoOnBoarding(name: string) {
    const url = `/api/v1/visualizacao-onboarding/${name}`
    return axiosInstance.post(url)
  },

  adicionarItemListaLeitura(fato: string) {
    const url = '/api/v1/minha-lista-leitura/adicionar-item'
    return axiosInstance.post(url, fato)
  },

  removerItemListaLeitura(fato: string) {
    const url = '/api/v1/minha-lista-leitura/remover-item'
    return axiosInstance.post(url, fato)
  },

  obterMinhaListaLeitura() {
    const url = '/api/v1/minha-lista-leitura'
    return axiosInstance.get(url)
  },

  verificarSeFatoEstaNaListaLeitura(fato: string) {
    const url = `/api/v1/checar-se-fato-esta-na-minha-lista-leitura/${fato}`
    return axiosInstance.get(url)
  },

  criarFicha(fichaCommand: any) {
    const url = '/api/v1/ficha'
    return axiosInstance.post(url, fichaCommand)
  },

  recuperarMinhasFichas() {
    const url = '/api/v1/minhas-fichas'
    return axiosInstance.get(url)
  },

  recuperarFichaPorId(id: string) {
    const url = `/api/v1/ficha/${id}`
    return axiosInstance.get(url)
  },

  editarDescricaoFicha(id: string, descricao: string) {
    const url = `/api/v1/ficha/${id}/editar-descricao-ficha`
    return axiosInstance.post(url, descricao)
  },

  editarTituloFicha(id: string, titulo: string) {
    const url = `/api/v1/ficha/${id}/editar-titulo-ficha`
    return axiosInstance.post(url, titulo)
  },

  deletarFicha(id: string) {
    const url = `/api/v1/ficha/${id}`
    return axiosInstance.delete(url)
  },

  adicionarTrechoFatoFichaExistente(id: string, trechoFato: any) {
    const url = `api/v1/ficha/${id}/adicionar-trecho-citacao-fato`
    return axiosInstance.post(url, trechoFato)
  },

  adicionarTrechoTextualFicha(id: string, command: any) {
    const url = `api/v1/ficha/${id}/adicionar-trecho-textual`
    return axiosInstance.post(url, command)
  },

  editarConteudoTrechoFichaTextual(fichaId: number, command: any) {
    const url = `api/v1/ficha/${fichaId}/editar-conteudo-trecho-textual`
    return axiosInstance.post(url, command)
  },

  alterarTipoTrechoFichaTextual(fichaId: number, command: any) {
    const url = `api/v1/ficha/${fichaId}/alterar-tipo-trecho-textual`
    return axiosInstance.post(url, command)
  },

  alterarOrdemTrechoFicha(fichaId: number, command: any) {
    const url = `api/v1/ficha/${fichaId}/alterar-ordem-trecho`
    return axiosInstance.post(url, command)
  },

  removerTrechoFicha(fichaId: number, trechoId: number) {
    const url = `api/v1/ficha/${fichaId}/trecho/${trechoId}`
    return axiosInstance.delete(url)
  },

}

