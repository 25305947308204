import React from 'react'
import { Favorito } from 'bff/src/main/domain/Favorito'
import { FatoTelaInicial } from 'bff/src/main/domain/FatoTelaInicial'
import { ListaFatosCenarioTelaInicialComponent } from '../../ui-components/fato/ListaFatosCenarioTelaInicialComponent'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { ListaFatosPlaceholderComponent } from '../../ui-components/fato/ListaFatosPlaceholderComponent'
import { fatoThunkCreators } from '../../../store/actions/fatoActions'
import { favoritoThunkCreators } from '../../../store/actions/favoritoActions'
import { TipoFavorito } from '../../ui-components/favorito/TipoFavorito'


export function FatosMeusCenariosContainer() {
  const dispatch = useTypedDispatch()
  const { data: fatosComCenario, isLoading, isSettled } = usePromiseEffect(recuperarFatosCenariosTelaInicial, [dispatch])

  if (isLoading) {
    return <ListaFatosPlaceholderComponent />
  }

  if (!fatosComCenario && isSettled) {
    return null
  }

  return (
    <div>
      {
        fatosComCenario?.map(fatoCenario => (
          <ListaFatosCenarioTelaInicialComponent
            key={fatoCenario.id}
            fato={fatoCenario}
            nomesCenarios={fatoCenario.cenarios.map(c => c.nome)}
          />
        ))
      }
    </div>
  )
}

async function recuperarFatosCenariosTelaInicial(dispatch: TypedDispatch): Promise<FatoTelaInicial[]> {
  try {
    const { data: resultado } = await dispatch(fatoThunkCreators.recuperarFatosTelaInicial())
    const { data: favoritos } = await dispatch(favoritoThunkCreators.recuperarMeusFavoritos(TipoFavorito.FATO))

    return criaFatosComFavoritos(resultado, favoritos)
  } catch (e) {
    console.warn(e)
  }
  return []
}

export function criaFatosComFavoritos(fatos: FatoTelaInicial[], favoritos: Favorito[]) {
  const fatosTelaInicial: FatoTelaInicial[] = []
  fatos.forEach((fatoTelaInicial) => {
    const result = favoritos.filter(favorito => fatoTelaInicial.id === favorito.itemFavorito)
    const fatoCopiado = { ...fatoTelaInicial }
    if (result.length > 0) {
      fatoCopiado.isFavorito = true
    }
    fatosTelaInicial.push(fatoCopiado)
  })

  return fatosTelaInicial
}

