import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TrechoFato } from 'bff/src/main/domain/Ficha'
import { frontendRoutesUtil } from 'bff/src/main/util/frontendRoutesUtil'
import { Link } from 'react-router-dom'
import { BotaoAcoesTrechoFichaContainer } from '../../containers/ficha/BotaoAcoesTrechoFichaContainer'


const useStyles = makeStyles({
  trechoFicha: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    background: '#f6f6f6',
    padding: '16px',
    marginBottom: '32px',
    '&:hover': {
      cursor: 'pointer',
    },
    display: 'flex',
    alignItems: 'center',
  },
  margin: {
    marginRight: '19px',
  },
  texto: {
    color: '#434343',
  },
})

interface TrechoCitacaoFatoComponentProps {
  trechoFato: TrechoFato
  fichaId: number
}

export function TrechoCitacaoFatoComponent({ trechoFato, fichaId }: TrechoCitacaoFatoComponentProps) {
  const classes = useStyles()
  return (
    <div className={classes.trechoFicha}>
      <div className={classes.margin}>
        <BotaoAcoesTrechoFichaContainer
          fichaId={fichaId}
          trechoFichaId={trechoFato.id}
          tipoTrecho={trechoFato.tipo}
          fatoCitadoId={trechoFato.fatoCitado}
        />
      </div>
      <Link to={frontendRoutesUtil.montarUrlDetalhamentoFatoPorId(trechoFato.fatoCitado ?? '/')}>
        <div className={classes.texto}>
          {trechoFato.conteudo}
        </div>
      </Link>
    </div>
  )
}
