import React from 'react'
import { Favorito } from 'bff/src/main/domain/Favorito'
import { FatoComFavorito, ResumoFato } from 'bff/src/main/domain/ResumoFato'
import { stringifyToQueryString } from 'bff/src/main/util/urlUtil'
import { BuscarFatosResumidosEndpoint } from 'bff/src/main/controllers/fato/fatosEndpoints'
import { TipoFato } from 'bff/src/main/domain/TipoFato'
import { TipoEvento } from 'bff/src/main/domain/TipoEvento'
import { TipoProcesso } from 'bff/src/main/domain/TipoProcesso'
import { Ordenacao } from 'bff/src/main/domain/Ordenacao'
import { GrauSigilo } from 'bff/src/main/domain/GrauSigilo'
import { EstadoProcesso } from 'bff/src/main/domain/EstadoProcesso'
import { TipoConectorOu } from 'bff/src/main/domain/TipoConectorOu'
import { ListaFatosComponent } from '../../ui-components/fato/ListaFatosComponent'
import { useQueryParamAsArrayOfString, useQueryParamAsString } from '../../../infra/libs/react-location-hooks/routerHooks'
import { fatoThunkCreators } from '../../../store/actions/fatoActions'
import { ListaFatosPlaceholderComponent } from '../../ui-components/fato/ListaFatosPlaceholderComponent'
import {
  QUERY_PARAM_ATORES,
  QUERY_PARAM_CONECTOR_OU,
  QUERY_PARAM_ENTIDADES,
  QUERY_PARAM_FILTRO_DATA_FIM,
  QUERY_PARAM_FILTRO_DATA_INICIO,
  QUERY_PARAM_FILTRO_ESTADO_PROCESSO,
  QUERY_PARAM_FILTRO_GRAUS_SIGILO,
  QUERY_PARAM_FILTRO_RELATOR,
  QUERY_PARAM_FILTRO_TIPO_EVENTOS,
  QUERY_PARAM_FILTRO_TIPO_FATOS,
  QUERY_PARAM_FILTRO_TIPO_PROCESSOS,
  QUERY_PARAM_KEYWORDS,
  QUERY_PARAM_ORDENACAO,
  QUERY_PARAM_TEMAS,
  QUERY_PARAM_TEXTO_LIVRE,
} from '../../../util/constantesQueryParams'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useMemoObject } from '../../../hooks/useMemoObject'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { favoritoThunkCreators } from '../../../store/actions/favoritoActions'
import { TipoFavorito } from '../../ui-components/favorito/TipoFavorito'


const size = 40

export function ListaFatosContainer() {

  const dispatch = useTypedDispatch()
  const payload = useMemoObject({
    idsAtores: useQueryParamAsArrayOfString(QUERY_PARAM_ATORES),
    idsEntidades: useQueryParamAsArrayOfString(QUERY_PARAM_ENTIDADES),
    keywords: useQueryParamAsArrayOfString(QUERY_PARAM_KEYWORDS),
    temas: useQueryParamAsArrayOfString(QUERY_PARAM_TEMAS),
    textoLivre: useQueryParamAsString(QUERY_PARAM_TEXTO_LIVRE),
    tiposFato: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_TIPO_FATOS) as TipoFato[],
    tiposEvento: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_TIPO_EVENTOS) as TipoEvento[],
    tiposProcesso: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_TIPO_PROCESSOS) as TipoProcesso[],
    estadosProcesso: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_ESTADO_PROCESSO) as EstadoProcesso[],
    relatores: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_RELATOR),
    grausSigilo: useQueryParamAsArrayOfString(QUERY_PARAM_FILTRO_GRAUS_SIGILO) as GrauSigilo[],
    dtInicio: useQueryParamAsString(QUERY_PARAM_FILTRO_DATA_INICIO),
    dtFim: useQueryParamAsString(QUERY_PARAM_FILTRO_DATA_FIM),
    listaConectoresOu: useQueryParamAsArrayOfString(QUERY_PARAM_CONECTOR_OU) as TipoConectorOu[],
    ordenacao: useQueryParamAsString(QUERY_PARAM_ORDENACAO) as Ordenacao,
    size,
  })

  const { data: listaFatos, isLoading } = usePromiseEffect(recuperarFatos, [dispatch, payload])

  if (isLoading) {
    return <ListaFatosPlaceholderComponent />
  }
  return (
    <ListaFatosComponent
      fatos={listaFatos || []}
      mostrarOrdenacao
      mostrarTitulo
    />
  )
}

async function recuperarFatos(dispatch: TypedDispatch, payload: BuscarFatosResumidosEndpoint.Query) {
  try {

    let { data: listaFatos } = await dispatch(fatoThunkCreators.buscarFatosResumidos(payload))
    listaFatos = gerarListaFatosComUrlDestinoComQueryParams(
      listaFatos, payload.idsAtores, payload.idsEntidades, payload.keywords, payload.temas, payload.textoLivre,
    )
    const { data: favoritos } = await dispatch(favoritoThunkCreators.recuperarMeusFavoritos(TipoFavorito.FATO))

    return criaFatosComFavoritos(listaFatos, favoritos)
  } catch (e) {
    console.warn(e)
  }
  return []
}

export function criaFatosComFavoritos(fatos: ResumoFato[], favoritos: Favorito[]) {
  const fatosComFavoritos: FatoComFavorito[] = []

  fatos.forEach((resumoFato) => {
    const fatoComFavorito: FatoComFavorito = { resumoFato, isFavorito: false }
    const result = favoritos.filter(favorito => resumoFato.id === favorito.itemFavorito)
    if (result.length > 0) {
      fatoComFavorito.isFavorito = true
    }

    fatosComFavoritos.push(fatoComFavorito)
  })

  return fatosComFavoritos
}

function gerarListaFatosComUrlDestinoComQueryParams(
  listaFatos: ResumoFato[],
  idsAtores?: string[],
  idsEntidades?: string[],
  keywords?: string[],
  temas?: string[],
  textoLivre?: string,
) {
  const queryParams = {
    atores: idsAtores,
    entidades: idsEntidades,
    keywords,
    temas,
    textoLivre,
  }
  const stringfiedQueryParams = stringifyToQueryString(queryParams)
  return listaFatos.map(fato => ({
    ...fato,
    urlDestinoFato: `${fato.urlDestinoFato}?${stringfiedQueryParams}`,
  }))
}
