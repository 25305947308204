import React from 'react'
import { useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { modalExcluirFichaActionCreators } from '../modal/modal-excluir-ficha-container/modalExcluirFichaActions'
import { OpcoesFichaComponent } from '../../ui-components/ficha/OpcoesFichaComponent'


export function OpcoesFichaContainer({ className, fichaId, tituloFicha }: { className?: string, fichaId: number, tituloFicha: string }) {
  const dispatch = useTypedDispatch()

  const onExcluir = () => dispatch(modalExcluirFichaActionCreators.abrirModal(fichaId, tituloFicha))

  return <OpcoesFichaComponent onExcluir={onExcluir} className={className} />
}
