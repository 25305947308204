import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Switch, TextField } from '@material-ui/core'
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'
import { DatePicker } from '@material-ui/pickers'
import Divider from '@material-ui/core/Divider'
import moment, { Moment } from 'moment'
import { CriarCenarioEndpoint } from 'bff/src/main/controllers/cenario/cenariosEndpoints'
import { TipoFato } from 'bff/src/main/domain/TipoFato'
import { ModalComponent } from '../../ui-components/modal/ModalComponent'
import { HeaderCenariosComponent } from '../../ui-components/comum/HeaderCenariosComponent'
import { FieldErrorComponent } from '../../ui-components/comum/FieldErrorComponent'
import { ComboboxComponent, ComboboxComponentItemProps } from '../../ui-components/comum/ComboboxComponent'
import { ButtonComponent } from '../../ui-components/comum/ButtonComponent'


const useStyles = makeStyles({
  box: {
    width: '583px',
  },
  margin: {
    marginBottom: '32px',
  },
  labelCenario: {
    color: '#434343',
    fontWeight: 500,
    fontSize: '16px',
  },
  datepicker: {
    '&:first-child': {
      marginRight: '5px',
    },
    '& .MuiInputBase-input': {
      padding: '13px 12px',
    },
  },
  comboboxDatas: {
    width: '202px',
  },
  switchButton: {
    height: '100px',
  },
  cancelButtom: {
    marginRight: '16px',
  },
  mostrarTelaInicio: {
    display: 'flex',
    alignItems: 'center',
  },
  textoFatosRecentes: {
    marginBottom: 0,
  },
  espacamentoSwitch: {
    marginLeft: '20px',
  },
  alinharSwitch: {
    marginLeft: '-8px',
  },
  divider: {
    marginBottom: '32px',
    marginTop: '16px',
  },
})

interface Fields {
  nomeCenario: string
  periodoSelecionado: ComboboxComponentItemProps
  dataInicio: Moment
  visualizarTelaInicio: boolean
}

interface ModalCriarCenarioFormProps {
  idsStakeholders: string[]
  keywords: string[]
  temas: string[]
  tiposFato: string[]
  textoLivre?: string
  conectoresOu?: string[]
  onConfirm: (criarCenarioCommand: CriarCenarioEndpoint.Body) => void
  onCancel: () => void
}

export function ModalCriarCenarioForm(propsModal: ModalCriarCenarioFormProps) {
  const classes = useStyles()
  const {
    register, handleSubmit, errors, control, watch,
  } = useForm<Fields>({ defaultValues: {
    nomeCenario: '',
    periodoSelecionado: { label: 'Qualquer data', key: 'QUALQUER_DATA', value: moment('1970-01-01').format('YYYY-MM-DD') },
    dataInicio: moment(),
    visualizarTelaInicio: true,
  } })

  const watchPeriodoSelecionado = watch('periodoSelecionado')

  function realizarAcao(formValues: Fields) {
    const command: CriarCenarioEndpoint.Body = {
      nome: formValues.nomeCenario,
      stakeholders: propsModal.idsStakeholders,
      keywords: propsModal.keywords,
      temas: propsModal.temas,
      textoLivre: propsModal.textoLivre,
      conectoresOu: propsModal.conectoresOu,
      filtrosTipoFato: propsModal.tiposFato as TipoFato[],
      dataBuscaAPartirDe: tratarDataInicio(formValues.periodoSelecionado, formValues.dataInicio),
      visivelEmTelaInicial: formValues.visualizarTelaInicio,
    }
    propsModal.onConfirm(command)
  }

  return (
    <ModalComponent>
      <form className={classes.box} onSubmit={handleSubmit(realizarAcao)}>
        <HeaderCenariosComponent
          titulo="Novo Cenário"
          descricao="O Mapa monitorará os últimos acontecimentos relacionados ao novo cenário, com base na sua pesquisa."
        />
        <FieldErrorComponent className={classes.margin} error={!!errors.nomeCenario} message="Defina um nome para o cenário">
          <span className={classes.labelCenario}>Nome do cenário</span>
          <TextField
            inputRef={register({ required: true })}
            name="nomeCenario"
            id="nomeCenario"
            variant="outlined"
            fullWidth
          />
        </FieldErrorComponent>
        <div>
          <span className={classes.labelCenario}>Pesquisar fatos a partir de:</span>
          <Controller
            name="periodoSelecionado"
            control={control}
            render={(props: ControllerRenderProps) => (
              <ComboboxComponent
                className={classes.comboboxDatas}
                options={getDatasOptions()}
                selectedOption={props.value}
                onChange={selected => props.onChange(selected)}
              />
            )}
          />
          <br />
          {
            watchPeriodoSelecionado.key === 'SELECIONAR_DATA'
            && (
              <Controller
                name="dataInicio"
                control={control}
                render={(props: ControllerRenderProps) => (
                  <DatePicker
                    className={classes.datepicker}
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    autoOk
                    placeholder="__/__/____"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={props.value}
                    onChange={selected => props.onChange(selected)}
                  />
                )}
              />
            )
          }
        </div>
        <Divider className={classes.divider} />
        <div className={classes.margin}>
          <div className={classes.mostrarTelaInicio}>
            <div className={classes.alinharSwitch}>
              <Controller
                name="visualizarTelaInicio"
                control={control}
                type="checkbox"
                render={(props: ControllerRenderProps) => (
                  <Switch
                    checked={props.value}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    onChange={event => props.onChange(event.target.checked)}
                  />
                )}
              />
            </div>
            <div className={classes.espacamentoSwitch}>
              <span className={classes.labelCenario}>Apresentar na página Início</span>
              <p className={classes.textoFatosRecentes}>Os fatos mais recentes sobre o cenário serão apresentados na página inicial.</p>
            </div>
          </div>         
         
        </div>
        <ButtonComponent
          buttonText="Cancelar"
          buttonTypeColor="white"
          onClick={propsModal.onCancel}
          className={classes.cancelButtom}
        />
        <ButtonComponent
          buttonText="Criar cenário"
          buttonTypeColor="blue"
          type="submit"
        />
      </form>
    </ModalComponent>
  )
}

function getDatasOptions(): ComboboxComponentItemProps[] {
  return [
    { label: 'Qualquer data', key: 'QUALQUER_DATA', value: moment('1970-01-01').format('YYYY-MM-DD') },
    { label: 'Hoje', key: 'HOJE', value: moment().format('YYYY-MM-DD') },
    { label: 'Semana passada', key: 'SEMANA_PASSADA', value: moment().subtract(7, 'days').format('YYYY-MM-DD') },
    { label: 'Mês passado', key: 'MES_PASSADO', value: moment().subtract(1, 'months').format('YYYY-MM-01') },
    { label: 'Último ano', key: 'ULTIMO_ANO', value: moment().subtract(1, 'years').format('YYYY-01-01') },
    { label: 'Selecionar data', key: 'SELECIONAR_DATA', value: null },
  ]
}

function tratarDataInicio(periodoSelecionado: ComboboxComponentItemProps, dataInicio: moment.Moment): string {
  return periodoSelecionado.key === 'SELECIONAR_DATA' ? dataInicio.format('YYYY-MM-DD') : periodoSelecionado.value
}
