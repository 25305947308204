import React from 'react'

export function IconEditComponent({ fill = '#7E7E7E', height = '17', width = '17' }: { fill?: string, height?: string, width?: string }) {
  return (
       
    <svg
      height={height}
      width={width}
      version="1.1"
      id="Capa_1"
      x="5px"
      y="5px"
      viewBox="0 0 512.001 512.001"
    >
      <g>
        <g>
          <path
            fill={fill}
            d="M496.063,62.299l-46.396-46.4c-21.199-21.199-55.689-21.198-76.888,0C352.82,35.86,47.964,340.739,27.591,361.113c-2.17,2.17-3.624,5.054-4.142,7.875L0.251,494.268c-0.899,4.857,0.649,9.846,4.142,13.339c3.497,3.497,8.487,5.042,13.338,4.143L143,488.549c2.895-0.54,5.741-2.008,7.875-4.143l345.188-345.214C517.311,117.944,517.314,83.55,496.063,62.299z M33.721,478.276l14.033-75.784l61.746,61.75L33.721,478.276z M140.269,452.585L59.41,371.721L354.62,76.488l80.859,80.865L140.269,452.585zM474.85,117.979l-18.159,18.161l-80.859-80.865l18.159-18.161c9.501-9.502,24.96-9.503,34.463,0l46.396,46.4C484.375,93.039,484.375,108.453,474.85,117.979z"
          />
        </g>
      </g>

    </svg>
  )
}
