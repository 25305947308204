import Axios, { AxiosRequestConfig } from 'axios'
import QueryString from 'query-string'
import { BffRequest } from './BffRequest'
import { BaseParams, BaseQuery } from './ExpressRequestHandler'

export function callBff<Body, Params extends BaseParams, Query extends BaseQuery, ResponseBody>(
  request: BffRequest<Body, Params, Query, ResponseBody>,
) {
  const axiosRequestConfig = createAxiosRequestConfig(request)
  return Axios.request<ResponseBody>(axiosRequestConfig)
}

function createAxiosRequestConfig(request: BffRequest<any, any, any, any>): AxiosRequestConfig {
  return {
    timeout: 30000,
    method: request.endpoint.httpMethod,
    url: createRequestUrl(request),
    headers: request.headers,
    data: request.body,
  }
}

function createRequestUrl(request: BffRequest<any, any, any, any>) {
  const baseUrl = getBffBaseUrl()
  let { url } = request.endpoint
  url = replacePathVariables(url, request.params)
  url = addQueryParamsToUrl(url, request.queryParams)
  url = addSlashToUrlIfItDoesntHave(url)
  return baseUrl + url
}

function getBffBaseUrl() {
  const { protocol, host } = window.location
  const bffUrlPrefix = 'bff'
  return `${protocol}//${host}/${bffUrlPrefix}`
}

function replacePathVariables(url: string, params: BaseParams) {
  if (!params) return url
  let newUrl = url
  Object.keys(params).forEach((key) => {
    newUrl = newUrl.replace(new RegExp(`:${key}`, 'g'), `${params[key]}`)
  })
  return newUrl
}

function addQueryParamsToUrl(url: string, queryParams?: object) {
  const hasQueryParams = queryParams && Object.keys(queryParams).length > 0
  if (hasQueryParams) {
    const stringifiedQueryParams = QueryString.stringify(queryParams as object, { arrayFormat: 'bracket' })
    return `${url}?${stringifiedQueryParams}`
  }
  return url
}

function addSlashToUrlIfItDoesntHave(url: string) {
  if (url[0] !== '/') {
    return `/${url}`
  }
  return url
}
