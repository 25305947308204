import React from 'react'
import { TiposFatoCheckbox, TiposFatoCheckboxContainer } from '../../containers/cenario/TiposFatoCheckboxContainer'
import {
  OutrosFiltrosCheckboxContainer,
  OutrosFiltrosOptions,
} from '../../containers/busca/OutrosFiltrosCheckboxContainer'

interface BuscaAvancadaOutrosFiltrosComponentProps {
  onTiposFatoChange: (tipos: TiposFatoCheckbox[]) => any
  onOutrosFiltrosChange: (filtros: OutrosFiltrosOptions[]) => any
  tiposFatoSelecionados: TiposFatoCheckbox[]
  outrosFiltrosSelecionados: OutrosFiltrosOptions[]
}

export function BuscaAvancadaOutrosFiltrosComponent(props: BuscaAvancadaOutrosFiltrosComponentProps) {
  return (
    <>
      <TiposFatoCheckboxContainer
        onChange={selected => props.onTiposFatoChange(selected)}
        selected={props.tiposFatoSelecionados}
        direction="row"
      />
      <OutrosFiltrosCheckboxContainer
        onChange={selected => props.onOutrosFiltrosChange(selected)}
        selected={props.outrosFiltrosSelecionados}
        direction="row"
      />
    </>
  )
}
