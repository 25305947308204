import {
  BuscarProcessoEndpoint,
  BuscarRelatoresDeProcessosPresentesNaPesquisa,
} from 'bff/src/main/controllers/fato/processosEndpoints'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'

export const processoThunkCreators = {

  buscarProcessoPorCod: (codigo: number) => createBffRequestThunk({
    request: {
      endpoint: BuscarProcessoEndpoint.definition,
      params: { codigo },
    },
  }),

  buscarRelatoresDeProcessosPresentesNaPesquisa: (queryParams: BuscarRelatoresDeProcessosPresentesNaPesquisa.Query) => createBffRequestThunk({
    request: {
      endpoint: BuscarRelatoresDeProcessosPresentesNaPesquisa.definition,
      queryParams,
    },
  }),

}
