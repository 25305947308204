import { frontendRoutesUtil } from '../../util/frontendRoutesUtil'

export function marcarStakeholdersNoTexto(campo: string, texto: string, mapaStakeholders: string, listaIdsStakeholders: string[]) {
  if (!texto) {
    return ''
  }

  const mapa = JSON.parse(mapaStakeholders)
  let textoComMarcacoes = texto
  const listaStakeholdersOrdenada: { id: string, inicio: string, fim: string }[] = []

  const stakeholdersCampo = mapa[campo]
  if (!stakeholdersCampo) {
    return texto
  }

  listaIdsStakeholders.forEach((idStakeholder) => {
    const ocorrenciasStakeholder = stakeholdersCampo[idStakeholder]
    if (ocorrenciasStakeholder) {
      listaStakeholdersOrdenada.push(
        ...ocorrenciasStakeholder?.map(
          ocorrencia => ({ id: idStakeholder, inicio: ocorrencia.inicio, fim: ocorrencia.fim }),
        ),
      )
    }
  })

  listaStakeholdersOrdenada.sort((a, b) => Number(a.inicio) - Number(b.inicio))

  listaStakeholdersOrdenada.reverse()

  listaStakeholdersOrdenada.forEach((ocorrencia) => {
    textoComMarcacoes = adicionarStringNaPosicao(
      textoComMarcacoes,
      ocorrencia.fim,
      '</span>',
    )
    textoComMarcacoes = adicionarStringNaPosicao(
      textoComMarcacoes,
      ocorrencia.inicio,
      `<span class="detaqueStakeholders" data-url="${frontendRoutesUtil.montarUrlPerfilStakeholderSomenteComId(ocorrencia.id)}">`,
    )
  })

  return textoComMarcacoes
}

export function marcarStakeholdersNaLista(
  lista: string[],
  listaNomesStakeholdersEncontrados: { id: string, aliases: string[], tipoStakeholder: string }[],
) {
  lista.sort((a, b) => a.localeCompare(b))
  return lista.map((nomeLista) => {
    let nomeParaAdicionar = nomeLista
    listaNomesStakeholdersEncontrados.forEach((stakeholderEncontrado) => {
      const url = frontendRoutesUtil.montarUrlPerfilStakeholder(stakeholderEncontrado.id, stakeholderEncontrado.tipoStakeholder)
      stakeholderEncontrado.aliases.forEach((aliasDoStakeholderEncontrado) => {
        if (aliasDoStakeholderEncontrado && (aliasDoStakeholderEncontrado.toLocaleLowerCase() === nomeParaAdicionar.toLocaleLowerCase())) {
          nomeParaAdicionar = `<span class="detaqueStakeholders" data-url="${url}">${nomeParaAdicionar}</span>`
        }
      })
    })
    return nomeParaAdicionar
  })
}

export function marcarStakeholdersNaListaNovo(campo: string, lista: string[], mapaStakeholders: string, listaIdsStakeholders: string[]) {
  const textoComDestaque = marcarStakeholdersNoTexto(campo, lista.join(','), mapaStakeholders, listaIdsStakeholders)
  return textoComDestaque.split(',')
}

function adicionarStringNaPosicao(stringCompleta, index, stringParaAdicionar) {
  if (index > 0) {
    return stringCompleta.substring(0, index) + stringParaAdicionar + stringCompleta.substring(index)
  }
  return stringParaAdicionar + stringCompleta
}
