import { fatosApi } from '../../../api/fatosApi'
import { dateUtil } from '../../../util/dateUtil'
import { recuperarProcessosRelacionadosDoFato } from '../processo/recuperarProcessosRelacionadosDoFato'
import { montarResumoProcesso } from '../processo/montarResumoProcesso'
import { marcarStakeholdersNoTexto } from '../../stakeholder/highlightNosStakeholders'
import { NoticiaClipping } from '../../../domain/NoticiaClipping'

export async function recuperarNoticiaClippingPorId(id: string) {
  const { body: respostaIndiceNoticias } = await fatosApi.buscarFatosPorId(id)
  const noticiaClipping = respostaIndiceNoticias.hits.hits[0]
  const processosRelacionados = await recuperarProcessosRelacionadosDoFato(noticiaClipping)
  return mapearNoticia(noticiaClipping, processosRelacionados)
}

function mapearNoticia(noticiaClipping, processosRelacionados: any[]): NoticiaClipping {
  return {
    id: noticiaClipping._id,
    titulo: noticiaClipping._source.titulo,
    tipoFato: noticiaClipping._source.tipo_fato,
    urlFonte: noticiaClipping._source.url_original,
    nomeAtor: noticiaClipping._source.autor,
    urlAtor: '/',
    texto: marcarStakeholdersNoTexto(
      'texto', 
      noticiaClipping._source.texto, noticiaClipping._source.marcacoes_stakeholders_relacionados,
      noticiaClipping._source.stakeholders_relacionados,
    ),
    nomeEntidade: noticiaClipping._source.veiculo_nome,
    urlEntidade: '/',
    dataHora: `Publicada em ${dateUtil.formatarDataHora(noticiaClipping._source.data_publicacao)}`,
    stakeholdersIds: noticiaClipping._source.stakeholders_relacionados,
    fatosRelacionados: processosRelacionados.map(montarResumoProcesso),
    marcacoesStakeholders: noticiaClipping._source.marcacoes_stakeholders_relacionados,
  }
}
