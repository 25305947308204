import React from 'react'
import { useHistory } from 'react-router-dom'
import { TipoTrechoFicha } from 'bff/src/main/domain/TipoTrechoFicha'
import { frontendRoutesUtil } from 'bff/src/main/util/frontendRoutesUtil'
import { BotaoAcoesTrechoFichaComponent } from '../../ui-components/ficha/BotaoAcoesTrechoFichaComponent'
import { useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { fichaThunkCreators } from '../../../store/actions/fichaActions'
import { useSpinningPromise } from '../../../infra/libs/react-swearing/SpinnerScreenProvider'
import { modalCenarioCriadoActions } from '../modal/ModalCenarioCriadoContainer'

interface BotaoAcoesTrechoFichaContainerProps {
  trechoFichaId: number
  tipoTrecho: TipoTrechoFicha
  fatoCitadoId?: string | number
  fichaId: number
}

export function BotaoAcoesTrechoFichaContainer({ trechoFichaId, tipoTrecho, fatoCitadoId, fichaId } : BotaoAcoesTrechoFichaContainerProps) {
  const dispatch = useTypedDispatch()
  const history = useHistory()

  const { run: onDelete } = useSpinningPromise(() => dispatch(fichaThunkCreators.removerTrechoFicha(fichaId, trechoFichaId)))
  const { run: onAlterarTipoTrechoTextual } = useSpinningPromise((tipoTrechoSelecionado: TipoTrechoFicha) => dispatch(
    fichaThunkCreators.alterarTipoTrechoTextual(fichaId, trechoFichaId, tipoTrechoSelecionado),
  ))

  function onRedirecionarParaFato() {
    history.push(frontendRoutesUtil.montarUrlDetalhamentoFatoPorId(fatoCitadoId?.toString() ?? '/'))
    dispatch(modalCenarioCriadoActions.fecharModal())
  }

  return (
    <BotaoAcoesTrechoFichaComponent
      onDelete={onDelete}
      tipoTrecho={tipoTrecho}
      onAlterarTipoTrechoTextual={onAlterarTipoTrechoTextual}
      onRedirecionarParaFato={onRedirecionarParaFato}
    />
  )
}

