import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
  animacao: {
    background: 'linear-gradient(45deg, #c7c7c7, #8a8a8a26, #cbcbcb24)',
    backgroundSize: '400% 400%',
    animation: '$gradient 3s ease infinite',
  },
  root: {
    display: 'flex',
    marginTop: '27px',
    flexDirection: 'column',
  },
  stakeholder: {
    width: '600px',
    marginRight: '20px',
  },
  fotoNome: {
    display: 'flex',
  },
  linha: {
    height: '25px',
    marginRight: '15px',
    marginBottom: '15px',
    width: '90%',
    borderRadius: '5px',
  },
  usuario: {
    borderRadius: '100%',
    width: '60px',
    padding: '60px 0 0 0',
  },
  nome: {
    height: '20px',
    margin: '20px 0 20px 10px',
    width: '50%',
  },
  cargo: {
    height: '20px',
    margin: '10px 0 50px 0',
    width: '60%',
  },
})

export function StakeholdersRelacionadosPlaceholderComponent({ qntFiguras = 5 }: { qntFiguras?: number }) {
  const classes = useStyles()
  const placeholders:any[] = []
  for (let i = 0; i < qntFiguras; i += 1) {
    placeholders.push(renderStakeholderPlaceholder)
  }
  return (
    <div className={classes.root}>
      {placeholders.map((item, index) => item(index, classes))}
    </div>
  )
}

function renderStakeholderPlaceholder(index: number, classes) {
  return (
    <div className={classes.stakeholder} key={index}>
      <div className={classes.fotoNome}>
        <div className={`${classes.usuario} ${classes.animacao}`} />
        <div className={`${classes.linha} ${classes.nome} ${classes.animacao}`} />
      </div>
      <div className={`${classes.linha} ${classes.cargo} ${classes.animacao}`} />
    </div>
  )
}
