import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  tab: {
    width: '100%',
  },
})


export function TabsBodyComponent({ children }: { children: ReactNode }) {
  const classes = useStyles()
  return (
    <div className={classes.tab}>
      {
        children
      }
    </div>
  )
}
