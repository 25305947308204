import React from 'react'
import { isAutoridadeTCU } from 'bff/src/main/util/stakeholdersUtil'
import { ImagemComBordaComponent } from '../../ui-components/comum/ImagemComBordaComponent'

interface ImagemComBordaContainerProps {
  urlImagem: string
  papel?: string
  stakeholderInterno: boolean
}

export function ImagemComBordaContainer({ urlImagem, papel, stakeholderInterno }: ImagemComBordaContainerProps) {
  let tipoStakeholder = 'EXTERNO'
  if (stakeholderInterno) {
    tipoStakeholder = 'INTERNO'
  }
  if (papel && isAutoridadeTCU(stakeholderInterno, papel)) {
    tipoStakeholder = 'AUTORIDADE_TCU'
  }
  return <ImagemComBordaComponent urlImagem={urlImagem} tipoStakeholder={tipoStakeholder} />
}
