import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, FormControl, Menu } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import FormLabel from '@material-ui/core/FormLabel'
import { DatePicker } from '@material-ui/pickers'
import DateIcon from '@material-ui/icons/Today'
import { Moment } from 'moment'

const useStyles = makeStyles({
  filter: {
    display: 'flex',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#919191',
    },
  },
  filterTitle: {
    marginBottom: '16px',
    left: '0px',
    padding: '0',
    color: '#231f20',
    backgroundColor: '#fff',
    fontSize: '16px',
    fontWeight: 500,
  },
  select: {
    border: '1px solid #919191',
    borderRadius: '4px',
    textTransform: 'capitalize',
    justifyContent: 'flex-start',
    borderColor: '#919191',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 10px',
    width: '100%',
    fontSize: '16px',
    fontWeight: 400,
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '45%',
      right: '10px',
      width: '0',
      height: '0',
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderTop: '5px solid #757575',
    },
  },
  selectIcon: {
    marginRight: '5px',
    fontSize: '28px',
  },
  option: {
    padding: '10px',
  },
  datepickers: {
    display: 'flex',
    marginTop: '18px',

  },
  datepicker: {
    '&:first-child': {
      marginRight: '5px',
    },
    '& .MuiInputBase-input': {
      padding: '13px 12px',
    },
  },
  menu: {
    '& .MuiPaper-root': {
      width: '310px',
      zIndex: '999',
    },
  },
})

export interface FiltroData {
  key: string
  label: string
  dtInicio?: Moment | null
  dtFim?: Moment | null
}

interface PeriodoFilterProps {
  onChange?: (dtInicioNewValue?: Moment, dtFimNewValue?: Moment, periodo?: string) => void
  dtInicio?: Moment
  dtFim?: Moment
  options: FiltroData[]
  label?: string
}

export function PeriodoFilterNovoComponent({
  onChange, dtInicio, dtFim, options, label, 
}: PeriodoFilterProps) {

  const classes = useStyles()

  const showDatepickers = label === 'Selecionar data'
  const [currentDtInicioValue, setCurrentDtInicioValue] = useState(dtInicio || null)
  const [currentDtFimValue, setCurrentDtFimValue] = useState(dtFim || null)
  const [anchorEl, setAnchorEl] = useState(null)

  function handleClose(event) {
    setAnchorEl(null)
    if (event.currentTarget.attributes.value !== undefined) {
      setCurrentDtInicioValue(null)
      setCurrentDtFimValue(null)
      const selectedOption = options.find(option => option.key === event.currentTarget.attributes.value.value)
      setCurrentDtInicioValue(null)
      setCurrentDtFimValue(null)
      onChange?.(selectedOption?.dtInicio as Moment | undefined, selectedOption?.dtFim as Moment | undefined, selectedOption?.key)
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  function onDtInicioChange(date: Moment) {
    setCurrentDtInicioValue(date)
    if (currentDtFimValue && date.isAfter(currentDtFimValue)) {
      setCurrentDtFimValue(null)
    }
    if (currentDtFimValue && (date.isBefore(currentDtFimValue) || date.isSame(currentDtFimValue))) {
      onChange?.(date, currentDtFimValue)
    }
  }

  function onDtFimChange(date: Moment) {
    setCurrentDtFimValue(date)
    if (currentDtInicioValue && date.isBefore(currentDtInicioValue)) {
      setCurrentDtInicioValue(null)
    }
    if (currentDtInicioValue && (date.isAfter(currentDtInicioValue) || date.isSame(currentDtInicioValue))) {
      onChange?.(currentDtInicioValue, date)
    }
  }

  return (
    <FormControl classes={{ root: classes.filter }}>
      <FormLabel classes={{ root: classes.filterTitle }}>
        Período
      </FormLabel>
      <div>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={classes.select}>
          <DateIcon className={classes.selectIcon} htmlColor="#808080" />
          {label}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.menu}
        >
          {
            options.map(option => <MenuItem key={option.key} onClick={handleClose} value={option.key}>{option.label}</MenuItem>)
          }
        </Menu>
      </div>
      {renderDatepickers(classes, currentDtInicioValue, onDtInicioChange, currentDtFimValue, onDtFimChange, showDatepickers)}
    </FormControl>
  )
}

function renderDatepickers(classes, currentDtInicioValue, onDtInicioChange, currentDtFimValue, onDtFimChange, showDatepickers) {
  if (!showDatepickers) {
    return null
  }
  return (
    <div className={classes.datepickers}>
      <DatePicker
        className={classes.datepicker}
        inputVariant="outlined"
        format="DD/MM/YYYY"
        autoOk
        label="De"
        placeholder="__/__/____"
        InputLabelProps={{
          shrink: true,
        }}
        value={currentDtInicioValue}
        onChange={onDtInicioChange}
      />
      <DatePicker
        className={classes.datepicker}
        inputVariant="outlined"
        format="DD/MM/YYYY"
        autoOk
        label="Até"
        placeholder="__/__/____"
        InputLabelProps={{
          shrink: true,
        }}
        value={currentDtFimValue}
        onChange={onDtFimChange}
      />
    </div>
  )
}
