import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button } from '@material-ui/core'
import { MapaColor } from '../../../style/theme'

const useStyles = makeStyles({
  button: {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 500,
    padding: '9px 20px',
  },
  blue: {
    backgroundColor: MapaColor.primary,
    color: MapaColor.neutralLightest,
    '&:hover': {
      backgroundColor: MapaColor.primaryMedium,
    },
  },
  red: {
    backgroundColor: MapaColor.dangerMedium,
    color: MapaColor.neutralLightest,
    '&:hover': {
      color: MapaColor.neutralLightest,
      backgroundColor: MapaColor.dangerDark,
    },
  },
  white: {
    border: 'solid 1px',
    borderColor: MapaColor.neutralMedium,
    color: MapaColor.neutralDark,
    backgroundColor: MapaColor.neutralLightest,
    '&:hover': {
      color: MapaColor.neutralDarkest,
      backgroundColor: MapaColor.neutralLight,
    },
  },
  disabled: {
    opacity: '40%',
    color: MapaColor.neutralLightest,
  },
  small: {
    fontSize: '14px',
    padding: '5px 13px',
  },
})

interface ButtonComponentProps {
  buttonText: ReactNode
  disabled?: boolean
  buttonTypeColor: 'blue' | 'red' | 'white'
  onClick?: (event?) => void
  type?: 'submit'
  className?: string
  size?: 'small'
}

export function ButtonComponent({
  buttonText, disabled, buttonTypeColor, onClick, type, className, size,
}: ButtonComponentProps) {
  const classes = useStyles()

  function handleClick(event) {
    if (onClick) {
      onClick(event)
    }
  }

  return (
    <Button 
      disabled={disabled} 
      variant="outlined"
      size={size}
      onClick={handleClick} 
      className={`${className}
      ${classes.button} 
      ${classes[buttonTypeColor]}
      ${size ? classes.small : ''} 
      ${disabled ? classes.disabled : ''}`}
      type={type}
    >
      {buttonText}
    </Button>
  )
}
