import React from 'react'
import { IconButton, makeStyles, Popover } from '@material-ui/core'
import { TIPOS_TRECHO_FICHA, TipoTrechoFicha } from 'bff/src/main/domain/TipoTrechoFicha'
import { IconPlusComponent } from '../icons/IconPlusComponent'
import { IconTituloTextoComponent } from '../icons/IconTituloTextoComponent'
import { IconTextoAlignComponent } from '../icons/IconTextoAlignComponent'

const useStyles = makeStyles({
  box: {
    display: 'flex',
    justifyContent: 'center',
  },
  acoesBox: {
    backgroundColor: '#434343',
  },
})

export function BotaoAdicionarTrechoNoDocumentoFichaComponent({ onClick }: { onClick: (tipoTrecho: TipoTrechoFicha) => void }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickAcao = (tipoTrecho: TipoTrechoFicha) => {
    onClick(tipoTrecho)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simplee-popover' : undefined
  return (
    <div className={classes.box}>

      <IconButton onClick={handleClick}>
        <IconPlusComponent fill="#c4c4c4" width="32" height="32" />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {renderAcoes(classes, handleClickAcao)}
      </Popover>
    </div>
  )
}

function renderAcoes(classes, handleClickAcao) {
  return (
    <div className={classes.acoesBox}>
      <IconButton onClick={() => handleClickAcao(TIPOS_TRECHO_FICHA.TITULO.id)}>
        <IconTituloTextoComponent fill="#fff" width="24" height="24" />
      </IconButton>
      <IconButton onClick={() => handleClickAcao(TIPOS_TRECHO_FICHA.SUBTITULO.id)}>
        <IconTituloTextoComponent fill="#fff" width="18" height="18" />
      </IconButton>
      <IconButton onClick={() => handleClickAcao(TIPOS_TRECHO_FICHA.PARAGRAFO.id)}>
        <IconTextoAlignComponent fill="#fff" width="24" height="24" />
      </IconButton>
    </div>
  )
}

