import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  boxNaoEncontrados: {
    width: '100%',
    height: '328px',
    boxShadow: '0px 1px 3px #00000040;',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: '15px',
    color: '#434343',
  },
  fonteIcon: {
    fontSize: '113px',
    color: '#c1cfef',
  },
})

export function FatosNaoEncontradosComponent() {
  const classes = useStyles()
  return (
    <div className={classes.boxNaoEncontrados}>
      <div className="col-md-12">
        <i className={`far fa-newspaper ${classes.fonteIcon}`} />
        <h4>Não encontramos fatos relacionados com a sua busca</h4>
        <span>Verifique os termos digitados ou os filtros selecionados e refaça a busca.</span>
      </div>
    </div>
  )
}
