import { EnumObjectWithId } from '../util/EnumObject'

export type TipoConectorOu = 'ATOR' | 'ENTIDADE' | 'TEMA' | 'KEYWORD'

export const TIPOS_CONECTORES_OU: EnumObjectWithId<TipoConectorOu, {}> = {
  ATOR: {
    id: 'ATOR',
  },
  ENTIDADE: {
    id: 'ENTIDADE',
  },
  TEMA: {
    id: 'TEMA',
  },
  KEYWORD: {
    id: 'KEYWORD',
  },
}
