import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import logo from '../../../assets/logo.png'
import { ButtonComponent } from '../comum/ButtonComponent'

const useStyles = makeStyles({
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingTop: '120px',
  },
  text: {
    fontSize: '30px',
    display: 'block',
    marginBottom: '15px',
    color: '#707070',
    '@media screen and (max-width:460px)': {
      fontSize: '25px',
    },
  },
  erro: {
    borderRight: '1px solid #ccc',
    marginRight: '15px',
    paddingRight: '15px',
  },
  fonteerro: {
    '@media screen and (max-width:460px)': {
      fontSize: '70px',
    },
    fontSize: '90px',
    color: '#707070',
  },
  msgerro: {
    display: 'block',
    textAlign: 'center',
  },
  image: {
    display: 'block',
    width: '250px',
    margin: '0 auto',
    marginTop: '72px',
    '@media screen and (max-width:460px)': {
      width: '200px',
    },
  },
  inicioButtom: {
    marginRight: '16px',
  },
})

export interface PaginaDeErroHttpProps {
  httpStatus?: number
  mensagemDeErro: string
}

export function PaginaDeErroHttpComponent({ httpStatus, mensagemDeErro }: PaginaDeErroHttpProps) {
  const history = useHistory()
  const classes = useStyles()
  return (
    <div>
      <div className={classes.page}>

        {
          httpStatus ? (
            <div className={classes.erro}>
              <span className={classes.fonteerro}>{httpStatus}</span>
            </div>
          ) : null 
        }
        
        <div className={classes.msgerro}>
          <span className={classes.text}>{mensagemDeErro}</span>
          <ButtonComponent
            buttonText="Ir para página inicial"
            buttonTypeColor="blue"
            onClick={() => history.push('/')}
            className={classes.inicioButtom}
          />
          <ButtonComponent
            buttonText="Voltar para a página anterior"
            buttonTypeColor="white"
            onClick={history.goBack}
          />
        </div>
      </div>
      <img className={classes.image} src={logo} alt="" />
    </div>
  )
}
