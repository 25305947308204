import { AuthParams } from './AuthParams'
import { requestScope } from '../express/requestScope'
import { authJwtApi } from './authJwtApi'


export async function gerarTokenDeSistema(codRecursoComputacional?: string): Promise<AuthParams> {
  const authParams = requestScope.getAuthParams()
  const axiosResponse = await authJwtApi.gerarTokenDeSistema(
    authParams,
    codRecursoComputacional,
  )
  return axiosResponse.data
}
