import { useState } from 'react'
import { TipoSugestaoDeBusca } from 'bff/src/main/domain/TipoSugestaoDeBusca'
import { BuscaAutocompleteInputOption } from '../../ui-components/busca/BuscaAutocompleteOptionComponent'
import { TypedDispatch, useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { sugestoesDeBuscaThunkCreators } from '../../../store/actions/sugestoesDeBuscaActions'

export interface AtorAutocompleteProps {
  onAtorWordChange: (atorWord: string) => any
  atoresOptions: BuscaAutocompleteInputOption[]
  onAtoresChange: (selectedOptions: BuscaAutocompleteInputOption[]) => any
  atoresSelecionados: BuscaAutocompleteInputOption[]
  conectorOuAtores: boolean
  onConectorOuAtoresChange: (value: boolean) => any
}

export interface EntidadeAutocompleteProps {
  onEntidadeWordChange: (entidadeWord: string) => any
  entidadesOptions: BuscaAutocompleteInputOption[]
  onEntidadesChange: (selectedOptions: BuscaAutocompleteInputOption[]) => any
  entidadesSelecionadas: BuscaAutocompleteInputOption[]
  conectorOuEntidades: boolean
  onConectorOuEntidadesChange: (value: boolean) => any
}

export interface TemaAutocompleteProps {
  onTemaWordChange: (temaWord: string) => any
  temasOptions: BuscaAutocompleteInputOption[]
  onTemasChange: (selectedOptions: BuscaAutocompleteInputOption[]) => any
  temasSelecionados: BuscaAutocompleteInputOption[]
  conectorOuTemas: boolean
  onConectorOuTemasChange: (value: boolean) => any
}

export interface KeywordSearchProps {
  onKeywordsChange: (newString: string) => any
  keywordsSelecionadas: string
  conectorOuKeywords: boolean
  onConectorOuKeywordsChange: (value: boolean) => any
}


export function useBuscaAutocomplete() {
  const dispatch = useTypedDispatch()

  // ator
  const [atorWord, setAtorWord] = useState('')
  const [atoresSelecionados, setAtoresSelecionados] = useState<BuscaAutocompleteInputOption[]>([])
  const [conectorOuAtores, setConectorOuAtores] = useState(false)
  const { data: sugestoesAtores } = usePromiseEffect(pegarSugestoes, [dispatch, atorWord, 'ATOR'])

  // entidade
  const [entidadeWord, setEntidadeWord] = useState('')
  const [entidadesSelecionadas, setEntidadesSelecionadas] = useState<BuscaAutocompleteInputOption[]>([])
  const [conectorOuEntidades, setConectorOuEntidades] = useState(false)
  const { data: sugestoesEntidades } = usePromiseEffect(pegarSugestoes, [dispatch, entidadeWord, 'ENTIDADE'])

  // tema
  const [temaWord, setTemaWord] = useState('')
  const [temasSelecionados, setTemasSelecionados] = useState<BuscaAutocompleteInputOption[]>([])
  const [conectorOuTemas, setConectorOuTemas] = useState(false)
  const { data: sugestoesTemas } = usePromiseEffect(pegarSugestoes, [dispatch, temaWord, 'TEMA'])

  // keyword
  const [keywords, setKeywords] = useState('')
  const [conectorOuKeywords, setConectorOuKeywords] = useState(false)

  const atorProps = {
    atoresSelecionados,
    atoresOptions: sugestoesAtores ?? [],
    onAtoresChange: setAtoresSelecionados,
    onAtorWordChange: setAtorWord,
    conectorOuAtores,
    onConectorOuAtoresChange: setConectorOuAtores,
  }
  const entidadeProps = {
    entidadesSelecionadas,
    entidadesOptions: sugestoesEntidades ?? [],
    onEntidadesChange: setEntidadesSelecionadas,
    onEntidadeWordChange: setEntidadeWord,
    conectorOuEntidades,
    onConectorOuEntidadesChange: setConectorOuEntidades,
  }
  const temaProps = {
    temasSelecionados,
    temasOptions: sugestoesTemas ?? [],
    onTemasChange: setTemasSelecionados,
    onTemaWordChange: setTemaWord,
    conectorOuTemas,
    onConectorOuTemasChange: setConectorOuTemas,
  }
  const keywordProps = {
    keywordsSelecionadas: keywords,
    onKeywordsChange: setKeywords,
    conectorOuKeywords,
    onConectorOuKeywordsChange: setConectorOuKeywords,
  }

  return {
    atorProps,
    entidadeProps,
    temaProps,
    keywordProps,
  }
}

async function pegarSugestoes(dispatch: TypedDispatch, text: string, tipoPesquisa?: TipoSugestaoDeBusca) {
  if (text) {
    try {
      const { data: sugestoes } = await dispatch(sugestoesDeBuscaThunkCreators.buscarSugestoesDeBusca(text, tipoPesquisa))
      return sugestoes
    } catch (e) { console.warn(e) }
  }
  return []
}
