export function ordernarAlfabeticamenteListaString(lista: string[]) {
  return lista.sort(ordemAlfabeticamenteListaString)
}

export function ordernarListaAlfabeticamentePorPropriedade(lista: any[], propriedade: string) {
  return lista.sort(ordemAlfabeticaPorPropriedade(propriedade))
}

function ordemAlfabeticamenteListaString(a, b) {
  return a.localeCompare(b)
}
function ordemAlfabeticaPorPropriedade(propriedade) {
  return function compare(a, b) {
    return a[propriedade].localeCompare(b[propriedade])
  }
}
