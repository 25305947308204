import { ActionCreator } from './defineActionCreators'
import { createReducer } from './createReducer'


// TODO: fazer documentação
export function createCounterReducer(actionCreators: ActionCreator[], initialState: number = 0) {
  return createReducer(initialState)(handle => (
    actionCreators.map(ac => (
      handle(ac, state => state + 1)
    ))
  ))
}
