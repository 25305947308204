import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import { Grow } from '@material-ui/core'
import { AppTheme, MapaColor } from '../../../style/theme'


const useStyles = makeStyles((theme: AppTheme) => ({
  box: (props: OnBoardingStepComponentProps) => ({
    position: 'absolute',
    top: '0px',
    right: props.position === 'right' ? '-457px' : 'auto',
    left: props.position === 'left' ? '-457px' : 'auto',
    background: MapaColor.primary,
    color: MapaColor.neutralLightest,
    borderBottomRightRadius: props.position === 'right' ? '140px' : '0px',
    borderBottomLeftRadius: props.position === 'left' ? '140px' : '0px',
    padding: '32px 54px 36px 54px',
    width: '457px',
    zIndex: theme.zIndex.tooltip,
    '@media screen and (max-width:567px)': {
      display: 'none',
    },
    '@media screen and (max-width:768px)': {
      display: 'none',
    },
  }),
  title: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '17px',
  },
  text: {
    fontSize: '16px',
    marginBottom: '44px',
    fontWeight: 300,
  },
  control: (props: OnBoardingStepComponentProps) => ({
    textAlign: props.position === 'left' ? 'right' : 'left',
  }),
  button: {
    color: MapaColor.neutralLightest,
    textTransform: 'capitalize',
    borderColor: MapaColor.neutralLightest,
    marginRight: '10px',
  },
  steps: {
    marginLeft: '35px',
    fontSize: '14px',
  },
}))

export interface OnBoardingStepComponentProps {
  children: any
  visible?: boolean
  position: 'left' | 'right'
  title: string
  text: string
  labelButtonNext: string
  onButtonNext: () => void
  labelButtonBack?: string
  onButtonBack?: () => void
  labelStep?: string
  totalSteps: number
  currentStep: number
}

export function OnBoardingStepComponent(props: OnBoardingStepComponentProps) {
  const classes = useStyles(props)
  return (
    <>
      {props.children}
      <Grow
        in={props.visible}
        // @ts-ignore
        style={props.position === 'left' ? { transformOrigin: 'right top' } : { transformOrigin: 'left top' }}
        timeout={props.visible ? 666 : 100}
      >
        <div className={classes.box}>
          <p className={classes.title}>{props.title}</p>
          <p className={classes.text}>{props.text}</p>
          <div className={classes.control}>
            {props.labelButtonBack && (
              <Button
                className={classes.button}
                onClick={props.onButtonBack}
              >
                {props.labelButtonBack}
              </Button>
            )}
            <Button
              className={classes.button}
              variant="outlined"
              onClick={props.onButtonNext}
            >
              {props.labelButtonNext}
            </Button>
            <span className={classes.steps}>
              {props.totalSteps > 1 && `${props.labelStep ?? ''} ${props.currentStep} de ${props.totalSteps}`}
            </span>
          </div>
        </div>
      </Grow>
    </>
  )
}
