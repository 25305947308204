import React from 'react'
import { ListaFatosCenarioContainer } from '../fato/ListaFatosCenarioContainer'
import { useParamAsInt } from '../../../infra/libs/react-location-hooks/routerHooks'
import { cenarioActionCreators, cenarioThunkCreators } from '../../../store/actions/cenarioActions'
import { PaginaDeErroHttpComponent } from '../../ui-components/erro/PaginaDeErroHttpComponent'
import { ListaFatosPlaceholderComponent } from '../../ui-components/fato/ListaFatosPlaceholderComponent'
import { ConfiguracaoCenarioContainer } from './ConfiguracaoCenarioContainer'
import { StakeholdersTweetsContainer } from '../stakeholder/StakeholdersTweetsContainer'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { createCounterReducer } from '../../../infra/libs/redux-hardtyped/createCounterReducer'
import { useSwan } from '../../../infra/libs/redux-swans/useSwan'
import { OnBoardingTelaCenario } from '../onboarding/OnBoardingTelaCenario'
import { ContadorQuantidadeFatosCenarioContainer } from './ContadorQuantidadeFatosCenarioContainer'

const reducer = createCounterReducer([
  cenarioActionCreators.cenarioArquivado,
  cenarioActionCreators.cenarioDesarquivado,
])

export function CenarioContainer() {
  const dispatch = useTypedDispatch()
  const counter = useSwan(reducer)
  const idCenario = useParamAsInt('id')
  const { data: cenario, isLoading } = usePromiseEffect(recuperarCenario, [dispatch, idCenario], [counter])

  if (isLoading) {
    return <ListaFatosPlaceholderComponent />
  }

  if (!cenario?.nome) {
    return (
      <PaginaDeErroHttpComponent
        mensagemDeErro="Tivemos um problema para exibir o cenário"
      />
    )
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-7">
          <ListaFatosCenarioContainer cenario={cenario} />
        </div>
        <div className="col-md-4 offset-md-1">
          <OnBoardingTelaCenario.Step1>
            <ConfiguracaoCenarioContainer cenario={cenario} />
            <ContadorQuantidadeFatosCenarioContainer cenario={cenario} />
            <div style={{ marginTop: '40px' }}>
              <StakeholdersTweetsContainer idsStakeholders={cenario.consulta.stakeholders ?? []} />
            </div>
          </OnBoardingTelaCenario.Step1>
        </div>
      </div>
    </div>
  )
}

async function recuperarCenario(dispatch: TypedDispatch, idCenario: number) {
  const { data: cenario } = await dispatch(cenarioThunkCreators.buscarCenarioPorId(idCenario))
  return cenario
}
