import { stakeholdersApi } from '../../api/stakeholdersApi'
import { fotoUtil } from '../../util/fotoUtil'
import { frontendRoutesUtil } from '../../util/frontendRoutesUtil'
import { Entidade } from '../../domain/Entidade'
import { TIPOS_STAKEHOLDER } from '../../domain/TipoStakeholder'


export async function recuperarEntidadePorId(id: string) {
  const { body: respostaIndice } = await stakeholdersApi.buscarStakeholdersPorIds([id])
  const entidade = respostaIndice.hits.hits[0]
  const stakeholdersRelacionados: any[] = []
  const atoresRelacionados = entidade._source.atores_relacionados
  if (atoresRelacionados && atoresRelacionados.length > 0) {
    const { body: respotaIndiceStakeholders } = await stakeholdersApi.buscarStakeholdersPorFonteDadosECodigo('Atores Relaciona', atoresRelacionados)
    stakeholdersRelacionados.push(...respotaIndiceStakeholders.hits.hits)
  }
  return mapearEntidade(entidade, stakeholdersRelacionados)
}

function mapearEntidade(entidade, stakeholdersRelacionados): Entidade {
  return {
    id: entidade._id,
    tipo: TIPOS_STAKEHOLDER.ENTIDADE.id,
    nomeApresentacao: entidade._source.nome_completo,
    nomeCompleto: entidade._source.nome_completo,
    sigla: entidade._source.nome,
    papel: 'Entidade',
    urlDestino: frontendRoutesUtil.montarUrlPerfilStakeholder(entidade._id, entidade._source.tipo_stakeholder),
    urlImagem: fotoUtil.tratarFotoEntidade(entidade),
    stakeholderInterno: false,
    perfilTwitter: entidade._source.twitter,
    aliases: entidade._source.aliases,
    idsStakeholdersRelacionados: stakeholdersRelacionados.map(stakeholder => stakeholder._id),
  }
}
