import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { SpinnerOverlayComponent } from './SpinnerOverlayComponent'
import { AppTheme } from '../../../style/theme'


const useStyles = makeStyles((theme: AppTheme) => ({
  screen: {
    backgroundColor: 'transparent',
    zIndex: theme.zIndex.spinner,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
}))

export function SpinnerScreenComponent() {
  const classes = useStyles()
  return (
    <div className={classes.screen}>
      <SpinnerOverlayComponent />
    </div>
  )
}
