import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { MapaColor } from '../../../style/theme'

const useStyles = makeStyles({
  breadcrumb: {
    color: MapaColor.neutralDark,
    fontWeight: 500,
    fontSize: '16px',
  },
  tituloCenario: {
    color: MapaColor.neutralDarkest,
    fontWeight: 500,
    fontSize: '24px',
  },
})

export function HeaderCenariosComponent(
  { className, breadcrumb, titulo, descricao }: { className?: string, breadcrumb?: string, titulo?: string, descricao?: string },
) {
  const classes = useStyles()
  return (
    <div className={className}>
      {
        breadcrumb ? <h4 className={classes.breadcrumb}>{breadcrumb}</h4> : null  
      }
      {
        titulo ? <h2 className={classes.tituloCenario}>{titulo}</h2> : null
      }
      {
        descricao ? <p>{descricao}</p> : null
      }
    </div>
  )
}
