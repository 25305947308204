import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/styles'
import { ModalComponent } from './ModalComponent'
import { ButtonComponent } from '../comum/ButtonComponent'

const useStyles = makeStyles({
  box: {
    width: '583px',
    textAlign: 'center',
    '@media screen and (max-width:567px)': {
      width: '100%',
    },
  },
  red: {
    '& $iconModal': {
      background: '#f5d8d8',
    },
  },
  blue: {
    '& $iconModal': {
      background: '#d9e3fa',
    },
  },
  iconModal: {
    borderRadius: '60px',
    padding: '15px',
    position: 'absolute',
  },
  title: {
    fontSize: '32px',
    color: '#434343',
  },
  text: {
    fontSize: '18px',
    color: '#7e7e7e',
  },
  headerModal: {
    minHeight: '125px',
    display: 'flex',
    justifyContent: 'center',
  },
  cancelButtom: {
    marginRight: '16px',
  },
})

interface ModalExcluirCenarioComponentProps {
  icon: ReactNode
  actionButtonText: string
  cancelButtonText?: string
  primaryColor: 'blue' | 'red'
  title: string
  text: string
  onAction: () => void
  onCancel?: () => void
}

export function ModalWithActionsComponent(props: ModalExcluirCenarioComponentProps) {
  const classes = useStyles()
    
  return (
    <ModalComponent>
      <div className={`${classes.box} ${classes[props.primaryColor]}`}>
        <div className={classes.headerModal}>
          <div className={classes.iconModal}>
            {props.icon}
          </div>
        </div>
        <h3 className={classes.title}>{props.title}</h3>
        <p className={classes.text}>{props.text}</p>
        {
          props.cancelButtonText && (
            <ButtonComponent
              buttonText={props.cancelButtonText}
              buttonTypeColor="white"
              onClick={props.onCancel}
              className={classes.cancelButtom}
            />
          )
        }
        <ButtonComponent
          buttonText={props.actionButtonText}
          buttonTypeColor={props.primaryColor}
          onClick={props.onAction}
        />
      </div>
    </ModalComponent>
  )
}
