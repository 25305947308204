import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TipoFato } from 'bff/src/main/domain/TipoFato'

const TIPOS_FATO = {
  NOTICIA_CLIPPING: {
    label: 'Notícia',
    color: '#F561BA',
  },
  EVENTO_RELACIONA: {
    label: 'Evento',
    color: '#4FBE83',
  },
  NOTICIA_UNIAO: {
    label: 'União',
    color: '#E9BC08',
  },
  PROCESSO: {
    label: 'Processo',
    color: '#214CA2',
  },
  PROPOSICAO_LEGISLATIVA: {
    label: 'Proposição',
    color: '#7E7E7E',
  },
}

const useStyles = makeStyles({
  container: (props: LabelTipoFatoProps) => ({
    display: 'inline-block',
    borderRightWidth: '2px',
    borderRightStyle: 'solid',
    paddingRight: '15px',
    lineHeight: '24px',
    borderColor: TIPOS_FATO[props.tipo].color,
  }),
  icone: (props: LabelTipoFatoProps) => ({
    position: 'absolute',
    left: '-17px',
    top: '5px',
    fontSize: '10px',
    color: TIPOS_FATO[props.tipo].color,
  }),
  label: {
    color: '#434343',
    fontSize: '16px',
    fontWeight: 500,
  },
})

export interface LabelTipoFatoProps {
  tipo: TipoFato
}

export function LabelTipoFatoComponent({ tipo }: LabelTipoFatoProps) {
  const classes = useStyles({ tipo })
  const tipoFatoRecuperado = TIPOS_FATO[tipo]
  return (
    <div className={classes.container}>
      <span className={classes.label}>{tipoFatoRecuperado.label}</span>
    </div>
  )
}
