import { createReducer } from '../../../infra/libs/redux-hardtyped/createReducer'
import { OnBoardingsState } from './OnBoardingsState'
import { onBoardingActionCreators } from '../../actions/onBoardingActions'


const initialState: OnBoardingsState = {}

export const onBoardingsReducer = createReducer(initialState)(handle => ([

  handle(onBoardingActionCreators.init, (state, action) => ({
    ...state,
    [action.name]: {
      totalSteps: action.totalSteps,
      currentStep: 1,
    },
  })),

  handle(onBoardingActionCreators.goNext, (state, action) => ({
    ...state,
    [action.name]: {
      totalSteps: state[action.name].totalSteps,
      currentStep: state[action.name].currentStep + 1,
    },
  })),

  handle(onBoardingActionCreators.goBack, (state, action) => ({
    ...state,
    [action.name]: {
      totalSteps: state[action.name].totalSteps,
      currentStep: state[action.name].currentStep - 1,
    },
  })),

  handle(onBoardingActionCreators.finish, (state, action) => {
    const newState = { ...state }
    delete newState[action.name]
    return newState
  }),

]))
