import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { MapaColor } from '../../../style/theme'

const useStyles = makeStyles({
  conector: {
    width: '25px',
    height: '25px',
    lineHeight: '25px',
    display: 'inline-block',
    borderRadius: '50%',
    background: MapaColor.neutralDark,
    marginLeft: '4px',
    marginRight: '9px',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    color: MapaColor.neutralLightest,
  },
})

export interface ConectorProps {
  isConectorOu: boolean
  nomeFuncao: string
}

export function ConectorComponent({ isConectorOu, nomeFuncao } : ConectorProps) {
  const classes = useStyles()
  return (
    <span role={nomeFuncao} className={classes.conector}>
      { isConectorOu ? 'ou' : 'e' }
    </span>
  )
}
