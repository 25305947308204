import React from 'react'
import PrintIcon from '@material-ui/icons/Print'
import { makeStyles } from '@material-ui/styles'


const useStyles = makeStyles({
  icon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

export function PrintButtonComponent() {
  const classes = useStyles()
  return (
    <span className={classes.icon}>
      <PrintIcon htmlColor="#808080" onClick={handleClick} />
    </span>
  )
}

function handleClick() {
  window.print()
}
