import { DependencyList, useCallback } from 'react'
import {
  AsyncFulfilled,
  AsyncInitial, AsyncOptions,
  AsyncPending,
  AsyncRejected,
  useAsync,
} from 'react-async'


type Arr = readonly unknown[]

export type PromiseFunction<Args extends Arr, R> = (...promiseArgs: [...Args]) => Promise<R>

export type PromiseState<Args extends Arr, R, S extends BaseState<Args, R> = BaseState<Args, R>>
  = AsyncInitial<R, S> | AsyncPending<R, S> | AsyncFulfilled<R, S> | AsyncRejected<R, S>

interface BaseState<Args extends Arr, R> {
  initialValue?: R | Error
  counter: number
  promise: Promise<R>
  run: (...args: [...Args]) => void
  reload: () => void
  cancel: () => void
  setData: (data: R, callback?: () => void) => R
  setError: (error: Error, callback?: () => void) => Error
}

// TODO: fazer documentação
export function usePromise<Args extends Arr, R>(promiseFunction: PromiseFunction<Args, R>, deps?: DependencyList): PromiseState<Args, R> {
  const wrappedPromiseFunction = (args: Args) => promiseFunction(...args)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFunction = useCallback(wrappedPromiseFunction, deps ?? [])
  return useAsync({ deferFn: memoizedFunction } as unknown as AsyncOptions<R>)
}
