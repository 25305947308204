import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Ficha } from 'bff/src/main/domain/Ficha'
import { ListItem, ListItemText } from '@material-ui/core'
import moment from 'moment'
import { IconFichaComponent } from '../icons/IconFichaComponent'
import { TabsComponent } from '../comum/TabsComponent'
import { ListaAtividadesFichaComponent } from './ListaAtividadesFichaComponent'
import { DescricaoFichaContainer } from '../../containers/ficha/DescricaoFichaContainer'

const useStyles = makeStyles({
  box: {
    marginTop: '44px',
    borderBottom: '1px solid #ccc',
    paddingBottom: '72px',
  },
  boxTitulo: {
    marginBottom: '46px',
  },
  titulo: {
    fontSize: '20px',
    fontWeight: 500,
    marginLeft: '10px',
  },
  itemLista: {
    paddingLeft: '0px',
    marginTop: '24px',
  },
})

interface InformacoesFichaComponentProps {
  ficha: Ficha
}

export function InformacoesFichaComponent({ ficha }: InformacoesFichaComponentProps) {
  const classes = useStyles()
  return (
    <div className={`${classes.box}`}>
      <h2 className={classes.boxTitulo}>
        <IconFichaComponent width="26" height="26" fill="#2F5DC9" />
        <span className={classes.titulo}>{ficha.titulo}</span>
      </h2>
      <div className="col-md-12">
        <TabsComponent tabs={getAbas(classes, ficha)} />
      </div>
    </div>
  )
}

function getAbas(classes, ficha: Ficha) {
  return [
    {
      tabTitle: 'Informações',
      tabBody: getInfo(classes, ficha),
      disabled: false,
    },
    {
      tabTitle: 'Atividade',
      tabBody: <ListaAtividadesFichaComponent listaAtividades={ficha.atividades} />,
      disabled: true,
    },
  ]
}

function getInfo(classes, ficha: Ficha) {
  return (
    <div>
      <ListItem className={classes.itemLista} alignItems="center">
        <ListItemText secondary="Proprietário" />
        <ListItemText primary={ficha.proprietario.login} />
      </ListItem>
      <ListItem className={classes.itemLista} alignItems="center">
        <ListItemText secondary="Criado em" />
        <ListItemText primary={moment(ficha.dataHoraCriacao).format('LL')} />
      </ListItem>
      <ListItem className={classes.itemLista} alignItems="center">
        <ListItemText secondary="Atualizado em" />
        <ListItemText primary={moment(ficha.dataHoraAtualizacao).format('LL')} />
      </ListItem>
      <ListItem className={classes.itemLista} alignItems="center">
        <DescricaoFichaContainer fichaId={ficha.id} descricao={ficha.descricao} />
      </ListItem>
    </div>
  )
}
