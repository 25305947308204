import React, { useState } from 'react'
import { BotaoAdicionarRemoverListaLeituraComponent } from '../../ui-components/comum/BotaoAdicionarRemoverListaLeituraComponent'
import { TypedDispatch, useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { listaLeituraThunkCreators } from '../../../store/actions/listaLeituraActions'
import { useSpinningPromise } from '../../../infra/libs/react-swearing/SpinnerScreenProvider'

export function BotaoAdicionarRemoverListaLeituraContainer({ fatoId }: { fatoId: string }) {
  const dispatch = useTypedDispatch()
  const [fatoJaAdicionado, setFatoJaAdicionado] = useState<boolean>(false)
  const { isLoading, isRejected } = usePromiseEffect(verificarSeConstaNaListaDeLeitura, [dispatch, fatoId, setFatoJaAdicionado])

  const { run: onAdicionar } = useSpinningPromise(() => {
    setFatoJaAdicionado(true)
    return dispatch(listaLeituraThunkCreators.adicionarNaListaDeLeitura(fatoId))
  })

  const { run: onRemover } = useSpinningPromise(() => {
    setFatoJaAdicionado(false)
    return dispatch(listaLeituraThunkCreators.removerDaListaDeLeitura(fatoId))
  })

  if (isLoading || isRejected) {
    return null
  }
  
  return <BotaoAdicionarRemoverListaLeituraComponent fatoJaAdicionadoALista={fatoJaAdicionado} onAdicionar={onAdicionar} onRemover={onRemover} />
}

async function verificarSeConstaNaListaDeLeitura(dispatch: TypedDispatch, fatoId: string, setFatoJaAdicionado: (value: boolean) => any) {
  const { data: constaNaListaDeLeitura } = await dispatch(listaLeituraThunkCreators.verificarSeFatoEstaNaListaDeLeitura(fatoId))
  setFatoJaAdicionado(constaNaListaDeLeitura)
}
