import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TipoFato } from 'bff/src/main/domain/TipoFato'
import { LabelTipoFatoComponent } from './LabelTipoFatoComponent'
import { PrintButtonComponent } from '../comum/PrintButtonComponent'
import { TagSigilosoComponent } from './TagSigilosoComponent'
import { BotaoAdicionarRemoverListaLeituraPelaPaginaDoFatoContainer } from '../../containers/fato/BotaoAdicionarRemoverListaLeituraPelaPaginaDoFatoContainer'

const useStyles = makeStyles({
  titulo: {
    color: '#434343',
    fontSize: '32px',
    fontWeight: 500,
    marginTop: '5px',
    marginBottom: '16px',
  },
  linkTitulo: {
    color: '#434343',
  },
  tituloSemClick: {
    '&:hover': {
      color: '#434343',
      cursor: 'default',
    },
  },
  tituloClick: {
    '&:hover': {
      color: '#636363',
      textDecoration: 'underline',
    },
  },
  fonteIcon: {
    marginLeft: '6px',
    fontSize: '23px',
  },
  barraAcoes: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  infoAdicional: {
    fontWeight: 500,
    color: '#434343',
    marginRight: '56px',
  },
  dataHora: {
    fontSize: '14px',
    color: '#434343',
  },
  sigiloso: {
    marginLeft: '15px',
  },
  adicionarRemoverListaLeitura: {
    marginRight: '32px',
  },
})

export interface FatoInternoCompletoHeaderProps {
  tipoFato: TipoFato
  titulo: string
  urlFonte: string
  infoAdicional?: string
  dataHora: string
  isSigiloso?: boolean
}

export function FatoCompletoHeaderComponent(props: FatoInternoCompletoHeaderProps) {
  const classes = useStyles()
  return (
    <div className="row">
      <div className="col-md-6">
        <LabelTipoFatoComponent tipo={props.tipoFato} />
        {
          props.isSigiloso ? <TagSigilosoComponent className={classes.sigiloso} /> : null
        }
        <h1 className={classes.titulo}>
          <a
            className={`${classes.linkTitulo}
         ${props.urlFonte ? classes.tituloClick : classes.tituloSemClick}`}
            href={props.urlFonte}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.titulo}
            {props.urlFonte ? <i className={`fas fa-external-link-alt ${classes.fonteIcon}`} /> : null}
          </a>
        </h1>
        {
          props.infoAdicional ? (
            <span className={classes.infoAdicional}>
              {props.infoAdicional}
            </span>
          ) : null   
        }
        {
          props.dataHora ? (
            <span className={classes.dataHora}>
              {props.dataHora}
            </span>      
          ) : null
        }
      </div>
      <div className={`col-md-5 offset-md-1 ${classes.barraAcoes}`}>
        <span className={classes.adicionarRemoverListaLeitura}>
          <BotaoAdicionarRemoverListaLeituraPelaPaginaDoFatoContainer />
        </span>
        <PrintButtonComponent />
      </div>
    </div>
  )
}
