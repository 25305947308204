import React from 'react'
import { criaStakeholdersComFavoritos } from 'bff/src/main/app/stakeholder/stakeholderUtil'
import { StakeholdersSugeridosComponent } from '../../ui-components/stakeholder/StakeholdersSugeridosComponent'
import { useQueryParamAsString } from '../../../infra/libs/react-location-hooks/routerHooks'
import { StakeholdersRelacionadosPlaceholderComponent } from '../../ui-components/stakeholder/StakeholdersRelacionadosPlaceholderComponent'
import { QUERY_PARAM_TEXTO_LIVRE } from '../../../util/constantesQueryParams'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { stakeholderThunkCreators } from '../../../store/actions/stakeholderActions'
import { favoritoThunkCreators } from '../../../store/actions/favoritoActions'
import { TipoFavorito } from '../../ui-components/favorito/TipoFavorito'


const limite = 8

export function StakeholdersSugeridosContainer() {

  const dispatch = useTypedDispatch()
  const textoLivre = useQueryParamAsString(QUERY_PARAM_TEXTO_LIVRE)

  const { data: stakeholders, isLoading } = usePromiseEffect(recuperarStakeholdersSugeridos, [dispatch, textoLivre])

  if (isLoading) {
    return <StakeholdersRelacionadosPlaceholderComponent qntFiguras={3} />
  }
  if (!stakeholders || stakeholders.length === 0) {
    return null
  }

  return <StakeholdersSugeridosComponent quantidadeMinimaMostrarMais={limite} listaStakeholders={stakeholders || []} titulo="Atores e entidades sugeridos" />
}

async function recuperarStakeholdersSugeridos(dispatch: TypedDispatch, textoLivre?: string) {
  try {
    if (!textoLivre) {
      return []
    }
    const { data: stakeholders } = await dispatch(stakeholderThunkCreators.buscarStakeholdersPorTexto(textoLivre, limite))
    const { data: favoritos } = await dispatch(favoritoThunkCreators.recuperarMeusFavoritos(TipoFavorito.STAKEHOLDER))

    return criaStakeholdersComFavoritos(stakeholders, favoritos)
  } catch (e) {
    console.warn(e)
  }
  return []
}
