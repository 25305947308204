import React, { ReactElement, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { TabsHeaderComponent } from './TabsHeaderComponent'
import { TabsBodyComponent } from './TabsBodyComponent'

const useStyles = makeStyles({
  tabs: {

  },
})

interface Tab {
  tabTitle: string
  tabBody: ReactElement
  disabled?: boolean
}

interface TabsProps {
  tabs: Tab[]
}

export function TabsComponent({ tabs }: TabsProps) {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className={classes.tabs}>
      <TabsHeaderComponent
        header={getTabs(tabs).map(t => ({ tabTitle: t.tabTitle, key: t.key, disabled: t.disabled }))}
        activeTab={activeTab}
        onClick={newValue => setActiveTab(newValue)}
      />
      <TabsBodyComponent>
        {getTabs(tabs)[activeTab].tabBody}
      </TabsBodyComponent>
    </div>
  )
}

function getTabs(tabs: Tab[]) {
  return tabs.map((tab, index) => ({
    key: index,
    tabTitle: tab.tabTitle,
    tabBody: tab.tabBody,
    disabled: tab.disabled,
  }))
}
