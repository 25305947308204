import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { NoticiaClipping } from '../../domain/NoticiaClipping'
import { recuperarNoticiaClippingPorId } from '../../app/fato/noticia-clipping/recuperarNoticiaClippingPorId'


export namespace BuscarNoticiaClippingEndpoint {
  export type Body = undefined
  export type Params = { id: string }
  export type Query = undefined
  export type Response = NoticiaClipping
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/noticias-clipping/:id',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<Response>) => {
      const { id } = request.params
      const noticiaClipping = await recuperarNoticiaClippingPorId(id)
      response.json(noticiaClipping)
    },
  })
}
