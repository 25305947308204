import moment, { Moment } from 'moment'
import { fatosApi } from '../../api/fatosApi'
import { montarResumoFato } from './montarResumoFato'
import { ResumoFato } from '../../domain/ResumoFato'
import { processosApi } from '../../api/processosApi'
import { TIPOS_FATO } from '../../domain/TipoFato'

const regexOnlyNumbers = /^[0-9]*$/

interface RespostaOrdenada {
  fato: any
  tipoFato: any
  dataHoraAdicionado: Moment
}

export async function recuperarFatosResumidosDaListaLeitura(listaLeitura) {
  if (listaLeitura.length < 1) {
    return []
  }
  const [
    { body: respostaIndiceFatos },
    { body: respostaIndiceProcessos },
  ] = await Promise.all([
    fatosApi.buscarFatosPorId(listaLeitura.map(item => item.fato)),
    processosApi.buscarProcessosPorCodigo(listaLeitura.filter(item => regexOnlyNumbers.test(item.fato)).map(item => String(item.fato))),
  ])

  const listaOrdenada = ordernarListaFatos(listaLeitura, respostaIndiceFatos, respostaIndiceProcessos)

  return montarViewModel(listaOrdenada)
}


function ordernarListaFatos(listaLeitura, respostaIndiceFatos, respostaIndiceProcessos) {
  const respostaOrdenada: RespostaOrdenada[] = []

  listaLeitura.forEach((item) => {
    respostaIndiceFatos.hits.hits.forEach((fato) => {
      if (item.fato === fato._id) {
        respostaOrdenada.push({
          fato,
          tipoFato: fato._source.tipo_fato,
          dataHoraAdicionado: moment(item.dataHoraAdicionado),
        })
      }
    })
    respostaIndiceProcessos.hits.hits.forEach((processo) => {
      if (item.fato === processo._id) {
        respostaOrdenada.push({
          fato: processo,
          tipoFato: TIPOS_FATO.PROCESSO.id,
          dataHoraAdicionado: moment(item.dataHoraAdicionado),
        })
      }
    })
  })

  return respostaOrdenada.sort((a, b) => moment(String(a.dataHoraAdicionado)).format('YYYYMMDDHHmm') as any - (moment(String(b.dataHoraAdicionado)).format('YYYYMMDDHHmm') as any))
    .reverse()

}

function montarViewModel(listaOrdenada: RespostaOrdenada[]): ResumoFato[] {
  return [
    ...listaOrdenada.map(item => montarResumoFato(item.fato, item.tipoFato)),
  ]
}
