import React from 'react'
import { TIPOS_EVENTO } from 'bff/src/main/domain/TipoEvento'
import { CheckboxesOption } from '../../ui-components/comum/CheckboxesComponent'
import { CheckboxesFilterContainer } from './CheckboxesFilterContainer'
import { ordernarListaAlfabeticamentePorPropriedade } from '../../../util/sortUtil'
import { QUERY_PARAM_FILTRO_TIPO_EVENTOS } from '../../../util/constantesQueryParams'

export function FiltroTiposDeEventosContainer() {
  return (
    <CheckboxesFilterContainer
      filterName={QUERY_PARAM_FILTRO_TIPO_EVENTOS}
      filterLabel="Tipos de Eventos"
      options={getOptions()}
      withOptionsFilter
    />
  )
}

function getOptions(): CheckboxesOption[] {
  const listaTiposEvento = Object.values(TIPOS_EVENTO).map(
    tipoEvento => ({ label: tipoEvento.label, value: tipoEvento.id }),
  )
  return ordernarListaAlfabeticamentePorPropriedade(listaTiposEvento, 'label')
}
