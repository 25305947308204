import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { StakeholderComFavorito } from 'bff/src/main/domain/ResumoStakeholder'
import { CardStakeholderFotoNomeComponent } from './CardStakeholderFotoNomeComponent'
import { StakeholdersRelacionadosNaoEncontradosComponent } from './StakeholdersRelacionadosNaoEncontradosComponent'
import { TituloComponent } from '../comum/TituloComponent'
import { ButtonIconFavoritarComponent } from '../comum/ButtonIconFavoritarComponent'
import { TipoFavorito } from '../favorito/TipoFavorito'

const useStyles = makeStyles({
  titulo: {
    marginBottom: '24px',
  },
  cardStakeholderPequeno: {
    height: '150px',
    background: '#fff',
    display: 'flex',
    gap: '15px',
  },
  collum: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  styleButton: {
    opacity: '50%',
    width: '150px',
    margin: '0 auto',
    marginTop: '24px',
  },
  favoritos: {
    marginTop: '5px',
  },

})

export interface StakeholdersRelacionadosProps {
  listaStakeholders: StakeholderComFavorito []
  titulo: string
}

export function StakeholdersPesquisadosComponent({ listaStakeholders, titulo }: StakeholdersRelacionadosProps) {
  const classes = useStyles()
  const role = 'favoritos'

  if (listaStakeholders.length < 1) {
    return <StakeholdersRelacionadosNaoEncontradosComponent />
  }
  return (
    <>
      <div>
        <TituloComponent className={classes.titulo} titulo={titulo} />
        <div className={classes.collum}>
          {
            listaStakeholders.map(item => (
              <div className={`${classes.cardStakeholderPequeno} col-md-12`} key={item.resumoStakeholder.id}>
                <CardStakeholderFotoNomeComponent
                  nome={item.resumoStakeholder.nome}
                  papel={item.resumoStakeholder.papel}
                  stakeholderInterno={item.resumoStakeholder.stakeholderInterno}
                  urlDestino={item.resumoStakeholder.urlDestino}
                  urlImagem={item.resumoStakeholder.urlImagem}
                />
                <div role={role} className={classes.favoritos}>
                  <ButtonIconFavoritarComponent
                    idItemFavorito={item.resumoStakeholder.id}
                    isFavorito={item.isFavorito}
                    tipoFavorito={TipoFavorito.STAKEHOLDER}
                  />
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </>
  )
}
