import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { ButtonComponent } from '../comum/ButtonComponent'

const useStyles = makeStyles({
  box: {
    marginTop: '24px',
    textAlign: 'left',
  },
  titulo: {
    fontSize: '18px',
    color: '#434343',
  },
  texto: {
    fontSize: '16px',
    color: '#7e7e7e',
  },
  header: {
    minHeight: '125px',
    display: 'flex',
    justifyContent: 'center',
  },
})

export function MeusCenariosVazioComponent() {
  const classes = useStyles()
  const history = useHistory()

  function criarCenario() {
    history.push('/cenarios/novo')
  }

  return (
    <div className="col-md-12">
      <div className={classes.box}>
        <h3 className={classes.titulo}>Comece criando seu primeiro cenário</h3>
        <p className={classes.texto}>Acompanhe os acontecimentos envolvendo os assuntos e atores de seu interesse.</p>
        <ButtonComponent
          buttonText="Criar novo cenário"
          buttonTypeColor="blue"
          onClick={criarCenario}
        />
      </div>
    </div>
  )
}
