import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { ModalComponent } from '../../ui-components/modal/ModalComponent'
import { HeaderCenariosComponent } from '../../ui-components/comum/HeaderCenariosComponent'
import { FieldErrorComponent } from '../../ui-components/comum/FieldErrorComponent'
import { ButtonComponent } from '../../ui-components/comum/ButtonComponent'

const useStyles = makeStyles({
  box: {
    width: '583px',
  },
  margin: {
    marginBottom: '32px',
  },
  label: {
    color: '#434343',
    fontWeight: 500,
    fontSize: '16px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButtom: {
    marginRight: '16px',
  },
})

interface Fields {
  tituloFicha: string
}

interface NovaFichaFormProps {
  onConfirm: (fichaTitulo: string) => void
  onCancel: () => void
}

export function NovaFichaForm(propsModal: NovaFichaFormProps) {
  const classes = useStyles()
  const {
    register, handleSubmit, errors,
  } = useForm<Fields>({ defaultValues: {
    tituloFicha: '',
  } })

  function realizarAcao(formValues: Fields) {
    propsModal.onConfirm(formValues.tituloFicha)
  }

  return (
    <ModalComponent>
      <form className={classes.box} onSubmit={handleSubmit(realizarAcao)}>
        <HeaderCenariosComponent
          titulo="Nova ficha"
          descricao="Para incluir o trecho selecionado crie uma nova ficha."
        />
        <FieldErrorComponent className={classes.margin} error={!!errors.tituloFicha} message="Defina um nome para a ficha">
          <span className={classes.label}>Nome da ficha</span>
          <TextField
            inputRef={register({ required: true })}
            name="tituloFicha"
            id="outlined-size-normal"
            variant="outlined"
            fullWidth
          />
        </FieldErrorComponent>
        <div className={classes.buttons}>
          <ButtonComponent
            buttonText="Cancelar"
            buttonTypeColor="white"
            onClick={propsModal.onCancel}
            className={classes.cancelButtom}
          />
          <ButtonComponent
            buttonText="Criar"
            buttonTypeColor="blue"
            type="submit"
          />
        </div>
      </form>
    </ModalComponent>
  )
}
