import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'
import { SwitchWithLabelComponent } from '../comum/SwitchWithLabelComponent'

const useStyles = makeStyles({
  switchBox: {
    display: 'flex',
    alignItems: 'center',
    borderColor: '#EAEAEA',
    paddingRight: '4px',
    paddingLeft: '4px',
    borderStyle: 'solid',
    borderWidth: '1px 1px 1px 0px',
    borderRadius: '0px 8px 8px 0px',
    backgroundColor: '#fdfdfd',
  },
})

interface SwitchConectorOUComponentProps {
  value: boolean
  onChange: (v: boolean) => void
}

const textoConectorELigado = 'Clique para inclui operador de pesquisa \'OU\' e localizar qualquer uma e ou todas as palavras digitadas. Exemplo: regulares OU irregulares'
const textoConectorOuLigado = 'Clique para inclui operador de pesquisa \'E\' e localizar todos os termos digitados. Exemplo: Jorge Oliveira E Acórdão 605/2022 - P'

export function SwitchConectorOUComponent({ value, onChange }: SwitchConectorOUComponentProps) {
  const classes = useStyles()
  const tooltipText = value ? textoConectorOuLigado : textoConectorELigado
  return (
    <Tooltip title={tooltipText}>
      <div className={classes.switchBox}>
        <SwitchWithLabelComponent labelOn="ou" labelOff="e" onChange={onChange} value={value} />
      </div>
    </Tooltip>
  )
}
