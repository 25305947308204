import React from 'react'
import { ModalArquivarCenarioContainer } from './modal-arquivar-cenario-container/ModalArquivarCenarioContainer'
import { ModalExcluirCenarioContainer } from './modal-excluir-cenario-container/ModalExcluirCenarioContainer'
import { ModalCenarioCriadoContainer } from './ModalCenarioCriadoContainer'
import { ModalCenarioDesarquivadoContainer } from './ModalCenarioDesarquivadoContainer'
import { ModalCenarioAlteradoContainer } from './ModalCenarioAlteradoContainer'
import { ModalCenarioCriadoTelaResultadoContainer } from './ModalCenarioCriadoTelaResultadoContainer'
import { ModalNovaFichaContainer } from './modal-nova-ficha/ModalNovaFichaContainer'
import { ModalFichaCriadaETrechoAdicionadoContainer } from './ModalFichaCriadaETrechoAdicionadoContainer'
import { ModalAlterarDescricaoFichaContainer } from './ModalAlterarDescricaoFichaContainer'
import { ModalExcluirFichaContainer } from './modal-excluir-ficha-container/ModalExcluirFichaContainer'
import { ModalAdicionarTrechoFatoFichaExistenteContainer } from './modal-adicionar-trecho-fato-ficha-existente/ModalAdicionarTrechoFatoFichaExistenteContainer'

export function ModalsContainer() {
  return (
    <div>
      <ModalArquivarCenarioContainer />
      <ModalExcluirCenarioContainer />
      <ModalCenarioCriadoContainer />
      <ModalCenarioAlteradoContainer />
      <ModalCenarioDesarquivadoContainer />

      <ModalCenarioCriadoTelaResultadoContainer />
      <ModalNovaFichaContainer />
      <ModalFichaCriadaETrechoAdicionadoContainer />
      <ModalAlterarDescricaoFichaContainer />
      <ModalExcluirFichaContainer />
      <ModalAdicionarTrechoFatoFichaExistenteContainer />
    </div>
  )
}
