import { EnumObjectWithId } from '../util/EnumObject'

export type TipoEvento =
  'CAFE_ALMOCO_JANTAR' |
  'CONFERENCIA' |
  'REUNIAO_APROXIMACAO' |
  'AUDIENCIA_PUBLICA' |
  'APRESENTACAO' |
  'CAPACITACAO' |
  'REUNIAO_TECNICA' |
  'ATENDIMENTO' |
  'ENTREVISTA' |
  'SOLENIDADE'

export const TIPOS_EVENTO: EnumObjectWithId<TipoEvento, { key: string, label: string }> = {
  CAFE_ALMOCO_JANTAR: {
    id: 'CAFE_ALMOCO_JANTAR',
    key: 'Almoço / Café da manhã / Jantar',
    label: 'Café da manhã | Almoço | Jantar',
  },
  CONFERENCIA: {
    id: 'CONFERENCIA',
    key: 'Conferência',
    label: 'Conferência',
  },
  REUNIAO_APROXIMACAO: {
    id: 'REUNIAO_APROXIMACAO',
    key: 'Reunião de aproximação',
    label: 'Reunião de aproximação',
  },
  AUDIENCIA_PUBLICA: {
    id: 'AUDIENCIA_PUBLICA',
    key: 'Audiência Pública',
    label: 'Audiência pública',
  },
  APRESENTACAO: {
    id: 'APRESENTACAO',
    key: 'Apresentação',
    label: 'Apresentação',
  },
  CAPACITACAO: {
    id: 'CAPACITACAO',
    key: 'Capacitação',
    label: 'Capacitação',
  },
  REUNIAO_TECNICA: {
    id: 'REUNIAO_TECNICA',
    key: 'Reunião técnica',
    label: 'Reunião técnica',
  },
  ATENDIMENTO: {
    id: 'ATENDIMENTO',
    key: 'Atendimento',
    label: 'Atendimento',
  },
  ENTREVISTA: {
    id: 'ENTREVISTA',
    key: 'Entrevista',
    label: 'Entrevista',
  },
  SOLENIDADE: {
    id: 'SOLENIDADE',
    key: 'Solenidade',
    label: 'Solenidade',
  },
}
