import React from 'react'
import { modalNovaFichaActionCreators } from './modalNovaFichaActions'
import { createReducer } from '../../../../infra/libs/redux-hardtyped/createReducer'
import { useTypedDispatch } from '../../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSwan } from '../../../../infra/libs/redux-swans/useSwan'
import { NovaFichaForm } from '../../ficha/NovaFichaForm'
import { fichaActionCreators, fichaThunkCreators } from '../../../../store/actions/fichaActions'
import { useSpinningPromise } from '../../../../infra/libs/react-swearing/SpinnerScreenProvider'

export type ModalNovaFichaContainerState = null | {
  fatoId?: string | number
  textoSelecionado?: string
  modalEstaAberta: boolean
}

const initialState = null

const reducer = createReducer<ModalNovaFichaContainerState>(initialState)(handle => ([
  handle(modalNovaFichaActionCreators.abrirModalTelaMinhasFichas, () => ({ modalEstaAberta: true })),
  
  handle(modalNovaFichaActionCreators.abrirModalTelaFato, (state, action) => {
    const { textoSelecionado, fatoId } = action
    return { textoSelecionado, fatoId, modalEstaAberta: true }
  }),
  handle(modalNovaFichaActionCreators.fecharModal, () => null),
  handle(fichaActionCreators.fichaCriada, () => null),
]))

export function ModalNovaFichaContainer() {
  const dispatch = useTypedDispatch()
  const state = useSwan(reducer)
  const modalEstaAberta = state?.modalEstaAberta
  const fatoId = state?.fatoId
  const texto = state?.textoSelecionado

  const { run: onConfirm } = useSpinningPromise(
    (tituloFicha: string) => dispatch(fichaThunkCreators.criarFicha(getPromiseBody(tituloFicha, texto, fatoId))),
    [fatoId, texto],
  )
  const onCancel = () => dispatch(modalNovaFichaActionCreators.fecharModal())

  if (!modalEstaAberta) return null
  return (
    <NovaFichaForm
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  )
}

function getPromiseBody(tituloFicha: string, texto?: string, fatoId?: string | number) {
  return fatoId && texto
    ? { titulo: tituloFicha, conteudo: texto, fatoCitado: fatoId.toString() }
    : { titulo: tituloFicha }
}
