import React, { useEffect, useState } from 'react'
import { Cenario } from 'bff/src/main/domain/Cenario'
import { ID_ENTIDADE_TCU } from 'bff/src/main/util/stakeholdersUtil'
import { StakeholdersTweetsContainer } from './StakeholdersTweetsContainer'


interface StakeholdersTweetsTelaInicioContainerProps {
  cenarios?: Cenario[]
}

export function StakeholdersTweetsTelaInicioContainer({ cenarios }: StakeholdersTweetsTelaInicioContainerProps) {
  const [idsStakeholders, setIdsStakeholders] = useState<string[]>([])

  useEffect(() => {
    if (cenarios === undefined) {
      return setIdsStakeholders([])
    }
    const idsStakeholdersCenario: string[] = []
    cenarios.forEach((c) => {
      if (c.consulta.stakeholders) {
        idsStakeholdersCenario.push(...c.consulta.stakeholders)
      }
    })
    const idsParaPesquisar = idsStakeholdersCenario.length > 0 ? idsStakeholdersCenario : getStakeholderBase()
    return setIdsStakeholders(idsParaPesquisar)
  }, [cenarios])

  if (!idsStakeholders || idsStakeholders.length === 0) {
    return null
  }
  return <StakeholdersTweetsContainer idsStakeholders={idsStakeholders ?? []} />
}

function getStakeholderBase() {
  return [ID_ENTIDADE_TCU]
}
