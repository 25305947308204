import React, { useState } from 'react'
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import { makeStyles } from '@material-ui/styles'


const useStyles = makeStyles({
  root: (props: TwitterProfileTimelineComponentProps) => ({
    marginBottom: '16px',
    padding: '16px',
    height: props.height ? `${props.height}px` : 'auto',
    backgroundColor: '#f6f6f6',
    borderRadius: '6px',
    overflow: 'hidden',
  }),
  closed: {
    display: 'none',
  },
  header: {
    paddingBottom: '12px',
    borderBottom: '1px solid #c4c4c4',
    fontSize: '16px',
    fontWeight: 500,
    color: '#434343',
  },
  body: {
    paddingTop: '16px',
  },
})

export interface TwitterProfileTimelineComponentProps {
  profile: string
  name: string
  height?: number
}

export function TwitterProfileTimelineComponent(props: TwitterProfileTimelineComponentProps) {
  const classes = useStyles(props)
  const [isLoaded, setLoaded] = useState(false)
  
  return (
    <div className={`${classes.root} ${isLoaded ? '' : classes.closed}`}>
      <div className={classes.header}>{`Tweets de ${props.name}`}</div>
      <div className={classes.body}>
        <TwitterTimelineEmbed
          key={props.profile}
          sourceType="profile"
          screenName={props.profile}
          lang="pt-br"
          noHeader
          noFooter
          noBorders
          transparent
          options={{ height: props.height && props.height - 90 }}
          onLoad={html => setLoaded(!!html)}
        />
      </div>
    </div>
  )
}
