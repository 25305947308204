import React from 'react'
import { useHistory } from 'react-router-dom'
import { TipoFato } from 'bff/src/main/domain/TipoFato'
import { ORDENACOES } from 'bff/src/main/domain/Ordenacao'
import { updatePropertyInQueryParams } from 'bff/src/main/util/urlUtil'
import { BuscarFatosResumidosEndpoint } from 'bff/src/main/controllers/fato/fatosEndpoints'
import { fatoThunkCreators } from '../../../store/actions/fatoActions'
import { ListaFatosPlaceholderComponent } from '../../ui-components/fato/ListaFatosPlaceholderComponent'
import { useParam } from '../../../infra/libs/react-location-hooks/routerHooks'
import { ListaFatosPerfilStakeholderComponent } from '../../ui-components/fato/ListaFatosPerfilStakeholderComponent'
import { QUERY_PARAM_ATORES, QUERY_PARAM_FILTRO_TIPO_FATOS, QUERY_PARAM_ORDENACAO } from '../../../util/constantesQueryParams'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { favoritoThunkCreators } from '../../../store/actions/favoritoActions'
import { TipoFavorito } from '../../ui-components/favorito/TipoFavorito'
import { criaFatosComFavoritos } from './ListaFatosContainer'


const TIPO_ORDENACAO = ORDENACOES.MAIS_RECENTE.id

export function ListaFatosPerfilAtorContainer({ tiposFato }: { tiposFato?: TipoFato[] }) {

  const dispatch = useTypedDispatch()
  const history = useHistory()
  const idAtor = useParam('id')
  const { data: listaFatos, isPending } = usePromiseEffect(recuperarFatos, [dispatch, idAtor, tiposFato])

  function handleClick() {
    const { search } = history.location
    let stringfiedQueryParams = updatePropertyInQueryParams(QUERY_PARAM_ATORES, [idAtor], search)
    stringfiedQueryParams = updatePropertyInQueryParams(QUERY_PARAM_FILTRO_TIPO_FATOS, tiposFato, stringfiedQueryParams)
    stringfiedQueryParams = updatePropertyInQueryParams(QUERY_PARAM_ORDENACAO, TIPO_ORDENACAO, stringfiedQueryParams)
    const url = `/resultados?${stringfiedQueryParams}`
    history.push(url)
  }
  
  if (isPending) {
    return <ListaFatosPlaceholderComponent />
  }
  return <ListaFatosPerfilStakeholderComponent fatos={listaFatos ?? []} onClick={handleClick} />
}

async function recuperarFatos(dispatch: TypedDispatch, idAtor: string, tiposFato?: TipoFato[]) {
  const payload: BuscarFatosResumidosEndpoint.Query = {
    idsAtores: [idAtor],
    ordenacao: TIPO_ORDENACAO,
    size: 4,
    tiposFato,
  }
  const { data: listaFatos } = await dispatch(fatoThunkCreators.buscarFatosResumidos(payload))
  const { data: favoritos } = await dispatch(favoritoThunkCreators.recuperarMeusFavoritos(TipoFavorito.FATO))

  return criaFatosComFavoritos(listaFatos, favoritos)
}
