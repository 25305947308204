import { MontarQueryRecuperarFatosPayload } from './queries/MontarQueryRecuperarFatosPayload'
import { montarQueryRecuperarProcessos, montarQueryRecuperarContadorProcessos } from './queries/montarQueryRecuperarProcessos'
import { StakeholdersComIdEAlias } from '../app/stakeholder/recuperarAliasStakeholderPorId'
import { searchOnIndex } from '../infra/elastic/searchOnIndex'
import { countOnIndex } from '../infra/elastic/countOnIndex'
import { MontarQueryRecuperarContadorFatosPayload } from './queries/MontarQueryRecuperarContadorFatosPayload'
import { ambienteUtil } from '../util/ambienteUtil'


const search = (body) => {
  const indexName = ambienteUtil.isAmbienteProducao() ? 'processos-controle-externo-prod' : 'processos-controle-externo-dev'
  return searchOnIndex({ body, index: indexName })
}

const count = (body?) => {
  const indexName = ambienteUtil.isAmbienteProducao() ? 'processos-controle-externo-prod' : 'processos-controle-externo-dev'
  return countOnIndex({ body, index: indexName })
}

export const processosApi = {

  buscarProcessosPorCodigo(codigos: string | string[]) {
    return search({
      query: {
        ids: {
          values: codigos,
        },
      },
    })
  },

  buscarProcessosPorNumeroFormatado(numeroFormatado: string) {
    return search({
      query: {
        match_phrase: {
          numeroCompleto: numeroFormatado,
        },
      },
    })
  },

  buscarContadorProcessosPorFiltro(
    payload: MontarQueryRecuperarContadorFatosPayload,
    atores: StakeholdersComIdEAlias[],
    entidades: StakeholdersComIdEAlias[],
  ) {
    const query = montarQueryRecuperarContadorProcessos(payload, atores, entidades)
    return count(query)
  },

  buscarContadorTotalProcessos() {
    return count()
  },

  buscarProcessos(
    payload: MontarQueryRecuperarFatosPayload,
    atores: StakeholdersComIdEAlias[],
    entidades: StakeholdersComIdEAlias[],
  ) {
    return search(montarQueryRecuperarProcessos(payload, atores, entidades))
  },
}
