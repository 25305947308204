import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'
import { Tooltip } from '@material-ui/core'
import { usePromise } from '../../../infra/libs/react-swearing/usePromise'
import { favoritoThunkCreators } from '../../../store/actions/favoritoActions'
import { useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { ButtonSpanComponent } from './ButtonSpanComponent'
import { MapaColor } from '../../../style/theme'
import { TipoFavorito } from '../favorito/TipoFavorito'

const useStyles = makeStyles({
  colorIcon: {
    color: MapaColor.primaryMedium,
    cursor: 'pointer',
  },
  colorIconNaoFavoritado: {
    color: MapaColor.neutralDarkest,
    cursor: 'pointer',
  },

})

interface FavoritosProps {
  isFavorito: boolean
  idItemFavorito: string
  tipoFavorito: TipoFavorito
}


export function ButtonIconFavoritarComponent(props: FavoritosProps) {
  const dispatch = useTypedDispatch()
  const [favoritado, setFavoritado] = useState(props.isFavorito)
  const classes = useStyles()

  const { run: handleClick } = usePromise(async (idItemFavorito: string) => {
    try {
      if (favoritado) {
        await dispatch(favoritoThunkCreators.excluirFavorito(idItemFavorito))
      } else {
        await dispatch(favoritoThunkCreators.criarFavorito({ itemFavorito: idItemFavorito, tipoFavorito: props.tipoFavorito }))
      }
      setFavoritado(!favoritado)
    } catch (e) {
      console.error(e)
    }
  }, [favoritado])

  return (
    <ButtonSpanComponent onClick={() => handleClick(props.idItemFavorito)}>
      <Tooltip title={favoritado ? 'Desfavoritar' : 'Favoritar'}>
        {
          favoritado ? <StarIcon className={classes.colorIcon} titleAccess={favoritado ? 'Desfavoritar' : 'Favoritar'} /> : <StarBorderIcon className={classes.colorIconNaoFavoritado} />
        }
      </Tooltip> 
    </ButtonSpanComponent>
  )
}
