import React from 'react'
import { ResumoStakeholder } from 'bff/src/main/domain/ResumoStakeholder'
import { isAutoridadeTCU } from 'bff/src/main/util/stakeholdersUtil'
import { ListaStakeholdersComponent } from '../../ui-components/stakeholder/ListaStakeholdersComponent'
import { StakeholdersRelacionadosPlaceholderComponent } from '../../ui-components/stakeholder/StakeholdersRelacionadosPlaceholderComponent'
import { stakeholderThunkCreators } from '../../../store/actions/stakeholderActions'
import { ordernarListaAlfabeticamentePorPropriedade } from '../../../util/sortUtil'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'


interface StakeholdersEnvolvidosContainerProps {
  stakeholdersIds: string[]
  tituloAutoridadesTcu: string
  tituloStakeholders: string
  exibirContadorAutoridades?: boolean
  exibitContadorStakeholders?: boolean
}

export function ListaStakeholdersContainer({
  stakeholdersIds,
  tituloAutoridadesTcu,
  tituloStakeholders,
  exibirContadorAutoridades = true,
  exibitContadorStakeholders = true,
}: StakeholdersEnvolvidosContainerProps) {

  const dispatch = useTypedDispatch()
  const { data: listas, isLoading } = usePromiseEffect(recuperarStakeholdersPesquisados, [dispatch, stakeholdersIds])

  if (isLoading) {
    return <StakeholdersRelacionadosPlaceholderComponent qntFiguras={5} />
  }
  return (
    <>
      <ListaStakeholdersComponent
        listaStakeholders={listas?.autoridadesTcu || []} 
        titulo={tituloAutoridadesTcu} 
        exibirContador={exibirContadorAutoridades}
      />
      <ListaStakeholdersComponent
        listaStakeholders={listas?.stakeholders || []} 
        titulo={tituloStakeholders} 
        componenteExpansivel 
        exibirContador={exibitContadorStakeholders} 
      />
    </>
  )
  
}

async function recuperarStakeholdersPesquisados(dispatch: TypedDispatch, stakeholdersIds?: string[]) {
  if (stakeholdersIds && stakeholdersIds.length !== 0) {
    try {
      const { data: stakeholders } = await dispatch(stakeholderThunkCreators.buscarStakeholdersPorId(stakeholdersIds))
      const listas: { autoridadesTcu: ResumoStakeholder[], stakeholders: ResumoStakeholder[] } = { autoridadesTcu: [], stakeholders: [] }
      listas.autoridadesTcu.push(...stakeholders.filter(stakeholder => isAutoridadeTCU(stakeholder.stakeholderInterno, stakeholder.papel)))
      listas.stakeholders.push(...stakeholders.filter(stakeholder => !isAutoridadeTCU(stakeholder.stakeholderInterno, stakeholder.papel)))
      listas.autoridadesTcu = ordernarStakeholdersPorNome(listas.autoridadesTcu) 
      listas.stakeholders = ordernarStakeholdersPorNome(listas.stakeholders)
      return listas
    } catch (e) {
      console.warn(e)
    }
  }
  return {} as { autoridadesTcu: ResumoStakeholder[], stakeholders: ResumoStakeholder[] }
}

function ordernarStakeholdersPorNome(stakeholders : ResumoStakeholder[]) {
  return ordernarListaAlfabeticamentePorPropriedade(stakeholders, 'nome')
}
