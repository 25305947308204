import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { SpinnerScreenComponent } from '../../../components/ui-components/progress/SpinnerScreenComponent'
import { useMemoObject } from '../../../hooks/useMemoObject'
import { usePromiseEffect } from './usePromiseEffect'
import { usePromise } from './usePromise'


interface SpinnerScreenContextType {
  counter: number
  increment: () => void
  decrement: () => void
}

const defaultValue: SpinnerScreenContextType = {
  counter: 0,
  increment: () => {},
  decrement: () => {},
}

const SpinnerScreenContext = React.createContext(defaultValue)

interface SpinnerScreenProviderProps {
  children: ReactNode
}

export function SpinnerScreenProvider({ children }: SpinnerScreenProviderProps) {
  const [counter, setCounter] = useState(0)
  const increment = useCallback(() => {
    setCounter((prevState => prevState + 1))
  }, [])
  const decrement = useCallback(() => {
    setCounter(((prevState) => {
      if (prevState === 0) throw new Error('Not possible to decrement because the actual counter is zero.')
      return prevState - 1
    }))
  }, [])
  const contextValue = useMemoObject({
    counter,
    increment,
    decrement,
  })
  return (
    <SpinnerScreenContext.Provider value={contextValue}>
      {children}
      {counter > 0 && <SpinnerScreenComponent />}
    </SpinnerScreenContext.Provider>
  )
}

function useSpinningController(loading: boolean) {
  const contextValue = useContext(SpinnerScreenContext)
  const isMounted = useRef(true)
  const spinning = useRef(false)
  useEffect(() => {
    if (isMounted.current) {
      if (loading && !spinning.current) {
        spinning.current = true
        contextValue.increment()
      }
      if (!loading && spinning.current) {
        spinning.current = false
        contextValue.decrement()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])
  useEffect(() => () => {
    isMounted.current = false
    if (spinning.current) {
      contextValue.decrement()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useSpinningPromise: typeof usePromise = (promiseFunction, deps?) => {
  const promiseState = usePromise(promiseFunction, deps)
  useSpinningController(promiseState.isLoading)
  return promiseState
}

export const useSpinningPromiseEffect: typeof usePromiseEffect = (promiseFunction, promiseArgs?, extraDeps?) => {
  const promiseEffectState = usePromiseEffect(promiseFunction, promiseArgs, extraDeps)
  useSpinningController(promiseEffectState.isLoading)
  return promiseEffectState
}
