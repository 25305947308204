import React, { useEffect, useState } from 'react'
import { CheckboxesOption } from '../../ui-components/comum/CheckboxesComponent'
import {
  CheckboxesDefaultStyleComponent,
  CheckboxesDirection,
} from '../../ui-components/filtro/CheckboxesDefaultStyleComponent'

interface OutrosFiltrosCheckboxContainerProps {
  onChange: (newSelectedValue: OutrosFiltrosOptions[]) => void
  selected?: string[]
  direction?: CheckboxesDirection
}

export type OutrosFiltrosOptions = 'SIGILOSO' | 'CITA_TCU'

export function OutrosFiltrosCheckboxContainer({ onChange, selected = [], direction = 'column' }: OutrosFiltrosCheckboxContainerProps) {
  const [filtrosSelecionados, setFiltrosSelecionados] = useState<string[]>(selected)

  function handleChangeTiposFato(lista) {
    setFiltrosSelecionados(lista)
    onChange(lista)
  }

  useEffect(() => {
    setFiltrosSelecionados(selected)
  }, [selected])

  return (
    <CheckboxesDefaultStyleComponent
      value={filtrosSelecionados}
      label="Outros filtros"
      options={getOptions()}
      onChange={handleChangeTiposFato}
      optionsDirection={direction}
    />
  )
}

function getOptions(): CheckboxesOption[] {
  return [
    { label: 'Fato sigiloso', value: 'SIGILOSO' },
    { label: 'Cita o TCU', value: 'CITA_TCU' },
  ]
}
