/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import SearchIcon from '@material-ui/icons/Search'
import { Chip } from '@material-ui/core'
import { AutocompleteGetTagProps } from '@material-ui/lab'
import atorIcon from '../../../assets/atorIcon.svg'
import entidadeIcon from '../../../assets/entidadeIcon.svg'
import { BuscaAutocompleteInputOption } from './BuscaAutocompleteOptionComponent'


const useTagsStyles = makeStyles({
  tagsWrapper: {
    display: 'inline-flex',
    color: '#434343',
    borderColor: '#434343',
    flexWrap: 'nowrap',
  },
  tag: {
    margin: '0 5px 0 0',
    height: '28px',
    maxWidth: 'none',
  },
  tagIcon: {
    marginLeft: '6px !important',
    height: '24px',
    fontSize: '24px',
    color: 'inherit',
  },
  tagDeleteIcon: {
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
      opacity: 0.8,
    },
  },
  tagIconTema: {
    fontSize: '17px',
    top: '4px',
  },
})

interface BuscaAutocompleteTagComponentProps {
  tags: BuscaAutocompleteInputOption[]
  getTagProps: AutocompleteGetTagProps
}

export function BuscaAutocompleteTagComponent({ getTagProps, tags }: BuscaAutocompleteTagComponentProps) {
  const classes = useTagsStyles()
  const iconComponents = {
    ATOR: <img src={atorIcon} alt="Ator" />,
    ENTIDADE: <img src={entidadeIcon} alt="Entidade" />,
    TEMA: <i className={`fas fa-tags fa-fw ${classes.tagIconTema}`} />,
    KEYWORD: <SearchIcon />,
  }
  return (
    <div className={classes.tagsWrapper}>
      {tags.map((tag, index: number) => (
        <Chip
          classes={{
            root: `${classes.tag}`,
            icon: classes.tagIcon,
            deleteIcon: classes.tagDeleteIcon,
          }}
          variant="outlined"
          icon={iconComponents[tag.tipo]}
          label={tag.nome}
          {...getTagProps({ index })}
        />
      ))}
    </div>
  )
}
