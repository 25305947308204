import axios from 'axios'
import * as fs from 'fs'

async function getTokenVault(): Promise<string | null> {
  try {
    const token = fs.readFileSync('/var/run/secrets/kubernetes.io/serviceaccount/token', 'utf8').trim()
    // const data = { jwt: token, role: 'nodejs-vault' }
    const data = { jwt: token, role: 'mapa-bff' }
    const resp = await axios.post('http://vault.vault:8200/v1/auth/kubernetes/login', data)
    if (resp.status === 200) {
      console.log(resp.data)
      const tokenVault = resp.data.auth.client_token
      console.log(`Login Ok. Token=${tokenVault}`)
      return tokenVault
    }
    console.log(`erro.${resp.data}`)
  } catch (error) {
    if (error.code === 'ENOENT') {
      return null
    }
    throw error
    
  }
  return null
}

export const loadVaultConfig = async function loadVaultConfig(): Promise<any> {
  const token = await getTokenVault()
  if (token === null) {
    console.log('token null')
    return '-'
  }
  const headers = { 'X-Vault-Token': token }

  const profile = process.env.NODE_ENV === 'production' ? 'prod' : 'dev'
  const resp = await axios.get('http://vault.vault:8200/v1/secret/data/mapa-bff/' + profile, { headers })

  if (resp === null || resp === undefined) {
    console.log('vault config null or undefined')
  } else {
    console.log('vault config ok')
    console.log('resp: ' + resp)
  }

  return resp
}
