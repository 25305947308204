import React, { useEffect, useState } from 'react'
import { TipoFato, TIPOS_FATO } from 'bff/src/main/domain/TipoFato'
import { CheckboxesOption } from '../../ui-components/comum/CheckboxesComponent'
import { ordernarListaAlfabeticamentePorPropriedade } from '../../../util/sortUtil'
import {
  CheckboxesDefaultStyleComponent,
  CheckboxesDirection,
} from '../../ui-components/filtro/CheckboxesDefaultStyleComponent'

interface TiposFatoCheckboxContainerProps {
  onChange: (newSelectedValue: TiposFatoCheckbox[]) => void
  selected?: string[]
  direction?: CheckboxesDirection
}

export type TiposFatoCheckbox = TipoFato | 'TODOS_OS_TIPOS'

export function TiposFatoCheckboxContainer({ onChange, selected = [], direction = 'column' }: TiposFatoCheckboxContainerProps) {
  const [tiposFato, setTiposFato] = useState<string[]>(selected)

  function selecionar(tipos) {
    setTiposFato(tipos)
    onChange(tipos)
  }

  useEffect(() => {
    setTiposFato(selected)
  }, [selected])

  function handleChangeTiposFato(lista) {
    if (tiposFato.find(tipo => tipo === 'TODOS_OS_TIPOS')) {
      // Nesse caso tinha marcado e não tem mais
      if (!lista.find(tipo => tipo === 'TODOS_OS_TIPOS')) {
        return selecionar([])
      }
      // Nesse caso tinha marcado e ainda tem, então foi retirado algum outro
      if (lista.find(tipo => tipo === 'TODOS_OS_TIPOS')) {
        return selecionar([...lista.filter(tipo => tipo !== 'TODOS_OS_TIPOS')])
      }
    }
    if (!tiposFato.find(tipo => tipo === 'TODOS_OS_TIPOS')) {
      // Nesse caso não tinha e foi marcado
      if (lista.find(tipo => tipo === 'TODOS_OS_TIPOS')) {
        const todosOsTipos = getOptions().map(o => o.value) as string[]
        return selecionar(todosOsTipos)
      }
      // Nesse caso não esta marcado todos os tipos, mas ele selecionou todos os tipos
      if (!lista.find(tipo => tipo === 'TODOS_OS_TIPOS') && lista.length === 5) {
        const todosOsTipos = getOptions().map(o => o.value) as string[]
        return selecionar(todosOsTipos)
      }
    }
    return selecionar(lista)
  }

  return (
    <CheckboxesDefaultStyleComponent
      value={tiposFato}
      label="Fatos do tipo"
      options={getOptions()}
      onChange={handleChangeTiposFato}
      optionsDirection={direction}
    />
  )
}

function getOptions(): CheckboxesOption[] {
  const listaTiposFato = Object.values(TIPOS_FATO).map(
    tipoFato => ({ label: tipoFato.label, value: tipoFato.id }),
  )
  return [{ label: 'Todos os tipos', value: 'TODOS_OS_TIPOS' }, ...ordernarListaAlfabeticamentePorPropriedade(listaTiposFato, 'label')]
}
