import { MapaRoute } from '../components/containers/infra/BasicSwitch'
import { PaginaNaoEncontradaView } from '../components/views/PaginaNaoEncontradaView'
import { AcessoNegadoView } from '../components/views/AcessoNegadoView'

export const emptyLayoutRoutes: MapaRoute[] = [
  {
    path: ['/401', '/403'],
    component: AcessoNegadoView,
  },
  {
    path: '/404',
    component: PaginaNaoEncontradaView,
  },
]
