
export function createApiCallerProxy<T>(apiCallerCreator: () => T) {
  let apiCallerLazyInstance: T | null = null
  return new Proxy({}, {
    get(target, propKey) {
      if (!apiCallerLazyInstance) {
        apiCallerLazyInstance = apiCallerCreator()
      }
      const prop = apiCallerLazyInstance[propKey]
      return typeof prop === 'function' ? wrapApiCallerFunction(prop) : prop
    },
  }) as T
}

function wrapApiCallerFunction(apiCallerFunction: () => any) {
  return function wrapper(...args) {
    const originalErr = new Error('(Stacktrace antes do request) Request gerou um erro.')
    // @ts-ignore
    return apiCallerFunction.apply(this, args).catch((err) => {
      // eslint-disable-next-line no-param-reassign
      err.stack += `\n${updateStacktrace(originalErr)}`
      logIfIsAxiosError(err, originalErr)
      throw err
    })
  }
}

function updateStacktrace(originalErr: Error) {
  /**
   * O .replace abaixo eh pra remover a linha que nao traz informacao relevante quando o erros acontecem.
   */
  // noinspection RegExpRepeatedSpace
  return originalErr.stack?.replace(/\n    at Proxy.wrapper (.*?:\d+:\d+)/g, '') // eslint-disable-line no-regex-spaces
}

function logIfIsAxiosError(err, originalErr: Error) {
  if (err.response) {
    console.error(`
----------------------------------------------------------------------------------
status: ${err.response.status}
method: ${err.response.config.method}
url: ${err.response.config.url}
resposta: ${JSON.stringify(err.response.data, null, ' ')}
stackTraceOriginal: ${updateStacktrace(originalErr)}
----------------------------------------------------------------------------------
     `)
  }
}
