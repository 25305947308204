import { BuscarNoticiaUniaoEndpoint } from 'bff/src/main/controllers/fato/noticiasUniaoEndpoints'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'

export const noticiaUniaoThunkCreators = {

  buscarNoticiaUniaoPorId: (id: string) => createBffRequestThunk({
    request: {
      endpoint: BuscarNoticiaUniaoEndpoint.definition,
      params: { id },
    },
  }),

}
