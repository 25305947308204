import React from 'react'
import { useQueryParamAsArrayOfString } from '../../../infra/libs/react-location-hooks/routerHooks'
import { QUERY_PARAM_ATORES, QUERY_PARAM_ENTIDADES } from '../../../util/constantesQueryParams'
import { StakeholdersTweetsContainer } from '../stakeholder/StakeholdersTweetsContainer'

export function StakeholdersTweetsTelaResultadoContainer() {

  const idsAtores = useQueryParamAsArrayOfString(QUERY_PARAM_ATORES)
  const idsEntidades = useQueryParamAsArrayOfString(QUERY_PARAM_ENTIDADES)
  
  return <StakeholdersTweetsContainer idsStakeholders={[...idsEntidades, ...idsAtores]} />
}
