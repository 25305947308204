import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { Ministro } from '../../domain/Ministro'
import { ministroApi } from '../../api/ministrosApi'


export namespace BuscarMinistrosPorListaCodigoEndpoint {
  export type Body = undefined
  export type Params = undefined
  export type Query = {
    ministrosIds: string[]
  }
  export type Response = Ministro[]
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/ministros',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<Response>) => {
      const { query } = request
      const { data: ministros } = await ministroApi.getMinistrosPorIds(query.ministrosIds)
      response.json(ministros)
    },
  })
}
