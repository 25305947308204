import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ImagemComBordaContainer } from '../../containers/comum/ImagemComBordaContainer'

const useStyles = makeStyles({
  imagemStakeholder: {
    height: '150px',
    marginRight: '34px',
    '@media screen and (max-width:1920px)': {
      width: '150px',
    },
    '@media screen and (max-width:1500px)': {
      width: '140px',
      height: '140px',
    },
    '@media screen and (max-width:1130px)': {
      width: '130px',
      height: '130px',
    },
    '@media screen and (max-width:767px)': {
      width: '120px',
      height: '120px',
      paddingTop: '20px',
    },
  },
  alingStakeholder: {
    margin: 'auto 0',
    '@media screen and (max-width:567px)': {
      margin: '30px 0 0 0',
    },
    '@media screen and (max-width:768px)': {
      margin: '30px 0 0 0',
    },
  },
  nome: {
    color: '#231f20',
    fontSize: '32px',
    fontWeight: 500,
    marginBottom: 0,
    '@media screen and (max-width:768px)': {
      marginTop: '20px',
    },
  },
  nomeCompleto: {
    fontSize: '14px',
    color: '#919090',
    fontWeight: 500,
  },
  informacoesAdicionais: {
    fontSize: '16px',
    fontWeight: 500,
    marginTop: '16px',
    color: '#525252',
  },
  alingHeaderStakeholder: {
    display: 'flex',
    alignItems: 'center',
    '@media screen and (max-width:768px)': {
      display: 'block',
    },
  },
})

export interface AvatarStakeholderProps {
  nome: string
  nomeCompleto: string
  urlImagem: string
  stakeholderInterno: boolean
  cargo?: string
  // ator
  orgao?: string
  // entidade
  nomeAutoridadeEntidade?: string
}

export function AvatarStakeholderComponent(props: AvatarStakeholderProps) {
  const classes = useStyles()
  return (
    <div className={`${classes.alingHeaderStakeholder} col-md-12 col-lg-12 col-xl-5`}>
      <div className={classes.imagemStakeholder}>
        <ImagemComBordaContainer urlImagem={props.urlImagem} papel={props.cargo} stakeholderInterno={props.stakeholderInterno} />
      </div>
      <div className={classes.alingStakeholder}>
        <span className={classes.nomeCompleto}>{props.nomeCompleto}</span>
        <h2 className={classes.nome}>{props.nome}</h2>
        <h4 className={classes.informacoesAdicionais}>
          {props.cargo}
          {
            props.orgao ? ` | ${props.orgao}` : null
          }
          {
            props.nomeAutoridadeEntidade ? ` | ${props.nomeAutoridadeEntidade}` : null
          }
        </h4>
      </div>
    </div>
  )
}
