import React, { ReactNode, MouseEvent } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Popover } from '@material-ui/core'
import { TuneOutlined } from '@material-ui/icons'
import { ButtonSpanComponent } from '../comum/ButtonSpanComponent'

const useStyles = makeStyles({
  box: {
    width: '855px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '18px',
  },
  buttonWrapper: {
    left: '-5px',
    display: 'flex',
    top: 0,
    alignItems: 'center',
    height: '100%',
    width: '225px',
  },
  button: {
    padding: '12px',
    fontSize: '14px',
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    height: '56px',
    backgroundColor: '#2F5DC9',
    color: '#fff',
    borderRadius: '0px 8px 8px 0px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#2b4faa',
    },
  },
  buttonIcon: {
    fontSize: '24px',
  },
})

interface BuscaAvancadaButtomPopoverComponentProps {
  anchorEl: HTMLButtonElement | null
  onClose: () => any
  onOpen: (event: MouseEvent<HTMLButtonElement>) => any
  children: ReactNode
}

export function BuscaAvancadaButtomPopoverComponent({ anchorEl, onClose, onOpen, children }: BuscaAvancadaButtomPopoverComponentProps) {
  const classes = useStyles()

  const open = Boolean(anchorEl)
  const id = open ? 'buscaAvancada' : undefined
  
  return (
    <div>
      <div className={classes.buttonWrapper}>
        <ButtonSpanComponent className={classes.button} onClick={onOpen}>
          <TuneOutlined className={classes.buttonIcon} htmlColor="#fff" />
          <span>Pesquisa avançada</span>
        </ButtonSpanComponent>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.box}>
          {children}
        </div>
      </Popover>
    </div>
  )
}
