import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles({
  textBox: {
    fontSize: '14px',
    color: '#434343',
    fontWeight: 500,
    margin: '8px 16px',
  },
  textoArquivado: {
    color: '#e35555',
  },
})

interface ContadorQuantidadeFatosCenarioComponentProps {
  quantidadeFatos?: number
  isCenarioArquivado: boolean
}

export function ContadorQuantidadeFatosCenarioComponent({ quantidadeFatos, isCenarioArquivado }: ContadorQuantidadeFatosCenarioComponentProps) {
  const classes = useStyles()
  if (isCenarioArquivado) {
    return <span className={` ${classes.textBox} ${classes.textoArquivado}`}>Este cenário está arquivado</span>
  }
  return (
    <h4 className={classes.textBox}>
      { `Total de fatos encontrados: ${quantidadeFatos ? quantidadeFatos.toLocaleString('pt-BR') : 0}` }
    </h4>
  )
}
