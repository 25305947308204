import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { MeusCenariosContainer } from '../containers/cenario/MeusCenariosContainer'

const useStyles = makeStyles({
  root: {
    marginTop: '32px',
    marginBottom: '80px',
    paddingLeft: '30px',
  },
})

export function MeusCenariosView() {
  const classes = useStyles()

  return (
    <div className={`${classes.root}`}>
      <MeusCenariosContainer />
    </div>
  )
}
