import { fatosApi } from '../../../api/fatosApi'
import { dateUtil } from '../../../util/dateUtil'
import { marcarStakeholdersNoTexto } from '../../stakeholder/highlightNosStakeholders'
import { recuperarProcessosRelacionadosDoFato } from '../processo/recuperarProcessosRelacionadosDoFato'
import { montarResumoProcesso } from '../processo/montarResumoProcesso'
import { NoticiaUniao } from '../../../domain/NoticiaUniao'


export async function recuperarNoticiaUniaoPorId(id: string) {
  const { body: respostaIndiceNoticias } = await fatosApi.buscarFatosPorId(id)
  const noticiaUniao = respostaIndiceNoticias.hits.hits[0]
  const processosRelacionados = await recuperarProcessosRelacionadosDoFato(noticiaUniao)
  return mapearNoticia(noticiaUniao, processosRelacionados)
}

function mapearNoticia(noticiaUniao, processosRelacionados: any[]): NoticiaUniao {
  return {
    id: noticiaUniao._id,
    titulo: noticiaUniao._source.titulo,
    tipoFato: noticiaUniao._source.tipo_fato,
    urlFonte: noticiaUniao._source.url_original,
    texto: marcarStakeholdersNoTexto(
      'texto',
      noticiaUniao._source.texto, noticiaUniao._source.marcacoes_stakeholders_relacionados,
      noticiaUniao._source.stakeholders_relacionados,
    ),
    dataHora: `Publicada em ${dateUtil.formatarData(noticiaUniao._source.data_publicacao)}`,
    stakeholdersIds: noticiaUniao._source.stakeholders_relacionados,
    fatosRelacionados: processosRelacionados.map(montarResumoProcesso),
    marcacoesStakeholders: noticiaUniao._source.marcacoes_stakeholders_relacionados,
  }
}
