import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { FatoComFavorito } from 'bff/src/main/domain/ResumoFato'
import { ListaFatosComponent } from './ListaFatosComponent'
import { ButtonComponent } from '../comum/ButtonComponent'

const useStyles = makeStyles({
  listaFatosStakeholderBox: {
    display: 'flex',
    justifyContent: 'center',
  },
})

interface ListaFatosPefilStakeholderProps {
  fatos: FatoComFavorito[]
  onClick: () => void
}

export function ListaFatosPerfilStakeholderComponent({ fatos, onClick }: ListaFatosPefilStakeholderProps) {
  const classes = useStyles()
  return (
    <div>
      <ListaFatosComponent
        fatos={fatos}
      />
      <div className={classes.listaFatosStakeholderBox}>
        <ButtonComponent
          buttonText="Mostrar mais"
          buttonTypeColor="white"
          onClick={onClick}
        />
      </div>
    </div>
  )
}
