import {
  BuscarContadoresTelaInicio,
  BuscarContadoresTiposDeFatos,
  BuscarContadorFatosEndpoint,
} from 'bff/src/main/controllers/contador/contadoresEndpoints'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'
import { alertActionCreators } from './alertActions'

export const contadorThunkCreators = {

  buscarContadorFatos: (queryParams: BuscarContadorFatosEndpoint.Query) => createBffRequestThunk({
    request: {
      endpoint: BuscarContadorFatosEndpoint.definition,
      queryParams,
    },
  }),

  buscarContadoresTiposDeFato: (queryParams: BuscarContadoresTiposDeFatos.Query) => createBffRequestThunk({
    request: {
      endpoint: BuscarContadoresTiposDeFatos.definition,
      queryParams,
    },
  }),

  buscarContadoresTelaInicio: () => createBffRequestThunk({
    request: {
      endpoint: BuscarContadoresTelaInicio.definition,
    },
    actions: {
      error: () => alertActionCreators.showError('Ocorreu um erro ao tentar recuperar as informações do sistema.'),
    },
  }),
}
