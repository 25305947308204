import React from 'react'
import { GRAUS_SIGILO } from 'bff/src/main/domain/GrauSigilo'
import { CheckboxesOption } from '../../ui-components/comum/CheckboxesComponent'
import { CheckboxesFilterContainer } from './CheckboxesFilterContainer'
import { ordernarListaAlfabeticamentePorPropriedade } from '../../../util/sortUtil'

export function FiltroSigilosoContainer() {
  return (
    <CheckboxesFilterContainer
      filterName="filtroGrausSigilo"
      filterLabel="Grau de sigilo do fato"
      options={getOptions()}
    />
  )
}

function getOptions(): CheckboxesOption[] {
  const listaGrausSigilo = Object.values(GRAUS_SIGILO).map(
    grauSigilo => ({ label: grauSigilo.label, value: grauSigilo.id }),
  )
  
  return ordernarListaAlfabeticamentePorPropriedade(listaGrausSigilo, 'label')
}
