import { createOnBoardingStepContainer } from './createOnBoardingStepContainer'


const name = 'TELA_INICIAL'
const urlRegex = /^\/?$/
const totalSteps = 4

export const OnBoardingTelaInicial = {

  Step1: createOnBoardingStepContainer({
    name,
    urlRegex,
    totalSteps,
    currentStep: 1,
    position: 'right',
    title: 'Bem vindo(a) ao novo Mapa',
    text: 'Agora, além das buscas por atores, entidades, temas e palavras-chave, acompanhe os fatos mais recentes na sua página Início.',
    labelButtonNext: 'Avançar',
    labelStep: 'Novidade',
  }),

  Step2: createOnBoardingStepContainer({
    name,
    urlRegex,
    totalSteps,
    currentStep: 2,
    position: 'right',
    title: 'Monitore seus cenário',
    text: 'Crie cenários a partir de suas pesquisas e monitore tudo que acontece envolvendo os temas e atores de seu interesse, acessando a página do cenário.',
    labelButtonNext: 'Avançar',
    labelButtonBack: 'Voltar',
    labelStep: 'Novidade',
  }),

  Step3: createOnBoardingStepContainer({
    name,
    urlRegex,
    totalSteps,
    currentStep: 3,
    position: 'right',
    title: 'Últimos acontecimentos',
    text: 'Veja os fatos mais recentes relacionados às suas últimas pesquisas ou aos cenários criados na sua tela Início. ',
    labelButtonNext: 'Avançar',
    labelButtonBack: 'Voltar',
    labelStep: 'Novidade',
  }),

  Step4: createOnBoardingStepContainer({
    name,
    urlRegex,
    totalSteps,
    currentStep: 4,
    position: 'left',
    title: 'Acesso rápido',
    text: 'Acesse os perfis dos atores e entidades que você mais acompanha e saiba o que eles estão comentando no Twitter.',
    labelButtonNext: 'Entendi',
    labelButtonBack: 'Voltar',
    labelStep: 'Novidade',
  }),

}
