import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

declare module '@material-ui/core/styles/zIndex' {
  interface ZIndex {
    spinner: number
  }
}

export const theme = createMuiTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  palette: {
    type: 'light',
    primary: {
      main: '#0071BC',
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
    spinner: 1600,
  },
})

export const MapaColor = {
  primary: '#2C71D9',
  primaryMedium: '#214CA2',
  secondaryDarkest: '#034639',
  secondaryDark: '#178A73',
  secondaryMedium: '#00B28E',
  secondaryLight: '#23D4B1',
  secondaryLightest: '#9BF0DF',
  neutralDarkest: '#323232',
  neutralDark: '#424242',
  neutralMediumDark: '#595959',
  neutralMedium: '#A6AEBA',
  neutralMediumLight: '#D4D9E0',
  neutralLight: '#F8F8F8',
  neutralLightest: '#FFFFFF',
  suport01: '#FF8800',
  suport02: '#F30E45',
  suport03: '#5821D5',
  infoDarkest: '#00478F',
  infoDark: '#005CB8',
  infoMedium: '#0680F9',
  infoMediumLight: '#51A3F6',
  infoLight: '#C8E0F9',
  infoLightest: '#EBF4FD',
  successDarkest: '#006600',
  successDark: '#008F00',
  successMedium: '#16AA09',
  successMediumLight: '#73D260',
  successLight: '#DAF4CD',
  successLightest: '#EDFEE5',
  dangerDarkest: '#970D0D',
  dangerDark: '#C61010',
  dangerMedium: '#E63737',
  dangerMediumLight: '#EF8080',
  dangerLight: '#F6CBCB',
  dangerLightest: '#FFF1F1',
  warningDarkest: '#66380F',
  warningDark: '#995417',
  warningMedium: '#B2621A',
  warningMediumLight: '#FF9D47',
  warningLight: '#FFBB80',
  warningLightest: '#FFE6CF',
  warningMain: '#FFC72E',
}

export type AppTheme = typeof theme
