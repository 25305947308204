import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { mapaApi } from '../../api/mapaApi'


export namespace ChecarSeUsuarioVisualizouOnBoardingEndpoint {
  export type Body = undefined
  export type Params = { nome: string }
  export type Query = undefined
  export type ResponseBody = boolean
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/checar-se-usuario-visualizou-on-boarding/:nome',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { nome } = request.params
      const { data: visualizou } = await mapaApi.checarSeUsuarioVisualizouOnBoarding(nome)
      response.json(visualizou)
    },
  })
}

export namespace RegistrarVisualizacaoOnBoardingEndpoint {
  export type Body = undefined
  export type Params = { nome: string }
  export type Query = undefined
  export type ResponseBody = unknown
  export const definition = defineEndpoint({
    httpMethod: 'POST',
    url: '/visualizacao-onboarding/:nome',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { nome } = request.params
      const { data: visualizacaoOnBoarding } = await mapaApi.registrarVisualizacaoOnBoarding(nome)
      response.status(201)
      response.json(visualizacaoOnBoarding)
    },
  })
}
