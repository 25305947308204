import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ListaFatosContainer } from '../containers/fato/ListaFatosContainer'
import { StakeholdersPesquisadosContainer } from '../containers/stakeholder/StakeholdersPesquisadosContainer'
import { TituloResultadoBuscaPrincipalContainer } from '../containers/busca/TituloResultadoBuscaPrincipalContainer'
import { FiltrosResultadosComponent } from '../ui-components/filtro/FiltrosResultadosComponent'
import { TitleComponent } from '../ui-components/head/TitleComponent'
import { StakeholdersTweetsTelaResultadoContainer } from '../containers/comum/StakeholdersTweetsTelaResultadoContainer'
import { BotaoCriarCenarioTelaResultadoContainer } from '../containers/cenario/BotaoCriarCenarioTelaResultadoContainer'
import filtro from '../../assets/filtro.svg'
import { StakeholdersSugeridosContainer } from '../containers/stakeholder/StakeholdersSugeridosContainer'

const useStyles = makeStyles({
  root: {
    marginTop: '18px',
  },
  containerDeCima: {
    marginTop: '28px',
  },
  containerDeBaixo: {
    marginTop: '28px',
  },
  stakeholdersPesquisados: {
    marginBottom: '86px',
  },
  filtros: {
    maxWidth: '310px',
    '&::-webkit-scrollbar-track': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      borderRadius: '20px',
      backgroundColor: '#eee',
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      borderRadius: '20px',
      backgroundColor: '#eee',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
      backgroundColor: '#CBCBCB',
    },
  },
  btnFiltrar: {
    display: 'none',
    border: '#7e7e7e solid 1px',
    padding: '0.5em',
    borderRadius: '3px',
    marginBottom: '2em',
    '@media screen and (max-width:580px)': {
      display: 'block',
    },
  },
  modalFiltros: {
    position: 'absolute',
    top: '0',
    left: '16px',
    width: 'calc(100% - 32px)',
    height: '43px',
    opacity: '0',
    display: 'none',
    '@media screen and (max-width:580px)': {
      display: 'block',
      '& + $filtros': {
        position: 'fixed',
        zIndex: '999',
        top: '66px',
        left: '-120vw',
        width: '100vw !important',
        maxWidth: '100vw !important',
        height: '100vh',
        overflow: 'auto',
        padding: '2em',
        background: '#f9f9f9',
        boxShadow: 'rgba(0,0,0,.5) 0px 2px 12px',
        display: 'flex',
        justifyContent: 'center',
        transitionProperty: 'left',
        transitionDuration: '0.3s',
        transitionTimingFunction: 'ease-in-out',
      },
      '&:checked + $filtros': {
        left: '0',
        transitionProperty: 'left',
        transitionDuration: '0.3s',
        transitionTimingFunction: 'ease-in-out',
      },
    },
    '@media screen and (max-width:359px)': {
      display: 'block',
      '& + $filtros': {
        width: '100%',
        maxWidth: '100%',
        height: '100%',
      },
    },
  },
  botaoCriarCenario: {
    paddingTop: '32px',
  },
})

export function ResultadosView() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <TitleComponent title="Resultados" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <TituloResultadoBuscaPrincipalContainer />
            <div className={classes.btnFiltrar}>
              <img src={filtro} alt="Filtro" />
              <span> Filtrar Resultados</span>
            </div>

            <input type="checkbox" id="chBox" className={classes.modalFiltros} />

            <div className={classes.filtros}>
              <FiltrosResultadosComponent />
              <div className={classes.botaoCriarCenario}>
                <BotaoCriarCenarioTelaResultadoContainer />
              </div>
            </div>
          </div>
          <div className="col-lg-10 col-md-12 col-ls-12">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-ls-12">
                <ListaFatosContainer />
              </div>
              <div className="col-lg-4 col-md-12 col-ls-12">
                <div className={classes.stakeholdersPesquisados}>
                  <StakeholdersPesquisadosContainer />
                  <StakeholdersSugeridosContainer />
                </div>
                <StakeholdersTweetsTelaResultadoContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
