import { ApiResponse } from '@elastic/elasticsearch/lib/Transport'
import { RequestParams } from '@elastic/elasticsearch'
import { CountResponse } from './elasticTypes'
import { elasticClient } from './elasticClient'

interface CountOnIndexParams extends RequestParams.Count {
  index: string
  body?: any
}

type CountOnIndexResult = Promise<ApiResponse<CountResponse>>

export function countOnIndex(params: CountOnIndexParams): CountOnIndexResult {
  return elasticClient.count(params)
}
