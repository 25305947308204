import { useState } from 'react'
import { Dispatch, Reducer } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from '../../../hooks/useEffectOnce'
import { swansReducersMap } from './swansReducersMap'
import { createSwanSelector } from './createSwansReducer'


// TODO: fazer documentação
export function useSwan<S>(reducer: Reducer<S>, initialState?: S) {
  const dispatch = useDispatch()
  const [swanName] = useState(() => {
    const componentName = getComponentNameWhereSwanIsBeenUsed() ?? 'swan'
    return `${componentName}-${generateRandomString(9)}`
  })
  if (isSwanNotStarted(swanName)) initSwan(dispatch, swanName, reducer, initialState)
  useEffectOnce(() => () => destroySwan(dispatch, swanName))
  return useSelector(createSwanSelector(swanName)) as S
}

function getComponentNameWhereSwanIsBeenUsed() {
  try {
    // @ts-ignore
    return (new Error()).stack.match(/at (\S+)/g)[6].slice(3)
  } catch (ignored) {
    return undefined
  }
}

function isSwanNotStarted(swanName: string) {
  return !swansReducersMap[swanName]
}

function initSwan<S>(dispatch: Dispatch, swanName: string, reducer: Reducer<S>, initialState?: S) {
  dispatch({
    type: '@swans/initSwan',
    swanName,
    reducer,
    initialState,
  })
}

function destroySwan(dispatch: Dispatch, swanName: string) {
  dispatch({
    type: '@swans/destroySwan',
    swanName,
  })
}

function generateRandomString(length: number) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
