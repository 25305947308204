import { EnumObjectWithId } from '../util/EnumObject'

export type TipoFato =
  'EVENTO_RELACIONA' |
  'NOTICIA_CLIPPING' |
  'NOTICIA_UNIAO' |
  'PROCESSO' |
  'PROPOSICAO_LEGISLATIVA'

export const TIPOS_FATO: EnumObjectWithId<TipoFato, { label: string }> = {
  EVENTO_RELACIONA: {
    id: 'EVENTO_RELACIONA',
    label: 'Evento',
  },
  NOTICIA_CLIPPING: {
    id: 'NOTICIA_CLIPPING',
    label: 'Notícia',
  },
  NOTICIA_UNIAO: {
    id: 'NOTICIA_UNIAO',
    label: 'União',
  },
  PROCESSO: {
    id: 'PROCESSO',
    label: 'Processo',
  },
  PROPOSICAO_LEGISLATIVA: {
    id: 'PROPOSICAO_LEGISLATIVA',
    label: 'Proposição legislativa',
  },
}
