import React, { ReactNode, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Menu } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles({
  menu: {
    '& .MuiPaper-root': {
      width: '310px',
      zIndex: '999',
    },
  },
  select: {
    border: '1px solid #919191',
    borderRadius: '4px',
    textTransform: 'capitalize',
    justifyContent: 'flex-start',
    borderColor: '#919191',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 10px',
    width: '100%',
    fontSize: '16px',
    fontWeight: 400,
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '45%',
      right: '10px',
      width: '0',
      height: '0',
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderTop: '5px solid #757575',
    },
  },
})

interface ComboboxComponentProps {
  className?: string
  icon?: ReactNode
  options: ComboboxComponentItemProps[]
  selectedOption: ComboboxComponentItemProps
  onChange: (option: ComboboxComponentItemProps) => void
}

export interface ComboboxComponentItemProps {
  label: string
  key: string
  value: any
}

export function ComboboxComponent({
  selectedOption, options, onChange, icon = null, className, 
}: ComboboxComponentProps) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  
  function handleClose(event) {
    setAnchorEl(null)
    if (event.currentTarget.attributes.value !== undefined) {
      const newSelectedOption = options.find(option => option.key === event.currentTarget.attributes.value.value)
      if (newSelectedOption) {
        onChange(newSelectedOption)
      }
    }
  }

  return (
    <div className={className}>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={classes.select}>
        {icon}
        {selectedOption.label}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
      >
        {
          options.map(option => <MenuItem key={option.key} onClick={handleClose} value={option.key}>{option.label}</MenuItem>)
        }
      </Menu>
    </div>
  )
}
