import React from 'react'
import { makeStyles, TextField } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { TIPOS_TRECHO_FICHA, TipoTrechoFicha } from 'bff/src/main/domain/TipoTrechoFicha'
import { ButtonComponent } from '../comum/ButtonComponent'
import { FieldErrorComponent } from '../comum/FieldErrorComponent'
import { IconTituloTextoComponent } from '../icons/IconTituloTextoComponent'
import { IconTextoAlignComponent } from '../icons/IconTextoAlignComponent'


const trechosIconsMap = {
  [TIPOS_TRECHO_FICHA.TITULO.id]: {
    icon: <IconTituloTextoComponent fill="#9E9E9E" width="20" height="20" />,
  },
  [TIPOS_TRECHO_FICHA.SUBTITULO.id]: {
    icon: <IconTituloTextoComponent fill="#9E9E9E" height="15" width="15" />,
  },
  [TIPOS_TRECHO_FICHA.PARAGRAFO.id]: {
    icon: <IconTextoAlignComponent fill="#9E9E9E" height="20" width="20" />,
  },
}

const useStyles = makeStyles({
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
  },
  icone: {
    marginRight: '19px',
  },
  textfield: {
    width: '100%',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
  cancelButtom: {
    marginRight: '16px',
  },
})

interface Fields {
  textoTrecho: string
}

interface TextfieldTrechoTextualFichaComponentProps {
  textoAnterior?: string
  onConfirm: (texto: string) => void
  onCancel: () => void
  tipoTrechoTextual?: TipoTrechoFicha
  labelButtonConfirm: string
  labelButtonCancel: string
}

export function TextfieldTrechoTextualFichaComponent(
  {
    textoAnterior, onConfirm, onCancel, tipoTrechoTextual, labelButtonCancel, labelButtonConfirm, 
  }
  : TextfieldTrechoTextualFichaComponentProps,
) {
  const classes = useStyles()

  const {
    register, handleSubmit, errors,
  } = useForm<Fields>({ defaultValues: {
    textoTrecho: textoAnterior ?? '',
  } })

  function realizarAcao(formValues: Fields) {
    onConfirm(formValues.textoTrecho)
  }


  return (
    <div>
      <form onSubmit={handleSubmit(realizarAcao)}>
        <div className={classes.box}>
          {
            tipoTrechoTextual ? (
              <div className={classes.icone}>
                {trechosIconsMap[tipoTrechoTextual].icon}
              </div>
            ) : null
          }
          <FieldErrorComponent className={classes.textfield} error={!!errors.textoTrecho}>
            <TextField
              inputRef={register({ required: true })}
              name="textoTrecho"
              id="outlined-size-normal"
              variant="outlined"
              fullWidth
              multiline={tipoTrechoTextual === 'PARAGRAFO'}
              rows={tipoTrechoTextual === 'PARAGRAFO' ? 4 : 1}
            />
          </FieldErrorComponent>
        </div>
        <div className={classes.buttons}>
          <ButtonComponent
            buttonText={labelButtonCancel}
            buttonTypeColor="white"
            onClick={onCancel}
            className={classes.cancelButtom}
            size="small"
          />
          <ButtonComponent
            buttonText={labelButtonConfirm}
            buttonTypeColor="blue"
            type="submit"
            size="small"
          />
        </div>
      </form>
    </div>
  )
}
