import { TipoFato, TIPOS_FATO } from '../../domain/TipoFato'
import { montarResumoNoticiaClipping } from './noticia-clipping/montarResumoNoticiaClipping'
import { montarResumoNoticiaUniao } from './noticia-uniao/montarResumoNoticiaUniao'
import { montarResumoEvento } from './evento/montarResumoEvento'
import { montarResumoProposicaoLegislativa } from './proposicao/montarResumoProposicaoLegislativa'
import { montarResumoProcesso } from './processo/montarResumoProcesso'
import { ResumoFato } from '../../domain/ResumoFato'


const FATO_HANDLER_MOUNT_MAP = {
  [TIPOS_FATO.NOTICIA_CLIPPING.id]: montarResumoNoticiaClipping,
  [TIPOS_FATO.NOTICIA_UNIAO.id]: montarResumoNoticiaUniao,
  [TIPOS_FATO.EVENTO_RELACIONA.id]: montarResumoEvento,
  [TIPOS_FATO.PROPOSICAO_LEGISLATIVA.id]: montarResumoProposicaoLegislativa,
  [TIPOS_FATO.PROCESSO.id]: montarResumoProcesso,
}

export function montarResumoFato(fato, tipoFato: TipoFato): ResumoFato {
  const montar = FATO_HANDLER_MOUNT_MAP[tipoFato]
  if (montar) {
    return montar(fato)
  }
  throw new Error('Tipo de fato não encontrado.')
}
