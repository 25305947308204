import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Cenario } from 'bff/src/main/domain/Cenario'
import { cenarioThunkCreators } from '../../../store/actions/cenarioActions'
import { TypedDispatch, useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { modalArquivarCenarioActionCreators } from '../modal/modal-arquivar-cenario-container/modalArquivarCenarioActions'
import { ConfiguracaoCenarioComponent } from '../../ui-components/cenario/ConfiguracaoCenarioComponent'
import { useSpinningPromise } from '../../../infra/libs/react-swearing/SpinnerScreenProvider'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { stakeholderThunkCreators } from '../../../store/actions/stakeholderActions'


interface ConfiguracaoCenarioContainerProps {
  cenario: Cenario
}

export function ConfiguracaoCenarioContainer({ cenario }: ConfiguracaoCenarioContainerProps) {

  const dispatch = useTypedDispatch()
  const history = useHistory()
  const [visivelTelaInicial, setVisivelTelaInicial] = useState<boolean>(cenario.visivelEmTelaInicial)

  const { data: stakeholders } = usePromiseEffect(recuperarStakeholders, [dispatch, cenario])

  const { run: onDesarquivar } = useSpinningPromise((id: number) => dispatch(cenarioThunkCreators.desarquivarCenario(id)))

  const { run: onClickVisualizarPaginaInicio } = useSpinningPromise(async (id: number, isVisivel: boolean) => {
    try {
      await dispatch(cenarioThunkCreators.alterarVisualizacaoTelaInicio(id, isVisivel))
      setVisivelTelaInicial(isVisivel)
    } catch {
      setVisivelTelaInicial(!isVisivel)
    }
  })
  
  const onEditar = (id: number) => { history.push(`/cenarios/editar/${id}`) }
  const onArquivar = (id: number) => dispatch(modalArquivarCenarioActionCreators.abrirModal(id))

  return (
    <ConfiguracaoCenarioComponent
      cenario={cenario}
      visivelTelaInicial={visivelTelaInicial}
      onArquivar={onArquivar}
      onDesarquivar={onDesarquivar}
      onEditar={onEditar}
      onClickVisualizarPaginaInicio={onClickVisualizarPaginaInicio}
      atores={stakeholders?.atores || []}
      entidades={stakeholders?.entidades || []}
    />
  )
}

async function recuperarStakeholders(dispatch: TypedDispatch, cenario: Cenario) {
  if (cenario.consulta.stakeholders) {
    const atores = cenario.consulta.stakeholders.filter(s => !s.includes('ENTIDADE'))
    const entidades = cenario.consulta.stakeholders.filter(s => s.includes('ENTIDADE'))
    try {
      const { data: atoresRecuperados } = await dispatch(stakeholderThunkCreators.buscarStakeholdersPorId(atores))
      const { data: entidadesRecuperadas } = await dispatch(stakeholderThunkCreators.buscarStakeholdersPorId(entidades))
      return {
        atores: atoresRecuperados.map(a => a.nome),
        entidades: entidadesRecuperadas.map(e => e.nome),
      }
    } catch (e) {
      console.warn(e)
    }
  }
  return { atores: [], entidades: [] }
}
