import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { NovoCenarioContainer } from '../containers/cenario/NovoCenarioContainer'
import { TitleComponent } from '../ui-components/head/TitleComponent'

const useStyles = makeStyles({
  root: {
    marginTop: '32px',
    marginBottom: '80px',
    paddingLeft: '30px',
  },
})

export function NovoCenarioView() {
  const classes = useStyles()
  return render(classes)
}

function render(classes) {
  return (
    <div className={`row ${classes.root}`}>
      <div className="col-md-8">
        <TitleComponent title="Novo cenário" />
        <NovoCenarioContainer />
      </div>
    </div>
  )
}
