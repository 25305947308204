import React from 'react'
import { makeStyles } from '@material-ui/styles'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import { Badge, Tooltip } from '@material-ui/core'
import { usePromise } from '../../../infra/libs/react-swearing/usePromise'
import { ButtonSpanComponent } from './ButtonSpanComponent'

const useStyles = makeStyles({
  colorIconNaoFavoritado: {
    color: '#6c757d',
    cursor: 'pointer',
  },
})

interface FavoritosProps {
  quantidadeFavoritos?: number
}


export function ButtonIconContadorFavoritosComponent(props: FavoritosProps) {
  const classes = useStyles()

  const { run: handleClick } = usePromise(async () => {
    // ir para a tela de favoritos
  })

  return (
    <ButtonSpanComponent onClick={() => handleClick()}>
      <Tooltip title="Listar meus favoritos">
        <Badge badgeContent={props.quantidadeFavoritos} color="error">
          <StarBorderIcon className={classes.colorIconNaoFavoritado} />
        </Badge>
      </Tooltip>
    </ButtonSpanComponent>
  )
}
