
export const fotoUtil = {
  tratarFotoAtor(ator) {
    if (ator._source.nome === 'José Mucio Monteiro') {
      return 'https://portal.tcu.gov.br/lumis/portal/file/fileDownload.jsp?fileId=8A8182A259752DDF01598851E85A60E2&inline=1'
    }

    if (ator._source.nome === 'Walton Alencar Rodrigues') {
      return 'https://portal.tcu.gov.br/lumis/portal/file/fileDownload.jsp?fileId=8A8182A259752DDF01598851EB1861A8&inline=1'
    }

    if (ator._source.nome === 'Aroldo Cedraz') {
      return 'https://portal.tcu.gov.br/lumis/portal/file/fileDownload.jsp?fileId=8A8182A259752DDF01598851EA306166&inline=1'
    }

    if (ator._source.nome === 'Augusto Sherman') {
      return 'https://portal.tcu.gov.br/lumis/portal/file/fileDownload.jsp?fileId=8A8182A159BDCB490159CD32971E1A32&inline=1'
    }

    if (ator._source.foto_url) {
      return ator._source.foto_url
    }
    if (ator._source.foto) {
      return ator._source.foto
    }
    if (ator._source.foto_base64) {
      return `data:image/png;base64,${ator._source.foto_base64}`
    }
    return 'IMAGEM_ATOR_NAO_ENCONTRADO'
  },

  tratarFotoEntidade(entidade) {
    if (entidade._source.foto_url) {
      return entidade._source.foto_url
    }
    if (entidade._source.foto) {
      return entidade._source.foto
    }
    if (entidade._source.foto_base64) {
      return `data:image/png;base64,${entidade._source.foto_base64}`
    }
    return 'IMAGEM_ENTIDADE_NAO_ENCONTRADO'
  },
}
