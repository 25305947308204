export class RecursoComputacional {

  private constructor(readonly baseUrlCloudConfigProp: string, readonly codigoRecursoComputacional: number) {}

  static readonly AUTH_JWT_MS = new RecursoComputacional('URL_BASE_AUTH_JWT_MS', 300)

  static readonly MAPA_BFF = new RecursoComputacional('URL_BASE_MAPA', 335)

  static readonly MAPA = new RecursoComputacional('URL_BASE_MAPA_SERVICO', 354)

  static readonly MINISTRO = new RecursoComputacional('URL_BASE_MINISTRO', 284)

  static readonly SERVICO_FONETICO = new RecursoComputacional('URL_BASE_SERVICO_FONETICO', 386)

}
