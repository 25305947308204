import { stakeholdersApi } from '../../api/stakeholdersApi'
import { SearchResponse } from '../../infra/elastic/elasticTypes'

export interface StakeholdersComIdEAlias {
  id: string
  nome: string
  listaNomes: string[]
  listaNomesFoneticos: string []
  nomeCompleto: string
  tipoStakeholder: string
}

export interface AtoresEntidadesComIdEAlias {
  atores: StakeholdersComIdEAlias[]
  entidades: StakeholdersComIdEAlias[]
}

export async function recuperarAliasStakeholderPorId(listaAtoresIds: string[], listaEntidadesIds: string[]) {
  const { body: respostaIndiceStakeholdersAtores } = await stakeholdersApi.buscarStakeholdersPorIds(listaAtoresIds)
  const { body: respostaIndiceStakeholdersEntidades } = await stakeholdersApi.buscarStakeholdersPorIds(listaEntidadesIds)
  const atoresEntidades: AtoresEntidadesComIdEAlias = {
    atores: mapearParaAliasStakeholders(respostaIndiceStakeholdersAtores),
    entidades: mapearParaAliasStakeholders(respostaIndiceStakeholdersEntidades),
  }
  return atoresEntidades
}

function mapearParaAliasStakeholders(resposta: SearchResponse) {
  const listaAutoridades = resposta.hits.hits
  const stakeholders: StakeholdersComIdEAlias[] = listaAutoridades.map(autoridade => ({
    id: autoridade._id,
    nome: autoridade._source.nome,
    listaNomes: autoridade._source.aliases,
    listaNomesFoneticos: autoridade._source.aliases_foneticos,
    nomeCompleto: autoridade._source.nome_completo,
    tipoStakeholder: autoridade._source.tipo_stakeholder,
  }))
  return stakeholders
}
