import React from 'react'
import { StakeholderComFavorito } from 'bff/src/main/domain/ResumoStakeholder'
import { StakeholdersRelacionadosPlaceholderComponent } from '../../ui-components/stakeholder/StakeholdersRelacionadosPlaceholderComponent'
import { stakeholderThunkCreators } from '../../../store/actions/stakeholderActions'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { ListaResultadoStakeholdersComponent } from '../../ui-components/stakeholder/ListaResultadosStakeholdersComponent'
import {
  useQueryParamAsString,
} from '../../../infra/libs/react-location-hooks/routerHooks'
import {
  QUERY_PARAM_TEXTO_LIVRE,
} from '../../../util/constantesQueryParams'
import { favoritoThunkCreators } from '../../../store/actions/favoritoActions'
import { TipoFavorito } from '../../ui-components/favorito/TipoFavorito'


const limite = 100

export function ListaResultadoStakeholdersContainer() {

  const textoLivre = useQueryParamAsString(QUERY_PARAM_TEXTO_LIVRE)
  const dispatch = useTypedDispatch()
  const { data: stakeholders, isLoading } = usePromiseEffect(recuperarStakeholdersPesquisados, [dispatch, textoLivre])

  if (isLoading) {
    return <StakeholdersRelacionadosPlaceholderComponent qntFiguras={5} />
  }
  return (
    <>
      <ListaResultadoStakeholdersComponent
        listaStakeholders={stakeholders || []}
      />
    </>
  )
  
}

async function recuperarStakeholdersPesquisados(dispatch: TypedDispatch, textoLivre?: string) {
  if (textoLivre) {
    try {
      const { data: stakeholders } = await dispatch(stakeholderThunkCreators.buscarStakeholdersPorTexto(textoLivre, limite))

      const { data: favoritos } = await dispatch(favoritoThunkCreators.recuperarMeusFavoritos(TipoFavorito.STAKEHOLDER))

      const stakeholdersComFavoritos: StakeholderComFavorito[] = []
      stakeholders.forEach((resumoStakeholder) => {
        const stakeComFavorito:StakeholderComFavorito = { resumoStakeholder, isFavorito: false }

        const result = favoritos.filter(favorito => resumoStakeholder.id === favorito.itemFavorito)
        if (result.length > 0) {
          stakeComFavorito.isFavorito = true
        }

        stakeholdersComFavoritos.push(stakeComFavorito)
      })

      return stakeholdersComFavoritos
    } catch (e) {
      console.warn(e)
    }
  }
  return []
}
