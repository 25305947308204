import { processosApi } from '../../../api/processosApi'

export async function recuperarProcessosRelacionadosDoFato(fato) {
  const numerosFormatados: string[] = fato._source.processos_tcu_relacionados
  if (!numerosFormatados || numerosFormatados.length === 0) {
    return []
  }
  const promises = numerosFormatados.map(processosApi.buscarProcessosPorNumeroFormatado)
  const responses = await Promise.all(promises)
  const processos = responses.map(r => r.body.hits.hits[0])
  return processos.filter(processo => processo)
}
