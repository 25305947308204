import { TIPOS_FATO } from '../domain/TipoFato'
import { TIPOS_STAKEHOLDER } from '../domain/TipoStakeholder'

const URL_DETALHAMENTO = {
  [TIPOS_FATO.NOTICIA_CLIPPING.id]: '/noticia/',
  [TIPOS_FATO.NOTICIA_UNIAO.id]: '/uniao/',
  [TIPOS_FATO.EVENTO_RELACIONA.id]: '/evento/',
  [TIPOS_FATO.PROPOSICAO_LEGISLATIVA.id]: '/proposicao-legislativa/',
  [TIPOS_FATO.PROCESSO.id]: '/processo/',
}

const URL_PERFIL = {
  [TIPOS_STAKEHOLDER.ATOR.id]: '/ator/',
  [TIPOS_STAKEHOLDER.ENTIDADE.id]: '/entidade/',
}

export const frontendRoutesUtil = {

  montarUrlPerfilStakeholder(idStakeholder, tipoStakeholder) {
    return `${URL_PERFIL[tipoStakeholder]}${idStakeholder}`
  },

  montarUrlPerfilStakeholderSomenteComId(idStakeholder: string) {
    return idStakeholder.includes('ENTIDADE') ? this.montarUrlPerfilStakeholder(idStakeholder, 'ENTIDADE') : this.montarUrlPerfilStakeholder(idStakeholder, 'ATOR') 
  },

  montarUrlDetalhamentoFato(idFato, tipoFato) {
    return `${URL_DETALHAMENTO[tipoFato]}${idFato}`
  },

  montarUrlDetalhamentoFatoPorId(idFato: string) {
    if (idFato.includes(TIPOS_FATO.NOTICIA_CLIPPING.id)) {
      return `${URL_DETALHAMENTO[TIPOS_FATO.NOTICIA_CLIPPING.id]}${idFato}`
    }
    if (idFato.includes(TIPOS_FATO.NOTICIA_UNIAO.id)) {
      return `${URL_DETALHAMENTO[TIPOS_FATO.NOTICIA_UNIAO.id]}${idFato}`
    }
    if (idFato.includes(TIPOS_FATO.PROPOSICAO_LEGISLATIVA.id)) {
      return `${URL_DETALHAMENTO[TIPOS_FATO.PROPOSICAO_LEGISLATIVA.id]}${idFato}`
    }
    if (idFato.includes(TIPOS_FATO.EVENTO_RELACIONA.id)) {
      return `${URL_DETALHAMENTO[TIPOS_FATO.EVENTO_RELACIONA.id]}${idFato}`
    }
    return `${URL_DETALHAMENTO[TIPOS_FATO.PROCESSO.id]}${idFato}`
  },
}
