import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { recuperarNoticiaUniaoPorId } from '../../app/fato/noticia-uniao/recuperarNoticiaUniaoPorId'
import { NoticiaUniao } from '../../domain/NoticiaUniao'


export namespace BuscarNoticiaUniaoEndpoint {
  export type Body = undefined
  export type Params = { id: string }
  export type Query = undefined
  export type Response = NoticiaUniao
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/noticias-uniao/:id',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<Response>) => {
      const { id } = request.params
      const noticiaUniao = await recuperarNoticiaUniaoPorId(id)
      response.json(noticiaUniao)
    },
  })
}
