import { AxiosResponse } from 'axios'
import QueryString from 'query-string'
import { RecursoComputacional } from '../infra/security/RecursoComputacional'
import { Ministro } from '../domain/Ministro'
import { createAxios } from '../infra/axios/createAxios'

const axiosInstance = createAxios(RecursoComputacional.SERVICO_FONETICO)

export const servicoFoneticoApi = {

  converterTextoParaTextoFonetico(texto: string): Promise<AxiosResponse<Ministro[]>> {
    const queryParamsString = QueryString.stringify({ pesquisa: texto })
    const url = `?${queryParamsString}`
    return axiosInstance.get(url)
  },

}

