import { fatosApi } from '../../../api/fatosApi'
import { dateUtil } from '../../../util/dateUtil'
import {
  marcarStakeholdersNaListaNovo,
  marcarStakeholdersNoTexto,
} from '../../stakeholder/highlightNosStakeholders'
import { Evento } from '../../../domain/Evento'

export async function recuperarEventoPorId(id: string) {
  const { body: respostaIndiceNoticias } = await fatosApi.buscarFatosPorId(id)
  const evento = respostaIndiceNoticias.hits.hits[0]
  return mapearEvento(evento)
}

function mapearEvento(evento): Evento {
  return {
    id: evento._id,
    titulo: evento._source.titulo,
    tipoFato: evento._source.tipo_fato,
    tipoEvento: evento._source.tipo_evento,
    participantesEvento: marcarStakeholdersNaListaNovo(
      'participantes_evento', evento._source.participantes_evento ?? [],
      evento._source.marcacoes_stakeholders_relacionados, evento._source.stakeholders_relacionados,
    ),
    urlFonte: '', // TODO colocar url do relaciona
    texto: marcarStakeholdersNoTexto(
      'texto',
      evento._source.texto, evento._source.marcacoes_stakeholders_relacionados,
      evento._source.stakeholders_relacionados,
    ),
    resultadoEvento: marcarStakeholdersNoTexto(
      'complemento',
      evento._source.complemento, evento._source.marcacoes_stakeholders_relacionados,
      evento._source.stakeholders_relacionados,
    ),
    localEvento: evento._source.local_evento,
    dataHora: `Realizado em ${dateUtil.formatarDataHora(evento._source.data_publicacao)}`,
    stakeholdersIds: evento._source.stakeholders_relacionados,
    isSigiloso: evento._source.sigiloso,
    marcacoesStakeholders: evento._source.marcacoes_stakeholders_relacionados,
  }
}
