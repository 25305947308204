import React from 'react'
import { ID_ENTIDADE_TCU } from 'bff/src/main/util/stakeholdersUtil'
import { Cenario } from 'bff/src/main/domain/Cenario'
import { criaStakeholdersComFavoritos } from 'bff/src/main/app/stakeholder/stakeholderUtil'
import { StakeholdersPesquisadosComponent } from '../../ui-components/stakeholder/StakeholdersPesquisadosComponent'
import { stakeholderThunkCreators } from '../../../store/actions/stakeholderActions'
import { StakeholdersRelacionadosPlaceholderComponent } from '../../ui-components/stakeholder/StakeholdersRelacionadosPlaceholderComponent'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { favoritoThunkCreators } from '../../../store/actions/favoritoActions'
import { TipoFavorito } from '../../ui-components/favorito/TipoFavorito'


interface StakeholdersPresentesNosCenariosContainerProps {
  cenarios?: Cenario[]
}

export function StakeholdersPresentesNosCenariosContainer({ cenarios }: StakeholdersPresentesNosCenariosContainerProps) {
  const dispatch = useTypedDispatch()

  const { data: stakeholders, isLoading } = usePromiseEffect(recuperarStakeholdersPesquisados, [dispatch, cenarios])

  if (isLoading) {
    return <StakeholdersRelacionadosPlaceholderComponent qntFiguras={3} />
  }
  if (!stakeholders || stakeholders.length === 0) {
    return null
  }
  return <StakeholdersPesquisadosComponent listaStakeholders={stakeholders ?? []} titulo="Atores e entidades" />
}

async function recuperarStakeholdersPesquisados(dispatch: TypedDispatch, cenarios?: Cenario[]) {
  if (cenarios === undefined) {
    return []
  }

  const idsStakeholdersCenario: string[] = []
  cenarios.forEach((c) => {
    if (c.consulta.stakeholders) {
      idsStakeholdersCenario.push(...c.consulta.stakeholders)  
    }
  })

  const idsParaPesquisar = idsStakeholdersCenario.length > 0 ? idsStakeholdersCenario : getStakeholderBase()
  
  try {
    const { data: stakeholders } = await dispatch(stakeholderThunkCreators.buscarStakeholdersPorId(idsParaPesquisar))
    const { data: favoritos } = await dispatch(favoritoThunkCreators.recuperarMeusFavoritos(TipoFavorito.STAKEHOLDER))

    return criaStakeholdersComFavoritos(stakeholders, favoritos)
  } catch (e) {
    console.warn(e)
  }
  return []
}

function getStakeholderBase() {
  return [ID_ENTIDADE_TCU]
}
