import React from 'react'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { fichaActionCreators, fichaThunkCreators } from '../../../store/actions/fichaActions'
import { MinhasFichasComponent } from '../../ui-components/ficha/MinhasFichasComponent'
import { PaginaDeErroHttpComponent } from '../../ui-components/erro/PaginaDeErroHttpComponent'
import { modalNovaFichaActionCreators } from '../modal/modal-nova-ficha/modalNovaFichaActions'
import { createCounterReducer } from '../../../infra/libs/redux-hardtyped/createCounterReducer'
import { useSwan } from '../../../infra/libs/redux-swans/useSwan'
import { MinhasFichasPlaceholderComponent } from '../../ui-components/ficha/MinhasFichasPlaceHolderComponent'

const reducer = createCounterReducer([
  fichaActionCreators.fichaCriada,
  fichaActionCreators.fichaExcluida,
])

export function MinhasFichasContainer() {

  const dispatch = useTypedDispatch()
  const counter = useSwan(reducer)
  const { data: fichas, isLoading, isRejected } = usePromiseEffect(recuperarFichas, [dispatch], [counter])

  function criarFicha() {
    dispatch(modalNovaFichaActionCreators.abrirModalTelaMinhasFichas())
  }

  if (isLoading) {
    return <MinhasFichasPlaceholderComponent />
  }

  if (isRejected) {
    return <PaginaDeErroHttpComponent mensagemDeErro="Tivemos um problema ao carregar fichas" />
  }

  return <MinhasFichasComponent fichas={fichas ?? []} onClickNovaFicha={criarFicha} />
}

async function recuperarFichas(dispatch: TypedDispatch) {
  try {
    const { data: fichas } = await dispatch(fichaThunkCreators.recuperarMinhasFichas())
    return fichas
  } catch (e) {
    console.warn(e)
    throw e
  }
}
