import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { ResumoStakeholder } from 'bff/src/main/domain/ResumoStakeholder'
import { CardStakeholderFotoNomeComponent } from './CardStakeholderFotoNomeComponent'
import { CaixaComNumeroComponent } from '../comum/CaixaComNumeroComponent'
import { ButtonComponent } from '../comum/ButtonComponent'
import { MapaColor } from '../../../style/theme'


const useStyles = makeStyles({
  cardStakeholderPequeno: {
    margin: '10px 0px',
    height: '100px',
    background: MapaColor.neutralLightest,
    marginBottom: '25px',
  },
  tituloStakeholderSel: {
    fontSize: '18px',
    fontWeight: 500,
    color: MapaColor.neutralDark,
    position: 'absolute',
    marginTop: '-40px',
  },
  boxBotaoMostrarMais: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '25px',
  },
  stakeholdersEnvolvidos: {
    marginBottom: '50px',
  },
})

export interface StakeholdersEnvolvidosProps {
  titulo: string
  listaStakeholders: ResumoStakeholder[]
  exibirContador: boolean
  componenteExpansivel?: boolean
}

export function ListaStakeholdersComponent(props: StakeholdersEnvolvidosProps) {
  const classes = useStyles()
  const [mostrarMais, setMostrarMais] = useState(false)

  function handleClick() {
    setMostrarMais(!mostrarMais)
  }

  return render(props, mostrarMais, handleClick, classes)
}

function render(props: StakeholdersEnvolvidosProps, mostrarMais: boolean, handleClick, classes) {
  return props.listaStakeholders.length < 1 ? null : renderStakeholders(props, mostrarMais, handleClick, classes)
}

function renderStakeholders(props: StakeholdersEnvolvidosProps, mostrarMais: boolean, handleClick, classes) {
  let stakeholders: any[] = []
  if (props.componenteExpansivel) {

    if (props.listaStakeholders.length <= 4) {
      stakeholders = props.listaStakeholders
    }
    if (props.listaStakeholders.length > 4 && mostrarMais) {
      stakeholders = props.listaStakeholders
    }
    if (props.listaStakeholders.length > 4 && !mostrarMais) {
      stakeholders = props.listaStakeholders.slice(0, 4)
    }

  } else {
    stakeholders = props.listaStakeholders
  }

  return (
    <div className="row">
      <h6 className={`${classes.tituloStakeholderSel} col-md-12`}>
        {props.titulo}
        {props.exibirContador ? <CaixaComNumeroComponent numero={props.listaStakeholders.length} /> : null}
      </h6>
      <div className={`${classes.stakeholdersEnvolvidos} col-md-12 row`}>
        {
          stakeholders.map(item => (
            <div className={`${classes.cardStakeholderPequeno} col-md-6 col-sm-6 col-lg-6`} key={item.id}>
              <CardStakeholderFotoNomeComponent
                nome={item.nome}
                papel={item.papel}
                urlImagem={item.urlImagem}
                urlDestino={item.urlDestino}
                stakeholderInterno={item.stakeholderInterno}
              />
            </div>
          ))
        }
        {
          props.listaStakeholders.length > 4 && props.componenteExpansivel
            ? (
              <div className={`col-md-12 ${classes.boxBotaoMostrarMais}`}>
                <ButtonComponent
                  buttonText={mostrarMais ? 'Mostrar menos' : 'Mostrar mais'}
                  buttonTypeColor="white"
                  onClick={handleClick}
                />
              </div>
            )
            : null
        }
      </div>
    </div>
  )
}
