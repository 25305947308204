import { useState } from 'react'
import { TypedDispatch, useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { stakeholderThunkCreators } from '../../../store/actions/stakeholderActions'
import {
  useQueryParamAsArrayOfString,
  useQueryParamAsString,
} from '../../../infra/libs/react-location-hooks/routerHooks'
import {
  QUERY_PARAM_ATORES,
  QUERY_PARAM_CONECTOR_OU,
  QUERY_PARAM_ENTIDADES,
  QUERY_PARAM_KEYWORDS,
  QUERY_PARAM_TEMAS,
  QUERY_PARAM_TEXTO_LIVRE,
} from '../../../util/constantesQueryParams'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { BuscaAutocompleteInputOption } from '../../ui-components/busca/BuscaAutocompleteOptionComponent'

export function useStakeholdersTemasKeywordsTextoLivreConectoresDaUrl() {
  const dispatch = useTypedDispatch()
  const atoresIds = useQueryParamAsArrayOfString(QUERY_PARAM_ATORES)
  const entidadesIds = useQueryParamAsArrayOfString(QUERY_PARAM_ENTIDADES)
  const keywords = useQueryParamAsArrayOfString(QUERY_PARAM_KEYWORDS)
  const temas = useQueryParamAsArrayOfString(QUERY_PARAM_TEMAS)
  const textoLivre = useQueryParamAsString(QUERY_PARAM_TEXTO_LIVRE)
  const conectoresOu = useQueryParamAsArrayOfString(QUERY_PARAM_CONECTOR_OU)
  const [selectedOptions, setSelectedOptions] = useState<BuscaAutocompleteInputOption[]>([])

  usePromiseEffect(atualizarSelectedOptions, [
    dispatch,
    setSelectedOptions,
    atoresIds,
    entidadesIds,
    keywords,
    temas,
  ])

  return {
    selectedOptions, textoLivre, conectoresOu, keywords, temas, 
  }
}

async function atualizarSelectedOptions(
  dispatch: TypedDispatch,
  setSelectedOptions: Function,
  atoresIds: string[],
  entidadesIds: string[],
  keywords: string[],
  temas: string[],
) {
  const newSelectedOptions: BuscaAutocompleteInputOption[] = []
  try {
    const stakeholdersIds = [...atoresIds, ...entidadesIds]
    if (stakeholdersIds.length !== 0) {
      const { data: stakeholders } = await dispatch(stakeholderThunkCreators.buscarStakeholdersPorId(stakeholdersIds))
      stakeholders.forEach(a => newSelectedOptions.push({ id: a.id, tipo: a.tipo, nome: a.nome, papel: a.papel }))
    }
  } catch (e) {
    console.error(e)
  }
  keywords.forEach(k => newSelectedOptions.push({ tipo: 'KEYWORD', nome: k, papel: 'Palavra-chave', id: k }))
  temas.forEach(t => newSelectedOptions.push({ tipo: 'TEMA', nome: t, papel: 'Tema', id: t }))
  setSelectedOptions(newSelectedOptions)
}
