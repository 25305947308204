import React from 'react'
import { BuscaAutocompleteInputComponent } from '../../ui-components/busca/BuscaAutocompleteInputComponent'
import { ListaTagsComponent } from '../../ui-components/comum/ListaTagsComponent'
import {
  BuscaAvancadaStakeholdersTemasKeywordsComponent,
} from '../../ui-components/busca/BuscaAvancadaStakeholdersTemasKeywordsComponent'
import { BuscaAvancadaOutrosFiltrosComponent } from '../../ui-components/busca/BuscaAvancadaOutrosFiltrosComponent'
import { BuscaAvancadaAcoesComponent } from '../../ui-components/busca/BuscaAvancadaAcoesComponent'
import { BuscaAvancadaButtomPopoverComponent } from '../../ui-components/busca/BuscaAvancadaButtomPopoverComponent'
import { useBuscaPrincipalContainer } from './BuscaPrincipalContainer.helper'
import { BuscaAutocompleteInputOption } from '../../ui-components/busca/BuscaAutocompleteOptionComponent'

export function BuscaPrincipalContainer() {
  const {
    buscaAvancadaAnchorEl,
    onCloseBuscaAvancada,
    onOpenBuscaAvancada,
    atorProps,
    entidadeProps,
    temaProps,
    keywordProps,
    setTiposFato,
    tiposFato,
    setOutrosFiltros,
    outrosFiltros,
    redirecionarParaPaginaDeResultados,
    limparPesquisa,
    onPesquisarSelecionado,
    handleLimparTags,
    onPesquisarTextoLivre,
    sugestoesTextoLivre,
    setText,
    text,
    conectoresOu,
  } = useBuscaPrincipalContainer()
  return (
    <>
      <BuscaAutocompleteInputComponent
        options={sugestoesTextoLivre ?? []}
        onInputChange={setText}
        onSelectedOptionsChange={onPesquisarSelecionado}
        onSubmit={onPesquisarTextoLivre}
        inputPlaceholder="Buscar atores, entidades, temas e palavras chave"
        actionOnEnter="search"
        inputValue={text}
        modoPesquisaLivre
        ignoreSuggestedKeyword
        layoutBuscaAvancada
      />
      <ListaTagsComponent
        onClear={handleLimparTags}
        tags={getTags(
          atorProps.atoresSelecionados, entidadeProps.entidadesSelecionadas,
          temaProps.temasSelecionados, keywordProps.keywordsSelecionadas,
        )}
        conectoresOu={conectoresOu}
      />
      <BuscaAvancadaButtomPopoverComponent
        anchorEl={buscaAvancadaAnchorEl}
        onClose={onCloseBuscaAvancada}
        onOpen={onOpenBuscaAvancada}
      >
        <BuscaAvancadaStakeholdersTemasKeywordsComponent
          atorProps={atorProps}
          entidadeProps={entidadeProps}
          temaProps={temaProps}
          keywordProps={keywordProps}
        />
        <BuscaAvancadaOutrosFiltrosComponent
          onTiposFatoChange={setTiposFato}
          tiposFatoSelecionados={tiposFato}
          onOutrosFiltrosChange={setOutrosFiltros}
          outrosFiltrosSelecionados={outrosFiltros}
        />
        <BuscaAvancadaAcoesComponent
          onSubmit={redirecionarParaPaginaDeResultados}
          onLimparPesquisa={limparPesquisa}
        />
      </BuscaAvancadaButtomPopoverComponent>
    </>

  )
}

function getTags(
  atoresSelecionados: BuscaAutocompleteInputOption[],
  entidadesSelecionadas: BuscaAutocompleteInputOption[],
  temasSelecionados: BuscaAutocompleteInputOption[],
  keywordsSelecionadas: string,
) {
  const result = [
    ...atoresSelecionados.map(a => ({ id: a.id, tipo: 'ATOR', nome: a.nome })),
    ...entidadesSelecionadas.map(e => ({ id: e.id, tipo: 'ENTIDADE', nome: e.nome })),
    ...temasSelecionados.map(t => ({ id: t.id, tipo: 'TEMA', nome: t.nome })),
  ]
  if (keywordsSelecionadas) {
    result.push(...keywordsSelecionadas.split(',').map(k => ({ id: k, tipo: 'KEYWORD', nome: k })))
  }
  return result
}
