import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles({
  divWidth: {
    maxWidth: '300px',
  },
})
export function LinhaCenarioSkeletonComponent() {
  const classes = useStyles()
  return (
    <TableRow>
      <TableCell>
        <div className={classes.divWidth}><Skeleton /></div>
      </TableCell>
      <TableCell>
        <div className={classes.divWidth}><Skeleton /></div>
      </TableCell>
      <TableCell>
        <div className={classes.divWidth}><Skeleton /></div>
      </TableCell>
      <TableCell>
        <div className={classes.divWidth}><Skeleton /></div>
      </TableCell>
    </TableRow>
  )
}

