import React from 'react'
import { PaginaDeErroHttpComponent } from '../ui-components/erro/PaginaDeErroHttpComponent'
import { TitleComponent } from '../ui-components/head/TitleComponent'

export function AcessoNegadoView() {
  const mensagemDeErro = 'Acesso negado'
  return (
    <>
      <TitleComponent title={mensagemDeErro} />
      <PaginaDeErroHttpComponent
        httpStatus={403}
        mensagemDeErro={mensagemDeErro}
      />
    </>
  )
}
