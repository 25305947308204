import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { Processo } from '../../domain/Processo'
import { recuperarProcessoPorCod } from '../../app/fato/processo/recuperarProcessoPorCod'
import { TipoFato } from '../../domain/TipoFato'
import { TipoProcesso } from '../../domain/TipoProcesso'
import { EstadoProcesso } from '../../domain/EstadoProcesso'
import { GrauSigilo } from '../../domain/GrauSigilo'
import { recuperarRelatoresDosProcessosPresentesNaPesquisa } from '../../app/fato/processo/recuperarRelatoresDosProcessosPresentesNaPesquisa'
import { MontarQueryRecuperarFatosPayload } from '../../api/queries/MontarQueryRecuperarFatosPayload'
import { ORDENACOES } from '../../domain/Ordenacao'
import { Ministro } from '../../domain/Ministro'
import { TipoConectorOu } from '../../domain/TipoConectorOu'


export namespace BuscarProcessoEndpoint {
  export type Body = undefined
  export type Params = { codigo: number }
  export type Query = undefined
  export type Response = Processo
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/processos/:codigo',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<Response>) => {
      const codigo = parseInt(request.params.codigo)
      const processo = await recuperarProcessoPorCod(codigo)
      response.json(processo)
    },
  })
}

export namespace BuscarRelatoresDeProcessosPresentesNaPesquisa {
  export type Body = undefined
  export type Params = undefined
  export type Query = {
    idsAtores?: string[]
    idsEntidades?: string[]
    keywords?: string[]
    temas?: string[]
    tiposFato?: TipoFato[]
    tiposProcesso?: TipoProcesso[]
    estadosProcesso?: EstadoProcesso[]
    dtInicio?: string
    dtFim?: string
    grausSigilo?: GrauSigilo[]
    listaConectoresOu?: TipoConectorOu[]
  }
  export type Response = Ministro[]
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/buscar-relatores-presentes-na-pesquisa',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<Response>) => {
      const { query } = request
      const payload: MontarQueryRecuperarFatosPayload = {
        idsAtores: query.idsAtores ?? [],
        idsEntidades: query.idsEntidades ?? [],
        keywords: query.keywords ?? [],
        temas: query.temas ?? [],
        tiposFato: query.tiposFato ?? [],
        tiposEvento: [],
        tiposProcesso: query.tiposProcesso ?? [],
        estadosProcesso: query.estadosProcesso ?? [],
        relatores: [],
        grausSigilo: query.grausSigilo ?? [],
        dtInicio: query.dtInicio,
        dtFim: query.dtFim,
        ordenacao: ORDENACOES.MAIS_RELEVANTE.id,
        size: 0,
        listaConectoresOu: [],
      }
      const ministros = await recuperarRelatoresDosProcessosPresentesNaPesquisa(payload)
      response.json(ministros)
    },
  })
}
