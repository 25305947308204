import { searchOnIndex } from '../infra/elastic/searchOnIndex'


const search = body => searchOnIndex({ body, index: 'mapa-temas' })

export const temasApi = {

  buscarTemasParaSugestaoBuscaPorPedacoNome(word: string) {
    return search({
      size: 100,
      query: {
        match_phrase_prefix: {
          funcao: word,
        },
      },
    })
  },

}
