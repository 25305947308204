import React from 'react'
import { Ficha } from 'bff/src/main/domain/Ficha'
import { VisualizarFichaComponent } from '../../ui-components/ficha/VisualizarFichaComponent'
import { TypedDispatch, useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { useParamAsInt } from '../../../infra/libs/react-location-hooks/routerHooks'
import { fichaActionCreators, fichaThunkCreators } from '../../../store/actions/fichaActions'
import { PaginaDeErroHttpComponent } from '../../ui-components/erro/PaginaDeErroHttpComponent'
import { VisualizarFichaPlaceholderComponent } from '../../ui-components/ficha/VisualizarFichaPlaceholderComponent'
import { BreadCrumbItem } from '../../ui-components/ficha/BreadCrumbComponent'
import { createCounterReducer } from '../../../infra/libs/redux-hardtyped/createCounterReducer'
import { useSwan } from '../../../infra/libs/redux-swans/useSwan'

const reducer = createCounterReducer([
  fichaActionCreators.descricaoAlterada,
  fichaActionCreators.tituloAlterado,
  fichaActionCreators.trechoTextualAdicionado,
  fichaActionCreators.trechoFichaRemovido,
  fichaActionCreators.textoTrechoAlterado,
  fichaActionCreators.tipoTrechoTextualFichaAlterado,
  fichaActionCreators.ordemTrechoAlterada,
])

export function VisualizarFichaContainer() {
  const dispatch = useTypedDispatch()
  const fichaId = useParamAsInt('id')
  const counter = useSwan(reducer)
  const { data: ficha, isLoading, isRejected } = usePromiseEffect(recuperarFicha, [dispatch, fichaId], [counter])

  if (isLoading) {
    return <VisualizarFichaPlaceholderComponent />
  }

  if (isRejected) {
    return <PaginaDeErroHttpComponent mensagemDeErro="Tivemos um problema ao carregar a ficha" />
  }

  if (!ficha) {
    return null
  }

  return <VisualizarFichaComponent ficha={ficha} itensBreadcrumb={getBreadcrumb(ficha)} />
}

async function recuperarFicha(dispatch: TypedDispatch, fichaId: number) {
  try {
    const { data: ficha } = await dispatch(fichaThunkCreators.recuperarFichaPorId(fichaId))
    return ficha
  } catch (e) {
    console.warn(e)
    throw e
  }
}

function getBreadcrumb(ficha: Ficha): BreadCrumbItem[] {
  return [{ label: 'Minhas fichas', url: '/fichas' }, { label: ficha.titulo, url: `/fichas/${ficha.id}` }]
}
