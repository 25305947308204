import React from 'react'
import { makeStyles } from '@material-ui/styles'
import atorIcon from '../../../assets/atorIcon.svg'


const useStyles = makeStyles({
  boxNaoEncontrados: {
    width: '100%',
    height: '148px',
    boxShadow: '0px 1px 3px #00000040;',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    color: '#434343',
  },
  iconeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icone: {
    height: '81px',
    opacity: 0.3,
  },
})

export function StakeholdersRelacionadosNaoEncontradosComponent() {
  const classes = useStyles()
  return (
    <div className={classes.boxNaoEncontrados}>
      <div className={`col-md-1 ${classes.iconeWrapper}`}>
        <img className={classes.icone} src={atorIcon} alt="Ator" />
      </div>
      <div className="col-md-11">
        <h4>Não encontramos stakeholders relacionados com a sua busca</h4>
        <span>Verifique os termos digitados ou os filtros selecionados e refaça a busca.</span>
      </div>
    </div>
  )
}
