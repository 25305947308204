import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
  animacao: {
    background: 'linear-gradient(45deg, #c7c7c7, #8a8a8a26, #cbcbcb24)',
    backgroundSize: '400% 400%',
    animation: '$gradient 3s ease infinite',
  },
  espaco: {
    marginTop: '10px',
  },
  margin: {
    height: '30px',
  },
  titulos: {
    height: '25px',
    marginBottom: '7px',
    borderRadius: '5px',
    width: '30%',
  },
  subTitulos: {
    height: '25px',
    marginBottom: '7px',
    borderRadius: '5px',
    width: '90%',
  },
  boxIcon: {
    width: '32px',
    height: '32px',
    borderRadius: '10px',
    marginRight: '16px',
    marginLeft: '7px',
  },
  widthModal: {
    width: '583px',
    minHeight: '320px',
  },
  boxLista: {
    display: 'flex',
    alignItems: 'center',
  },
  subTituloLista: {
    height: '25px',
    marginBottom: '7px',
    borderRadius: '5px',
    width: '48%',
    marginLeft: '56px',
  },
})

export function ModalAddTrechoFichasPlaceHolderComponent() {
  const classes = useStyles()
  return (
    <div className={classes.widthModal}>
      <div className={`${classes.titulos} ${classes.animacao}`} />
      <div className={`col-md-12 ${classes.espaco}`} />
      <div className={`${classes.subTitulos} ${classes.animacao}`} />
      <div className={`col-md-12 ${classes.margin}`} />
      <div className={classes.boxLista}>        
        <div className={`${classes.boxIcon} ${classes.animacao}`} />
        <div className={`${classes.titulos} ${classes.animacao}`} />              
      </div>
      <div className={`${classes.subTituloLista} ${classes.animacao}`} />
      <div className={`col-md-12 ${classes.margin}`} />
      <div className={classes.boxLista}>
        <div className={`${classes.boxIcon} ${classes.animacao}`} />
        <div className={`${classes.titulos} ${classes.animacao}`} />
      </div>
      <div className={`${classes.subTituloLista} ${classes.animacao}`} />
    </div>
  )
}
