import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  tabsHeader: {
    '@media screen and (max-width:550px)': {
      display: 'contents',
    },
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '5px',
    position: 'relative',
    borderBottom: '1px solid #ccc',
    fontSize: '16px',
    fontWeight: 500,
    color: '#525252',
    '@media screen and (max-width:992px)': {
      height: '58px',
    },
    '@media screen and (max-width:991px)': {
      height: '40px',
    },
  },
  tabHeader: {
    display: 'block',
    padding: '1rem',
    fontSize: '14px',
    '&:hover': {
      cursor: 'pointer',
    },
    '@media screen and (max-width:1126px)': {
      textAlign: 'center',
    },
    width: '100%',
    textAlign: 'center',
  },
  activeTabHeader: {
    fontWeight: 'bold',
    color: '#2f5dc9',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '4px',
      left: 0,
      bottom: '4px',
      background: '#2f5dc9',
    },
  },
  disabled: {
    color: '#d3d3d3',
    '&:hover': {
      cursor: 'default',
    },
  },
})

interface TabHeader {
  key: number
  tabTitle: string
  disabled?: boolean
}

interface TabsHeaderProps {
  header: TabHeader[]
  activeTab: number
  onClick: (newValue: number) => void
}

export function TabsHeaderComponent({ header, activeTab, onClick }: TabsHeaderProps) {
  const classes = useStyles()

  function handleClick(event) {
    onClick(Number(event.currentTarget.dataset.value))
  }

  return (
    <nav className={classes.tabsHeader}>
      {
        header.map(item => (
          <span
            key={item.key}
            role="button" 
            tabIndex={0}
            className={`${classes.tabHeader} ${activeTab === item.key ? classes.activeTabHeader : ''} ${item.disabled ? classes.disabled : ''}`}
            onClick={item.disabled ? () => null : handleClick}
            onKeyPress={item.disabled ? () => null : handleClick}
            data-value={item.key}
          >
            {item.tabTitle}
          </span>   
        ))
      }
    </nav>
  )
}
