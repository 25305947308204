import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Ficha } from 'bff/src/main/domain/Ficha'
import moment from 'moment'
import { BreadCrumbComponent, BreadCrumbItem } from './BreadCrumbComponent'
import { InformacoesFichaComponent } from './InformacoesFichaComponent'
import { OpcoesFichaContainer } from '../../containers/ficha/OpcoesFichaContainer'
import { ConteudoFichaContainer } from '../../containers/ficha/ConteudoFichaContainer'


const useStyles = makeStyles({
  box: {
    marginLeft: '0px',
    marginRight: '0px',
  },
  header: {
    padding: '19px 60px 19px 60px',
    borderBottom: '1px solid #c4c4c4',
    borderTop: '1px solid #c4c4c4',
    marginTop: '-15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  semBorda: {
    paddingLeft: '0px',
  },
  textAtualizado: {
    fontSize: '14px',
  },
})

interface VisualizarFichaComponentProps {
  itensBreadcrumb: BreadCrumbItem[]
  ficha: Ficha
}

export function VisualizarFichaComponent({ ficha, itensBreadcrumb }: VisualizarFichaComponentProps) {
  const classes = useStyles()
  return (
    <div className={`${classes.box} row`}>
      <div className={`${classes.header} col-md-12`}>
        <BreadCrumbComponent itens={itensBreadcrumb} />
        <div>
          <span className={classes.textAtualizado}>{`Atualizado em ${moment(ficha.dataHoraAtualizacao).format('LL')}`}</span>
        </div>
        <OpcoesFichaContainer fichaId={ficha.id} tituloFicha={ficha.titulo} />
      </div>
      <div className={`${classes.semBorda} col-md-9`}>
        <ConteudoFichaContainer ficha={ficha} />
      </div>
      <div className="col-md-3">
        <InformacoesFichaComponent ficha={ficha} />
      </div>
    </div>
  )
}
