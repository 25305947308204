import React from 'react'
import { makeStyles } from '@material-ui/styles'
import atorIcon from '../../../assets/ator-icon.svg'
import entidadeIcon from '../../../assets/entidade-icon.svg'
import { MapaColor } from '../../../style/theme'

const useStyles = makeStyles({
  avatar: {
    height: 'inherit',
    width: 'inherit',
    boxShadow: '0 1px 3px #214CA240',
    alignItems: 'center',
    borderRadius: '50%',
    display: 'inline-flex',
    WebkitBoxPack: 'center',
    MsFlexPack: 'center',
    justifyContent: 'center',
    lineHeight: 'normal',
    position: 'relative',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  img_stakeholder: {
    objectFit: 'cover',
    border: '3px solid',
    padding: 0,
    borderRadius: 'inherit',
    width: 'inherit',
    height: 'inherit',
    display: 'inline-flex',
  },
  INTERNO: {
    borderColor: MapaColor.primaryMedium,
  },
  AUTORIDADE_TCU: {
    borderColor: MapaColor.warningMain,
  },
  EXTERNO: {
    borderColor: MapaColor.neutralMedium,
  },
})

interface ImagemComBordaProps {
  urlImagem: string
  tipoStakeholder: string
}

const IMAGEM_ENTIDADE_NAO_ENCONTRADO = 'IMAGEM_ENTIDADE_NAO_ENCONTRADO'
const IMAGEM_ATOR_NAO_ENCONTRADO = 'IMAGEM_ATOR_NAO_ENCONTRADO'

export function ImagemComBordaComponent({ urlImagem, tipoStakeholder }: ImagemComBordaProps) {
  const classes = useStyles()
  let imagem = urlImagem
  if (urlImagem === IMAGEM_ATOR_NAO_ENCONTRADO) {
    imagem = atorIcon
  }
  if (urlImagem === IMAGEM_ENTIDADE_NAO_ENCONTRADO) {
    imagem = entidadeIcon
  }
  return (
    <div className={classes.avatar}>
      <img src={imagem} alt="stakeholder" className={`${classes.img_stakeholder} ${classes[tipoStakeholder]} img-fluid`} />
    </div>
  )
}
