import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { recuperarSugestoesBusca } from '../../app/busca/recuperarSugestoesBusca'
import { SugestaoDeBusca } from '../../domain/SugestaoDeBusca'
import { TipoSugestaoDeBusca } from '../../domain/TipoSugestaoDeBusca'

export namespace BuscarSugestoesDeBuscaEndpoint {
  export type Body = undefined
  export type Params = { word: string }
  export type Query = { tipoSugestao?: TipoSugestaoDeBusca }
  export type ResponseBody = SugestaoDeBusca[]
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/sugestoes-de-busca/:word',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { word } = request.params
      const sugestoesBusca = await recuperarSugestoesBusca(word, request.query.tipoSugestao)
      response.json(sugestoesBusca)
    },
  })
}
