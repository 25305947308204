import React from 'react'
import { useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSwan } from '../../../infra/libs/redux-swans/useSwan'
import { createReducer } from '../../../infra/libs/redux-hardtyped/createReducer'
import { cenarioActionCreators } from '../../../store/actions/cenarioActions'
import { defineActionCreators } from '../../../infra/libs/redux-hardtyped/defineActionCreators'
import { ModalWithActionsComponent } from '../../ui-components/modal/ModalWithActionsComponent'
import { IconDesarquivarComponent } from '../../ui-components/icons/IconDesarquivarComponent'


export const modalCenarioDesarquivadoActions = defineActionCreators('modalCenarioDesarquivado', {
  fecharModal: () => ({}),
})

const reducer = createReducer(false)(handle => ([
  handle(cenarioActionCreators.cenarioDesarquivado, () => true),
  handle(modalCenarioDesarquivadoActions.fecharModal, () => false),
]))

export function ModalCenarioDesarquivadoContainer() {

  const dispatch = useTypedDispatch()
  const modalEstaAberta = useSwan(reducer)

  function onAction() {
    dispatch(modalCenarioDesarquivadoActions.fecharModal())
  }

  if (!modalEstaAberta) return null
  return (
    <ModalWithActionsComponent
      icon={<IconDesarquivarComponent fill="#2f5dc9" height="74" width="74" />}
      primaryColor="blue"
      title="Cenário desarquivado?"
      text="O Mapa voltará a monitorar e atualizar o cenário com os últimos acontecimentos."
      actionButtonText="Entendi"
      onAction={onAction}
    />
  )
}
