import { frontendRoutesUtil } from '../../../util/frontendRoutesUtil'
import { TIPOS_FATO } from '../../../domain/TipoFato'
import { ResumoFato } from '../../../domain/ResumoFato'

export function montarResumoProcesso(processo): ResumoFato {
  const tipoFato = TIPOS_FATO.PROCESSO.id
  return {
    id: processo._id,
    titulo: `${processo._source.numeroCompleto} ${processo._source.descricaoTipoProcesso}`,
    tipoFato,
    resumo: processo._source.assunto,
    urlDestinoFato: frontendRoutesUtil.montarUrlDetalhamentoFato(processo._source.codigoProcesso, tipoFato),
    informacaoAdicionalMaisRelevante: processo._source.descricaoUnidadeResponsavelTecnica,
    informacaoAdicionalMenosRelevante: processo._source.descricaoSituacao,
    isSigiloso: processo._source.sigiloso === 'true',
    data: processo._source.dataAutuacao,
  }
}
