import { processosApi } from '../../../api/processosApi'
import { MontarQueryRecuperarFatosPayload } from '../../../api/queries/MontarQueryRecuperarFatosPayload'
import { recuperarAliasStakeholderPorId } from '../../stakeholder/recuperarAliasStakeholderPorId'
import { ministroApi } from '../../../api/ministrosApi'
import { TIPOS_FATO } from '../../../domain/TipoFato'


export async function recuperarRelatoresDosProcessosPresentesNaPesquisa(payload: MontarQueryRecuperarFatosPayload) {
  const stakeholdersComAlias = await recuperarAliasStakeholderPorId(payload.idsAtores, payload.idsEntidades)
  let respostaIndiceProcessos
  if (payload.tiposFato.length === 0 || payload.tiposFato.includes(TIPOS_FATO.PROCESSO.id)) {
    respostaIndiceProcessos = await processosApi.buscarProcessos(payload, stakeholdersComAlias.atores, stakeholdersComAlias.entidades)
  }
  return montarResposta(respostaIndiceProcessos?.body?.aggregations?.relatores?.buckets ?? [])
}

async function montarResposta(aggregatorRelatores: any[]) {
  if (aggregatorRelatores.length > 0) {
    const { data: ministros } = await ministroApi.getMinistrosPorIds(aggregatorRelatores.map(item => item.key))
    return ministros
  }
  return []
}
