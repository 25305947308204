import { createReducer } from '../../../../infra/libs/redux-hardtyped/createReducer'
import { modalExcluirFichaActionCreators } from './modalExcluirFichaActions'
import { fichaActionCreators } from '../../../../store/actions/fichaActions'


export type ModalExcluirFichaState = null | {
  idFicha: number
  tituloFicha: string
}

const initialState = null

export const modalExcluirFichaReducer = createReducer<ModalExcluirFichaState>(initialState)(handle => ([

  handle(modalExcluirFichaActionCreators.abrirModal, (state, action) => {
    const { id, titulo } = action
    return { idFicha: id, tituloFicha: titulo }
  }),

  handle(modalExcluirFichaActionCreators.fecharModal, fecharModal),

  handle(fichaActionCreators.fichaExcluida, fecharModal),

]))

function fecharModal(): ModalExcluirFichaState {
  return null
}
