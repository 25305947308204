import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
  animacao: {
    background: 'linear-gradient(45deg, #c7c7c7, #8a8a8a26, #cbcbcb24)',
    backgroundSize: '400% 400%',
    animation: '$gradient 3s ease infinite',
  },
  fato: {
    display: 'flex',
    marginBottom: '30px',
  },
  flex: {
    display: 'flex',
  },
  linhas: {
    flex: '1 1 auto',
    marginTop: '20px',
  },
  linha: {
    height: '25px',
    marginRight: '15px',
    marginBottom: '15px',
    width: '90%',
    borderRadius: '5px',
  },
  tag: {
    width: '20%',
  },
})

export function ListaFatosPlaceholderComponent() {
  const classes = useStyles()
  return (
    <>
      {renderFatoPlaceholder(classes)}
      {renderFatoPlaceholder(classes)}
      {renderFatoPlaceholder(classes)}
    </>
  )
}

function renderFatoPlaceholder(classes) {
  return (
    <div className={classes.fato}>
      <div className={classes.linhas}>
        <div className={`${classes.linha} ${classes.tag} ${classes.animacao}`} />
        <div className={`${classes.linha} ${classes.animacao}`} />
        <div className={`${classes.linha} ${classes.animacao}`} />
        <div className={classes.flex}>
          <div className={`${classes.linha} ${classes.tag} ${classes.animacao}`} />
          <div className={`${classes.linha} ${classes.tag} ${classes.animacao}`} />
          <div className={`${classes.linha} ${classes.tag} ${classes.animacao}`} />
        </div>
      </div>
    </div>
  )
}
