import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { FatoComFavorito } from 'bff/src/main/domain/ResumoFato'
import { ResumoFatoComponent } from './ResumoFatoComponent'
import { FatosNaoEncontradosComponent } from './FatosNaoEncontradosComponent'
import { OrdenacaoComponent } from '../comum/OrdenacaoComponent'
import { BasesPesquisaComponente } from '../comum/BasesPesquisaComponente'

const useStyles = makeStyles({
  header: {
    marginBottom: '30px',
    paddingBottom: '15px',
    borderBottom: '1px solid #C4C4C4',
    '& .row': {
      alignItems: 'flex-end',
    },
  },
  basesPesquisa: {
    marginBottom: '5px',
  },
  tituloFatos: {
    marginBottom: 0,
    color: '#231f20',
    fontWeight: 500,
    fontSize: '24px',
  },
  subtituloFatos: {
    fontSize: '14px',
    color: '#808080',
  },
  fatoBox: {
    marginBottom: '56px',
  },
})

export interface ListaFatosProps {
  fatos: FatoComFavorito[]
  mostrarOrdenacao?: boolean
  mostrarTitulo?: boolean
  titulo?: string
}

export function ListaFatosComponent({ fatos, mostrarOrdenacao = false, mostrarTitulo = false, titulo = 'Fatos encontrados' } : ListaFatosProps) {
  const classes = useStyles()
  if (fatos.length < 1) {
    return <FatosNaoEncontradosComponent />
  }
  return (
    <div>
      <div className={classes.header}>
        {
          mostrarTitulo ? <h3 className={classes.tituloFatos}>{titulo}</h3> : null
        }
      </div>
      <div className={classes.basesPesquisa}>
        <BasesPesquisaComponente />
        {
          mostrarOrdenacao ? <OrdenacaoComponent /> : null
        }
      </div>
      {fatos.map(fato => (
        <ResumoFatoComponent
          key={fato.resumoFato.id}
          isFavorito={fato.isFavorito}
          resumoFato={fato.resumoFato}
          className={classes.fatoBox}
        />
      ))}
    </div>
  )
}
