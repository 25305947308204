import React from 'react'
import { PaginaDeErroHttpComponent } from '../../ui-components/erro/PaginaDeErroHttpComponent'
import { ListaFatosPlaceholderComponent } from '../../ui-components/fato/ListaFatosPlaceholderComponent'
import { useTypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { listaLeituraActionCreators, listaLeituraThunkCreators } from '../../../store/actions/listaLeituraActions'
import { ListaFatosComponent } from '../../ui-components/fato/ListaFatosComponent'
import { createCounterReducer } from '../../../infra/libs/redux-hardtyped/createCounterReducer'
import { useSwan } from '../../../infra/libs/redux-swans/useSwan'
import { ListaLeituraVaziaComponent } from '../../ui-components/lista-leitura/ListaLeituraVaziaComponent'
import { favoritoThunkCreators } from '../../../store/actions/favoritoActions'
import { TipoFavorito } from '../../ui-components/favorito/TipoFavorito'
import { criaFatosComFavoritos } from '../fato/ListaFatosContainer'

const reducer = createCounterReducer([
  listaLeituraActionCreators.fatoRemovido,
])

export function ListaLeituraContainer() {
  const dispatch = useTypedDispatch()
  const counter = useSwan(reducer)
  const { data: listaDeLeitura, isLoading, isRejected, isFulfilled } = usePromiseEffect(async () => {
    const { data: listaLeitura } = await dispatch(listaLeituraThunkCreators.recuperarFatosDaListaDeLeitura())
    const { data: favoritos } = await dispatch(favoritoThunkCreators.recuperarMeusFavoritos(TipoFavorito.FATO))

    return criaFatosComFavoritos(listaLeitura, favoritos)
  }, [], [counter])

  if (isLoading) {
    return <ListaFatosPlaceholderComponent />
  }

  if (isRejected) {
    return (
      <PaginaDeErroHttpComponent
        mensagemDeErro="Tivemos um problema para exibir a lista de leitura."
      />
    )
  }

  if (isFulfilled && (!listaDeLeitura || listaDeLeitura.length < 1)) {
    return <ListaLeituraVaziaComponent />
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-7">
          <ListaFatosComponent
            fatos={listaDeLeitura || []}
            mostrarTitulo
            titulo="Lista de leitura"
          />
        </div>
      </div>
    </div>
  )
}
