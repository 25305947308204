import { AxiosRequestConfig } from 'axios'
import { AuthParams } from './AuthParams'
import { gerarTokenDeSistema } from './gerarTokenDeSistema'

export function createAuthAxiosInterceptor(codRecursoComputacional: string) {
  return async (config: AxiosRequestConfig) => {
    try {
      const authParams = await gerarTokenDeSistema(codRecursoComputacional)
      return adicionarAuthParamsAosHeaders(config, authParams)
    } catch (e) {
      return config
    }
  }
}

function adicionarAuthParamsAosHeaders(config: AxiosRequestConfig, authParams: AuthParams) {
  const { headers } = config
  headers.Authorization = `Bearer ${authParams.tokenJwt}`
  headers['X-UFP'] = `${authParams.userFingerPrint}`
  return { ...config, headers }
}
