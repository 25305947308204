import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTypedDispatch } from '../../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSwan } from '../../../../infra/libs/redux-swans/useSwan'
import { ModalWithActionsComponent } from '../../../ui-components/modal/ModalWithActionsComponent'
import { IconRemoveComponent } from '../../../ui-components/icons/IconRemoveComponent'
import { modalExcluirFichaReducer } from './modalExcluirFichaReducer'
import { modalExcluirFichaActionCreators } from './modalExcluirFichaActions'
import { fichaThunkCreators } from '../../../../store/actions/fichaActions'
import { useSpinningPromise } from '../../../../infra/libs/react-swearing/SpinnerScreenProvider'


export function ModalExcluirFichaContainer() {

  const dispatch = useTypedDispatch()
  const history = useHistory()
  const state = useSwan(modalExcluirFichaReducer)
  const modalEstaFechada = !state
  const idFicha = state?.idFicha ?? 0
  const tituloFicha = state?.tituloFicha

  const { run: onExcluir } = useSpinningPromise((id: number) => dispatch(fichaThunkCreators.excluirFicha(id ?? 0)))

  async function onAction() {
    await onExcluir(idFicha)
    history.push('/fichas')
  }


  function onCancel() {
    dispatch(modalExcluirFichaActionCreators.fecharModal())
  }

  if (modalEstaFechada) return null
  return (
    <ModalWithActionsComponent
      icon={<IconRemoveComponent fill="#e35555" height="74" width="74" />}
      primaryColor="red"
      title="Excluir ficha"
      text={`Você está prestes a excluir a ficha ${tituloFicha}. Essa ação não poderá ser desfeita.`}
      actionButtonText="Excluir ficha"
      onAction={onAction}
      cancelButtonText="Cancelar"
      onCancel={onCancel}
    />
  )
}
