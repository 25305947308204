import React from 'react'
import { makeStyles } from '@material-ui/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Switch, Tooltip } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { SituacaoCenario, SITUACAO_CENARIO } from 'bff/src/main/domain/SituacaoCenario'
import { TipoConectorOu } from 'bff/src/main/domain/TipoConectorOu'
import { IconButtonComponent } from '../comum/IconButtonComponent'
import { IconEditComponent } from '../icons/IconEditComponent'
import { IconArquivarComponent } from '../icons/IconArquivarComponent'
import { IconDesarquivarComponent } from '../icons/IconDesarquivarComponent'
import { IconRemoveComponent } from '../icons/IconRemoveComponent'
import { ItensBuscadosComConectoresComponent } from '../busca/ItensBuscadosComConectoresComponent'

const useStyles = makeStyles({
  tableTitle: {
    color: '#2f5dc9',
  },
  linha: {
    '&:hover': {
      background: '#f1f1f1',
      '& $acoes': {
        visibility: 'visible',
      },
    },
  },
  acoes: {
    visibility: 'hidden',
  },
  icon: {
    margin: '3px',
    marginLeft: '11px',
  },
  lista: {
    marginLeft: '8px',
    paddingLeft: '8px',
  },
})

interface LinhaCenarioComponentProps {
  idCenario: number
  situacaoCenario: SituacaoCenario
  nomeCenario: string
  isCenarioVisivel: boolean
  atores: string[]
  entidades: string[]
  temas: string[]
  keywords: string[]
  textoLivre?: string
  conectoresOu: TipoConectorOu[]
  filtros: string[]
  onClickVisualizarPaginaInicio: (id: number, isVisivel: boolean) => void
  onClickEditar: (id: number) => void
  onClickArquivar: (id: number) => void
  onClickDesarquivar: (id: number) => void
  onClickExcluir: (id: number, nome: string) => void
}

export function LinhaCenarioComponent(props: LinhaCenarioComponentProps) {
  const classes = useStyles()

  function handleClickVisualizarPaginaInicio() {
    props.onClickVisualizarPaginaInicio(props.idCenario, !props.isCenarioVisivel)
  }

  function handleClickEditar() {
    props.onClickEditar(props.idCenario)
  }

  function handleClickArquivar() {
    props.onClickArquivar(props.idCenario)
  }

  function handleClickDesarquivar() {
    props.onClickDesarquivar(props.idCenario)
  }

  function handleClickExcluir() {
    props.onClickExcluir(props.idCenario, props.nomeCenario)
  }

  return (
    <TableRow key={props.idCenario} className={classes.linha}>
      <TableCell className={classes.tableTitle} component="th" scope="row">
        <Link to={`/cenarios/${props.idCenario}`}>
          {props.nomeCenario}
        </Link>
      </TableCell>
      <TableCell align="left">
        <ItensBuscadosComConectoresComponent
          atores={props.atores}
          entidades={props.entidades}
          temas={props.temas}
          keywords={props.keywords}
          textoLivre={props.textoLivre}
          conectoresOu={props.conectoresOu}
        />
      </TableCell>
      <TableCell align="left">
        <ul className={classes.lista}>
          {
            props.filtros.map(item => (
              <li key={item}>
                {item}
              </li>
            ))
          }
        </ul>
      </TableCell>
      <TableCell align="left">
        <Switch
          checked={props.isCenarioVisivel}
          disabled={props.situacaoCenario === SITUACAO_CENARIO.ARQUIVADO.id}
          color="primary"
          name="mostrarTelaInicial"
          onChange={handleClickVisualizarPaginaInicio}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </TableCell>
      <TableCell align="left">
        {renderAcoes(classes, props, handleClickArquivar, handleClickDesarquivar, handleClickEditar, handleClickExcluir)}
      </TableCell>
    </TableRow>
  )
}

function renderAcoes(classes, props, handleClickArquivar, handleClickDesarquivar, handleClickEditar, handleClickExcluir) {
  return (
    <span className={classes.acoes}>
      {
        props.situacaoCenario === SITUACAO_CENARIO.ATIVO.id
        && (
          <>
            <Tooltip title="Editar" className={classes.icon}>
              <span>
                <IconButtonComponent onClick={handleClickEditar}>
                  <IconEditComponent width="17" height="17" />  
                </IconButtonComponent>  
              </span>
            </Tooltip>
            <Tooltip title="Arquivar" className={classes.icon}>
              <span>
                <IconButtonComponent onClick={handleClickArquivar}>
                  <IconArquivarComponent width="17" height="17" />
                </IconButtonComponent>
              </span>
            </Tooltip>
          </>
        )
      }
      {
        props.situacaoCenario === SITUACAO_CENARIO.ARQUIVADO.id
        && (
          <>
            <Tooltip title="Desarquivar" className={classes.icon}>
              <span>
                <IconButtonComponent onClick={handleClickDesarquivar}>
                  <IconDesarquivarComponent width="17" height="17" />  
                </IconButtonComponent>  
              </span>
            </Tooltip>
          </>
        )
      }
      <Tooltip title="Excluir" className={classes.icon}>
        <span>
          <IconButtonComponent onClick={handleClickExcluir}>
            <IconRemoveComponent width="17" height="17" />  
          </IconButtonComponent>  
        </span>
      </Tooltip>
    </span>
  )
}
