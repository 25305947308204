import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ButtonComponent } from '../comum/ButtonComponent'

const useStyles = makeStyles({
  buttonGroup: {
    display: 'flex',
    justifyContent: 'end',
    gap: '16px',
  },
})

interface BuscaAvancadaAcoesComponentProps {
  onSubmit: () => void
  onLimparPesquisa: () => void
}

export function BuscaAvancadaAcoesComponent({ onLimparPesquisa, onSubmit }: BuscaAvancadaAcoesComponentProps) {
  const classes = useStyles()
  return (
    <div className={classes.buttonGroup}>
      <ButtonComponent buttonText="Pesquisar" buttonTypeColor="blue" onClick={onSubmit} />
      <ButtonComponent buttonText="Limpar filtro" buttonTypeColor="white" onClick={onLimparPesquisa} />
    </div>
  )
}
