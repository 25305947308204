import React from 'react'
import { SITUACAO_CENARIO } from 'bff/src/main/domain/SituacaoCenario'
import { MenuComponent } from '../../ui-components/menu/MenuComponent'
import { MenuPlaceholderComponent } from '../../ui-components/menu/MenuPlaceholderComponent'
import { cenarioActionCreators, cenarioThunkCreators } from '../../../store/actions/cenarioActions'
import { useSwan } from '../../../infra/libs/redux-swans/useSwan'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { createCounterReducer } from '../../../infra/libs/redux-hardtyped/createCounterReducer'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'


const reducer = createCounterReducer([
  cenarioActionCreators.cenarioCriadoPelaTelaNovoCenario,
  cenarioActionCreators.cenarioAlterado,
  cenarioActionCreators.cenarioExcluido,
  cenarioActionCreators.cenarioArquivado,
  cenarioActionCreators.cenarioDesarquivado,
])

export function MenuContainer() {
  const dispatch = useTypedDispatch()
  const counter = useSwan(reducer)
  const { data: cenarios, isLoading } = usePromiseEffect(recuperarCenarios, [dispatch], [counter])
  if (isLoading) {
    return <MenuPlaceholderComponent />
  }
  return <MenuComponent cenarios={cenarios ?? []} />
}

async function recuperarCenarios(dispatch: TypedDispatch) {
  try {
    const { data: cenarios } = await dispatch(cenarioThunkCreators.recuperarMeusCenarios())
    return cenarios.filter(c => c.situacao === SITUACAO_CENARIO.ATIVO.id)
  } catch (e) {
    console.warn(e)
  }
  return []
}
