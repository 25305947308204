import React from 'react'
import { BuscarContadoresTelaInicio } from 'bff/src/main/controllers/contador/contadoresEndpoints'
import { contadorThunkCreators } from '../../../store/actions/contadorActions'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { StatusSistemaComponent } from '../../ui-components/comum/StatusSistemaComponent'


export function StatusSistemaContainer() {
  const dispatch = useTypedDispatch()
  const { data: total } = usePromiseEffect(recuperarContadores, [dispatch])
  
  if (!total) {
    return null
  }
  
  return (
    <StatusSistemaComponent
      atores={getNumeroFormatado(total.totalAtores)}
      entidades={getNumeroFormatado(total.totalEntidades)}
      eventos={getNumeroFormatado(total.totalEventos)}
      NoticiasEuniao={getNumeroFormatado(total.totalUniao + total.totalClipping)}
      processos={getNumeroFormatado(total.totalProcessos)}
      proposicoes={getNumeroFormatado(total.totalProposicoes)} 
    />
  )
}

async function recuperarContadores(dispatch: TypedDispatch) {
  try {
    const { data: contadores } = await dispatch(contadorThunkCreators.buscarContadoresTelaInicio())
    return contadores
  } catch (e) {
    console.warn(e)
  }
  return {} as BuscarContadoresTelaInicio.ResponseBody
}

function getNumeroFormatado(totalTipo?: number) {
  const total = totalTipo ?? 0
  return total.toLocaleString('pt-BR')
}
