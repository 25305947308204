import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FiltrosSelecionadosContainer } from '../../containers/filtro/FiltrosSelecionadosContainer'
import { FiltroTiposDeFatosContainer } from '../../containers/filtro/FiltroTiposDeFatosContainer'
import { FiltroPeriodoContainer } from '../../containers/filtro/FiltroPeriodoContainer'
import { FiltroTiposDeEventosContainer } from '../../containers/filtro/FiltroTiposDeEventosContainer'
import { ContadorQuantidadeFatosFiltradosContainer } from '../../containers/fato/ContadorQuantidadeFatosFiltradosContainer'
import { FiltroSigilosoContainer } from '../../containers/filtro/FiltroSigilosoContainer'
import { FiltroTiposDeProcessosContainer } from '../../containers/filtro/FiltroTiposDeProcessosContainer'
import { IconFiltroComponent } from '../icons/IconFiltroComponent'
import { FiltroEstadosProcessoContainer } from '../../containers/filtro/FiltroEstadosProcessoContainer'
import { FiltroRelatorProcessoContainer } from '../../containers/filtro/FiltroRelatorProcessoContainer'

const useStyles = makeStyles({
  filter: {
    marginBottom: '50px',
  },
  btnFechar: {
    display: 'none',
    '@media screen and (max-width:580px)': {
      display: 'flex',
      justifyContent: 'flex-end',
      letterSpacing: '1px',
      marginBottom: '10px',
    },
  },
  txtFechar: {
    border: '#7e7e7e solid 1px',
    padding: '0.5em',
  },
  quantidadeFatos: {
    fontSize: '14px',
    fontWeight: 400,
    marginBottom: '16px',
    marginTop: '8px',
  },
  filtroTipoFato: {
    marginTop: '32px',
  },
  accordionRoot: {
    marginTop: '24px',
    '&:before': {
      content: 'none',
    },
    '.Mui-expanded': {
      marginTop: '72px',
    },
  },
  accordion: {
    borderBottomColor: '#c4c4c4',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    boxShadow: 'none',
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    borderStyle: 'none',
    fontSize: '18px',
    fontWeight: 500,
    color: '#434343',
  },
  accordionSummaryRoot: {
    padding: '0px',
  },
  accordionContent: {
    flexGrow: 1,
    margin: '0 auto',
    padding: '0px 8px 0 0px',
    fontWeight: 500,
    '& > *': {
      display: 'block',
      marginBottom: '15px',
    },
  },
  labelFiltrarPesquisa: {
    paddingLeft: '8px',
    marginBottom: '5px',
  },
  iconFiltrarPesquisa: {
    marginBottom: '9px',
  },
})

export function FiltrosResultadosComponent() {
  const classes = useStyles()
  return (
    <div>
      <div>
        <div className={classes.btnFechar}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={classes.txtFechar} htmlFor="chBox">Fechar Filtros</label>
        </div>
      </div>
      <div>
        <ContadorQuantidadeFatosFiltradosContainer className={classes.quantidadeFatos} />
        <FiltrosSelecionadosContainer />
        {/* <div className={classes.filter}>
        <FiltroTiposDeAtoresContainer />
      </div> */}
        <Accordion className={classes.accordion} classes={{ root: classes.accordionRoot }}>
          <AccordionSummary classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummary }} expandIcon={<ExpandMoreIcon />}>
            <span className={classes.iconFiltrarPesquisa}>
              <IconFiltroComponent />
            </span>
            <span className={classes.labelFiltrarPesquisa}>Filtrar pesquisa</span>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionContent}>
            <div>
              <div className={`${classes.filter} ${classes.filtroTipoFato}`}>
                <FiltroTiposDeFatosContainer />
              </div>
              <div className={classes.filter}>
                <FiltroSigilosoContainer />
              </div>
              <div className={classes.filter}>
                <FiltroTiposDeProcessosContainer />
              </div>
              <div className={classes.filter}>
                <FiltroRelatorProcessoContainer />
              </div>
              <div className={classes.filter}>
                <FiltroEstadosProcessoContainer />
              </div>
              <div className={classes.filter}>
                <FiltroTiposDeEventosContainer />
              </div>
              <div className={classes.filter}>
                <FiltroPeriodoContainer />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}
