import React from 'react'
import { ResumoStakeholder } from 'bff/src/main/domain/ResumoStakeholder'
import { TIPOS_STAKEHOLDER } from 'bff/src/main/domain/TipoStakeholder'
import { TituloResultadoBuscaPrincipalComponent, BuscadoPor } from '../../ui-components/busca/TituloResultadoBuscaPrincipalComponent'
import {
  useQueryParamAsArrayOfString,
  useQueryParamAsString,
} from '../../../infra/libs/react-location-hooks/routerHooks'
import {
  QUERY_PARAM_ATORES,
  QUERY_PARAM_CONECTOR_OU,
  QUERY_PARAM_ENTIDADES,
  QUERY_PARAM_KEYWORDS,
  QUERY_PARAM_TEMAS,
  QUERY_PARAM_TEXTO_LIVRE,
} from '../../../util/constantesQueryParams'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { stakeholderThunkCreators } from '../../../store/actions/stakeholderActions'

export function TituloResultadoBuscaPrincipalContainer() {

  const dispatch = useTypedDispatch()
  const idsAtores = useQueryParamAsArrayOfString(QUERY_PARAM_ATORES)
  const idsEntidades = useQueryParamAsArrayOfString(QUERY_PARAM_ENTIDADES)
  const keywords = useQueryParamAsArrayOfString(QUERY_PARAM_KEYWORDS)
  const temas = useQueryParamAsArrayOfString(QUERY_PARAM_TEMAS)
  const textoLivre = useQueryParamAsString(QUERY_PARAM_TEXTO_LIVRE)
  const conectoresOu = useQueryParamAsArrayOfString(QUERY_PARAM_CONECTOR_OU)

  const { data: stakeholders } = usePromiseEffect(recuperarAtoresPesquisados, [dispatch, idsAtores, idsEntidades])

  const termosPesquisados = montarListaBuscadoPor({
    stakeholders: stakeholders || [],
    keywords,
    temas,
    textoLivre,
    conectoresOu,
  })
  return <TituloResultadoBuscaPrincipalComponent criterios={termosPesquisados || []} />
}

async function recuperarAtoresPesquisados(dispatch: TypedDispatch, idsAtores: string[], idsEntidades: string[]) {
  try {
    const { data: stakeholders } = await dispatch(stakeholderThunkCreators.buscarStakeholdersPorId([...idsAtores, ...idsEntidades]))
    return stakeholders
  } catch (e) {
    console.warn(e)
  }
  return []
}

function montarListaBuscadoPor(
  {
    stakeholders,
    keywords,
    temas,
    textoLivre,
    conectoresOu,
  } : {
    stakeholders: ResumoStakeholder[]
    keywords: string[]
    temas: string[]
    textoLivre?: string
    conectoresOu: string[]
  },
) {

  const resultado: BuscadoPor = {
    atores: stakeholders.filter(stakeholder => stakeholder.tipo === TIPOS_STAKEHOLDER.ATOR.id),
    entidades: stakeholders.filter(stakeholder => stakeholder.tipo === TIPOS_STAKEHOLDER.ENTIDADE.id),
    keywords,
    temas,
    textoLivre: textoLivre || '',
    conectoresOu,
  }
  return resultado
}
