import React from 'react'

export function IconFichaComponent({ fill = '#7E7E7E', height = '17', width = '17' }: { fill?: string, height?: string, width?: string }) {
  return (

    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} viewBox="0 0 24 24">
      <path fill={fill} d="M20 6h-8l-2-2H4c-1.11 0-2 .89-2 2v12c0 .53.21 1.04.586 1.414C2.96 19.79 3.47 20 4 20h16c.53 0 1.04-.21 1.414-.586C21.79 19.04 22 18.53 22 18V8c0-.53-.21-1.04-.586-1.414C21.04 6.21 20.53 6 20 6z" />
    </svg>
  )
}
