import { stakeholdersApi } from '../../api/stakeholdersApi'
import { fotoUtil } from '../../util/fotoUtil'
import { frontendRoutesUtil } from '../../util/frontendRoutesUtil'
import { isAtorInterno } from '../../util/stakeholdersUtil'
import { TIPOS_STAKEHOLDER } from '../../domain/TipoStakeholder'
import { Ator } from '../../domain/Ator'
import { tratarDescricao } from './stakeholderUtil'


export async function recuperarAtorPorId(id: string) {
  const { body: respostaIndiceAutoridades } = await stakeholdersApi.buscarStakeholdersPorIds([id])
  const autoridade = respostaIndiceAutoridades.hits.hits[0]
  return mapearAtor(autoridade)
}

function mapearAtor(ator): Ator {
  return {
    id: ator._id,
    tipo: TIPOS_STAKEHOLDER.ATOR.id,
    descricao: tratarDescricao(ator._source.perfil),
    papel: ator._source.cargo,
    orgao: ator._source.orgao,
    nome: ator._source.nome,
    nomeCompleto: ator._source.nome_completo,
    urlDestino: frontendRoutesUtil.montarUrlPerfilStakeholder(ator._id, ator._source.tipo_stakeholder),
    stakeholderInterno: isAtorInterno(ator),
    urlImagem: fotoUtil.tratarFotoAtor(ator),
    email: ator._source.email,
    telefone: ator._source.telefone,
    endereco: ator._source.endereco,
    cep: ator._source.cep,
    dataNascimento: ator._source.data_nascimento,
    aliases: ator._source.aliases,
    perfilTwitter: ator._source.twitter,
  }
}

