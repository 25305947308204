import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { callBff } from 'bff/src/main/infra/libs/bff-rpc/callBff'
import { BffRequest, BffRequestHeaders } from 'bff/src/main/infra/libs/bff-rpc/BffRequest'
import { BaseParams, BaseQuery } from 'bff/src/main/infra/libs/bff-rpc/ExpressRequestHandler'
import { AuthParams } from 'bff/src/main/infra/security/AuthParams'
import { ActionOrThunk, BffRequestThunkParams } from './BffRequestThunkParams'
import { bffActionCreators } from './bffActions'
import { RootState } from '../../../store/reducers/root/RootState'
import { TypedDispatch } from '../redux-hardtyped/useTypedDispatch'

/**
 * Cria um thunk action que inicializa, gerencia e trata uma requisição ao BFF da aplicação
 *
 * TODO: terminar documentação
 *
 * @param actionParams
 */
export const createBffRequestThunk = <Body, Params extends BaseParams, Query extends BaseQuery, ResponseBody, ErrorBody = unknown>(
  actionParams: BffRequestThunkParams<Body, Params, Query, ResponseBody, ErrorBody>,
): ThunkAction<AxiosPromise<ResponseBody>, RootState, any, Action<string>> => (
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    const authParams = getState().session?.authParams
    const requestWithAuthHeaders = insertDefaultHeadersInRequest(actionParams.request, authParams)
    handleRequest(actionParams, dispatch)
    return callBff(requestWithAuthHeaders)
      .then(response => handleSuccess(actionParams, response, dispatch))
      .catch((error: AxiosError<ErrorBody>) => handleError(actionParams, error, dispatch))
  }
)

function insertDefaultHeadersInRequest(request: BffRequest<any, any, any, any>, authParams?: AuthParams) {
  const defaultHeaders: BffRequestHeaders = {
    Accept: 'application/json',
  }
  if (authParams) {
    defaultHeaders.Authorization = `Bearer ${authParams.tokenJwt}`
    defaultHeaders['X-UFP'] = `${authParams.userFingerPrint}`
  }
  const newRequest: BffRequest<any, any, any, any> = {
    ...request,
    headers: {
      ...(request.headers ?? {}),
      ...defaultHeaders,
    },
  }
  return newRequest
}

function handleRequest<Body, Params extends BaseParams, Query extends BaseQuery, ResponseBody, ErrorBody = unknown>(
  actionParams: BffRequestThunkParams<Body, Params, Query, ResponseBody, ErrorBody>,
  dispatch: TypedDispatch,
) {
  actionParams.callbacks?.onRequest?.(dispatch)
  dispatchRequestActions(dispatch, actionParams)
}

function handleSuccess<Body, Params extends BaseParams, Query extends BaseQuery, ResponseBody, ErrorBody = unknown>(
  actionParams: BffRequestThunkParams<Body, Params, Query, ResponseBody, ErrorBody>,
  response: AxiosResponse<ResponseBody>,
  dispatch: TypedDispatch,
) {
  const successful = true
  actionParams.callbacks?.onSuccess?.(response, dispatch)
  actionParams.callbacks?.onFinish?.(successful, dispatch)
  dispatchSuccessActions(dispatch, actionParams, response)
  dispatchFinishActions(dispatch, actionParams, successful)
  return Promise.resolve(response)
}

function handleError<Body, Params extends BaseParams, Query extends BaseQuery, ResponseBody, ErrorBody = unknown>(
  actionParams: BffRequestThunkParams<Body, Params, Query, ResponseBody, ErrorBody>,
  error: AxiosError<ErrorBody>,
  dispatch: TypedDispatch,
) {
  const successful = false
  actionParams.callbacks?.onError?.(error, dispatch)
  actionParams.callbacks?.onFinish?.(successful, dispatch)
  dispatchErrorActions(dispatch, actionParams, error)
  dispatchFinishActions(dispatch, actionParams, successful)
  return Promise.reject(error)
}

function dispatchRequestActions<Body, Params extends BaseParams, Query extends BaseQuery, ResponseBody, ErrorBody = unknown>(
  dispatch: TypedDispatch,
  actionParams: BffRequestThunkParams<Body, Params, Query, ResponseBody, ErrorBody>,
) {
  const action = bffActionCreators.request()
  const customAction = actionParams?.actions?.request?.()
  dispatchActions(dispatch, action, customAction)
}

function dispatchSuccessActions<Body, Params extends BaseParams, Query extends BaseQuery, ResponseBody, ErrorBody = unknown>(
  dispatch: TypedDispatch,
  actionParams: BffRequestThunkParams<Body, Params, Query, ResponseBody, ErrorBody>,
  response: AxiosResponse<ResponseBody>,
) {
  const action = bffActionCreators.success(response)
  const customAction = actionParams?.actions?.success?.(response)
  dispatchActions(dispatch, action, customAction)
}

function dispatchErrorActions<Body, Params extends BaseParams, Query extends BaseQuery, ResponseBody, ErrorBody = unknown>(
  dispatch: TypedDispatch,
  actionParams: BffRequestThunkParams<Body, Params, Query, ResponseBody, ErrorBody>,
  error: AxiosError<ErrorBody>,
) {
  const action = bffActionCreators.error(error)
  const customAction = actionParams?.actions?.error?.(error)
  dispatchActions(dispatch, action, customAction)
}

function dispatchFinishActions<Body, Params extends BaseParams, Query extends BaseQuery, ResponseBody, ErrorBody = unknown>(
  dispatch: TypedDispatch,
  actionParams: BffRequestThunkParams<Body, Params, Query, ResponseBody, ErrorBody>,
  successful: boolean,
) {
  const action = bffActionCreators.finish(successful)
  const customAction = actionParams?.actions?.finish?.(successful)
  dispatchActions(dispatch, action, customAction)
}

function dispatchActions(dispatch: TypedDispatch, action: ActionOrThunk, customAction?: ActionOrThunk | ActionOrThunk[]) {
  dispatch(action)
  if (customAction) {
    if (Array.isArray(customAction)) {
      customAction.forEach(dispatch)
    } else {
      dispatch(customAction)
    }
  }
}
