import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TitleComponent } from '../ui-components/head/TitleComponent'
import { ListaLeituraContainer } from '../containers/lista-leitura/ListaLeituraContainer'

const useStyles = makeStyles({
  root: {
    marginTop: '32px',
  },
})

export function ListaLeituraView() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <TitleComponent title="Lista de leitura" />
      <ListaLeituraContainer />
    </div>
  )
}
