import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress } from '@material-ui/core'
import { AppTheme } from '../../../style/theme'


const useStyles = makeStyles((theme: AppTheme) => ({
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: theme.zIndex.spinner,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export function SpinnerOverlayComponent() {
  const classes = useStyles()
  return (
    <div className={classes.overlay}>
      <CircularProgress size={50} />
    </div>
  )
}
