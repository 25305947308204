import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { recuperarAtorPorId } from '../../app/stakeholder/recuperarAtorPorId'
import { Ator } from '../../domain/Ator'


export namespace BuscarAtorEndpoint {
  export type Body = undefined
  export type Params = { id: string }
  export type Query = undefined
  export type Response = Ator
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/atores/:id',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<Response>) => {
      const { id } = request.params
      const ator = await recuperarAtorPorId(id)
      response.json(ator)
    },
  })
}
