
export function createClauseMatch(campoParaPesquisar: string, valor) {
  const objeto = {
    match: {},
  }
  objeto.match[campoParaPesquisar] = valor
  return objeto
}

export function createClauseMatchPhrase(campoParaPesquisar: string, valor) {
  const objeto = {
    match_phrase: {},
  }
  objeto.match_phrase[campoParaPesquisar] = valor
  return objeto
}

export function createClauseBoolShould() {
  return {
    bool: {
      should: [] as any[],
    },
  }
}

export function createClauseBoolMust() {
  return {
    bool: {
      must: [] as any[],
    },
  }
}

export function createClauseBoolMustNot() {
  return {
    bool: {
      must_not: [] as any[],
    },
  }
}
