import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { ResumoStakeholder } from 'bff/src/main/domain/ResumoStakeholder'
import { TIPOS_CONECTORES_OU } from 'bff/src/main/domain/TipoConectorOu'
import { CriteriosBuscaPrincipalComponent } from '../comum/CriteriosBuscaPrincipalComponent'
import { isConectorEntreCampos } from '../../../util/conectoresUtil'

const useStyles = makeStyles({
  texto: {
    fontSize: '14px',
    color: '#434343',
  },
  itensPesquisa: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    fontSize: '14px',
  },
})

export interface BuscadoPor {
  atores: ResumoStakeholder[]
  entidades: ResumoStakeholder[]
  keywords: string[]
  temas: string[]
  textoLivre: string
  conectoresOu: string[]
}

interface TituloResultadoBuscaPorStakeholdersTemasPalavrasChavesProps {
  criterios: BuscadoPor
}

export function TituloResultadoBuscaPrincipalComponent(
  { criterios } : TituloResultadoBuscaPorStakeholdersTemasPalavrasChavesProps,
) {
  const classes = useStyles()
  return (
    <div className={classes.itensPesquisa}>
      <h5>{'Buscando por: '}</h5>
      <CriteriosBuscaPrincipalComponent
        criterios={converteCriteriosTipoStakeholder(criterios.atores)}
        conectoresOu={criterios.conectoresOu}
        tipoConector={TIPOS_CONECTORES_OU.ATOR.id}
        isConectorEntreCampos={
          isConectorEntreCampos(
            criterios.atores,
            [criterios.entidades, criterios.keywords, criterios.temas],
            criterios.textoLivre,
          )
        }
      />
      <CriteriosBuscaPrincipalComponent
        criterios={converteCriteriosTipoStakeholder(criterios.entidades)}
        conectoresOu={criterios.conectoresOu}
        tipoConector={TIPOS_CONECTORES_OU.ENTIDADE.id}
        isConectorEntreCampos={
          isConectorEntreCampos(
            criterios.entidades,
            [criterios.keywords, criterios.temas],
            criterios.textoLivre,
          )
        }
      />
      <CriteriosBuscaPrincipalComponent
        criterios={converteCriterios(criterios.temas, 'tema')}
        conectoresOu={criterios.conectoresOu}
        tipoConector={TIPOS_CONECTORES_OU.TEMA.id}
        isConectorEntreCampos={
          isConectorEntreCampos(
            criterios.temas,
            [criterios.keywords],
            criterios.textoLivre,
          )
        }
      />
      <CriteriosBuscaPrincipalComponent
        criterios={converteCriterios(criterios.keywords, 'palavra-chave')}
        conectoresOu={criterios.conectoresOu}
        tipoConector={TIPOS_CONECTORES_OU.KEYWORD.id}
        isConectorEntreCampos={
          isConectorEntreCampos(
            criterios.keywords,
            [],
            criterios.textoLivre,
          )
        }
      />
      { criterios.textoLivre !== undefined && criterios.textoLivre !== '' && (
        <CriteriosBuscaPrincipalComponent
          criterios={converteCriterios([criterios.textoLivre], 'texto-livre')}
          conectoresOu={[]}
          tipoConector={undefined}
          isConectorEntreCampos={false}
        />
      )}
    </div>
  )
}

function converteCriterios(criterios, papel) {
  return criterios.map(item => ({
    nome: item,
    papel,
  }))
}

function converteCriteriosTipoStakeholder(criterios) {
  return criterios.map(item => ({
    nome: item.nome,
    papel: item.papel,
  }))
}
