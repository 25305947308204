import {
  ChecarSeUsuarioVisualizouOnBoardingEndpoint,
  RegistrarVisualizacaoOnBoardingEndpoint,
} from 'bff/src/main/controllers/onboarding/onBoardingEndpoints'
import { defineActionCreators } from '../../infra/libs/redux-hardtyped/defineActionCreators'
import { alertActionCreators } from './alertActions'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'


export const onBoardingActionCreators = defineActionCreators('onBoarding', {
  init: (name: string, totalSteps: number) => ({ name, totalSteps }),
  goNext: (name: string) => ({ name }),
  goBack: (name: string) => ({ name }),
  finish: (name: string) => ({ name }),
})

export const onBoardingThunkCreators = {

  iniciarOnBoardingSeUsuarioNaoTiverFeito: (nome: string, totalSteps: number) => createBffRequestThunk({
    request: {
      endpoint: ChecarSeUsuarioVisualizouOnBoardingEndpoint.definition,
      params: { nome },
    },
    actions: {
      error: () => alertActionCreators.showWarn('Não foi possível verificar se já visualizou o tutorial.'),
    },
    callbacks: {
      onSuccess: (response, dispatch) => {
        if (response.data === false) {
          dispatch(onBoardingActionCreators.init(nome, totalSteps))
        }
      },
    },
  }),

  concluirOnBoardingProUsuario: (nome: string) => createBffRequestThunk({
    request: {
      endpoint: RegistrarVisualizacaoOnBoardingEndpoint.definition,
      params: { nome },
    },
    actions: {
      request: () => onBoardingActionCreators.finish(nome),
      error: () => alertActionCreators.showWarn('Ocorreu um erro ao tentar registrar a visualização do tutorial.'),
    },
  }),

}
