import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { EditarCenarioEndpoint } from 'bff/src/main/controllers/cenario/cenariosEndpoints'
import { TIPOS_STAKEHOLDER } from 'bff/src/main/domain/TipoStakeholder'
import { TIPOS_CONECTORES_OU } from 'bff/src/main/domain/TipoConectorOu'
import { cenarioThunkCreators } from '../../../store/actions/cenarioActions'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { useSpinningPromise } from '../../../infra/libs/react-swearing/SpinnerScreenProvider'
import { useParamAsInt } from '../../../infra/libs/react-location-hooks/routerHooks'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { CenarioForm } from './CenarioForm'
import { PaginaDeErroHttpComponent } from '../../ui-components/erro/PaginaDeErroHttpComponent'
import { useBuscaAutocomplete } from '../busca/useBuscaAutocomplete'
import { BuscaAutocompleteInputOption } from '../../ui-components/busca/BuscaAutocompleteOptionComponent'
import { stakeholderThunkCreators } from '../../../store/actions/stakeholderActions'


export function EditarCenarioContainer() {
  const dispatch = useTypedDispatch()
  const history = useHistory()
  const idCenario = useParamAsInt('id')
  const { atorProps, entidadeProps, temaProps, keywordProps } = useBuscaAutocomplete()
  const { data: cenarioEStakeholders, isLoading, isRejected } = usePromiseEffect(recuperarCenarioEStakeholders, [dispatch, idCenario])
  const { onAtoresChange, onConectorOuAtoresChange } = atorProps
  const { onEntidadesChange, onConectorOuEntidadesChange } = entidadeProps
  const { onTemasChange, onConectorOuTemasChange } = temaProps
  const { onConectorOuKeywordsChange } = keywordProps

  useEffect(() => {
    if (cenarioEStakeholders && cenarioEStakeholders.newSelectedOptions.length > 0) {
      onAtoresChange(cenarioEStakeholders.newSelectedOptions.filter(a => a.tipo === TIPOS_STAKEHOLDER.ATOR.id))
      onEntidadesChange(cenarioEStakeholders.newSelectedOptions.filter(e => e.tipo === TIPOS_STAKEHOLDER.ENTIDADE.id))
    }
  }, [onAtoresChange, cenarioEStakeholders, onEntidadesChange])

  useEffect(() => {
    if (cenarioEStakeholders && cenarioEStakeholders.cenario.consulta.temas) {
      onTemasChange(cenarioEStakeholders.cenario.consulta.temas.map(t => ({ tipo: 'TEMA', nome: t, papel: 'Tema', id: t })))
    }
  }, [cenarioEStakeholders, onTemasChange])
  
  useEffect(() => {
    if (cenarioEStakeholders && cenarioEStakeholders.cenario.consulta.conectoresOu) {
      onConectorOuAtoresChange(cenarioEStakeholders.cenario.consulta.conectoresOu.includes(TIPOS_CONECTORES_OU.ATOR.id))
      onConectorOuEntidadesChange(cenarioEStakeholders.cenario.consulta.conectoresOu.includes(TIPOS_CONECTORES_OU.ENTIDADE.id))
      onConectorOuTemasChange(cenarioEStakeholders.cenario.consulta.conectoresOu.includes(TIPOS_CONECTORES_OU.TEMA.id))
      onConectorOuKeywordsChange(cenarioEStakeholders.cenario.consulta.conectoresOu.includes(TIPOS_CONECTORES_OU.KEYWORD.id))
    }
  }, [cenarioEStakeholders, onConectorOuAtoresChange, onConectorOuEntidadesChange, onConectorOuKeywordsChange, onConectorOuTemasChange])

  const { run: onConfirm } = useSpinningPromise(
    (command: EditarCenarioEndpoint.Body) => dispatch(cenarioThunkCreators.alterarCenario(idCenario, command)),
  )

  const onCancel = () => history.goBack()

  if (!cenarioEStakeholders?.cenario && isLoading) {
    return null
  }

  if (isRejected) {
    return <PaginaDeErroHttpComponent mensagemDeErro="Tivemos um problema ao carregar informações de cenário" />
  }

  return (
    <CenarioForm 
      cenarioInicial={cenarioEStakeholders?.cenario}
      onConfirm={onConfirm}
      onCancel={onCancel} 
      atorProps={atorProps}
      entidadeProps={entidadeProps}
      temaProps={temaProps}
      keywordProps={keywordProps}
    />
  )
}

async function recuperarCenarioEStakeholders(dispatch: TypedDispatch, idCenario: number) {
  const { data: cenario } = await dispatch(cenarioThunkCreators.buscarCenarioPorId(idCenario))
  const newSelectedOptions: BuscaAutocompleteInputOption[] = []
  if (cenario.consulta.stakeholders && cenario.consulta.stakeholders.length > 0) {
    const { data: stakeholders } = await dispatch(stakeholderThunkCreators.buscarStakeholdersPorId(cenario.consulta.stakeholders))
    stakeholders.forEach(a => newSelectedOptions.push({ id: a.id, tipo: a.tipo, nome: a.nome, papel: a.papel }))
  }
  return { cenario, newSelectedOptions }
}
