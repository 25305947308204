import { EnumObjectWithId } from '../util/EnumObject'

export type Ordenacao =
  'MAIS_RELEVANTE' |
  'MAIS_RECENTE' |
  'MAIS_ANTIGO'

export const ORDENACOES: EnumObjectWithId<Ordenacao, OrdenacaoShape> = {
  MAIS_RELEVANTE: {
    id: 'MAIS_RELEVANTE',
    label: 'Mais relevante',
    clause: [
      { ano_relevancia: { order: 'desc' } },
      { mes_relevancia: { order: 'desc' } },
      { dia_relevancia: { order: 'desc' } },
      { _score: { order: 'desc' } },
    ],
  },
  MAIS_RECENTE: {
    id: 'MAIS_RECENTE',
    label: 'Mais recente',
    clause: [{ data_publicacao: 'desc' }],
  },
  MAIS_ANTIGO: {
    id: 'MAIS_ANTIGO',
    label: 'Mais antigo',
    clause: [{ data_publicacao: 'asc' }],
  },
}

interface OrdenacaoShape {
  label: string
  clause: {
    [key: string]: any
  }[]
}
