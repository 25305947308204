import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/styles'
import layoutBg from '../../assets/bg_inicial.jpg'

const useStyles = makeStyles({
  bg: {
    backgroundImage: `url(${layoutBg})`,
    width: '100%',
    height: '100%',
  },
})

export interface EntranceLayoutProps {
  children: ReactNode
}

export function EmptyLayout({ children }: EntranceLayoutProps) {
  const classes = useStyles()
  return (
    <div className={classes.bg}>
      <div className="container">
        {children}
      </div>
    </div>
  )
}
