import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  titulo: {
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 400,
    marginTop: '5px',
  },
})

export function BasesPesquisaComponente() {

  const classes = useStyles()

  return (
    <div className={classes.titulo}>
      Bases de pesquisa:
      <a href="https://monitoring.knewin.com/Noticias.aspx"> Clipping</a>
      ,
      <a href="https://contas.tcu.gov.br/ords/f?p=ASPAR"> SisLegis</a>
      ,
      <a href="https://portal.tcu.gov.br/e-tcu/"> e-TCU </a>
      e
      <a href="https://contas.tcu.gov.br/ords/f?p=RELACIONA"> Relaciona</a>
    </div>
  )
}
