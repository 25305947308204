import { BuscarEntidadeEndpoint } from 'bff/src/main/controllers/stakeholder/entidadesEndpoints'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'

export const entidadeThunkCreators = {

  buscarEntidadePorId: (id: string) => createBffRequestThunk({
    request: {
      endpoint: BuscarEntidadeEndpoint.definition,
      params: { id },
    },
  }),

}
