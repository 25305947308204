import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import { TIPOS_FATO } from 'bff/src/main/domain/TipoFato'
import { ResumoFato } from 'bff/src/main/domain/ResumoFato'
import { LabelTipoFatoComponent } from './LabelTipoFatoComponent'
import { TagSigilosoComponent } from './TagSigilosoComponent'
import imagemUniao from '../../../assets/imagem-uniao-nao-encontrada.jpg'
import { OpcoesFatoContainer } from '../../containers/fato/OpcoesFatoContainer'
import { ButtonIconFavoritarComponent } from '../comum/ButtonIconFavoritarComponent'
import { TipoFavorito } from '../favorito/TipoFavorito'

const useStyles = makeStyles({
  fato: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  fatoUniao: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '15px',
  },
  headerESubHeaderUniao: {
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  tipoDeFato: {
    marginRight: '15px',
  },
  titulo: {
    color: '#434343',
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: 0,
  },
  dataHora: {
    fontSize: '14px',
    color: '#434343',
  },
  resumo: {
    marginTop: '8px',
    color: '#434343',
    fontSize: '16px',
    display: '-webkit-box',
    marginBottom: 0,
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '@media screen and (max-width:1000px)': {
      display: 'block',
      width: '100%',
    },
  },
  informacaoAdicional: {
    marginRight: '24px',
    fontSize: '14px',
    fontWeight: 500,
    color: '#434343',
  },
  imagemBox: {
    width: 'auto',
    height: '88px',
    borderRadius: '3px',
  },
  imagemNoticia: {
    width: 'auto',
    height: '100%',
    objectFit: 'cover',
  },
  sigiloso: {
    marginRight: '24px',

  },
  favoritos: {
    display: 'flex',
    alignItems: 'flex-start',
  },

})

export interface ResumoFatoProps {
  resumoFato: ResumoFato
  className?: string
  isFavorito: boolean
}

const role = 'favoritos'

export function ResumoFatoComponent(props: ResumoFatoProps) {

  const classes = useStyles()
  if (props.resumoFato.tipoFato === TIPOS_FATO.NOTICIA_UNIAO.id) {
    return renderFatoUniao(props, classes)
  }
  return renderFato(props, classes)
}

function renderFato(props: ResumoFatoProps, classes) {

  return (
    <div className={`${classes.fato} ${props.className}`}>
      <Link to={props.resumoFato.urlDestinoFato}>
        <div>
          {renderHeader(props, true, classes)}
          {renderSubHeader(props, classes)}
          {renderResumo(props, classes)}
        </div>
      </Link>
      <div role={role} className={classes.favoritos}>
        <ButtonIconFavoritarComponent idItemFavorito={props.resumoFato.id} isFavorito={props.isFavorito} tipoFavorito={TipoFavorito.FATO} />
        <OpcoesFatoContainer fatoId={props.resumoFato.id} />
      </div>

    </div>
  )
}

function renderFatoUniao(props: ResumoFatoProps, classes) {
  return (
    <div className={classes.fato}>
      <Link to={props.resumoFato.urlDestinoFato}>
        <div className={classes.fatoUniao}>
          {renderImagemUniao(props, classes)}
          <div className={classes.headerESubHeaderUniao}>
            {renderHeader(props, false, classes)}
            {renderSubHeader(props, classes)}
          </div>
        </div>
      </Link>
      <div role={role} className={classes.favoritos}>
        <ButtonIconFavoritarComponent idItemFavorito={props.resumoFato.id} isFavorito={props.isFavorito} tipoFavorito={TipoFavorito.FATO} />
        <OpcoesFatoContainer fatoId={props.resumoFato.id} />
      </div>

    </div>
  )
}

function renderHeader({ resumoFato }: ResumoFatoProps, mostrarTipoFato: boolean, classes) {
  return (
    <div className={classes.header}>
      {
        mostrarTipoFato
          ? (
            <span className={classes.tipoDeFato}>
              <LabelTipoFatoComponent tipo={resumoFato.tipoFato} />
            </span>
          ) : null    
      }
      <h5 className={classes.titulo}>{resumoFato.titulo}</h5>
    </div>
  )
}

function renderSubHeader({ resumoFato }: ResumoFatoProps, classes) {
  return (
    <div className={classes.subHeader}>
      {
        resumoFato.isSigiloso ? (
          <TagSigilosoComponent className={classes.sigiloso} />
        ) : null
      }
      {
        resumoFato.informacaoAdicionalMaisRelevante ? (
          <span className={classes.informacaoAdicional}>{resumoFato.informacaoAdicionalMaisRelevante}</span>
        ) : null
      }
      <span className={classes.dataHora}>{resumoFato.informacaoAdicionalMenosRelevante}</span>
    </div>
  )
}

function renderResumo({ resumoFato }: ResumoFatoProps, classes) {
  return (
    <p className={classes.resumo}>{resumoFato.resumo}</p>
  )
}

function renderImagemUniao({ resumoFato }: ResumoFatoProps, classes) {
  return (
    <div className={classes.imagemBox}>
      <img src={resumoFato.imagemNoticia ? resumoFato.imagemNoticia : imagemUniao} alt="imagemUniao" className={`${classes.imagemNoticia}`} />
    </div>
  )
}
