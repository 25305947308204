import React, { ReactNode } from 'react'
import { Provider as StoreProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import 'moment/locale/pt-br'
import MomentUtils from '@date-io/moment'
import { theme } from './style/theme'
import { setupStore } from './store/store'
import { SpinnerScreenProvider } from './infra/libs/react-swearing/SpinnerScreenProvider'

moment.locale('pt-br')
const store = setupStore()

export interface ProvidersProps {
  children: ReactNode
}

export const Providers = ({ children }: ProvidersProps) => (
  <StoreProvider store={store}>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <BrowserRouter>
          <SpinnerScreenProvider>
            {children}
          </SpinnerScreenProvider>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </StoreProvider>
)
