import { SnackbarMessage } from 'notistack'
import { enqueueSnackbar } from '../../infra/libs/redux-notistack/notistackActions'


export const alertActionCreators = {
  showSuccess: (message: SnackbarMessage) => enqueueSnackbar({ message, options: { variant: 'success' } }),
  showInfo: (message: SnackbarMessage) => enqueueSnackbar({ message, options: { variant: 'info' } }),
  showWarn: (message: SnackbarMessage) => enqueueSnackbar({ message, options: { variant: 'warning' } }),
  showError: (message: SnackbarMessage) => enqueueSnackbar({ message, options: { variant: 'error' } }),
}
