import React from 'react'
import * as Sentry from '@sentry/react'
import { isProducao } from '../../../util/ambienteUtil'


if (isProducao()) {
  Sentry.init({ dsn: 'https://c91ad552f2cb4a4b83c00d8c5e4ccb2a@sentry.apps.tcu.gov.br/56' })
} else {
  Sentry.init({ dsn: 'https://ad0f0385689b4ece840b32d173923823@sentry.apps.tcu.gov.br/25' })
}

export interface RootErrorBoundaryProps {
  children: any
}

export function RootErrorBoundary({ children }: RootErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary fallback={renderFallback}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

function renderFallback() {
  return <div>Ocorreu um erro inesperado!!!</div>
}
