
export const QUERY_PARAM_ATORES = 'atores'
export const QUERY_PARAM_ENTIDADES = 'entidades'
export const QUERY_PARAM_KEYWORDS = 'keywords'
export const QUERY_PARAM_TEMAS = 'temas'
export const QUERY_PARAM_TEXTO_LIVRE = 'textoLivre'
export const QUERY_PARAM_FILTRO_TIPO_FATOS = 'filtroTiposDeFatos'
export const QUERY_PARAM_FILTRO_RELATOR = 'filtroRelator'
export const QUERY_PARAM_FILTRO_TIPO_EVENTOS = 'filtroTiposDeEventos'
export const QUERY_PARAM_FILTRO_ESTADO_PROCESSO = 'filtroEstadosProcesso'
export const QUERY_PARAM_FILTRO_TIPO_PROCESSOS = 'filtroTiposProcessos'
export const QUERY_PARAM_FILTRO_TIPO_ATORES = 'filtroTiposDeAtores'
export const QUERY_PARAM_FILTRO_DATA_INICIO = 'filtroDtInicio'
export const QUERY_PARAM_FILTRO_DATA_FIM = 'filtroDtFim'
export const QUERY_PARAM_FILTRO_PERIODO = 'filtroPeriodo'
export const QUERY_PARAM_FILTRO_GRAUS_SIGILO = 'filtroGrausSigilo'
export const QUERY_PARAM_ORDENACAO = 'ordenacao'
export const QUERY_PARAM_CONECTOR_OU = 'conectoresOu'
