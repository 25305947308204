import React from 'react'
import { TipoFato, TIPOS_FATO } from 'bff/src/main/domain/TipoFato'
import { BuscarContadoresTiposDeFatos } from 'bff/src/main/controllers/contador/contadoresEndpoints'
import { ContadoresTiposDeFatos } from 'bff/src/main/domain/ContadoresTiposDeFatos'
import { TipoConectorOu } from 'bff/src/main/domain/TipoConectorOu'
import { CheckboxesFilterContainer } from './CheckboxesFilterContainer'
import {
  useQueryParamAsArrayOfString,
  useQueryParamAsString,
} from '../../../infra/libs/react-location-hooks/routerHooks'
import { contadorThunkCreators } from '../../../store/actions/contadorActions'
import {
  QUERY_PARAM_ATORES, QUERY_PARAM_CONECTOR_OU,
  QUERY_PARAM_ENTIDADES,
  QUERY_PARAM_FILTRO_DATA_FIM,
  QUERY_PARAM_FILTRO_DATA_INICIO,
  QUERY_PARAM_FILTRO_TIPO_FATOS,
  QUERY_PARAM_KEYWORDS,
  QUERY_PARAM_TEMAS,
  QUERY_PARAM_TEXTO_LIVRE,
} from '../../../util/constantesQueryParams'
import { useTypedDispatch, TypedDispatch } from '../../../infra/libs/redux-hardtyped/useTypedDispatch'
import { usePromiseEffect } from '../../../infra/libs/react-swearing/usePromiseEffect'
import { useMemoObject } from '../../../hooks/useMemoObject'


export function FiltroTiposDeFatosContainer() {

  const dispatch = useTypedDispatch()
  const payload = useMemoObject({
    idsAtores: useQueryParamAsArrayOfString(QUERY_PARAM_ATORES),
    idsEntidades: useQueryParamAsArrayOfString(QUERY_PARAM_ENTIDADES),
    keywords: useQueryParamAsArrayOfString(QUERY_PARAM_KEYWORDS),
    temas: useQueryParamAsArrayOfString(QUERY_PARAM_TEMAS),
    textoLivre: useQueryParamAsString(QUERY_PARAM_TEXTO_LIVRE),
    dtInicio: useQueryParamAsString(QUERY_PARAM_FILTRO_DATA_INICIO),
    dtFim: useQueryParamAsString(QUERY_PARAM_FILTRO_DATA_FIM),
    listaConectoresOu: useQueryParamAsArrayOfString(QUERY_PARAM_CONECTOR_OU) as TipoConectorOu[],
  })

  const { data: contadores } = usePromiseEffect(recuperarContadoresTiposFatos, [dispatch, payload])

  return (
    <CheckboxesFilterContainer
      filterName={QUERY_PARAM_FILTRO_TIPO_FATOS}
      filterLabel="Tipos de Fatos"
      options={getOptions(contadores)}
    />
  )
}

async function recuperarContadoresTiposFatos(dispatch: TypedDispatch, payload: BuscarContadoresTiposDeFatos.Query) {
  try {
    const { data: contadores } = await dispatch(contadorThunkCreators.buscarContadoresTiposDeFato(payload))
    return contadores
  } catch (e) {
    console.warn(e)
  }
  return undefined
}

function getOptions(contadores?: ContadoresTiposDeFatos) {
  return [
    createOptionForTipoFato('EVENTO_RELACIONA', contadores?.totalEventos),
    createOptionForTipoFato('NOTICIA_CLIPPING', contadores?.totalClipping),
    createOptionForTipoFato('PROCESSO', contadores?.totalProcessos),
    createOptionForTipoFato('PROPOSICAO_LEGISLATIVA', contadores?.totalProposicoes),
    createOptionForTipoFato('NOTICIA_UNIAO', contadores?.totalUniao),
  ]
}

function createOptionForTipoFato(tipoFato: TipoFato, totalFatosDoTipo?: number) {
  const total = totalFatosDoTipo ?? 0
  return {
    label: `${(TIPOS_FATO[tipoFato]?.label)} (${total.toLocaleString('pt-BR')})`,
    value: tipoFato,
    disabled: total < 1,
  }
}
