import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/styles'
import { HeaderComponent } from '../ui-components/comum/HeaderComponent'
import { AppTheme } from '../../style/theme'

const useStyles = makeStyles((theme: AppTheme) => ({
  layout: {
    minHeight: '100%',
    height: 'auto',
    width: '100%',
  },
  header: {
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
  },
  content: {
    backgroundColor: '#fff',
  },
}))

export interface MainLayoutProps {
  children: ReactNode
}

export function HeaderLayout({ children }: MainLayoutProps) {
  const classes = useStyles()
  return (
    <div className={classes.layout}>
      <div className={classes.header}>
        <HeaderComponent />
      </div>
      <div className={classes.content}>
        {children}
      </div>
    </div>
  )
}
