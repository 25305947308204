import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TitleComponent } from '../ui-components/head/TitleComponent'
import { CenarioContainer } from '../containers/cenario/CenarioContainer'


const useStyles = makeStyles({
  root: {
    marginTop: '15px',
  },
})

export function CenarioView() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <TitleComponent title="Cenário" />
      <CenarioContainer />
    </div>
  )
}
