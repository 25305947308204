import { fatosApi } from '../../api/fatosApi'
import { stakeholdersApi } from '../../api/stakeholdersApi'
import { TIPOS_FATO } from '../../domain/TipoFato'
import { TIPOS_STAKEHOLDER } from '../../domain/TipoStakeholder'
import { processosApi } from '../../api/processosApi'

export async function recuperarContadoresTelaInicio() {
  const [
    { body: respostaIndiceFatos },
    { body: respostaIndiceStakeholders },
    { body: respostaIndiceProcessos },
  ] = await Promise.all([
    fatosApi.buscarTotalDivididoPorTipo(),
    stakeholdersApi.buscarTotalDivididoPorTipo(),
    processosApi.buscarContadorTotalProcessos(),
  ])
  const listaContadoresFatos = respostaIndiceFatos.aggregations.count_by_type.buckets
  const listaContadoresStakeholders = respostaIndiceStakeholders.aggregations.count_by_type.buckets
  return {
    totalProcessos: respostaIndiceProcessos.count,
    totalClipping: getTotalPorTipo(listaContadoresFatos, TIPOS_FATO.NOTICIA_CLIPPING.id),
    totalUniao: getTotalPorTipo(listaContadoresFatos, TIPOS_FATO.NOTICIA_UNIAO.id),
    totalEventos: getTotalPorTipo(listaContadoresFatos, TIPOS_FATO.EVENTO_RELACIONA.id),
    totalProposicoes: getTotalPorTipo(listaContadoresFatos, TIPOS_FATO.PROPOSICAO_LEGISLATIVA.id),
    totalAtores: getTotalPorTipo(listaContadoresStakeholders, TIPOS_STAKEHOLDER.ATOR.id),
    totalEntidades: getTotalPorTipo(listaContadoresStakeholders, TIPOS_STAKEHOLDER.ENTIDADE.id),
  }
}

function getTotalPorTipo(lista: any[], tipo: string) {
  const obj = lista.find(item => item.key === tipo)
  return obj?.doc_count ?? 0
}
