import { EnumObjectWithId } from '../util/EnumObject'

export type TipoProcesso =
  'ADS' | 'APOS' | 'SOLI' | 'CBEX' | 'TCE' | 'REPR' | 'PCIV' |
  'AJSOL' | 'PC' | 'PMIL' | 'RA' | 'REFO' | 'DEN' | 'MON' | 'SCT' |
  'TC' | 'TCSP' | 'RL' | 'PCSP' | 'ACOM' | 'SCN' | 'PEEC' | 'CONS' |
  'RI' | 'RACOM' | 'RMON' | 'DES' | 'IND' | 'PCEX' | 'CCTO' | 'CGOV' |
  'TCEX' | 'ARGUI' | 'DBR'

export const TIPOS_PROCESSO: EnumObjectWithId<TipoProcesso, { key: string, label: string }> = {
  ADS: {
    id: 'ADS',
    key: 'ATOS_DE_ADMISSAO',
    label: 'Atos de admissão',
  },
  APOS: {
    id: 'APOS',
    key: 'APOSENTADORIA',
    label: 'Aposentadoria',
  },
  SOLI: {
    id: 'SOLI',
    key: 'SOLICITACAO',
    label: 'Solicitação',
  },
  CBEX: {
    id: 'CBEX',
    key: 'COBRANÇA_EXECUTIVA',
    label: 'Cobrança executiva',
  },
  TCE: {
    id: 'TCE',
    key: 'TOMADA_DE_CONTAS_ESPECIAL',
    label: 'Tomada de contas especial',
  },
  REPR: {
    id: 'REPR',
    key: 'REPRESENTACAO',
    label: 'Representação',
  },
  PCIV: {
    id: 'PCIV',
    key: 'PENSAO_CIVIL',
    label: 'Pensão civil',
  },
  AJSOL: {
    id: 'AJSOL',
    key: 'ACOES_JUDICIAIS_SOLICITACAO_DE_SUBSIDIOS',
    label: 'Ações judiciais / Solicitação de subsídios ',
  },
  PC: {
    id: 'PC',
    key: 'PRESTACAO_DE_CONTAS',
    label: 'Prestação de contas',
  },
  PMIL: {
    id: 'PMIL',
    key: 'PENSAO_MILITAR',
    label: 'Pensão militar',
  },
  RA: {
    id: 'RA',
    key: 'RELATORIO_DE_AUDITORIA',
    label: 'Relatório de auditoria',
  },
  REFO: {
    id: 'REFO',
    key: 'REFORMA',
    label: 'Reforma',
  },
  DEN: {
    id: 'DEN',
    key: 'DENUNCIA',
    label: 'Denúncia',
  },
  MON: {
    id: 'MON',
    key: 'MONITORAMENTO',
    label: 'Monitoriamento',
  },
  SCT: {
    id: 'SCT',
    key: 'SOLICITACAO_DE_CERTIDAO',
    label: 'Solicitação de certidão',
  },
  TC: {
    id: 'TC',
    key: 'TOMADA_DE_CONTAS',
    label: 'Tomada de contas',
  },
  TCSP: {
    id: 'TCSP',
    key: 'TOMADA_DE_CONTAS_SIMPLIFICADA',
    label: 'Tomada de contas simplificada',
  },
  RL: {
    id: 'RL',
    key: 'RELATORIO_DE_LEVANTAMENTO',
    label: 'Relatório de levantamento',
  },
  PCSP: {
    id: 'PCSP',
    key: 'PRESTACAO_DE_CONTAS_SIMPLIFICADA',
    label: 'Prestação de contas simplificadas',
  },
  ACOM: {
    id: 'ACOM',
    key: 'ACOMPANHAMENTO',
    label: 'Acompanhamento',
  },
  SCN: {
    id: 'SCN',
    key: 'SOLICITACAO_DO_CONGRESSO_NACIONAL',
    label: 'Solicitação do congresso nacional',
  },
  PEEC: {
    id: 'PEEC',
    key: 'PENSAO_ESPECIAL_DE_EX_COMBATENTE',
    label: 'Pensão especial de ex-combatente',
  },
  CONS: {
    id: 'CONS',
    key: 'CONSULTA',
    label: 'Consulta',
  },
  RI: {
    id: 'RI',
    key: 'RELATORIO_DE_INSPENCAO',
    label: 'Relatório de inspeção',
  },
  RACOM: {
    id: 'RACOM',
    key: 'RELATORIO_DE_ACOMPANHAMENTO',
    label: 'Relatório de acompanhamento',
  },
  RMON: {
    id: 'RMON',
    key: 'RELATORIO_DE_MONITORAMENTO',
    label: 'Relatório de monitoramento',
  },
  DES: {
    id: 'DES',
    key: 'DESESTATIZACAO',
    label: 'Desestatização',
  },
  IND: {
    id: 'IND',
    key: 'INDISPONIBILIDADE_DE_BENS',
    label: 'Indisponibilidade de bens',
  },
  PCEX: {
    id: 'PCEX',
    key: 'PRESTACAO_DE_CONTAS_EXTRAORDINARIA',
    label: 'Prestação de contas extraordinária',
  },
  CCTO: {
    id: 'CCTO',
    key: 'CONTESTACAO_DE_COEFICIENTES_DE_TRANSF_OBRIGATORIAS',
    label: 'Contestação de coeficientes de transf. obrigatórias',
  },
  CGOV: {
    id: 'CGOV',
    key: 'CONTAS_DO_PRESIDENTE_DA_REPUBLICA',
    label: 'Contas do presidente da república',
  },
  TCEX: {
    id: 'TCEX',
    key: 'TOMADA_DE_CONTAS_EXTRAORDINARIA',
    label: 'Tomada de contas extraordinária',
  },
  ARGUI: {
    id: 'ARGUI',
    key: 'ARGUICAO_DE_IMPEDIMENTO/SUSPEICAO',
    label: 'Arguição de impedimento/suspeição',
  },
  DBR: {
    id: 'DBR',
    key: 'DECLARACAO_DE_BENS_E_RENDAS',
    label: 'Declaração de bens e rendas',
  },
}
