import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { AvatarStakeholderComponent } from '../AvatarStakeholderComponent'

const useStyles = makeStyles({
  spaceTop: {
    paddingTop: '36px',
  },
})

export interface PerfilEntidadeHeaderProps {
  nomeApresentacao: string
  nomeCompleto: string
  sigla: string
  urlImagem: string
  stakeholderInterno: boolean
  idsStakeholdersRelacionados: string[]
}

export function PerfilEntidadeHeaderComponent(props: PerfilEntidadeHeaderProps) {
  const classes = useStyles()
  return (
    <div className={`${classes.spaceTop} row`}>
      <AvatarStakeholderComponent
        nome={props.sigla}
        nomeCompleto={props.nomeCompleto}
        urlImagem={props.urlImagem}
        stakeholderInterno={props.stakeholderInterno}
      />
      {renderStakeholders()}
    </div>
  )
}


function renderStakeholders() {
  return (
    <div className="col-md-7">
      {/* <div>
        <StakeholdersEnvolvidosContainer
          stakeholdersIds={idsStakeholdersRelacionados || []}
          tituloAutoridadesTcu="Autoridades do TCU"
          tituloStakeholders="Atores Relacionados"
        />
      </div> */}
    </div>
  )
}
