import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import logo from '../../../assets/logo_2.png'
import { BuscaPrincipalContainer } from '../../containers/busca/BuscaPrincipalContainer'
import { LoginUserContainer } from '../../containers/comum/LoginUserContainer'
import { ContadorFavoritosContainer } from '../../containers/comum/ContadorFavoritosContainer'

const useStyles = makeStyles({
  nav: {
    boxShadow: '0px 2px 6px #00000026',
    backgroundColor: '#fff',
    padding: '6px 0',
    height: '72px',
    '@media screen and (max-width:580px)': {
      height: 'auto',
    },
  },
  navRow: {
    alignItems: 'center',
  },
  topo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media screen and (max-width:580px)': {
      maxWidth: '100%',
      padding: '0 20px',
    },
  },
  logo: {
    '@media screen and (max-width:580px)': {
      maxWidth: '45px',
      marginRight: '15px',
      overflow: 'hidden',
    },
  },
  busca: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    '@media screen and (max-width:580px)': {
      marginRight: '15px',
    },
  },
  usuario: {
    '@media screen and (max-width:580px)': {
      display: 'none',
    },
  },
  hiddenBuscaPrint: {
    '@media print': {
      display: 'none',
    },
  },
})

export function HeaderComponent() {
  const classes = useStyles()
  return (
    <nav className={`${classes.nav}`}>
      <div className={`container ${classes.topo}`}>
        <div className={`${classes.logo}`}>
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className={classes.busca}>
          <BuscaPrincipalContainer />
        </div>
        <ContadorFavoritosContainer />
        <LoginUserContainer />
      </div>
    </nav>
  )
}
