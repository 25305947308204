import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { recuperarStakeholdersResumidosPorIds } from '../../app/stakeholder/recuperarStakeholdersResumidosPorIds'
import { ResumoStakeholder } from '../../domain/ResumoStakeholder'
import { recuperarStakeholdersResumidosPorTexto } from '../../app/stakeholder/recuperarStakeholdersResumidosPorTexto'


export namespace BuscarStakeholdersResumidosPorIdsEndpoint {
  export type Body = undefined
  export type Params = undefined
  export type Query = {
    ids: string[]
  }
  export type Response = ResumoStakeholder[]
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/stakeholders-resumidos-por-ids',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<Response>) => {
      const { ids } = request.query
      const stakeholdersResumidos = await recuperarStakeholdersResumidosPorIds(ids)
      response.json(stakeholdersResumidos)
    },
  })
}

export namespace BuscarStakeholdersResumidosPorTextoEndpoint {
  export type Body = undefined
  export type Params = undefined
  export type Query = {
    textoLivre: string
    limite: string
  }
  export type Response = ResumoStakeholder[]
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/stakeholders-resumidos-por-texto',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<Response>) => {
      const { textoLivre, limite } = request.query
      const stakeholdersResumidos = await recuperarStakeholdersResumidosPorTexto(textoLivre, parseInt(limite))
      response.json(stakeholdersResumidos)
    },
  })
}
