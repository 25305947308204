import { AxiosResponse } from 'axios'
import QueryString from 'query-string'
import { createAxiosWithAuth } from '../infra/axios/createAxiosWithAuth'
import { RecursoComputacional } from '../infra/security/RecursoComputacional'
import { Ministro } from '../domain/Ministro'

const axiosInstance = createAxiosWithAuth(RecursoComputacional.MINISTRO)

export const ministroApi = {

  getMinistrosPorIds(ids: string[]): Promise<AxiosResponse<Ministro[]>> {
    const queryParamsString = QueryString.stringify({ codigosMinistros: ids })
    const url = `ministros?${queryParamsString}`
    return axiosInstance.get(url)
  },

}

