import { AuthParams } from 'bff/src/main/infra/security/AuthParams'
import { ObterTokenJwtPeloTokenPortalTcuEndpoint } from 'bff/src/main/controllers/auth/authEndpoints'
import { defineActionCreators } from '../../infra/libs/redux-hardtyped/defineActionCreators'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'


export const authActionCreators = defineActionCreators('auth', {
  logado: (authParams: AuthParams) => ({ authParams }),
})

export const authThunkCreators = {

  gerarTokenJwt: (tokenPortalTcu: string) => createBffRequestThunk({
    request: {
      endpoint: ObterTokenJwtPeloTokenPortalTcuEndpoint.definition,
      body: { tokenPortalTcu },
    },
    actions: {
      success: response => authActionCreators.logado(response.data),
    },
  }),

}
