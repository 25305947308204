import React from 'react'
import { AuthGate } from './components/containers/infra/AuthGate'
import { LgpdGate } from './components/containers/lgpd/LgpdGate'

export interface GatesProps {
  children: any
}

export const Gates = ({ children }: GatesProps) => (
  <AuthGate>
    <LgpdGate>
      {children}
    </LgpdGate>
  </AuthGate>
)
