import React from 'react'

export function IconPlusComponent({ fill = '#7E7E7E', height = '19', width = '19' }: { fill?: string, height?: string, width?: string }) {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15.5" fill="white" stroke="#C4C4C4" />
      <path d="M22.4001 17.6016H17.6001V22.4016H14.4001V17.6016H9.6001V14.4016H14.4001V9.60156H17.6001V14.4016H22.4001V17.6016Z" fill={fill} />
    </svg>

  )
}
