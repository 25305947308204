import { createOnBoardingStepContainer } from './createOnBoardingStepContainer'


const name = 'TELA_CENARIO'
const urlRegex = /^\/cenarios\/\d+\/?$/
const totalSteps = 1

export const OnBoardingTelaCenario = {

  Step1: createOnBoardingStepContainer({
    name,
    urlRegex,
    totalSteps,
    currentStep: 1,
    position: 'left',
    title: 'Configure, edite ou arquive o cenário',
    text: 'Altere os parâmetros de busca do cenário, configure a apresentação do conteúdo na tela Início ou deixe de acompanhar o cenário ao arquivá-lo.',
    labelButtonNext: 'Entendi',
  }),

}
