import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { FatoTelaInicial } from 'bff/src/main/domain/FatoTelaInicial'
import { ResumoFatoComponent } from './ResumoFatoComponent'
import { IconCenarioComponent } from '../icons/IconCenarioComponent'


const useStyles = makeStyles({
  divisor: {
    marginTop: '20px',
    marginBottom: '36px',
  },
  boxTagsNomeCenario: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#2f5dc9',
  },
  tagNome: {
    marginRight: '16px',
  },
  nome: {
    marginLeft: '7px',
  },
  fatoBox: {
    marginBottom: '56px',
  },
})

export function ListaFatosCenarioTelaInicialComponent({ fato, nomesCenarios }: { fato: FatoTelaInicial, nomesCenarios?: string[] }) {
  const classes = useStyles()
  return (
    <div>
      <ResumoFatoComponent key={fato.id} resumoFato={fato} className={classes.fatoBox} isFavorito={fato.isFavorito} />
      {renderNomes(classes, nomesCenarios)}
    </div>
  )
}

function renderNomes(classes, nomes?: string[]) {
  if (!nomes) {
    return null
  }

  const nomesValidos = nomes.filter(nome => nome)
  return (
    nomesValidos.length < 1
      ? null
      : (
        <div>
          <div className={classes.boxTagsNomeCenario}>
            {
              nomesValidos.map(nome => (
                <span key={nome} className={classes.tagNome}>
                  <IconCenarioComponent fill="#2f5dc9" />
                  <span className={classes.nome}>{nome}</span>
                </span>
              ))
            }
          </div>
          <hr className={classes.divisor} />
        </div>
      )
  )
}
