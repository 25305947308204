import { createSelector } from 'reselect'
import jwtDecode from 'jwt-decode'
import { DecodedToken } from 'bff/src/main/infra/security/AuthParams'
import { RootState } from '../root/RootState'


export const sessionSelector = (state: RootState) => state.session

export const authParamsSelector = createSelector(sessionSelector, session => session?.authParams)

export const tokenJwtSelector = createSelector(authParamsSelector, authParams => authParams?.tokenJwt)

export const decodedTokenJwtSelector = createSelector(tokenJwtSelector, tokenJwt => (tokenJwt ? jwtDecode<DecodedToken>(tokenJwt) : undefined))

export const userNameSelector = createSelector(decodedTokenJwtSelector, docodedToken => docodedToken?.nus)

export const userLoginSelector = createSelector(decodedTokenJwtSelector, docodedToken => docodedToken?.sub)
