import { defineEndpoint } from '../../infra/libs/bff-rpc/defineEndpoint'
import { ExpressRequest, ExpressResponse } from '../../infra/libs/bff-rpc/ExpressRequestHandler'
import { ResumoFato } from '../../domain/ResumoFato'
import { mapaApi } from '../../api/mapaApi'
import { recuperarFatosResumidosDaListaLeitura } from '../../app/fato/recuperarFatosResumidosDaListaLeitura'

export namespace RecuperarFatosDaListaDeLeituraEndpoint {
  export type Body = undefined
  export type Params = undefined
  export type Query = undefined
  export type ResponseBody = ResumoFato[]
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/lista-leitura',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { data: listaLeitura } = await mapaApi.obterMinhaListaLeitura()
      const fatos = await recuperarFatosResumidosDaListaLeitura(listaLeitura)
      response.json(fatos)
    },
  })
}

export namespace VerificarSeFatoEstaNaListaDeLeituraEndpoint {
  export type Body = undefined
  export type Params = { fatoId: string }
  export type Query = undefined
  export type ResponseBody = boolean
  export const definition = defineEndpoint({
    httpMethod: 'GET',
    url: '/fato-consta-na-lista-leitura/:fatoId',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { fatoId } = request.params
      const { data } = await mapaApi.verificarSeFatoEstaNaListaLeitura(fatoId)
      response.json(data)
    },
  })
}

export namespace AdicionarNaListaDeLeituraEndpoint {
  export type Body = { fatoId: string }
  export type Params = undefined
  export type Query = undefined
  export type ResponseBody = string[]
  export const definition = defineEndpoint({
    httpMethod: 'POST',
    url: '/lista-leitura/adicionar',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { fatoId } = request.body
      const { data } = await mapaApi.adicionarItemListaLeitura(fatoId)
      response.json(data)
    },
  })
}


export namespace RemoverDaListaDeLeituraEndpoint {
  export type Body = { fatoId: string }
  export type Params = undefined
  export type Query = undefined
  export type ResponseBody = string[]
  export const definition = defineEndpoint({
    httpMethod: 'POST',
    url: '/lista-leitura/remover',
    handler: async (request: ExpressRequest<Body, Params, Query>, response: ExpressResponse<ResponseBody>) => {
      const { fatoId } = request.body
      const { data } = await mapaApi.removerItemListaLeitura(fatoId)
      response.json(data)
    },
  })
}
