import { montarQueryRecuperarFatos, montarQueryRecuperarContadorFatos } from './queries/montarQueryRecuperarFatos'
import { MontarQueryRecuperarFatosPayload } from './queries/MontarQueryRecuperarFatosPayload'
import { searchOnIndex } from '../infra/elastic/searchOnIndex'
import { montarSubqueryDeEventosSigilososBaseadaEmPerfil } from './queries/montarSubqueryDeEventosSigilososBaseadaEmPerfil'
import { countOnIndex } from '../infra/elastic/countOnIndex'
import { MontarQueryRecuperarContadorFatosPayload } from './queries/MontarQueryRecuperarContadorFatosPayload'
import { StakeholdersComIdEAlias } from '../app/stakeholder/recuperarAliasStakeholderPorId'


const search = body => searchOnIndex({ body, index: 'mapa-fatos' })
const count = body => countOnIndex({ body, index: 'mapa-fatos' })

export const fatosApi = {

  buscarFatosPorId(id: string | string[]) {
    return search({
      query: {
        bool: {
          must: [
            { ids: { values: id } },
            { ...montarSubqueryDeEventosSigilososBaseadaEmPerfil() },
          ],
        },
      },
    })
  },

  buscarTotalDivididoPorTipo() {
    return search({
      size: 0,
      aggs: {
        count_by_type: {
          terms: {
            field: 'tipo_fato',
          },
        },
      },
      query: {
        ...montarSubqueryDeEventosSigilososBaseadaEmPerfil(),
      },
    })
  },

  buscarFatos(
    payload: MontarQueryRecuperarFatosPayload,
    atores: StakeholdersComIdEAlias[],
    entidades: StakeholdersComIdEAlias[],
  ) {
    return search(montarQueryRecuperarFatos(payload, atores, entidades))
  },

  buscarContadorFatosPorFiltro(
    payload: MontarQueryRecuperarContadorFatosPayload,
    atores: StakeholdersComIdEAlias[],
    entidades: StakeholdersComIdEAlias[],
  ) {
    return count(montarQueryRecuperarContadorFatos(payload, atores, entidades))
  },

  buscarFatosPorProcessoRelacionado(numeroProcesso: string) {
    return search({
      query: {
        bool: {
          must: [
            { match_phrase: { processos_tcu_relacionados: numeroProcesso } },
            { ...montarSubqueryDeEventosSigilososBaseadaEmPerfil() },
          ],
        },
      },
    })
  },

}
