import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { TitleComponent } from '../ui-components/head/TitleComponent'
import { VisualizarFichaContainer } from '../containers/ficha/VisualizarFichaContainer'


const useStyles = makeStyles({
  root: {
    marginTop: '15px',
  },
})

export function VisualizarFichaView() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <TitleComponent title="Visualizar ficha" />
      <VisualizarFichaContainer />
    </div>
  )
}
