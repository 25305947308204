import { AceitarLgpdEndpoint, ChecarSeUsuarioAceitouLgpdEndpoint } from 'bff/src/main/controllers/lgpd/lgpdEndpoints'
import { alertActionCreators } from './alertActions'
import { createBffRequestThunk } from '../../infra/libs/redux-bff-rpc-actions/createBffRequestThunk'

export const lgpdThunkCreators = {

  verificarSeUsuarioLogadoAceitouLgpd: () => createBffRequestThunk({
    request: {
      endpoint: ChecarSeUsuarioAceitouLgpdEndpoint.definition,
    },
    actions: {
      error: () => alertActionCreators.showError('Ocorreu um erro inesperado. Não foi possível verificar o aceite dos termos da LGPD.'),
    },
  }),

  aceitarLgpd: () => createBffRequestThunk({
    request: {
      endpoint: AceitarLgpdEndpoint.definition,
    },
    actions: {
      error: () => alertActionCreators.showError('Ocorreu um erro inesperado. Não foi possível registrar o aceite dos termos da LGPD.'),
    },
  }),

}
